import {
	FETCH_CREATE_TODO_REQUEST,
	FETCH_CREATE_TODO_SUCCESS,
	FETCH_CREATE_TODO_FAILURE,
} from '../../types/todo';

const todoCreateReducer = (
	state = {
		data: null,
		error: null,
		isLoading: false,
	},
	action,
) => {
	switch (action.type) {
		case FETCH_CREATE_TODO_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_CREATE_TODO_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_CREATE_TODO_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default todoCreateReducer;
