import {
	FETCH_CATEGORY_DELETE_REQUEST,
	FETCH_CATEGORY_DELETE_SUCCESS,
	FETCH_CATEGORY_DELETE_FAILURE,
} from '../../types/categories';
import { deleteApi } from '../api';

export const fetchDeleteCategoryRequest = () => ({
	type: FETCH_CATEGORY_DELETE_REQUEST,
});

export const fetchDeleteCategorySuccess = (data) => ({
	type: FETCH_CATEGORY_DELETE_SUCCESS,
	data: data,
});

export const fetchDeleteCategoryFailure = (error) => ({
	type: FETCH_CATEGORY_DELETE_FAILURE,
	error,
});

export const fetchDeleteCategory = (id) => (dispatch, getState) => {
	dispatch(fetchDeleteCategoryRequest());
	return deleteApi(`bshift/api/categories/${id}`)
		.then((data) => {
			dispatch(fetchDeleteCategorySuccess(data));
		})
		.catch((error) => {
			dispatch(fetchDeleteCategoryFailure(error));
		});
};
