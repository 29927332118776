import React, { Component } from "react";
import moment from "moment";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Formik } from "formik";
import { displayFormErrors } from "../../../modules/validation";
import { isValidArray } from "../../../modules/utils";
import { ChromePicker } from "react-color";

export default class EditShift extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getInitialValue = () => {
    const { editShiftData, usersData } = this.props;
    const { restaurantsDataList } = this.props;

    const location = restaurantsDataList.find(
      (rest) => rest.id === editShiftData?.restaurant
    );
    return {
      shiftDate:
        moment(editShiftData?.shift_time?.created).format("YYYY-MM-DD") || "",
      employee: editShiftData?.user?.full_name || "",
      location: location?.id || "",
      shiftBreak: editShiftData?.shift_break || "",
      color: editShiftData?.color || "",
      note: editShiftData?.note || "",
      shift_time_id: editShiftData?.shift_time?.id,
    };
  };

  handleChangeColor = (setFieldValue, value) => {
    setFieldValue("color", value);
  };

  handleSubmit = (values) => {
    const { editScheduleShift, editShiftData } = this.props;
    const passData = {
      color: values?.color,
      created_by: null,
      dates: [editShiftData?.date] || "",
      date: editShiftData?.date || "",
      id: editShiftData?.id || "",
      modified_by: null,
      note: values?.note || "",
      restaurant_id: parseInt(values?.location) || "",
      // shift_break: values?.shiftBreak || "",
      shift_time_id: values?.shift_time_id || "",
      user_id: editShiftData?.user?.id || "",
    };
    if (values?.shift_break) {
      passData["shift_break"] = values?.shiftBreak;
    }
    editScheduleShift(passData);
  };

  render() {
    const {
      isOpen,
      toggle,
      editShiftData,
      restaurantsDataList,
      shiftTimeList,
      shiftDayPartList,
    } = this.props;

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={toggle}>Edit Shift</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={this.getInitialValue()}
            // validationSchema={addPositionValidation}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              submitCount,
              setFieldValue,
            }) => {
              const showError = (key) =>
                displayFormErrors(key, errors, touched, submitCount);
              return (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Shift Date</label>
                      <input
                        type="text"
                        value={values.shiftDate}
                        name="shiftDate"
                        disabled={true}
                        // onChange={handleChange}
                      />
                      {/* {showError('name')} */}
                    </div>
                    <div className="col-md-6">
                      <label>Employee</label>
                      <input
                        type="text"
                        value={values.employee}
                        name="employee"
                        disabled={true}
                        // onChange={handleChange}
                      />
                      {/* {showError('name')} */}
                    </div>
                    <div className="col-md-12">
                      <label>Select Location</label>
                      <select
                        name="location"
                        className="form-control"
                        onChange={handleChange}
                        value={values.location}
                        // placeholder="Select Location"
                      >
                        {isValidArray(restaurantsDataList) &&
                          restaurantsDataList?.map((d) => {
                            return <option value={d.id}>{d.title}</option>;
                          })}
                      </select>
                    </div>
                    <div className="col-md-12">
                      <label>Shift Time</label>
                      <select
                        name="shift_time_id"
                        className="form-control"
                        onChange={handleChange}
                        value={values.shift_time_id}
                        // placeholder="Select Location"
                      >
                        {/* {isValidArray(restaurantsDataList) &&
													restaurantsDataList?.map((d) => {
														return <option value={d.id}>{d.title}</option>;
													})} */}
                        {isValidArray(shiftTimeList) &&
                          shiftTimeList
                            ?.filter(
                              (data) => data.group === editShiftData?.group
                            )
                            .map((d) => {
                              return (
                                <option value={d.id}>
                                  {d.day_part === 1
                                    ? "Lunch"
                                    : d.day_part === 2
                                    ? "Dinner"
                                    : ""}
                                  - [{d.time_start} - {d.time_end}] {d.note}
                                </option>
                              );
                            })}
                      </select>
                    </div>

                    <div className="col-md-6">
                      <label>Shift Break</label>
                      <select
                        name="shiftBreak"
                        className="form-control"
                        onChange={handleChange}
                        value={values.shiftBreak}
                        // placeholder="Select Shift break"
                      >
                        <option value={0}>0</option>
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                        <option value={45}>45</option>
                        <option value={60}>60</option>
                      </select>
                    </div>
                    <div className="col-md-12">
                      <label>Color</label>
                      <input
                        type="text"
                        value={values.color}
                        name="color"
                        readOnly
                      />
                      <ChromePicker
                        color={values.color}
                        onChangeComplete={(e) =>
                          this.handleChangeColor(setFieldValue, e?.hex)
                        }
                      />
                    </div>
                    <div className="col-md-12">
                      <label>Shift Note</label>
                      <input
                        type="text"
                        value={values.note}
                        name="note"
                        onChange={handleChange}
                      />
                      {/* {showError('name')} */}
                    </div>
                    <ModalFooter>
                      <div className="record_btn" onClick={toggle}>
                        <button>CANCEL</button>
                      </div>
                      <div className="record_btn" onClick={handleSubmit}>
                        <button>SAVE</button>{" "}
                      </div>
                    </ModalFooter>
                  </div>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}
