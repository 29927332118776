export const FETCH_CALENDAR_EVENT_REQUEST = 'FETCH_CALENDAR_EVENT_REQUEST';
export const FETCH_CALENDAR_EVENT_FAILURE = 'FETCH_CALENDAR_EVENT_FAILURE';
export const FETCH_CALENDAR_EVENT_SUCCESS = 'FETCH_CALENDAR_EVENT_SUCCESS';

export const FETCH_CALENDAR_EVENT_KIND_REQUEST =
	'FETCH_CALENDAR_EVENT_KIND_REQUEST';
export const FETCH_CALENDAR_EVENT_KIND_FAILURE =
	'FETCH_CALENDAR_EVENT_KIND_FAILURE';
export const FETCH_CALENDAR_EVENT_KIND_SUCCESS =
	'FETCH_CALENDAR_EVENT_KIND_SUCCESS';

export const CREATE_CALENDAR_EVENT_REQUEST = 'CREATE_CALENDAR_EVENT_REQUEST';
export const CREATE_CALENDAR_EVENT_FAILURE = 'CREATE_CALENDAR_EVENT_FAILURE';
export const CREATE_CALENDAR_EVENT_SUCCESS = 'CREATE_CALENDAR_EVENT_SUCCESS';

export const DELETE_CALENDAR_EVENT_REQUEST = 'DELETE_CALENDAR_EVENT_REQUEST';
export const DELETE_CALENDAR_EVENT_FAILURE = 'DELETE_CALENDAR_EVENT_FAILURE';
export const DELETE_CALENDAR_EVENT_SUCCESS = 'DELETE_CALENDAR_EVENT_SUCCESS';
