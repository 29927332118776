import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersDropdown } from "../../actions/users/userDropdownData";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchChatList } from "../../actions/chat/userChatList";
import ChatBox from "./ChatBox";
import Home from "./Home";
import userImgPlaceHolder from "../../assets/css/images/user_placeholder.png";
import Loader from "react-loader-spinner";
import { fetchChatMessages } from "../../actions/chat/ChatMessages";
import { onMessageListener } from "../../firebase";
import { chatUserObjectAction } from "../../actions/deviceTokenAction";

const ChatLayout = (props) => {
  const dispatch = useDispatch();
  const [toggleChat, settoggle] = useState(0);
  const [currentUser, setcurrentUser] = useState();
  const [userSearch, setuserSearch] = useState();
  const [groupId, setGroupId] = useState(false);

  const loggedinid = localStorage.getItem("loggedUserId");
  const {
    usersDropdownData,
    restaurantsDataList,
    chatListReducer,
    chatUserObject,
  } = useSelector((state) => ({
    usersDropdownData: state.usersDropdownData.data?.results,
    restaurantsDataList: state.restaurantsData?.data?.results || [],
    //ChatMessages: state.chatMessages?.data,
    chatListReducer: state.chatListReducer?.data?.data || [],
    chatUserObject: state.chatUserObject?.obj,
  }));

  const chatListReducerLoading = useSelector(
    (state) => state?.chatListReducer?.isLoading
  );

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        dispatch(fetchChatList());
        if (payload?.data?.sender_id === chatUserObject) {
          if (payload?.data?.sender_id) {
            dispatch(fetchChatMessages(payload?.data?.sender_id, 0));
          } else if (payload?.data?.group_id) {
            dispatch(fetchChatMessages(payload?.data?.group_id, 1));
          }
        }
      })
      .catch((err) => console.log("failed: ", err));
  });

  useEffect(() => {
    dispatch(fetchUsersDropdown());
    dispatch(fetchRestaurants());
    dispatch(fetchChatList());
  }, []);

  const openChat = (data) => {
    dispatch(chatUserObjectAction(data?.user_id));
    setcurrentUser(data);
    dispatch(fetchChatList());
    settoggle(0);
  };

  useEffect(() => {
    if (currentUser?.first_name) {
      dispatch(fetchChatMessages(currentUser?.user_id, 0));
    } else if (currentUser?.full_name) {
      dispatch(fetchChatMessages(currentUser?.id, 0));
    } else if (currentUser?.title) {
      dispatch(fetchChatMessages(currentUser?.id, 1));
    } else {
    }
  }, [currentUser]);

  return (
    <>
      <div className="main_box">
        <div className="middle_contain chat_height">
          <div className="pull_left full_section_details">
            <div className="main_container">
              <div className="wrapper">
                <div
                  className="container-fluid box"
                  style={{ background: "none" }}
                >
                  <main
                    className="content"
                    style={{ border: "1px solid #ddd" }}
                  >
                    <div className="p-0">
                      {/* <h1 className="h3 mb-3">Messages</h1> */}
                      <div className="card" style={{ border: "none" }}>
                        <div className="row g-0 chatbox chatboxheight">
                          <div
                            className="col-12 col-lg-5 col-xl-3 chat-sidebar-name"
                            style={{ height: "inherit" }}
                          >
                            <div className="chat-side">
                              <div className="px-4 d-none d-md-block">
                                <div className="d-flex align-items-center">
                                  <div className="flex-grow-1">
                                    <input
                                      type="text"
                                      className="form-control my-3"
                                      placeholder="Search..."
                                      onChange={(e) =>
                                        setuserSearch(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="w-10/12 my-3 mx-auto d-flex justify-between justify-content-between w-75  custom_chat_buttons">
                                <button
                                  className="btn mr-2 custom_background_color custom_chat_button"
                                  onClick={() => settoggle(0)}
                                >
                                  Chat
                                </button>
                                <button
                                  className="btn mr-2 custom_background_color custom_chat_button"
                                  onClick={() => settoggle(1)}
                                >
                                  Groups
                                </button>
                                {/* )} */}
                                <button
                                  className="btn ml-2 custom_background_color custom_chat_button"
                                  onClick={() => settoggle(2)}
                                >
                                  New
                                </button>
                              </div>

                              <div className="m-auto w-auto chat_user">
                                {chatListReducerLoading && (
                                  <Loader
                                    type="TailSpin"
                                    color="#38425B"
                                    // color="#2196f3"
                                    height={50}
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  />
                                )}
                                {chatListReducer.length === 0 &&
                                  !chatListReducerLoading && (
                                    <h4 className="px-3 py-1 text-center">
                                      No Recent Chats Available
                                    </h4>
                                  )}

                                {/* Chat user list */}
                                {toggleChat === 0
                                  ? chatListReducer
                                      .filter((item) => {
                                        if (!userSearch) {
                                          return item.user_id != loggedinid;
                                        } else if (
                                          item.full_name
                                            ?.toLowerCase()
                                            .includes(userSearch)
                                        ) {
                                          return item.user_id != loggedinid;
                                        }
                                      })
                                      .map((d, index) => (
                                        <button
                                          key={index}
                                          className="list-group-item list-group-item-action border-0 position-static chat-border"
                                          onClick={() => {
                                            openChat(d);
                                            setGroupId(false);
                                          }}
                                        >
                                          <div className="flex-grow-1 ml-3 d-flex ">
                                            <img
                                              src={
                                                d.avatar
                                                  ? d.avatar
                                                  : userImgPlaceHolder
                                              }
                                              className="rounded-circle mr-1 w-14 h-14 mr-3"
                                              style={{
                                                height: "60px",
                                                width: "60px",
                                              }}
                                            />
                                            <div className="w-75 pt-1">
                                              <span
                                                style={{
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {d.first_name +
                                                  " " +
                                                  d.last_name}
                                              </span>
                                              <div className="small">
                                                <span className="fas fa-circle chat-online"></span>{" "}
                                                {d.message.length < 30
                                                  ? d.message
                                                  : d.message.substring(0, 29) +
                                                    "..."}
                                              </div>
                                            </div>
                                            <span className="text-center  d-flex align-items-center ">
                                              {d.count_unread !== 0 && (
                                                <span className="badge badge-info">
                                                  {d.count_unread}
                                                </span>
                                              )}
                                            </span>
                                          </div>
                                        </button>
                                      ))
                                  : toggleChat === 1
                                  ? restaurantsDataList
                                      .filter((item) => {
                                        if (!userSearch) {
                                          return item;
                                        } else if (
                                          item.title
                                            ?.toLowerCase()
                                            .includes(userSearch)
                                        ) {
                                          return item;
                                        }
                                      })
                                      .map((d, index) => (
                                        <button
                                          key={index}
                                          className="list-group-item list-group-item-action border-0"
                                          onClick={() => {
                                            openChat(d);
                                            setGroupId(true);
                                          }}
                                        >
                                          <div className="d-flex align-items-center">
                                            {/* <img src="https://bootdey.com/img/Content/avatar/avatar5.png" className="rounded-circle mr-1 w-14 h-14" alt="Vanessa Tucker" /> */}
                                            <div className="flex-grow-1 ml-3 text-lg font-extralight">
                                              {d.title}
                                            </div>
                                          </div>
                                        </button>
                                      ))
                                  : usersDropdownData
                                      ?.filter((item) => {
                                        if (!userSearch) {
                                          return item?.id != loggedinid;
                                        } else if (
                                          item.full_name
                                            ?.toLowerCase()
                                            .includes(userSearch)
                                        ) {
                                          return item?.id != loggedinid;
                                        }
                                      })
                                      .map((d, index) => (
                                        <button
                                          key={index}
                                          type="button"
                                          className="list-group-item list-group-item-action border-0"
                                          onClick={() => {
                                            openChat(d);
                                            setGroupId(false);
                                          }}
                                        >
                                          <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 ml-3 text-lg font-extralight">
                                              {d.full_name}
                                            </div>
                                          </div>
                                        </button>
                                      ))}
                              </div>
                            </div>
                          </div>
                          {currentUser ? (
                            <ChatBox
                              userObject={currentUser}
                              groupId={groupId}
                            />
                          ) : (
                            <Home />
                          )}
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatLayout;
