import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import LaddaButton from "@zumper/react-ladda";

import { fetchLoginAccessToken } from "../../actions/loginAccessToken/loginAccessToken";
import { fetchProfile } from "../../actions/profile/profile";
import { requestForToken } from "../../firebase";
import { setDeviceTokenAction } from "../../actions/deviceTokenAction";

// import logo from "../../assets/css/images/logo.png";
import {
  displayFormErrors,
  loginFormValidation,
} from "../../modules/validation";
import { getPermission } from "../../modules/utils";

import { Link } from "react-router-dom";
import logo from "../../assets/css/images/logo.svg";
import IOS from "../../assets/css/images/IOS.png";
import { toast } from "react-toastify";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginDisabled: false,
    };
  }

  componentDidMount() {
    // const token = requestForToken(this.props.setDeviceTokenAction);
    // console.log("My token : ", token);
    const loginAccessToken = localStorage.getItem("loginAccessToken");
    if (loginAccessToken) {
      this.props.history.push("/dashboard");
      this.getInitialPath();
    }
  }

  onHandleSubmit = (values) => {
    const { history } = this.props;
    const loginFunction = (currentToken) => {
      const apiVal = {
        password: values.password,
        username: values.username,
        device_token: currentToken,
        device_type: "web",
      };
      if (!currentToken) {
        toast.info("Notification permission is not enabled!!!");
      }
      this.props
        .fetchLoginAccessToken(apiVal)
        .then(() => {
          this.setState({ logoutDisabled: true });
          this.props.fetchProfile().then(() => {
            history.push("/dashboard");
          });
        })
        .catch((err) => {
          this.setState({ logoutDisabled: false });
        });
    };

    const requestToken = requestForToken(
      this.props.setDeviceTokenAction,
      loginFunction,
      history
    );
  };

  getInitialPath = () => {
    const { profileData } = this.props;
    if (getPermission("bshift.bs_can_view_calendar", profileData))
      return "/calendar";
    else if (getPermission("users.bs_can_view_employees", profileData))
      return "/mainInfo";
    else if (getPermission("bshift.bs_view_log_record", profileData))
      return "/managers-log";
    else if (getPermission("bshift.bs_can_view_schedule", profileData))
      return "/schedule";
  };

  render() {
    const { loginAccessTokenLoading, profileDataLoading } = this.props;

    return (
      <div>
        <div className="login">
          <div className="l_wrapper">
            <div className="l_left   pb-5">
              <div className="l_left_inner">
                <div className="login_heading">
                  <h1 style={{ fontFamily: "unset" }}>Welcome To</h1>
                  <span>
                    <div className="l_logo ">
                      <img
                        src={logo}
                        alt="logo"
                        className="login_custom_logo"
                      />
                      {/* <h1>THE BUSINESS SHIFT</h1> */}
                    </div>
                  </span>
                </div>

                <Formik
                  initialValues={{
                    username: "",
                    password: "",
                  }}
                  validationSchema={loginFormValidation}
                  onSubmit={this.onHandleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    submitCount,
                  }) => {
                    const showError = (key) =>
                      displayFormErrors(key, errors, touched, submitCount);
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="login_form">
                          {/* <label>Login to your account</label> */}
                          <div className="l_user">
                            <input
                              name="username"
                              type="text"
                              placeholder="Enter your Email"
                              onChange={handleChange}
                              value={values.username}
                              className="login_input"
                              autoComplete="off"
                            />
                            {showError("username")}
                          </div>

                          <div className="l_pwd" style={{ color: "white" }}>
                            <input
                              name="password"
                              type="password"
                              placeholder="Enter your password"
                              onChange={handleChange}
                              value={values.password}
                              className="login_input"
                              autoComplete="off"
                            />
                            {showError("password")}
                          </div>

                          <div className="forgot_password my-3">
                            <Link
                              to="/forgot-password"
                              className="forgot_password_link"
                            >
                              Forgot Password?
                            </Link>
                          </div>

                          <div className="login_btn">
                            <LaddaButton
                              type="submit"
                              className="btn"
                              loading={
                                loginAccessTokenLoading || profileDataLoading
                              }
                              // style={SLIDE_UP}
                              onClick={handleSubmit}
                              spinnerSize={20}
                              disabled={this.state.loginDisabled}
                            >
                              Login
                            </LaddaButton>
                          </div>
                          {/* <div className="l_btm_btn">
														<button type="submit">Submit</button>
													</div> */}

                          <div className="row my-4 px-4 justify-content-center">
                            {/* <div className="col-6"> */}
                            <a
                              className="apple_store_button"
                              href="https://apps.apple.com/in/app/the-business-shift/id6446923019"
                              target="blank"
                            >
                              <img src={IOS} alt="IOS app" />
                              {/* </div> */}
                            </a>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginAccessTokenLoading: state.loginAccessToken.isLoading,
    loginAccessToken: state.loginAccessToken.data,

    profileDataLoading: state.profileData.isLoading,
    profileData: state.profileData.data,

    deviceToken: state.deviceToken,
  };
};
const mapDispatchToProps = {
  fetchLoginAccessToken,
  fetchProfile,
  setDeviceTokenAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);
