import {
  FETCH_LOG_REPORT_REQUEST,
  FETCH_LOG_REPORT_SUCCESS,
  FETCH_LOG_REPORT_FAILURE,
} from "../../types/managerLogs";
import { getApi, getBlobApi } from "../api";
import { toast } from "react-toastify";

export const fetchLogReportRequest = () => ({
  type: FETCH_LOG_REPORT_REQUEST,
});

export const fetchLogReportSuccess = (data) => ({
  type: FETCH_LOG_REPORT_SUCCESS,
  data: data,
});

export const fetchLogReportFailure = (error) => ({
  type: FETCH_LOG_REPORT_FAILURE,
  error,
});

export const fetchLogReport = (
  restaurant,
  from_date,
  to_date,
  period,
  action,
  onDate
) => (dispatch, getState) => {
  dispatch(fetchLogReportRequest());

  let queryParams = [];

  if (period == "range") {
    queryParams = [
      ...queryParams,
      `period=${period}`,
      `from_date=${from_date}`,
      `to_date=${to_date}`,
      `restaurant=${restaurant}`,
      `action=${action}`,
    ];
  }

  if (period == "date") {
    queryParams = [
      ...queryParams,
      `period=${period}`,
      `to_date=${onDate}`,
      `restaurant=${restaurant}`,
      `action=${action}`,
    ];
  }

  if (period == "today") {
    queryParams = [
      ...queryParams,
      `period=${period}`,
      `restaurant=${restaurant}`,
      `action=${action}`,
    ];
  }

  if (period == "week") {
    queryParams = [
      ...queryParams,
      `period=${period}`,
      `restaurant=${restaurant}`,
      `action=${action}`,
    ];
  }
  if (period == "month") {
    queryParams = [
      ...queryParams,
      `period=${period}`,
      `restaurant=${restaurant}`,
      `action=${action}`,
    ];
  }
  if (period == "year") {
    queryParams = [
      ...queryParams,
      `period=${period}`,
      `restaurant=${restaurant}`,
      `action=${action}`,
    ];
  }
  return getBlobApi(`bshift/api/logreport?${queryParams.join("&")}`)
    .then((data) => {
      console.log("Api data", data.data);
      dispatch(fetchLogReportSuccess(data));

      if (action === "download") {
        const blob = new Blob([data.data], {
          type: "application/pdf",
        });
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "report.pdf";
        alink.click();
      } else if (action === "email") {
        toast.success("Email sent");
      }
    })
    .catch((error) => {
      dispatch(fetchLogReportFailure(error));
      toast.error("Something went wrong with preparing the report");
    });
  // return getApi(`bshift/api/logreport?${queryParams.join("&")}`)
  //   .then((data) => {
  //     dispatch(fetchLogReportSuccess(data));
  //   })
  //   .catch((error) => {
  //     dispatch(fetchLogReportFailure(error));
  //   });
};
