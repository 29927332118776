import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default class WhetherModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textValue: "",
      whether: [],
    };
  }

  componentDidMount() {
    const { passValue, modalType } = this.props;
    if (modalType === "whether" && passValue) {
      this.setState({ whether: passValue.split(",") });
    }
  }

  inputHandler = (e) => {
    this.setState({
      textValue: e.target.value,
    });
  };

  whetherInputHandler = (e) => {
    this.setState({
      whether: e.target.value,
    });
  };

  updateLogData = () => {
    const { textValue, whether } = this.state;
    const {
      modalType,
      updateLogRecord,
      log_id,
      restaurantId,
      fetchLogRecordData,
    } = this.props;
    let formData = new FormData();

    formData.append("id", log_id);
    if (modalType === "open") {
      formData.append("loc_open", textValue);
    } else if (modalType === "mid") {
      formData.append("loc_mid", textValue);
    } else if (modalType === "close") {
      formData.append("loc_close", textValue);
    } else if (modalType === "whether") {
      formData.append("loc_weather", whether);
    }
    formData.append("restaurant", restaurantId);
    formData.append("user", parseInt(localStorage.getItem("loggedUserId")));

    updateLogRecord(log_id, formData);
  };

  whetherHandler = (title = "") => {
    this.setState({ whether: [...this.state.whether, title] });
  };

  render() {
    const { isOpen, toggle, modalType, passValue, whetherData } = this.props;
    const { textValue, whether } = this.state;

    if (modalType === "whether") {
      return (
        <div className="row">
          <div className="col-md-12">
            <Modal
              isOpen={isOpen}
              className="whether_model modal-dialog-centered"
            >
              <ModalHeader toggle={toggle}>Weather</ModalHeader>
              <ModalBody>
                <div className="col-md-12 whether_input">
                  <input
                    type="text"
                    value={whether}
                    onChange={(e) => this.whetherInputHandler(e)}
                  />
                </div>
                {modalType === "whether" &&
                  whetherData &&
                  whetherData?.results?.map((d) => {
                    return (
                      <div className="record_btn whether_btn">
                        <button
                          onClick={() => this.whetherHandler(d.title)}
                          disabled={whether.includes(d.title)}
                        >
                          {d.title}
                          {", "}
                        </button>
                      </div>
                    );
                  })}
              </ModalBody>
              <ModalFooter>
                <div className="cancle_btn" onClick={toggle}>
                  <button>CANCEL</button>
                </div>
                <div className="record_btn" onClick={this.updateLogData}>
                  <button>SAVE</button>{" "}
                </div>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-md-12">
            <Modal isOpen={isOpen} className="whether_sub_model">
              <ModalHeader toggle={toggle}>
                {modalType === "open"
                  ? "Open"
                  : modalType === "close"
                  ? "Close"
                  : modalType === "mid"
                  ? "Mid"
                  : ""}
              </ModalHeader>
              <ModalBody>
                <div className="col-md-12">
                  <input
                    type="text"
                    defaultValue={passValue}
                    onChange={(e) => this.inputHandler(e)}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="cancle_btn" onClick={toggle}>
                  <button>CANCEL</button>
                </div>
                <div className="record_btn" onClick={this.updateLogData}>
                  <button>SAVE</button>{" "}
                </div>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      );
    }
  }
}
