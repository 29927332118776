import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Gallery from "react-grid-gallery";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchNewFoodGallery } from "../../actions/foodGallery/newFoodGallery";

import { isValidArray } from "../../modules/utils";

import Select from "react-select";
import moment from "moment";

const NewFoodGallery = () => {
  const dispatch = useDispatch();
  // const history = useHistory();

  const {
    restaurantsData,
    restaurantsDataLoading,
    foodGalleryData,
    foodGalleryDataLoading,
  } = useSelector((state) => ({
    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,
    foodGalleryData: state.newFoodGalleryData.data,
    foodGalleryDataLoading: state.newFoodGalleryData.isLoading,
  }));

  const [optionRestaurant, setOptionRestaurant] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(
    restaurantsData?.results[0]?.id
  );

  const [imageData, setImageData] = useState([]);
  const [paramsData, setParamsData] = useState({
    limit: 1,
    offset: 0,
    restaurant: null,
    // restaurant: 7,
  });

  useEffect(() => {
    dispatch(fetchRestaurants());
  }, []);

  useEffect(() => {
    setParamsData((prev) => {
      return {
        ...prev,
        offset: 0,
        restaurant: restaurantsData?.results[0]?.id,
      };
    });
  }, [restaurantsData]);

  useEffect(() => {
    const optionRestaurant =
      restaurantsData &&
      restaurantsData?.results.map((d) => {
        return {
          value: d.id,
          label: d.title,
        };
      });
    setOptionRestaurant(optionRestaurant);
    setSelectedRestaurant(
      isValidArray(optionRestaurant) ? optionRestaurant[0] : []
    );
  }, [restaurantsData]);

  useEffect(() => {
    const newImages = [];
    let caption;

    const result = foodGalleryData?.results?.map((res) => {
      res?.editor_images?.map((img) => newImages.push(img));
      res?.files?.map((img) => {
        newImages.push(img);
        // newImages.push(img?.attachment_file);
      });

      caption = moment(res.created).format("MM/DD/yyyy");
      return { image: newImages, createdDate: res.created };
    });

    const finalresult = newImages?.map((res) => {
      return {
        src: res,
        thumbnail: res,
        thumbnailWidth: 350,
        thumbnailHeight: 180,
        caption: caption,
      };
    });

    if (
      finalresult.length === 0 &&
      // paramsData?.restaurnt !== undefined &&
      // foodGalleryData &&
      foodGalleryDataLoading === false
    ) {
      setParamsData((prevData) => {
        return {
          ...prevData,
          offset: prevData.offset + 1,
        };
      });
    } else {
      setImageData(finalresult);
    }
  }, [foodGalleryData]);

  useEffect(() => {
    if (paramsData.restaurant) {
      dispatch(fetchNewFoodGallery(paramsData, false));
    }
  }, [paramsData]);

  const handleChangeRestaurant = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setParamsData((prevData) => ({
      ...prevData,
      offset: 0,
      restaurant: restaurant?.value,
    }));
  };

  const nextHandler = () => {
    setParamsData((prevData) => ({
      ...prevData,
      offset: prevData.offset + 1,
    }));
    // dispatch(fetchNewFoodGallery(paramsData, false));
  };

  const previousHandler = () => {
    setParamsData((prevData) => ({
      ...prevData,
      offset: prevData.offset - 1,
    }));
    // dispatch(fetchNewFoodGallery(paramsData, false));
  };

  // WORKING CODE ****************//

  // const dispatch = useDispatch();
  // const history = useHistory();

  // const {
  //   restaurantsData,
  //   restaurantsDataLoading,
  //   foodGalleryData,
  //   foodGalleryDataLoading,
  // } = useSelector((state) => ({
  //   restaurantsDataLoading: state.restaurantsData.isLoading,
  //   restaurantsData: state.restaurantsData.data,
  //   foodGalleryData: state.newFoodGalleryData.data,
  //   foodGalleryDataLoading: state.newFoodGalleryData.isLoading,
  // }));

  // const [optionRestaurant, setOptionRestaurant] = useState([]);
  // const [selectedRestaurant, setSelectedRestaurant] = useState(7);
  // //   const [offset, setOffset] = useState(0);
  // //   const [limit, setLimit] = useState(1);
  // const [imageData, setImageData] = useState([]);
  // const [paramsData, setParamsData] = useState({
  //   limit: 1,
  //   offset: 0,
  //   // restaurant: 7,
  // });

  // useEffect(() => {
  //   dispatch(fetchRestaurants());
  //   // dispatch(fetchNewFoodGallery(paramsData, false));
  // }, []);

  // useEffect(() => {
  //   const optionRestaurant =
  //     restaurantsData &&
  //     restaurantsData?.results.map((d) => {
  //       return {
  //         value: d.id,
  //         label: d.title,
  //       };
  //     });
  //   setOptionRestaurant(optionRestaurant);
  //   setSelectedRestaurant(
  //     isValidArray(optionRestaurant) ? optionRestaurant[0] : []
  //   );
  // }, [restaurantsData]);

  // useEffect(() => {
  //   const newImages = [];
  //   let caption;

  //   const result = foodGalleryData?.results?.map((res) => {
  //     res?.editor_images?.map((img) => newImages.push(img));
  //     res?.files?.map((img) => {
  //       newImages.push(img);
  //       // newImages.push(img?.attachment_file);
  //     });

  //     caption = moment(res.created).format("MM/DD/yyyy");
  //     return { image: newImages, createdDate: res.created };
  //   });

  //   const finalresult = newImages?.map((res) => {
  //     return {
  //       src: res,
  //       thumbnail: res,
  //       thumbnailWidth: 350,
  //       thumbnailHeight: 180,
  //       caption: caption,
  //     };
  //   });

  //   if (
  //     finalresult.length === 0 &&
  //     !isValidArray(imageData?.results) &&
  //     foodGalleryData &&
  //     !foodGalleryDataLoading
  //   ) {
  //     setParamsData((prevData) => {
  //       return {
  //         ...prevData,
  //         offset: prevData.offset + 1,
  //       };
  //     });
  //   } else {
  //     setImageData(finalresult);
  //   }
  // }, [foodGalleryData]);

  // useEffect(() => {
  //   dispatch(fetchNewFoodGallery(paramsData, false));
  // }, [paramsData]);

  // // useEffect(() => {

  // //   if (
  // //     foodGalleryData &&
  // //     !isValidArray(imageData?.results) &&
  // //     imageData.length === 0 &&
  // //     paramsData.offset === 0 &&
  // //     !foodGalleryDataLoading
  // //   ) {
  // //     setParamsData((prevData) => {
  // //       return {
  // //         ...prevData,
  // //         offset: prevData.offset + 1,
  // //       };
  // //     });
  // //   }
  // // }, [imageData]);

  // const handleChangeRestaurant = (restaurant) => {
  //   setSelectedRestaurant(restaurant);
  //   setParamsData((prevData) => ({
  //     ...prevData,
  //     offset: 0,
  //     restaurant: restaurant?.value,
  //   }));
  // };

  // const nextHandler = () => {
  //   setParamsData((prevData) => ({
  //     ...prevData,
  //     offset: prevData.offset + 1,
  //   }));
  //   // dispatch(fetchNewFoodGallery(paramsData, false));
  // };

  // const previousHandler = () => {
  //   setParamsData((prevData) => ({
  //     ...prevData,
  //     offset: prevData.offset - 1,
  //   }));
  //   // dispatch(fetchNewFoodGallery(paramsData, false));
  // };

  return (
    <div className="main_box">
      <div className="pull_left full_section_details">
        <div className="main_container">
          <div className="wrapper">
            <div className="container-fluid">
              <div className="row mt-5  justify-content-between page_title">
                <div className="col-md-6">
                  <h2>Food Gallery</h2>
                </div>
                <div className="col-md-6">
                  <div className="main_info_select">
                    <Select
                      options={optionRestaurant}
                      value={selectedRestaurant}
                      onChange={handleChangeRestaurant}
                      placeholder="Select Restaurant"
                      className="form_select "
                      menuShouldBlockScroll={true}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="mt-5 d-flex flex-column">
                  {/* {imageData && imageData.length === 0 && (
                    <h1>
                      No Images Available In This Restaurant's Recent Log.
                    </h1>
                  )} */}
                  {foodGalleryDataLoading ? (
                    <div className="justify-content-center d-block text-center">
                      <h5 className="text-center mt-3 mb-3">
                        <Loader
                          type="TailSpin"
                          color="#38425B"
                          // color="#2196f3"
                          height="100"
                          width="100"
                        />
                      </h5>
                    </div>
                  ) : (
                    imageData &&
                    isValidArray(imageData) && (
                      <Gallery
                        images={imageData}
                        enableImageSelection={false}
                        margin={7}
                        backdropClosesModal={true}
                      />
                    )
                  )}
                  {/* {foodGalleryData &&
                    Object.keys(foodGalleryData).length === 0 && (
                      <h1>No images available for this restaurant.</h1>
                    )} */}
                </div>
                <div className="my-5">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          !foodGalleryData?.previous && "disabled"
                        }`}
                      >
                        <button
                          className={`page-link`}
                          tabIndex="-1"
                          onClick={previousHandler}
                        >
                          Previous
                        </button>
                      </li>
                      <li
                        className={`page-item ${
                          !foodGalleryData?.next && "disabled"
                        }`}
                      >
                        <button className={`page-link`} onClick={nextHandler}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewFoodGallery;
