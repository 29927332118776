import React, { Component } from "react";
import {
  Input,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { fetchScheduleTemplate } from "../../../actions/schedule/scheduleTemplate";
import { connect } from "react-redux";
import { isValidArray } from "../../../modules/utils";

import { fetchDeleteScheduleTemplate } from "../../../actions/schedule/deleteScheduleTemplate";
import { fetchLoadTemplate } from "../../../actions/schedule/loadTemplate";
import { fetchRestaurants } from "../../../actions/restaurants/restaurants";

import DeleteModal from "../../common/DeleteModal";
import moment from "moment";

class LoadTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenDeleteModal: false,
      deleteId: 0,
      restaurant: 0,
      showTemplates: false,
    };
  }

  componentDidMount() {
    this.props.fetchScheduleTemplate();
    this.props.fetchRestaurants();
  }

  getCategoryListing = () => {
    const { scheduleTemplateList } = this.props;

    return (
      isValidArray(scheduleTemplateList) &&
      scheduleTemplateList?.map((d) => {
        return {
          id: d.id,
          name: d.title || "",
          date: d.date || "",
        };
      })
    );
  };

  getTableColumns = () => {
    return [
      {
        name: "NAME",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "DATE",
        selector: (row) => row.date,
        sortable: true,
      },

      {
        name: "ACTIONS",
        selector: (row) => row.actions,
        sortable: false,
        right: true,
        cell: (row) => (
          <React.Fragment>
            <span>
              <button
                className="btn sm btn-outline-primary btn-sm"
                onClick={() => this.handleLoad(row)}
              >
                LOAD
              </button>{" "}
              <button
                value={row.id}
                className="btn btn-outline-secondary btn-sm ml-1"
                onClick={() => this.deleteModalToggle(row.id)}
              >
                DELETE
              </button>
            </span>
          </React.Fragment>
        ),
      },
    ];
  };

  handleLoad = (row) => {
    const { scheduleTemplateList, date, toggle, fetchSchedule } = this.props;
    const data =
      isValidArray(scheduleTemplateList) &&
      scheduleTemplateList?.find((d) => d.id === row?.id);
    const apiVal = {
      ...data,
      ondate: moment(date).format("YYYY-MM-DD"),
    };

    this.props.fetchLoadTemplate(row?.id, apiVal).then(() => {
      this.props.restaurant(this.state.restaurant);

      if (this.props.viewBy === "week") {
        this.props.updateViewBy("week");
      } else {
        this.props.updateViewBy("day");
      }
      fetchSchedule({ restaurant: 0, category: 0, group: 0 });
      toggle();
    });
  };

  deleteLog = () => {
    this.props.fetchDeleteScheduleTemplate(this.state.deleteId).then(() => {
      this.props.fetchScheduleTemplate();
      this.deleteModalToggle();
      this.setState({
        deleteId: 0,
      });
    });
  };

  deleteModalToggle = (id = "") => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
      deleteId: id,
    });
  };

  updateRestaurant = (e) => {
    this.setState({ restaurant: e.target.value });
    if (e.target.value === "Select Location") {
      this.state.showTemplates = false;
    } else {
      this.state.showTemplates = true;
    }
  };

  render() {
    const { isOpen, toggle, restaurantsDataList } = this.props;
    const { isOpenDeleteModal } = this.state;
    return (
      <>
        {/* {this.props.loadTemplateLoading ? (
          <Loader type="TailSpin" color="#2196f3" height={50} />
        ) : null} */}

        <Modal isOpen={isOpen} className="load_template_modal">
          <ModalHeader toggle={toggle}>Load Template</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-4 m-auto">
                <Input
                  type="select"
                  name="restaurant"
                  id="exampleSelect"
                  onChange={this.updateRestaurant}
                >
                  <option>Select Location</option>
                  {restaurantsDataList.map((d, index) => (
                    <option value={d.id} key={index}>
                      {d.title}
                    </option>
                  ))}
                </Input>
              </div>

              {this.state.showTemplates && (
                <div className="col-md-12">
                  <div className="v-data-table text-center schedule-table theme--light">
                    <div className="v-data-table__wrapper">
                      <DataTable
                        className="table"
                        responsive={true}
                        striped={true}
                        // progressPending={this.props.categoriesDataLoading}
                        columns={this.getTableColumns()}
                        data={this.getCategoryListing()}
                      />
                    </div>
                  </div>
                </div>
              )}
              {isOpenDeleteModal && (
                <DeleteModal
                  isOpen={isOpenDeleteModal}
                  toggle={this.deleteModalToggle}
                  deleteLog={this.deleteLog}
                  templateDelete={true}
                />
              )}
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    scheduleTemplateLoading: state.scheduleTemplate.isLoading,
    scheduleTemplateList: state.scheduleTemplate?.data?.results,

    loadTemplateLoading: state.loadTemplateData.isLoading,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsDataList: state.restaurantsData?.data?.results || [],
  };
};
const mapDispatchToProps = {
  fetchScheduleTemplate,
  fetchDeleteScheduleTemplate,
  fetchLoadTemplate,
  fetchRestaurants,
};
export default connect(mapStateToProps, mapDispatchToProps)(LoadTemplate);
