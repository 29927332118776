import {
	FETCH_MY_QUIZ_REQUEST,
	FETCH_MY_QUIZ_FAILURE,
	FETCH_MY_QUIZ_SUCCESS,
} from '../../types/training';
import { getApi } from '../api';

export const fetchMyQuizRequest = () => ({
	type: FETCH_MY_QUIZ_REQUEST,
});

export const fetchMyQuizSuccess = (data) => ({
	type: FETCH_MY_QUIZ_SUCCESS,
	data: data,
});

export const fetchMyQuizFailure = (error) => ({
	type: FETCH_MY_QUIZ_FAILURE,
	error,
});

export const fetchMyQuiz = (
	page,
	itemsPerPage,
	search,
	sort_field,
	sort_type,
) => (dispatch, getState) => {
	dispatch(fetchMyQuizRequest());

	let queryParams = [];
	if (page) {
		queryParams = [...queryParams, `page=${page}`];
	}
	if (itemsPerPage) {
		queryParams = [...queryParams, `itemsPerPage=${itemsPerPage}`];
	}
	if (search) {
		queryParams = [...queryParams, `search=${search}`];
	}
	if (sort_field) {
		queryParams = [...queryParams, `sortBy[]=${sort_field}`];
	}
	if (sort_field) {
		queryParams = [...queryParams, `sortDesc[]=${sort_type}`];
	}

	return getApi(
		`bshift/api/training_assigned_quizzes?is_employee=${true}&mustSort=${false}&mustSort=${false}&${queryParams.join(
			'&',
		)}`,
	)
		.then((data) => {
			dispatch(fetchMyQuizSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchMyQuizFailure(error));
		});
};
