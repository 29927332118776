import { toast } from "react-toastify";
import {
  FETCH_CATEGORY_CREATE_REQUEST,
  FETCH_CATEGORY_CREATE_SUCCESS,
  FETCH_CATEGORY_CREATE_FAILURE,
} from "../../types/categories";
import { postApi } from "../api";

export const fetchCreateCategoryRequest = () => ({
  type: FETCH_CATEGORY_CREATE_REQUEST,
});

export const fetchCreateCategorySuccess = (data) => ({
  type: FETCH_CATEGORY_CREATE_SUCCESS,
  data: data,
});

export const fetchCreateCategoryFailure = (error) => ({
  type: FETCH_CATEGORY_CREATE_FAILURE,
  error,
});

export const fetchCreateCategory = (apiData) => (dispatch, getState) => {
  dispatch(fetchCreateCategoryRequest());
  return postApi(`bshift/api/categories`, apiData)
    .then((data) => {
      toast.success("Category created successfully!");
      dispatch(fetchCreateCategorySuccess(data));
    })
    .catch((error) => {
      dispatch(fetchCreateCategoryFailure(error));
    });
};
