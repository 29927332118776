import { toast } from "react-toastify";
import {
  DELETE_CALENDAR_EVENT_REQUEST,
  DELETE_CALENDAR_EVENT_FAILURE,
  DELETE_CALENDAR_EVENT_SUCCESS,
} from "../../types/calendar";
import { deleteApi } from "../api";

export const deleteCalendarEventRequest = () => ({
  type: DELETE_CALENDAR_EVENT_REQUEST,
});

export const deleteCalendarEventSuccess = (data) => ({
  type: DELETE_CALENDAR_EVENT_SUCCESS,
  data: data,
});

export const deleteCalendarEventFailure = (error) => ({
  type: DELETE_CALENDAR_EVENT_FAILURE,
  error,
});

export const deleteCalendarEvent = (data) => (dispatch, getState) => {
  dispatch(deleteCalendarEventRequest());
  return deleteApi(`bshift/api/calendarevent/${data}`)
    .then((data) => {
      toast.success("Event deleted successfully!");
      dispatch(deleteCalendarEventSuccess(data));
    })
    .catch((error) => {
      dispatch(deleteCalendarEventFailure(error));
    });
};
