import * as Yup from "yup";
import moment from "moment";

export const validator = (type) => {
  switch (type) {
    // case "startDateTime":
    //   return Yup.date().required("Required").min(moment(), "Invalid");
    case "emailRequired":
      return Yup.string()
        .email("Expected input: email")
        .required("This field is Required");
    // case "intPositiveRequired":
    //   return Yup.number().positive(positiveValMessage).required("Required");
    // case "intRequired":
    //   return Yup.number()
    //     .min(0, minValMessage)
    //     .max(100, maxValMessage)
    //     .required("Required");
    case "notRequired":
      return Yup.string().notRequired();
    case "requiredMultiSelect":
      return Yup.array()
        .min(1, "Select at least one value")
        .required("This field is Required");
    case "phone":
      const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

      return Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("This field is Required");
    case "password":
      const passwordExp = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
      return Yup.string()
        .required("This field is Required")
        .matches(
          passwordExp,
          "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        );
    case "passwordConfirmation":
      return Yup.string()
        .required("This field is Required")
        .oneOf([Yup.ref("password"), null], "Password must be same.");

    case "editPassword":
      // return Yup.string();
      const passwordEdit = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
      return Yup.string().matches(
        passwordEdit,
        "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      );

    case "editPasswordConfirmation":
      const editPass = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

      return Yup.string().when("password", {
        is: (value) => value && value.length > 0,
        then: Yup.string()
          .required("Confirm Password is required")
          .matches(
            editPass,
            "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
          )
          .oneOf([Yup.ref("password"), null], "Password must be same."),
      });

    case "alphabet":
      return Yup.string().matches(
        /^[aA-zZ\s]+$/,
        "Only alphabets are allowed for this field "
      );
    case "required":
    default:
      return Yup.string().required("This field is Required");
  }
};

export const displayFormErrors = (
  key = "",
  errors = {},
  touched = {},
  submitCount = 1
) => {
  if (errors[key] !== undefined && errors[key] && submitCount) {
    return (
      <span className="text-danger input-feedback font12 input-error mt-5">
        {errors[key]}
      </span>
    );
  }
  return null;
};

export const loginFormValidation = () => {
  return Yup.object().shape({
    username: validator("required"),
    password: validator("required"),
  });
};

export const AddEmployeeValidation = () => {
  return Yup.object().shape({
    email: validator("emailRequired"),
    password: validator("password"),
    confirmPassword: validator("passwordConfirmation"),
    city: validator("alphabet"),
    state: validator("alphabet"),
    salary: validator("required"),
    phone: validator("phone"),
    // phone: validator("required"),
  });
};

export const EditEmployeeValidation = (values) => {
  return Yup.object().shape({
    email: validator("emailRequired"),
    password: validator("editPassword"),
    confirmPassword: validator("editPasswordConfirmation"),
    city: validator("alphabet"),
    state: validator("alphabet"),
    salary: validator("required"),
    phone: validator("phone"),
    // phone: validator("required"),
  });
};

export const changePasswordValidation = () => {
  return Yup.object().shape({
    password: validator("password"),
    confirmPassword: validator("passwordConfirmation"),
  });
};

export const profileValidation = () => {
  return Yup.object().shape({
    email: validator("emailRequired"),
  });
};

export const announcementFormValidation = () => {
  return Yup.object().shape({
    title: validator("required"),
    //message: validator('required'),
  });
};

export const addPositionValidation = () => {
  return Yup.object().shape({
    name: validator("required"),
  });
};

export const addEditEventValidation = () => {
  return Yup.object().shape({
    title: Yup.string().required("Title is required"),
    kind: Yup.object().required("This field is Required").nullable(),
    restaurant: Yup.object().required("This field is Required.").nullable(),
    // date_start: Yup.date().default(() => new Date()),
    date_start: Yup.date()
      .required("Start date is Required")
      .min(moment(new Date()).add(5, "hours").format("YYYY-MM-DDTHH:MM")),

    date_end: Yup.date()
      .required("End Date is Required")
      .when("date_start", (date_start, schema) => {
        return date_start && schema.min(date_start);
      }),
  });
};

export const addDocumentFormValidation = () => {
  return Yup.object().shape({
    title: validator("required"),
  });
};
