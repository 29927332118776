import {
	FETCH_UPDATE_PICKUP_REQUEST_REQUEST,
	FETCH_UPDATE_PICKUP_REQUEST_SUCCESS,
	FETCH_UPDATE_PICKUP_REQUEST_FAILURE,
} from '../../types/schedule';
import { putApi } from '../api';

export const fetchUpdatePickupRequestRequest = () => ({
	type: FETCH_UPDATE_PICKUP_REQUEST_REQUEST,
});

export const fetchUpdatePickupRequestSuccess = (data) => ({
	type: FETCH_UPDATE_PICKUP_REQUEST_SUCCESS,
	data: data,
});

export const fetchUpdatePickupRequestFailure = (error) => ({
	type: FETCH_UPDATE_PICKUP_REQUEST_FAILURE,
	error,
});

export const fetchUpdatePickupRequest = (id, data) => (dispatch, getState) => {
	dispatch(fetchUpdatePickupRequestRequest());

	return putApi(`bshift/api/schedule_pickup_request/${id}`, data)
		.then((data) => {
			dispatch(fetchUpdatePickupRequestSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchUpdatePickupRequestFailure(error));
		});
};
