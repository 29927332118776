import React from "react";

export default function ContactUs() {
  return (
    <div className="container">
      <div className="main-cms-container text-center my-3 mx-5">
        <p style={{ fontSize: "32px" }}>Contact Us</p>
        <div className="cms-content" style={{ fontSize: "20px" }}>
          <p>
            Contact Us at :{" "}
            <a href="mailto:information@tbshift.com">information@tbshift.com</a>
          </p>
        </div>
      </div>
    </div>
  );
}
