import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik } from "formik";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  isValidArray,
} from "../../../modules/utils";

class AddEditTimeShift extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  handleSubmit = (values) => {
    const { addEditTimeShift } = this.props;
    let passData = {
      color: values.color ? values.color : "",
      day_part: values.day_part ? parseInt(values.day_part) : "",
      group: values.group ? parseInt(values.group) : "",
      note: values.note ? values.note : "",
      shift_break: values.shift_break ? values.shift_break : 0,
      time_start: values.time_start
        ? moment(values.time_start, "hh:mm A").format("HH:mm")
        : "",
      time_end: values.time_end
        ? moment(values.time_end, "hh:mm A").format("HH:mm")
        : "",
    };

    if (values.id) {
      passData = { ...passData, id: values.id };
      addEditTimeShift(passData, values.id);
    } else {
      addEditTimeShift(passData);
    }
  };

  getInitialValue = () => {
    const { editData } = this.props;

    return {
      id: editData?.id ? editData?.id : "",
      group: editData?.group ? editData?.group : "",
      day_part: editData?.day_part ? editData?.day_part : "",
      // time_start: editData?.time_start
      // 	? (editData?.time_start)
      // 	: moment(),
      time_start: editData?.time_start
        ? moment(editData?.time_start, "hh:mm A").format("HH:mm:ss")
        : "",
      // time_end: editData?.time_end ? editData?.time_end : moment(),
      time_end: editData?.time_start
        ? moment(editData?.time_end, "hh:mm A").format("HH:mm:ss")
        : "",
      shift_break: editData?.shift_break ? editData?.shift_break : 0,
      color: editData?.color ? editData?.color : "",
      note: editData?.note ? editData?.note : "",
    };
  };

  handleChangeValue = (e, setFieldValue, fieldName) => {
    setFieldValue(fieldName, e.target.value);
  };

  render() {
    const {
      isOpen,
      toggle,
      groupsDataList,
      shiftDayPartList,
      editData,
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        className="calender_new_model calender_new_model_1"
        centered
      >
        <ModalHeader toggle={toggle}>New Shift Time</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={this.getInitialValue()}
            // validationSchema={addEditEventValidation}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              submitCount,
              setFieldValue,
            }) => {
              // const showError = (key) =>
              // 	displayFormErrors(key, errors, touched, submitCount);
              return (
                <form onSubmit={handleSubmit} autoComplete="on">
                  <div className="row">
                    <div className="col-md-4 calender_event_title">
                      <label>Schedule Category</label>
                      <select
                        name="group"
                        className="form-control"
                        onChange={handleChange}
                        value={values.group}
                        placeholder="group"
                      >
                        <option value="" disabled defaultValue>
                          Schedule Category
                        </option>
                        {isValidArray(groupsDataList) &&
                          groupsDataList?.map((d, id) => {
                            return (
                              <option value={d.id} key={id}>
                                {d.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="col-md-4 calender_event_title">
                      <label>Day Part</label>
                      <select
                        name="day_part"
                        className="form-control"
                        onChange={handleChange}
                        value={values.day_part}
                        placeholder="day_part"
                      >
                        <option value="" disabled defaultValue>
                          Day Part
                        </option>
                        {isValidArray(shiftDayPartList) &&
                          shiftDayPartList?.map((d, id) => {
                            return (
                              <option value={d.id} key={id}>
                                {d.title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="col-md-4 calender_event_title">
                      <label>Shift Break</label>
                      <select
                        name="shift_break"
                        className="form-control"
                        onChange={handleChange}
                        value={values.shift_break}
                        placeholder="shift_break"
                      >
                        <option value="0">0</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                        <option value="60">60</option>
                      </select>
                    </div>
                    <div className="col-md-6 calendar_date_start mt-5">
                      <label>Time start</label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {/* <TimePicker
                          format="hh:mm"
                          inputVariant="outlined"
                          name="time_start"
                          onChange={(e) =>
                            this.handleChangeValue(
                              e,
                              setFieldValue,
                              "time_start"
                            )
                          }
                        /> */}
                        <input
                          type="time"
                          name="time_start"
                          onChange={(e) => {
                            this.handleChangeValue(
                              e,
                              setFieldValue,
                              "time_start"
                            );
                          }}
                          value={values.time_start}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-6 calendar_date_start mt-5">
                      <label>Time end</label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {/* <TimePicker
                          // label="DateTimePicker"
                          format="hh:mm"
                          inputVariant="outlined"
                          name="time_end"
                          onChange={(e) =>
                            this.handleChangeValue(e, setFieldValue, "time_end")
                          }
                        /> */}
                        <input
                          type="time"
                          name="time_end"
                          onChange={(e) => {
                            this.handleChangeValue(
                              e,
                              setFieldValue,
                              "time_end"
                            );
                          }}
                          value={values.time_end}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-md-6 calender_event_title">
                      <label>Note</label>
                      <input
                        type="text"
                        value={values.note}
                        name="note"
                        onChange={handleChange}
                      />
                      {/* <ChromePicker
												color={values.color}
												onChangeComplete={(e) =>
													this.handleChange(setFieldValue, e?.hex)
												}
											/> */}
                    </div>
                    <div className="col-md-6 calender_event_title">
                      <label>Color</label>
                      <input
                        type="text"
                        value={values.color}
                        name="color"
                        onChange={handleChange}
                      />
                      {/* <ChromePicker
												color={values.color}
												onChangeComplete={(e) =>
													this.handleChange(setFieldValue, e?.hex)
												}
											/> */}
                    </div>
                    <ModalFooter>
                      <div className="record_btn" toggle={toggle}>
                        <button>CANCEL</button>
                      </div>
                      <div className="record_btn" onClick={handleSubmit}>
                        <button>SAVE</button>{" "}
                      </div>
                    </ModalFooter>
                  </div>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

export default AddEditTimeShift;
