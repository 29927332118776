import React from "react";
import {
  displayFormErrors,
  loginFormValidation,
} from "../../modules/validation";
import { Formik } from "formik";
import { useHistory, Link } from "react-router-dom";
import LaddaButton from "@zumper/react-ladda";
import { useSelector, useDispatch } from "react-redux";
import { fetchForgotPasswordAction } from "../../actions/forgotPassword/forgotPassword";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const history = useHistory();

  const forgotPasswordData = useSelector((state) => state.forgotPassword);

  const onHandleSubmit = (values) => {
    if (/\S+@\S+\.\S+/.test(values.email)) {
      const body = {
        email: values.email,
      };
      dispatch(fetchForgotPasswordAction(body));
    } else {
      toast.error("Please enter valid email address");
    }

    // history.push("/login");
    // console.log("Email: ", values.email);

    // const apiVal = {
    //   password: values.password,
    //   username: values.username,
    // };
    // this.props.fetchLoginAccessToken(apiVal).then(() => {
    //   const { history } = this.props;
    //   this.props.fetchProfile().then(() => {
    //     // history.push(this.getInitialPath());
    //     history.push("/dashboard");
    //   });
    // });
  };

  return (
    <div>
      <div className="login">
        <div className="l_wrapper">
          <div className="l_left   pb-5">
            <div className="l_left_inner">
              <div className="login_heading">
                <h1>Welcome To</h1>
                <span>
                  <div className="l_logo">
                    <h1>THE BUSINESS SHIFT</h1>
                  </div>
                </span>
              </div>

              <Formik
                initialValues={{
                  email: "",
                }}
                onSubmit={onHandleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  submitCount,
                }) => {
                  const showError = (key) =>
                    displayFormErrors(key, errors, touched, submitCount);
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="login_form">
                        <div className="l_user">
                          <input
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                            onChange={handleChange}
                            value={values.email}
                          />
                          {showError("email")}
                        </div>

                        <div className="login_btn">
                          <LaddaButton
                            type="submit"
                            className="btn btn-primary  "
                            onClick={handleSubmit}
                            spinnerSize={20}
                          >
                            Reset Password
                          </LaddaButton>
                        </div>
                        <div className="forgot_password my-3">
                          <Link to="/Login" className="forgot_password_link">
                            Login Here
                          </Link>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
