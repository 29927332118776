import React, { Component } from "react";
import {
  Card,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";

import { clearScheduleWeek } from "../../../actions/schedule/clearScheduleWeek";

import {
  days,
  formatDateISO,
  getDate,
  isValidArray,
} from "../../../modules/utils";
import DeleteModal from "../../common/DeleteModal";

class WeekClear extends Component {
  constructor(props) {
    super(props);

    this.state = {
      max_weeks: 5,
      week_post: 0,
      selected_cats: [],
      selected_cats_all: true,
      selected_daypart: [],
      selected_daypart_all: true,
      isOpenDeleteModal: false,
    };
  }

  componentDidMount() {
    const cats = this.getCategories();
    const day = [...Array(7)].map((i, d) => d);
    this.setState({
      selected_cats: cats.map((d) => d.id),
      selected_daypart: day,
    });
  }

  week_items = () => {
    const { max_weeks } = this.state;

    let items = [];
    let d1 = null;
    let d2 = null;
    const opts = { year: "numeric", month: "short", day: "numeric" };
    for (let i = 1; i <= max_weeks; i++) {
      d1 = new Date(this.getDate(1));
      d2 = new Date(this.getDate(1));
      d1.setDate(d1.getDate() + i * 7);
      d2.setDate(d2.getDate() + i * 7 + 6);
      items.push({
        value: i,
        text:
          d1.toLocaleString("en-US", opts) +
          " - " +
          d2.toLocaleString("en-US", opts),
      });
    }
    return items;
  };

  getDate = (num) => {
    const { inStart } = this.props;
    return getDate(num, formatDateISO(inStart));
  };

  getCategories() {
    // let { groups: items, scheduleItems } = this.props;

    // let keep = [...new Set(scheduleItems.map((itm) => itm.group))];
    // items = items.filter((item) => keep.indexOf(item.id) > -1);
    // return items;
    const { scheduleItems } = this.props;
    const item = scheduleItems
      ?.filter((d) => d.type === "position")
      .map((i) => {
        return {
          id: i.instance.id,
          name: i.instance.name,
        };
      });

    return item;
  }

  handleDayPart = (val) => {
    const { selected_daypart } = this.state;
    let updated = [...selected_daypart];

    updated.includes(val)
      ? (updated = updated.filter((item) => item != val))
      : updated.push(val);

    this.setState({
      selected_daypart: updated,
    });
  };

  handleAllDay = () => {
    const { selected_daypart_all } = this.state;
    this.setState(
      {
        selected_daypart_all: !selected_daypart_all,
      },
      () => {
        const { selected_daypart_all } = this.state;
        const { scheduleList, inStart } = this.props;
        let updated = selected_daypart_all
          ? [...Array(7)].map((i, d) => d)
          : [];
        this.setState({
          selected_daypart: updated,
        });
      }
    );
  };

  handleAllSchedule = () => {
    const { selected_cats_all } = this.state;
    this.setState(
      {
        selected_cats_all: !selected_cats_all,
      },
      () => {
        const { selected_cats_all } = this.state;
        const { scheduleItems } = this.props;
        let updated = selected_cats_all
          ? scheduleItems
              ?.filter((d) => d.type === "position")
              .map((i) => i.instance.id)
          : [];
        this.setState({
          selected_cats: updated,
        });
      }
    );
  };

  handleSchedule = (id) => {
    const { selected_cats } = this.state;
    let updated = [...selected_cats];

    updated.includes(id)
      ? (updated = updated.filter((item) => item != id))
      : updated.push(id);

    this.setState({
      selected_cats: updated,
    });
  };

  handleSubmit = () => {
    const { selected_cats, selected_daypart } = this.state;
    const { inStart, clearScheduleWeek, toggle } = this.props;
    const apiVal = {
      selected_cats: selected_cats,
      selected_days: selected_daypart,
    };

    clearScheduleWeek(inStart, apiVal).then(() => {
      this.props.fetchSchedule();
      this.setState({
        selected_daypart: [],
        selected_cats: [],
      });
      toggle();
    });
  };

  deleteModalToggle = () => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
    });
  };

  render() {
    const { isOpen, toggle, inStart, inEnd } = this.props;
    const { selected_cats, selected_daypart } = this.state;

    const week_items = this.week_items();

    return (
      <>
        <Modal isOpen={isOpen}>
          <ModalHeader toggle={toggle}>
            Delete week: {inStart} - {inEnd}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12 as_chbx">
                <Card>
                  <div className="row m-1">
                    <div className="col-8">
                      <span className="subtitle-1">
                        Which schedule do you want to copy?
                      </span>
                    </div>
                    <div className="col-4">
                      <FormGroup check>
                        <div className="form-group custom_checkbox">
                          <Input
                            type="checkbox"
                            className="sch-checkbox"
                            id="scheduleSelectAll"
                            onChange={this.handleAllSchedule}
                            checked={
                              selected_cats.length ===
                              this.getCategories().length
                            }
                          />

                          <label for="scheduleSelectAll">Select All</label>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <div className="schedule_checkbox d-flex mt-3 mb-3">
                        {this.getCategories().map((ps) => {
                          return (
                            <FormGroup check>
                              <div className="form-group custom_checkbox">
                                <Input
                                  type="checkbox"
                                  className="sch-checkbox"
                                  id={`cat${ps.id}`}
                                  onChange={() => this.handleSchedule(ps.id)}
                                  checked={selected_cats.includes(ps.id)}
                                />

                                <label for={`cat${ps.id}`}>{ps.name}</label>
                              </div>
                            </FormGroup>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Card>

                {!isValidArray(selected_cats) && (
                  <p style={{ color: "red" }}>This field is required.</p>
                )}

                <Card className="mt-3">
                  <div className="row m-1">
                    <div className="col-8">
                      <span className="subtitle-1 span_Flwidth">
                        Which day do you want to delete?
                      </span>
                    </div>
                    <div className="col-4">
                      <FormGroup check>
                        <div className="form-group custom_checkbox">
                          <Input
                            type="checkbox"
                            className="sch-checkbox"
                            id="daySelectAll"
                            onChange={this.handleAllDay}
                            checked={selected_daypart.length === 7}
                          />

                          <label for="daySelectAll">Select All</label>
                        </div>
                      </FormGroup>
                    </div>
                    <div className="col-12 d-flex ">
                      <div className="schedule_checkbox d-flex mt-3 mb-3">
                        {[...Array(7)].map((x, day) => {
                          return (
                            <FormGroup check>
                              <div className="form-group custom_checkbox">
                                <Input
                                  type="checkbox"
                                  className="sch-checkbox"
                                  id={`day${day}`}
                                  onChange={() => this.handleDayPart(day)}
                                  checked={selected_daypart.includes(day)}
                                />

                                <label for="{`day${day}`}">{days[day]}</label>
                              </div>
                            </FormGroup>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Card>
                {!isValidArray(selected_daypart) && (
                  <p style={{ color: "red" }}>This field is required.</p>
                )}
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <div className="record_btn">
              <button type="button" data-dismiss="modal" onClick={toggle}>
                CANCEL
              </button>
            </div>
            <div className="record_btn">
              <button
                type="button"
                onClick={this.deleteModalToggle}
                disabled={
                  !isValidArray(selected_daypart) ||
                  !isValidArray(selected_cats)
                }
              >
                DELETE NOW
              </button>
            </div>
          </ModalFooter>
        </Modal>
        <DeleteModal
          isOpen={this.state.isOpenDeleteModal}
          toggle={this.deleteModalToggle}
          deleteLog={this.handleSubmit}
          scheduleClear={true}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = {
  clearScheduleWeek,
};
export default connect(mapStateToProps, mapDispatchToProps)(WeekClear);
