import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Dock from "react-dock";
import {
  FaBullhorn,
  FaRegEdit,
  FaTimes,
  FaEye,
  FaSistrix,
  FaUser,
  FaRegEnvelopeOpen,
} from "react-icons/fa";
import { BsFillChatRightTextFill } from "react-icons/bs";
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from "react-icons/bs";
import Avatar from "react-avatar";
import moment from "moment";
import { Formik } from "formik";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactTooltip from "react-tooltip";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { fetchEmailAnnouncements } from "../../actions/announcements/getEmailAnnouncements";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import MobileMenu from "./MobileMenu";

import { fetchAnnouncements } from "../../actions/announcements/announcements";
import { fetchCreateAnnouncements } from "../../actions/announcements/create";
import { fetchAnnoucementEmail } from "../../actions/announcements/emailAnnoucement";
import { fetchGroups } from "../../actions/groups/groups";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { setSearchQuery } from "../../actions/common/globalSearch";
import { fetchProfile } from "../../actions/profile/profile";
import { fetchUsersDropdown } from "../../actions/users/userDropdownData";
import { fetchAnnouncementsById } from "../../actions/announcements/annoucementById";
import { fetchChatCounts } from "../../actions/chat/unreadCount";
import { toast } from "react-toastify";

import { getApi, postApi } from "../../actions/api";

import {
  displayFormErrors,
  announcementFormValidation,
} from "../../modules/validation";
import { getPermission, isValidObject } from "../../modules/utils";
import axios from "axios";

import logo from "../../assets/css/images/logo.svg";
// import logo from "../../assets/css/images/logo2.jpg";

export class Navbar extends Component {
  constructor(props) {
    super(props);

    this.formRef = React.createRef();

    this.state = {
      isVisible: false,
      isOpen: false,
      modalData: {},
      isAddDocVisible: false,
      optionPosition: null,
      optionLocation: null,
      optionEmployee: null,
      positionValue: null,
      locationValue: null,
      employeeValue: null,
      filteredEmployee: [],
      title: "",
      message: "",
      searchQuery: "",
      date: moment(),
      width: 0,
      //isNotification: false,
      isMessage: false,
      count: null,
      page: 1,
      emailPage: 1,
      emailView: false,
      logoutDisabled: false,
    };
  }

  componentDidMount() {
    getApi("/bshift/api/announcements/unread_count")
      .then((result) => {
        this.setState({
          count: result?.data?.unread_count,
        });
      })
      .catch((err) => {
        toast.error(err);
      });
    this.updateWindowDimensions();
    this.props.fetchEmailAnnouncements({ mine: true });
    this.props.fetchAnnouncements({ mine: true }).then(() => {
      this.setState({
        // count: this.props.announcementsData?.results?.slice(-1),
      });
    });
    this.props.fetchChatCounts();
  }

  doLogout = () => {
    const device_token = localStorage.getItem("device_token");

    postApi(`/users/api/logout`, {
      device_token: device_token,
      device_type: "web",
    })
      .then((res) => {
        this.setState({ logoutDisabled: true });
        toast.success("Logged out successfully");
        localStorage.removeItem("device_token");
        localStorage.removeItem("loginAccessToken");
        localStorage.removeItem("loggedUserId");
        // localStorage.clear();
        this.props?.history.push("/login");
      })
      .catch((err) => {
        this.setState({ logoutDisabled: false });
        this.props?.history.push("/");
        console.log(err);
      });
  };

  handleDock = () => {
    this.props.fetchAnnouncements({ mine: true });

    this.setState({
      isVisible: !this.state.isVisible,
    });
  };

  handleMsgs = () => {
    this.setState({
      isMessage: !this.state.isMessage,
    });
    this.setState({ isAddDocVisible: !this.state.isAddDocVisible });
    this.handleAddAnnouncement();
  };

  handleAddAnnouncement = () => {
    const {
      fetchGroups,
      fetchRestaurants,
      //fetchUsers,
      fetchUsersDropdown,
    } = this.props;
    this.setState({
      isAddDocVisible: !this.state.isAddDocVisible,
    });
    fetchGroups().then(() => {
      fetchRestaurants().then(() => {
        //fetchUsers().then(() => {});
        fetchUsersDropdown().then(() => {});
      });
    });
  };

  // openModal = (d) => {
  // 	this.setState({ modalData: d }, this.toggleModal);
  // };

  openModal = (d) => {
    this.setState({ modalData: d, isOpen: true });
    this.props.fetchAnnouncementsById(d.id).then(() => {
      getApi("/bshift/api/announcements/unread_count").then((result) => {
        this.setState({
          count: result?.data?.unread_count,
        });
      });
      // this.props.fetchAnnouncements().then(() => {
      //   this.setState({
      //     count: this.props.announcementsData?.results?.slice(-1),
      //     count: this.props.announcementsData?.results?.slice(-1),
      //   });
      // });
    });
  };

  toggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      // isVisible: !this.state.isVisible,
    });
  };

  onHandleSubmit = (values, { resetForm }) => {
    resetForm();
    const {
      positionValue,
      employeeValue,
      locationValue,
      isMessage,
      isAddDocVisible,
    } = this.state;

    if (locationValue === null) {
      toast.error("Please select location");
    }

    let formData = new FormData();

    formData.append("title", values.title);

    if (positionValue !== null) {
      positionValue.forEach((item) => {
        formData.append("groups", item);
      });
    }
    if (employeeValue !== null) {
      employeeValue.forEach((item) => {
        formData.append("employees", item);
      });
    }
    if (locationValue !== null) {
      locationValue.forEach((item) => {
        formData.append("restaurants", item);
      });
    }
    formData.append(
      "created_by",
      parseInt(localStorage.getItem("loggedUserId"))
    );

    formData.append("message", this.state.message);
    formData.append("unread_notifications", 1);

    if (isMessage === false) {
      formData.append(
        "date",
        moment(this.state.date).format("YYYY-MM-DD").toString()
      );
      this.props.fetchCreateAnnouncements(formData).then(() => {
        this.props.fetchAnnouncements({ mine: true }).then(() => {
          this.setState({
            isAddDocVisible: false,
            positionValue: "",
            employeeValue: "",
            locationValue: "",
            optionPosition: null,
            optionLocation: null,
            data: new Date(),
            optionEmployee: null,
          });
        });
      });
    } else if (isMessage === true) {
      //formData.append("message", this.state.message);
      this.props.fetchAnnoucementEmail(formData).then((err, res) => {
        toast.success("Email sent successfully");
        //this.closeModal();
        // this.setState({
        //   isAddDocVisible: false,
        //   positionValue: "",
        //   employeeValue: "",
        //   locationValue: "",
        //   optionPosition: null,
        //   optionLocation: null,
        //   data: new Date(),
        //   optionEmployee: null,
        // });
      });
    }
  };

  closeModal = () => {
    this.setState({ isAddDocVisible: false });
    setTimeout(() => {
      this.setState({ isMessage: false });
      this.setState({
        optionPosition: null,
        optionEmployee: null,
        optionLocation: null,
        title: "",
        message: "",
      });
      if (this.formRef.current.values.title) {
        this.formRef.current.values.title = "";
      }
    }, [500]);

    // if (this.props.match.path === "/mainInfo") {
    //   window.location.reload(true);
    // }
  };

  handleChangeOption = (optionPosition) => {
    const positionValue = optionPosition?.map((d) => {
      return d.value;
    });
    this.setState({ optionPosition, positionValue });
  };

  handleChangeLocation = (optionLocation) => {
    const locationValue = optionLocation?.map((d) => {
      return d.value;
    });
    this.setState({ optionLocation, locationValue });
    const newEmps = [];
    locationValue.forEach((item, index) => {
      this.props?.usersDropdownData?.results?.forEach((user, ind) => {
        if (user.restaurants.includes(item)) {
          if (!newEmps.includes(user)) {
            newEmps.push(user);
          }
        }
      });
    });

    this.setState({
      filteredEmployee: newEmps,
    });
  };

  handleChangeEmployee = (optionEmployee) => {
    const employeeValue = optionEmployee?.map((d) => {
      return d.value;
    });
    this.setState({ optionEmployee, employeeValue });
  };

  handleSearch = (event) => {
    this.setState(
      {
        searchQuery: event?.target?.value,
      },
      () => this.props.setSearchQuery(event?.target?.value)
    );
  };

  handleDateChange = (date) => {
    this.setState({ date });
  };

  getInitialValue = () => {
    return {
      title: "",
      message: "",
    };
  };

  handleCk5change = (event, editor) => {
    const data = editor.getData();
    this.setState({
      message: data,
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.page !== this.state.page) {
      this.props.fetchAnnouncements({ page: this.state.page, mine: true });
      const objDiv = document.getElementById("announcements-div");
      objDiv.scrollTop = 0;
    }

    if (prevState.emailPage !== this.state.emailPage) {
      this.props.fetchEmailAnnouncements({
        page: this.state.emailPage,
        mine: true,
      });
      const objDiv = document.getElementById("announcements-div");
      objDiv.scrollTop = 0;
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  nextAnnouncementHandler = () => {
    this.setState({
      page: this.state.page + 1,
    });
  };

  previousAnnouncementHandler = () => {
    this.setState({
      page: this.state.page - 1,
    });
  };

  previousEmailHandler = () => {
    this.setState({
      emailPage: this.state.emailPage - 1,
    });
  };

  nextEmailHandler = () => {
    this.setState({
      emailPage: this.state.emailPage + 1,
    });
  };

  render() {
    const {
      isOpen,
      modalData,
      isAddDocVisible,
      isVisible,
      date,
      searchQuery,
      width,
      //isNotification,
      isMessage,
      filteredEmployee,
    } = this.state;
    const {
      announcementsData,
      announcementsByIdReducer,
      groupsData,
      restaurantsData,
      usersDropdownData,
      globalSearch,
      profileData,
      announcementsDataLoading,
      emailAnnouncementsData,
    } = this.props;
    const isMobile = width <= 1024;

    const optionEmployee = filteredEmployee?.map((d) => {
      return {
        value: d.id,
        label: d.full_name,
        id: d.id,
      };
    });
    // const optionEmployee = usersDropdownData?.results?.map((d) => {
    //   return {
    //     value: d.id,
    //     label: d.full_name,
    //     id: d.id,
    //   };
    // });
    const optionLocation = restaurantsData?.results?.map((d) => {
      return {
        value: d.id,
        label: d.title,
      };
    });
    const optionPosition = groupsData?.results?.map((d) => {
      return {
        value: d.id,
        label: d.name,
      };
    });

    function uploadAdapter(loader) {
      return {
        upload: () => {
          return new Promise((resolve, reject) => {
            const body = new FormData();
            loader.file.then((file) => {
              body.append("photo", file);
              //console.log("file", file);
              //fetch(`http://3.132.219.157:8000/bshift/api/editorimage`, {
              fetch(
                `https://admin.thebusinessshifts.com/bshift/api/editorimage`,
                {
                  method: "post",
                  body: body,
                }
              )
                .then((res) => res.json())
                .then((res) => {
                  resolve({
                    default: `${res.image}`,
                  });
                })
                .catch((err) => {
                  reject(err);
                });
            });
          });
        },
      };
    }

    function uploadPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
      };
    }

    // const cnt = announcementsData?.slice(-1);

    return (
      <div>
        <header>
          <div className="full_wrapper navbar_background">
            <div className="row header_align justify-content-center">
              <div className="col-3 col-md-1 col-lg-1 col-xl-12 mobileMenuBar">
                <MobileMenu />
              </div>
              <div className="col-6 col-md-3 col-lg-2 col-xl-2 logo_col d-flex justify-content-center">
                <div className="pull_left">
                  {/* <div className="logo"> */}
                  <img
                    src={logo}
                    alt="logo"
                    className=""
                    style={{
                      width: "150px",
                      height: "71px",
                    }}
                    onClick={() => this.props?.history.push("/dashboard")}
                  />

                  {/* <div
                      className="logo_text my-0"
                      onClick={() => this.props?.history.push("/dashboard")}
                    >
                      The Business Shift
                    </div> */}
                  {/* </div> */}
                </div>
              </div>
              <div
                className="col-3 col-md-3 col-lg-2 col-xl-2 logo_col justify-content-center"
                style={{ paddingLeft: "0px" }}
              >
                <div className="d-flex justify-start align-end logout_btn">
                  {profileData?.is_superuser ? (
                    <a
                      className="btn btn-primary"
                      style={{
                        background: "#4caf50",
                        borderColor: "#4caf50",
                        padding: "10px",
                      }}
                      //href="http://3.132.219.157:8000/admin"
                      //href="http://3.219.165.65:8000/admin"
                      href="https://admin.thebusinessshifts.com/admin"
                      target="_blank"
                    >
                      Admin Portal
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4 form_col justify-content-center">
                <div className="form-group busines_form">
                  <input
                    type="text"
                    className="form-control m-2"
                    value={searchQuery}
                    placeholder="Search"
                    onChange={this.handleSearch}
                  />
                  <h5 style={{ color: "white" }}>
                    <FaSistrix />
                  </h5>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 col-lg-4 col-xl-4 form_col d-flex justify-content-center">
                <div className="pull_right d-flex logout_btn">
                  <h2
                    style={{ color: "white", cursor: "pointer" }}
                    className="mr-2 px-3"
                  >
                    {/* mailbox */}
                    <FaRegEnvelopeOpen
                      //onClick={this.handleNotificationDock}
                      onClick={this.handleMsgs}
                      data-tip="Mail Box"
                      data-for="notification-tip"
                    />
                  </h2>
                  <Link
                    style={{
                      color: "white",
                      cursor: "pointer",
                      position: "relative",
                    }}
                    className="mr-2 px-2 h5"
                    to="/chat"
                  >
                    <BsFillChatRightTextFill
                      data-tip="Unread Messages"
                      data-for="announcement-tip"
                      onClick={() => {}}
                    />

                    {this.props.chatUnreadCount &&
                    this.props.chatUnreadCount !== 0 ? (
                      +this.props.chatUnreadCount > 99 ? (
                        <span
                          style={{
                            fontSize: "large",
                            position: "absolute",
                            top: "-10px",
                            left: "88%",
                          }}
                        >
                          99+
                        </span>
                      ) : (
                        <span
                          style={{
                            fontSize: "large",
                            position: "absolute",
                            top: "-10px",
                            left: "88%",
                            color: "#fff",
                          }}
                        >
                          {this.props.chatUnreadCount}
                        </span>
                      )
                    ) : null}
                  </Link>
                  <h2
                    style={{
                      color: "white",
                      cursor: "pointer",
                      position: "relative",
                    }}
                    className="mr-2 px-2"
                  >
                    <FaBullhorn
                      onClick={this.handleDock}
                      data-tip="Announcements"
                      data-for="announcement-tip"
                    />
                    {/* this.state.count[0]?.unread_count !== 0 ? (
                      +this.state.count[0]?.unread_count > 99 ? ( */}
                    {this.state?.count && this.state?.count !== 0 ? (
                      +this.state.count > 99 ? (
                        <span
                          style={{
                            fontSize: "large",
                            position: "absolute",
                            top: "-10px",
                            left: "88%",
                          }}
                        >
                          99+
                        </span>
                      ) : (
                        <span
                          style={{
                            fontSize: "large",
                            position: "absolute",
                            top: "-10px",
                            left: "88%",
                            color: "#fff",
                          }}
                        >
                          {this.state.count}
                        </span>
                      )
                    ) : null}
                    {/* {cnt && cnt[0]?.unread_count !== 0 ? (
                      +cnt[0]?.unread_count > 99 ? (
                        <span
                          style={{
                            fontSize: "large",
                            position: "absolute",
                            top: "-10px",
                            left: "88%",
                          }}
                        >
                          99+
                        </span>
                      ) : (
                        <span
                          style={{
                            fontSize: "large",
                            position: "absolute",
                            top: "-10px",
                            left: "88%",
                            color: "red",
                          }}
                        >
                          {cnt[0]?.unread_count}
                        </span>
                      )
                    ) : null} */}
                  </h2>

                  <Dropdown className="profile_icon">
                    <Dropdown.Toggle id="dropdown-basic">
                      {profileData?.avatar ? (
                        <img
                          src={profileData?.avatar}
                          style={{
                            minHeight: "32px",
                            maxHeight: "32px",
                            minWidth: "32px",
                            maxWidth: "32px",
                            borderRadius: "200px",
                          }}
                        />
                      ) : (
                        <FaUser />
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          this.props.history.push("/profile");
                        }}
                      >
                        {/* <Link to="/profile"> */}
                        My Profile
                        {/* </Link> */}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          this.props.history.push("/change-password");
                        }}
                      >
                        {/* <Link to="/change-password"> */}
                        Change Password
                        {/* </Link> */}
                      </Dropdown.Item>

                      {/* <Dropdown.Item>
												<Link to="/profile">
													My Profile
												</Link>
											</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>

                  <button
                    className="btn btn-primary logout_btn"
                    onClick={this.doLogout}
                    disabled={this.state.logoutDisabled}
                  >
                    LOGOUT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="announcement_dock">
          {/* second sidebar doc */}
          <Dock
            position="right"
            isVisible={isVisible}
            // fluid={isMobile ? false : undefined}
            // size={isMobile ? 300 : undefined}
          >
            <div className={isOpen ? "dock_side d-flex" : "dock_side"}>
              {isOpen && (
                <div className="dock_detail p-3">
                  <div className="dock_resize">
                    <div className="">
                      <div className="d-flex mb-3 list_detail_header">
                        {/* <h4>Announcements</h4> */}
                        <div className="list_detail_title">
                          {modalData?.created_by_details?.avatar === "" ? (
                            <Avatar
                              name={
                                modalData?.created_by_details?.full_name.split(
                                  " ("
                                )[0]
                              }
                              size="35"
                              round="50px"
                              color="rgb(56, 66, 91)"
                            />
                          ) : (
                            <Avatar
                              src={modalData?.created_by_details?.avatar}
                              size="35"
                              round="50px"
                            />
                          )}
                          <h6 className="ml-1">
                            {modalData?.created_by_details.full_name}
                          </h6>
                        </div>
                        <h3 className="">
                          <FaTimes
                            onClick={() => this.toggleModal()}
                            style={{ cursor: "pointer" }}
                          />
                        </h3>
                      </div>
                      <div>
                        <b>
                          {moment(modalData?.created).format("MMM DD, YYYY")}
                        </b>
                      </div>
                      {modalData?.employees_printable && (
                        // <p>
                        <div className="row mt-2">
                          <div className="col-3">
                            <b>To:</b>
                          </div>
                          <div className="col-9">
                            {modalData?.employees_printable}
                          </div>
                        </div>
                        // </p>
                      )}
                    </div>
                    {isValidObject(modalData) && (
                      <>
                        <div className="">
                          {modalData?.restaurants_printable && (
                            <div className="row">
                              <div className="col-3">
                                <b>Restaurants:</b>
                              </div>
                              <div className="col-9">
                                {modalData?.restaurants_printable}
                              </div>
                            </div>
                          )}
                          {modalData?.groups_printable && (
                            <div className="row">
                              <div className="col-3">
                                <b>Positions:</b>
                              </div>
                              <div className="col-9">
                                {modalData?.groups_printable}
                              </div>
                            </div>
                          )}

                          <h5
                            style={{ textTransform: "capitalize" }}
                            className="mt-2"
                          >
                            {modalData?.title}
                          </h5>

                          {/* {modalData?.message && ( */}
                          <div
                            style={{ textAlign: "justify" }}
                            dangerouslySetInnerHTML={{
                              __html: modalData?.message,
                            }}
                          ></div>

                          <div className="d-flex peter_icon">
                            {modalData?.unread_notifications === false ? (
                              <span
                                className="mt-2"
                                style={{ color: "blue" }}
                              />
                            ) : (
                              <span
                                className="mt-2"
                                style={{ color: "blue" }}
                              />
                            )}
                            <span className="ml-1 mt-2">
                              {modalData?.read_qty}/{modalData?.total_qty}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              <div className="dock_list dock_list2">
                <div className="dock_list_scroll" id="announcements-div">
                  <div className="doc_header">
                    <div className="p-4">
                      <div className="row justify-content-between">
                        <div className="col-md-12 announce_dock d-flex justify-content-between align-item-center">
                          {/* <div className="d-flex"> */}
                          {/* <h4>Announcements</h4>
                            <h4>Email</h4> */}
                          {/* </div> */}
                          <div
                            className="d-flex"
                            style={{ borderRight: "2px solid white" }}
                          >
                            <p
                              className={
                                !this.state.emailView
                                  ? "text-underline-2px my-auto mx-2 border-white "
                                  : "my-auto mx-2"
                              }
                              onClick={() =>
                                this.setState({ emailView: false })
                              }
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              ANNOUNCEMENTS
                            </p>
                          </div>
                          <div className="d-flex">
                            <p
                              className={
                                this.state.emailView
                                  ? "text-underline-2px my-auto mx-2 border-white"
                                  : "my-auto mx-2"
                              }
                              onClick={() => this.setState({ emailView: true })}
                              style={{
                                fontSize: "16px",
                              }}
                            >
                              EMAIL
                            </p>
                          </div>

                          <div className="dock_header_icon">
                            {getPermission(
                              "bshift.bs_edit_announcement",
                              profileData
                            ) && (
                              <h3 className="mr-3">
                                <FaRegEdit
                                  onClick={this.handleAddAnnouncement}
                                  style={{ cursor: "pointer" }}
                                />
                              </h3>
                            )}

                            <h3 className="">
                              <FaTimes
                                onClick={() =>
                                  this.setState({ isVisible: !isVisible })
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </h3>
                          </div>
                        </div>
                        {/* <div className="col-md-6 col-sm-6 icon_announce d-flex "></div> */}
                      </div>
                    </div>
                  </div>

                  {/* first sidebar announcements */}

                  {/* {announcementsData &&
                    //announcementsData?.results &&
                    //announcementsData?.results.map((d, i) => {
                    announcementsData?.map((d, i, newarr) => {
                      if (newarr.length - 1 === i) {
                        return <React.Fragment key={i}></React.Fragment>;
                      } else {
                        return (
                          <div
                            className="doc_card m-3"
                            onClick={() => this.openModal(d)}
                            style={{ cursor: "pointer" }}
                            key={i}
                          >
                            <div className="p-3">
                              <div className="row justify-content-between">
                                <div className="col-md-8 d-flex">
                                  <Avatar
                                    name={
                                      d?.created_by_details?.full_name?.split(
                                        " ("
                                      )[0]
                                    }
                                    size="35"
                                    round="50px"
                                    color="#38425B"
                                    // color="#1976d2"
                                    className="mt-1"
                                  />
                                  <h6 className="ml-1 mt-2">
                                    {d?.created_by_details?.full_name}
                                  </h6>
                                </div>
                                <div className="col-md-4 peter">
                                  <p className="mt-2">
                                    {moment(d?.created).format("MMM DD, YYYY")}
                                  </p>
                                </div>
                              </div>
                              <div className="row justify-content-between">
                                <div className="col-md-8 ">
                                  <h5>{d?.title}</h5>
                                </div>
                                <div className="col-md-4 d-flex peter_icon">
                                  {d.unread_notifications === false ? (
                                    <FaEye className="mt-2" />
                                  ) : (
                                    <FaEye
                                      className="mt-2"
                                      style={{ color: "blue" }}
                                    />
                                  )}
                                  <span className="ml-1 mt-2">
                                    {d?.read_qty}/{d?.total_qty}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })} */}

                  {/* Loading */}
                  {!this.state.emailView && announcementsDataLoading && (
                    <div className="mt-3 justify-content-center text-center m-auto d-flex">
                      <h5 className="text-center mt-3 mb-3">
                        <Loader
                          type="TailSpin"
                          color="#38425B"
                          // color="#2196f3"
                          height="100"
                          width="100"
                        />
                      </h5>
                    </div>
                  )}

                  {this.state.emailView &&
                    this.props.emailAnnoucementsData?.isLoading && (
                      <div className="mt-3 justify-content-center text-center m-auto d-flex">
                        <h5 className="text-center mt-3 mb-3">
                          <Loader
                            type="TailSpin"
                            color="#38425B"
                            // color="#2196f3"
                            height="100"
                            width="100"
                          />
                        </h5>
                      </div>
                    )}

                  {/* No Data */}
                  {!this.state.emailView &&
                    announcementsData?.data &&
                    announcementsData?.data.results?.length === 0 && (
                      <div className="mt-3 justify-content-center text-center m-auto d-flex">
                        <div className="container mt-4">
                          <h1>No Announcements Yet!!!</h1>
                        </div>
                      </div>
                    )}

                  {this.state.emailView &&
                    this.props.emailAnnoucementsData?.data &&
                    this.props.emailAnnoucementsData?.data?.results?.length ==
                      0 && (
                      <div className="mt-3 justify-content-center text-center m-auto d-flex">
                        <div className="container mt-4">
                          <h1>No Announcements Yet!!!</h1>
                        </div>
                      </div>
                    )}

                  {/* Data */}
                  {!this.state.emailView &&
                    announcementsData &&
                    announcementsData?.results?.map((d, i, newarr) => {
                      return (
                        <div
                          className="doc_card m-3"
                          onClick={() => this.openModal(d)}
                          style={{ cursor: "pointer" }}
                          key={i}
                        >
                          <div className="p-3">
                            <div className="row justify-content-between">
                              <div className="col-md-8 d-flex">
                                {d?.created_by_details?.avatar === "" ? (
                                  <Avatar
                                    name={
                                      d?.created_by_details?.full_name?.split(
                                        " ("
                                      )[0]
                                    }
                                    size="35"
                                    round="50px"
                                    color="#38425B"
                                    // color="#1976d2"
                                    className="mt-1"
                                  />
                                ) : (
                                  <Avatar
                                    src={d?.created_by_details?.avatar}
                                    size="35"
                                    round="50px"
                                  />
                                )}
                                <h6 className="ml-1 mt-2">
                                  {d?.created_by_details?.full_name}
                                </h6>
                              </div>
                              <div className="col-md-4 peter">
                                <p className="mt-2">
                                  {moment(d?.created).format("MMM DD, YYYY")}
                                </p>
                              </div>
                            </div>
                            <div className="row justify-content-between">
                              <div className="col-md-8 ">
                                <h5>{d?.title}</h5>
                              </div>
                              <div className="col-md-4 d-flex peter_icon">
                                {d.unread_notifications === false ? (
                                  <FaEye className="mt-2" />
                                ) : (
                                  <FaEye
                                    className="mt-2"
                                    style={{ color: "blue" }}
                                  />
                                )}
                                <span className="ml-1 mt-2">
                                  {d?.read_qty}/{d?.total_qty}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  {this.state.emailView &&
                    this.props?.emailAnnoucementsData?.data &&
                    this.props?.emailAnnoucementsData?.data?.results?.length >
                      0 &&
                    this.props?.emailAnnoucementsData?.data?.results?.map(
                      (d, i, newarr) => {
                        return (
                          <div
                            className="doc_card m-3"
                            onClick={() => this.openModal(d)}
                            style={{ cursor: "pointer" }}
                            key={i}
                          >
                            <div className="p-3">
                              <div className="row justify-content-between">
                                <div className="col-md-8 d-flex">
                                  {d?.created_by_details?.avatar === "" ? (
                                    <Avatar
                                      name={
                                        d?.created_by_details?.full_name?.split(
                                          " ("
                                        )[0]
                                      }
                                      size="35"
                                      round="50px"
                                      color="#38425B"
                                      // color="#1976d2"
                                      className="mt-1"
                                    />
                                  ) : (
                                    <Avatar
                                      src={d?.created_by_details?.avatar}
                                      size="35"
                                      round="50px"
                                    />
                                  )}
                                  <h6 className="ml-1 mt-2">
                                    {d?.created_by_details?.full_name}
                                  </h6>
                                </div>
                                <div className="col-md-4 peter">
                                  <p className="mt-2">
                                    {moment(d?.created).format("MMM DD, YYYY")}
                                  </p>
                                </div>
                              </div>
                              <div className="row justify-content-between">
                                <div className="col-md-8 ">
                                  <h5>{d?.title}</h5>
                                </div>
                                <div className="col-md-4 d-flex peter_icon">
                                  {d.unread_notifications === false ? (
                                    <FaEye className="mt-2" />
                                  ) : (
                                    <FaEye
                                      className="mt-2"
                                      style={{ color: "blue" }}
                                    />
                                  )}
                                  <span className="ml-1 mt-2">
                                    {d?.read_qty}/{d?.total_qty}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}

                  {/* Next Previous Handler */}

                  {!this.state.emailView && (
                    <div className="d-flex justify-content-center">
                      {announcementsData?.previous && (
                        <BsArrowLeftSquareFill
                          className="announcement_pagination"
                          onClick={this.previousAnnouncementHandler}
                        />
                      )}
                      {announcementsData?.next && (
                        <BsArrowRightSquareFill
                          className="announcement_pagination"
                          onClick={this.nextAnnouncementHandler}
                        />
                      )}
                    </div>
                  )}

                  {this.state.emailView && (
                    <div className="d-flex justify-content-center">
                      {this.props?.emailAnnoucementsData?.data?.previous && (
                        <BsArrowLeftSquareFill
                          className="announcement_pagination"
                          onClick={this.previousEmailHandler}
                        />
                      )}
                      {this.props?.emailAnnoucementsData?.data?.next && (
                        <BsArrowRightSquareFill
                          className="announcement_pagination"
                          onClick={this.nextEmailHandler}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Dock>
        </div>

        {/* {isMessage && (
          <>
            <div className="announcement_dock">
              <MailBox isMessage={isMessage} handleMsgs={this.handleMsgs} />
            </div>
          </>
        )} */}

        <div className="announcement_dock">
          <Dock
            position="right"
            isVisible={isAddDocVisible}
            // fluid={isMobile ? false : undefined}
            // size={isMobile ? 300 : undefined}
          >
            <div className="dock_list_scroll">
              <div className="doc_header">
                <div className="p-3">
                  <div className="row justify-content-between">
                    <div className="col-md-12 d-flex justify-content-between">
                      <h4>
                        {isMessage === true
                          ? "Mail Announcement"
                          : "Create New Announcement"}
                      </h4>
                      <h4 className="">
                        <FaTimes
                          onClick={this.closeModal}
                          style={{ cursor: "pointer" }}
                        />
                      </h4>
                    </div>
                    {/* <div className="col-md-2 create_announce"></div> */}
                  </div>
                </div>
              </div>

              <div className="add_announcement_card m-3">
                <Formik
                  enableReinitialize={true}
                  initialValues={this.getInitialValue()}
                  validationSchema={announcementFormValidation}
                  onSubmit={this.onHandleSubmit}
                  onReset={this.closeModal}
                  innerRef={this.formRef}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    submitCount,
                    setFieldValue,
                    resetForm,
                  }) => {
                    const showError = (key) =>
                      displayFormErrors(key, errors, touched, submitCount);
                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group announce_form form_input ">
                          <Select
                            options={optionLocation || []}
                            isMulti
                            name="optionLocation"
                            value={this.state.optionLocation || []}
                            onChange={(e) => this.handleChangeLocation(e)}
                            placeholder="Select location"
                            isDisabled={this.props.usersDropdownDataLoading}
                          />
                          {/* {/ {!this.state.locationValue && showError('optionLocation')} /} */}
                        </div>
                        <div className="form-group announce_form form_input">
                          <Select
                            options={optionPosition}
                            isMulti
                            value={this.state.optionPosition}
                            onChange={(e) => this.handleChangeOption(e)}
                            placeholder="Select position"
                            isDisabled={this.props.usersDropdownDataLoading}
                          />
                          {/* {/ {showError('select')} /} */}
                        </div>
                        <div className="form-group announce_form form_input">
                          <Select
                            options={optionEmployee}
                            isMulti
                            value={this.state.optionEmployee}
                            onChange={(e) => this.handleChangeEmployee(e)}
                            placeholder="Select employee"
                            isDisabled={this.props.usersDropdownDataLoading}
                          />
                          {this.props.usersDropdownDataLoading && (
                            <Loader
                              type="TailSpin"
                              color="#38425B"
                              // color="#2196f3"
                              height="50"
                              width="50"
                              className="mt-3 d-flex justify-content-center align-items-center"
                            />
                          )}
                          {/* {/ {showError('select')} /} */}
                        </div>
                        {isMessage === true ? null : (
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              // label="DateTimePicker"
                              autoOk
                              variant="inline"
                              format="MM/dd/yyyy"
                              inputadornmentprops={{ position: "start" }}
                              inputVariant="outlined"
                              value={this.state.date}
                              name="date"
                              onChange={(date) => this.handleDateChange(date)}
                              maxDate={new Date()}
                            />
                          </MuiPickersUtilsProvider>
                        )}
                        <div className="form-group announce_form form_input">
                          <input
                            type="text"
                            className="form-control"
                            placeholder={
                              isMessage === true ? "Subject" : "Title"
                            }
                            name="title"
                            // onChange={e => { this.setState({ title: e.target.value }) }}
                            // value={this.state.title}
                            onChange={handleChange}
                            value={values.title}
                          />
                          {showError("title")}
                        </div>
                        <div className="form-group announce_form form_input">
                          {isMessage === true ? (
                            <>
                              <CKEditor
                                name="message"
                                config={{
                                  extraPlugins: [uploadPlugin],
                                }}
                                editor={ClassicEditor}
                                //data={values.message ? values.message : ""}
                                onChange={this.handleCk5change}
                                // onInit={(editor) => {
                                onReady={(editor) => {
                                  editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                      "height",
                                      "500px",
                                      editor.editing.view.document.getRoot()
                                    );
                                  });
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <textarea
                                className="form-control"
                                placeholder="Message"
                                name="message"
                                onChange={(e) =>
                                  this.setState({ message: e.target.value })
                                }
                                value={this.state.message}
                                // onChange={handleChange}
                                // value={values.message}
                              />
                              {showError("message")}
                            </>
                          )}
                        </div>

                        <div className="d-flex new_announce">
                          <div className="record_btn">
                            <button
                              className="btn btn-primary mr-2 custom_color"
                              //onClick={this.closeModal}
                              onClick={resetForm}
                              type="reset"
                            >
                              CANCEL
                            </button>
                          </div>
                          <div className="record_btn">
                            {isMessage === true ? (
                              <button
                                className="btn btn-primary custom_color"
                                type="submit"
                              >
                                SEND
                              </button>
                            ) : (
                              <button className="btn btn-primary" type="submit">
                                SAVE
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Dock>
        </div>

        <ReactTooltip id="notification-tip" place="bottom" />
        <ReactTooltip id="announcement-tip" place="bottom" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    announcementsDataLoading: state.announcementsData.isLoading,
    announcementsData: state.announcementsData.data,

    createAnnouncementsDataLoading: state.createAnnouncementsData.isLoading,
    createAnnouncementsData: state.createAnnouncementsData.data,

    groupsDataLoading: state.groupsData.isLoading,
    groupsData: state.groupsData.data,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    //usersDataLoading: state.usersData.isLoading,
    //usersData: state.usersData.data,

    announcementsByIdReducer: state.announcementsByIdReducer.data,

    usersDropdownData: state.usersDropdownData.data,
    usersDropdownDataLoading: state.usersDropdownData.isLoading,

    globalSearch: state.globalSearch.searchQuery,

    profileData: state.profileData.data,

    emailAnnoucementsData: state?.emailAnnouncementsData,
    deviceToken: state.deviceToken,
    chatUnreadCount: state.chatUnreadCount?.data,
  };
};
const mapDispatchToProps = {
  fetchAnnouncements,
  fetchGroups,
  fetchRestaurants,
  //fetchUsers,
  fetchCreateAnnouncements,
  setSearchQuery,
  fetchProfile,
  fetchAnnoucementEmail,
  fetchUsersDropdown,
  fetchAnnouncementsById,
  fetchEmailAnnouncements,
  fetchChatCounts,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
