import {
  GET_EMAIL_ANNOUNCEMENTS_REQUEST,
  GET_EMAIL_ANNOUNCEMENTS_SUCCESS,
  GET_EMAIL_ANNOUNCEMENTS_FAILURE,
} from "../../types/announcements";
import { getApi } from "../api";

export const getEmailRequest = () => ({
  type: GET_EMAIL_ANNOUNCEMENTS_REQUEST,
});

export const getEmailSuccess = (data) => ({
  type: GET_EMAIL_ANNOUNCEMENTS_SUCCESS,
  data: data,
});

export const getEmailFailure = (error) => ({
  type: GET_EMAIL_ANNOUNCEMENTS_FAILURE,
  error,
});

export const fetchEmailAnnouncements = (params) => (dispatch, getState) => {
  dispatch(getEmailRequest());
  return getApi(`bshift/api/announcements`, { is_email: true, ...params })
    .then((data) => {
      dispatch(getEmailSuccess(data));
    })
    .catch((error) => {
      dispatch(getEmailFailure(error));
    });
  // if (page) {
  //   dispatch(getEmailRequest());
  //   return getApi(`bshift/api/announcements`, { is_email: true, page: page })
  //     .then((data) => {
  //       dispatch(getEmailSuccess(data));
  //     })
  //     .catch((error) => {
  //       dispatch(getEmailFailure(error));
  //     });
  // } else {
  //   dispatch(getEmailRequest());
  //   return getApi(`bshift/api/announcements`, { is_email: true })
  //     .then((data) => {
  //       dispatch(getEmailSuccess(data));
  //     })
  //     .catch((error) => {
  //       dispatch(getEmailFailure(error));
  //     });
  // }
};
