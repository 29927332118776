import { toast } from "react-toastify";
import moment from "moment";

export const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export function getDate(day, inDateStart = null, inWeekNum = 0) {
  let d = new Date(inDateStart);
  // let d = new Date(formatDateISO(inDateStart));
  d.setDate(d.getDate() - d.getDay() + day + inWeekNum * 7);
  d.setHours(0, 0, 0, 0);
  return d;
}

export function getDateShortFormatted(day, inDateStart, inWeekNum) {
  let d = getDate(day, inDateStart, inWeekNum);
  return formatDateShort(d);
}

export function formatDateShort(date) {
  if (!date) return null;

  const [month, day] = [
    date.toLocaleString("default", { month: "short" }),
    date.getDate(),
  ];
  return `${month}, ${day}`;
}

export function formatDate(date) {
  if (!date) return null;
  const [year, month, day] = [
    date.getFullYear(),
    // date.getYear() + 1900,
    date.getMonth() + 1,
    date.getDate(),
  ];
  return `${month}/${day}/${year}`;
}

export function getDay(day) {
  return days[day - 1];
}

export function testCurrent(day, inDateStart, inWeekNum) {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  if (getDate(day, inDateStart, inWeekNum).getTime() === today.getTime()) {
    return "active";
  }
  return "";
}

export function hourConvert(time) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}

export function formatDateISO(d = null) {
  if (!d) return null;

  let date = new Date(d);
  let [year, month, day] = [
    date.getFullYear(),
    // date.getYear() + 1900,
    date.getMonth() + 1,
    date.getDate(),
  ];
  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  return `${year}-${month}-${day}`;
}

export function invertHex(hex) {
  if (!hex) {
    return "#000";
  }
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 8) {
    hex = hex.slice(0, 6);
  }
  // convert 3-digit hex to 6-digits.
  else if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    return "#000000";
  }

  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";

  // // // invert color components
  // r = (255 - r).toString(16);
  // g = (255 - g).toString(16);
  // b = (255 - b).toString(16);
  // // pad each with zeros and return
  // let ret = "#" + this.padZero(r) + this.padZero(g) + this.padZero(b) + ' !important';
  // return ret;
}

export function strTimeFormat(value) {
  if (!value) return "";
  let a = value.split(":");
  a.pop();
  a[0] = parseInt(a[0]);
  let dp = "";
  if (a[0] > 12) {
    a[0] = a[0] - 12;
    dp = " PM";
  } else {
    dp = " AM";
  }
  return a.join(":") + dp;
}

const timeFormat = "ddd MM YYYY h:mm a";

export const isValidArray = (data) => {
  return data && Array.isArray(data) && data.length > 0;
};

export const fileToBase64 = (e) => {
  return new Promise((resolve, reject) => {
    if (e && e.target && e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();

      // Read file content on file loaded event
      reader.onload = function (event) {
        resolve({
          file: event.target.result,
          type: getFileType(file.type),
        });
      };

      // Convert data to base64
      reader.readAsDataURL(file);
    } else {
      reject(new Error("Something went wrong."));
    }
  });
};

export const getFileType = (type) => {
  if (type.startsWith("video/")) {
    return "video";
  }
  if (type.startsWith("image/")) {
    return "image";
  }
  return type;
};

export const isValidObject = (data) => {
  return typeof data === "object" && data
    ? Object.keys(data).length > 0
    : false;
};

export const apiToastSuccess = (data) => {
  if (data && data.data && data.data.message) {
    toast.success(data.data.message);
  }
};

export const apiToastError = (error) => {
  // const Msg = () => (
  // 	<div>
  // 		<i className="fas fa-exclamation"></i>Lorem ipsum dolor {1}
  // 	</div>
  // );
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    toast.dark(error.response.data.message, {
      className: "dark-error",
    });
  } else if (error && error.message) {
    toast.dark(error.message, {
      className: "dark-error",
    });
  }
};

export const toastSuccess = (message) => {
  toast.success(message, {
    className: "dark-success",
  });
};

export const toastError = (message) => {
  toast.success(message, {
    className: "dark-error",
  });
};

export const convertUTC = (date) => {
  return moment.utc(date).format(timeFormat);
};

export const formatCost = (d) => {
  return d !== undefined && !isNaN(d) ? d.toFixed(2) : 0;
};

export const activityTimeFormat = (date) => {
  return moment.utc(date).format("ddd, MMMM DD");
};

export const getTodayDate = () => {
  return moment().format("YYYY-MM-DD");
};

export const randomColor = () => {
  return Math.floor(Math.random() * 16777215).toString(16);
};

export const sortSelectOptionWithLabels = (data) => {
  if (data !== undefined && data && data.length > 0) {
    return data.sort((a1, b1) => {
      const a = a1.label.toLowerCase();
      const b = b1.label.toLowerCase();
      // if (a < b) return -1;
      // if (a > b) return 1;
      // return 0;
      return a.localeCompare(b);
    });
  }
  return data;
};

export const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontSize: "12px",
    },
  },
};

export const getPermission = (str, profileData) => {
  const result = profileData?.permissions.includes(str);
  if (result) return true;
  else return false;
};

export const getRoundValue = (number) => {
  return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
};
