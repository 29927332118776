import {
	FETCH_CATEGORY_DETAIL_REQUEST,
	FETCH_CATEGORY_DETAIL_SUCCESS,
	FETCH_CATEGORY_DETAIL_FAILURE,
} from '../../types/categories';
import { getApi } from '../api';

export const fetchCategoryDetailRequest = () => ({
	type: FETCH_CATEGORY_DETAIL_REQUEST,
});

export const fetchCategoryDetailSuccess = (data) => ({
	type: FETCH_CATEGORY_DETAIL_SUCCESS,
	data: data,
});

export const fetchCategoryDetailFailure = (error) => ({
	type: FETCH_CATEGORY_DETAIL_FAILURE,
	error,
});

export const fetchCategoryDetail = (id) => (dispatch, getState) => {
	dispatch(fetchCategoryDetailRequest());
	return getApi(`bshift/api/categories/${id}`)
		.then((data) => {
			dispatch(fetchCategoryDetailSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchCategoryDetailFailure(error));
		});
};
