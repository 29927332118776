import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik } from "formik";
import {
  displayFormErrors,
  addPositionValidation,
} from "../../../modules/validation";
import { ChromePicker } from "react-color";
import { isValidArray, isValidObject } from "../../../modules/utils";
import Select from "react-select";
import { connect } from "react-redux";

import { fetchEditGroup } from "../../../actions/groups/editGroup";
class AddPositionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      color: "",
      permission: "",
      optionPermission: null,
      permissionValue: null,
      isCategory: false,
    };
  }

  componentDidMount() {
    const { fetchPermissionData, data } = this.props;
    const permission =
      data && isValidArray(data[4])
        ? data[4]
            .map((d) => {
              const value = fetchPermissionData?.results.find((i) => {
                if (i.id == d) return i;
              });
              return {
                value: value ? value.id : "",
                label: value ? value.name : "",
              };
            })
            .filter((d) => d.value !== "")
        : [];
    const permissionValue = permission.map((d) => d.value);
    this.setState({
      optionPermission: permission,
      permissionValue,
    });
  }

  componentWillUnmount() {
    this.setState({
      optionPermission: null,
      permissionValue: null,
    });
  }

  handleChange = (setFieldValue, value) => {
    setFieldValue("color", value);
  };

  handleCategory = (setFieldValue, value) => {
    setFieldValue("category", value);
    this.setState({
      isCategory: value ? false : true,
    });
  };

  handleSubmit = (values) => {
    if (isValidObject(values.category)) {
      const { permissionValue } = this.state;
      const { data, toggle, fetchEditGroup, createGroups } = this.props;
      const passData = {
        color: values.color,
        permissions: permissionValue,
        name: values.name,
        category: values.category.value,
      };
      if (isValidArray(data)) {
        fetchEditGroup(data[0], passData).then(() => {
          toggle();
        });
      } else {
        createGroups(passData);
      }
    } else {
      this.setState({
        isCategory: true,
      });
    }
  };

  handlePermission = (optionPermission) => {
    const permissionValue = optionPermission.map((d) => {
      return d.value;
    });
    this.setState({ optionPermission, permissionValue });
  };

  getInitialValue = () => {
    const { data, categoriesData } = this.props;
    const category =
      data &&
      data[2] &&
      categoriesData?.results.find(
        (d) => d.title.toLowerCase() === data[2].toLowerCase()
      );

    return {
      name: data && data[1] ? data[1] : "",
      category: isValidObject(category)
        ? { value: category?.id, label: category?.title }
        : "",
      color: data && data[3] ? data[3] : "",
    };
  };

  render() {
    const {
      isOpen,
      toggle,
      categoriesData,
      fetchPermissionData,
      data,
    } = this.props;
    const { isCategory } = this.state;
    const optionPermission =
      isValidArray(fetchPermissionData?.results) &&
      fetchPermissionData?.results?.map((d) => {
        return {
          value: d.id,
          label: d.name,
        };
      });
    const optionCategory =
      categoriesData &&
      categoriesData?.results.map((d) => {
        return {
          value: d.id,
          label: d.title,
        };
      });

    return (
      <Modal isOpen={isOpen} className="add_position_model">
        <ModalHeader toggle={toggle}>
          {isValidArray(data) ? "Edit Group" : "New Group"}
        </ModalHeader>

        <ModalBody>
          <Formik
            initialValues={this.getInitialValue()}
            validationSchema={addPositionValidation}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              submitCount,
              setFieldValue,
            }) => {
              const showError = (key) =>
                displayFormErrors(key, errors, touched, submitCount);
              return (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Name</label>
                      <input
                        type="text"
                        value={values.name}
                        name="name"
                        onChange={handleChange}
                      />
                      {showError("name")}
                    </div>
                    <div className="col-md-6">
                      <label>Category</label>
                      <Select
                        options={optionCategory}
                        value={values.category}
                        onChange={(e) => this.handleCategory(setFieldValue, e)}
                        placeholder="Select Category"
                        name="category"
                        // clearValue={ () => void()}
                        isClearable
                        className="form_select"
                      />
                      {isCategory && (
                        <p style={{ color: "red" }}>This field is required.</p>
                      )}
                    </div>
                    <div className="col-md-12">
                      <label>Permission</label>
                      <Select
                        options={optionPermission}
                        isMulti
                        name="optionPermission"
                        value={this.state.optionPermission}
                        onChange={(e) => this.handlePermission(e)}
                        placeholder="Select permission"
                      />
                    </div>
                    <div className="col-md-12">
                      <label>Color</label>
                      <input
                        type="text"
                        value={values.color}
                        name="color"
                        readOnly
                      />
                      <ChromePicker
                        className="color_picker"
                        color={values.color}
                        onChangeComplete={(e) =>
                          this.handleChange(setFieldValue, e?.hex)
                        }
                      />
                    </div>

                    <ModalFooter>
                      <div className="record_btn" onClick={toggle}>
                        <button>CANCEL</button>
                      </div>
                      <div className="record_btn" onClick={handleSubmit}>
                        <button>SAVE</button>{" "}
                      </div>
                    </ModalFooter>
                  </div>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    editGroupDataLoading: state.editGroupData.isLoading,
    editGroupData: state.editGroupData.data,
  };
};
const mapDispatchToProps = {
  fetchEditGroup,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPositionModal);
