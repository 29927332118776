import { getApi } from "../api";
import {
  FETCH_CHATMESSAGES_REQUEST,
  FETCH_CHATMESSAGES_SUCCESS,
  FETCH_CHATMESSAGES_FAILURE,
} from "../../types/chat";

export const fetchChatMessagesRequest = () => ({
  type: FETCH_CHATMESSAGES_REQUEST,
});

export const fetchChatMessagesSuccess = (data) => ({
  type: FETCH_CHATMESSAGES_SUCCESS,
  data: data,
});

export const fetchChatMessagesFailure = (error) => ({
  type: FETCH_CHATMESSAGES_FAILURE,
  error,
});

export const fetchChatMessages = (id, flag, page) => (dispatch, getState) => {
  dispatch(fetchChatMessagesRequest());
  if (!page) {
    page = 1;
  }

  let queryParams = [];
  if (flag == 0) {
    queryParams = [...queryParams, `recipient_id=${id}`];
  } else if (flag == 1) {
    queryParams = [...queryParams, `group_id=${id}`];
  }
  return getApi(`bshift/api/chat?${queryParams}&page=${page}`)
    .then((data) => {
      dispatch(fetchChatMessagesSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchChatMessagesFailure(error));
    });
};
