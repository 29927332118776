import { SET_SEARCH_QUERY } from '../../types/common';

const globalSearchReducer = (
	state = {
		searchQuery: null,
	},
	action,
) => {
	switch (action.type) {
		case SET_SEARCH_QUERY:
			return {
				...state,
				searchQuery: action.value,
			};
		default:
			return state;
	}
};

export default globalSearchReducer;
