import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { fetchCreateTodo } from '../../actions/todo/create';

import { displayFormErrors } from '../../modules/validation';

class AddTodoPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			commentList: [],
			comment: '',
		};
	}

	componentDidMount() {}

	doSubmitForm = (values) => {
		const { restaurantId, toggle } = this.props;

		const apiData = {
			title: values.title,
			description: values.description,
			restaurant: restaurantId,
			created: parseInt(localStorage.getItem('loggedUserId')),
		};

		this.props.fetchCreateTodo(apiData).then(() => {
			const { todoCreate, getTodoList } = this.props;

			if (todoCreate) {
				getTodoList();
				toggle();
			}
		});
	};

	render() {
		return (
			<Modal isOpen={true} size="lg" centered>
				<ModalHeader toggle={this.props.toggle}>
					<h5 className="modal-title" id="exampleModalLabel">
						Add Todo
					</h5>
				</ModalHeader>
				<ModalBody>
					<Formik
						initialValues={{}}
						validationSchema={Yup.object().shape({
							title: Yup.string().required('This field is required.'),
							description: Yup.string().required('This field is required.'),
						})}
						onSubmit={this.doSubmitForm}
					>
						{({
							values,
							errors,
							touched,
							submitCount,
							handleChange,
							handleSubmit,
						}) => {
							const showError = (key) =>
								displayFormErrors(key, errors, touched, submitCount);
							return (
								<Form>
									<div className="form-group">
										<label for="recipient-name" className="col-form-label">
											Title:
										</label>
										<input
											type="text"
											className="form-control"
											id="recipient-name"
											name="title"
											value={values?.title}
											onChange={handleChange}
										/>
									</div>
									<div className="form-group">
										<label for="message-text" className="col-form-label">
											Description:
										</label>
										<textarea
											className="form-control"
											id="message-text"
											name="description"
											value={values?.description}
											onChange={handleChange}
										></textarea>
									</div>
									<div className="form-group">
										<button
											type="button"
											className="btn btn-primary"
											onClick={handleSubmit}
										>
											Add
										</button>
									</div>
								</Form>
							);
						}}
					</Formik>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		todoCreateIsLoading: state.todoCreate.isLoading,
		todoCreate: state.todoCreate.data,
	};
};
const mapDispatchToProps = {
	fetchCreateTodo,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTodoPopup);
