import * as types from "../types/deviceToken";

export const setDeviceTokenAction = (token) => ({
  type: types.SET_DEVICE_TOKEN,
  data: token,
});

export const chatUserObjectAction = (id) => ({
  type: types.CHAT_USER_OBJECT,
  data: id,
});
