import { toastError, toastSuccess } from '../../modules/utils';
import {
	FETCH_CREATE_TODO_TASK_REQUEST,
	FETCH_CREATE_TODO_TASK_SUCCESS,
	FETCH_CREATE_TODO_TASK_FAILURE,
} from '../../types/todo';
import { postApi } from '../api';

export const fetchCreateTodoTaskRequest = () => ({
	type: FETCH_CREATE_TODO_TASK_REQUEST,
});

export const fetchCreateTodoTaskSuccess = (data) => ({
	type: FETCH_CREATE_TODO_TASK_SUCCESS,
	data: data,
});

export const fetchCreateTodoTaskFailure = (error) => ({
	type: FETCH_CREATE_TODO_TASK_FAILURE,
	error,
});

export const fetchCreateTodoTask = (apiData) => (dispatch) => {
	dispatch(fetchCreateTodoTaskRequest());
	return postApi(`restaurant/api/todo_task_create`, apiData)
		.then((data) => {
			toastSuccess('Record created successfully.');
			dispatch(fetchCreateTodoTaskSuccess(data));
		})
		.catch((error) => {
			toastError('Something went wrong!');
			dispatch(fetchCreateTodoTaskFailure(error));
		});
};
