import {
  FETCH_EMAIL_ANNOUNCEMENTS_REQUEST,
  FETCH_EMAIL_ANNOUNCEMENTS_SUCCESS,
  FETCH_EMAIL_ANNOUNCEMENTS_FAILURE,
} from "../../types/announcements";
import { postApi } from "../api";

export const fetchAnnoucementEmailRequest = () => ({
  type: FETCH_EMAIL_ANNOUNCEMENTS_REQUEST,
});

export const fetchAnnoucementEmailSuccess = (data) => ({
  type: FETCH_EMAIL_ANNOUNCEMENTS_SUCCESS,
  data: data,
});

export const fetchAnnoucementEmailFailure = (error) => ({
  type: FETCH_EMAIL_ANNOUNCEMENTS_FAILURE,
  error,
});

export const fetchAnnoucementEmail = (data) => (dispatch, getState) => {
  data.append("is_email", true);
  dispatch(fetchAnnoucementEmailRequest());
  return postApi(`bshift/api/announcementsEmail`, data)
    .then((data) => {
      dispatch(fetchAnnoucementEmailSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchAnnoucementEmailFailure(error));
    });
};
