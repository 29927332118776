import {
  FETCH_LOG_RECORDS_FIELD_REQUEST,
  FETCH_LOG_RECORDS_FIELD_FAILURE,
  FETCH_LOG_RECORDS_FIELD_SUCCESS,
} from "../../types/managerLogs";
import { getApi } from "../api";

export const fetchLogRecordsFieldRequest = () => ({
  type: FETCH_LOG_RECORDS_FIELD_REQUEST,
});

export const fetchLogRecordsFieldSuccess = (data) => ({
  type: FETCH_LOG_RECORDS_FIELD_SUCCESS,
  data: data,
});

export const fetchLogRecordsFieldFailure = (error) => ({
  type: FETCH_LOG_RECORDS_FIELD_FAILURE,
  error,
});

export const fetchLogRecordsField = (formData) => (dispatch, getState) => {
  dispatch(fetchLogRecordsFieldRequest());

  return getApi(`bshift/api/logrecordfields?restaurant_id=${formData}`)
    .then((data) => {
      dispatch(fetchLogRecordsFieldSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchLogRecordsFieldFailure(error));
    });
};
