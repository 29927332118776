import {
	FETCH_TODO_TASK_DELETE_REQUEST,
	FETCH_TODO_TASK_DELETE_SUCCESS,
	FETCH_TODO_TASK_DELETE_FAILURE,
} from '../../types/todo';
import { deleteApi } from '../api';
import { toastError, toastSuccess } from '../../modules/utils';

export const fetchTodoTaskDeleteRequest = () => ({
	type: FETCH_TODO_TASK_DELETE_REQUEST,
});

export const fetchTodoTaskDeleteSuccess = (data) => ({
	type: FETCH_TODO_TASK_DELETE_SUCCESS,
	data: data,
});

export const fetchTodoTaskDeleteFailure = (error) => ({
	type: FETCH_TODO_TASK_DELETE_FAILURE,
	error,
});

export const fetchTodoTaskDelete = (id) => (dispatch) => {
	dispatch(fetchTodoTaskDeleteRequest());
	return deleteApi(`restaurant/api/todo_task_delete/${id}`)
		.then((data) => {
			toastSuccess('Record deleted successfully.');
			dispatch(fetchTodoTaskDeleteSuccess(data));
		})
		.catch((error) => {
			toastError('Something went wrong!');
			dispatch(fetchTodoTaskDeleteFailure(error));
		});
};
