import {
	FETCH_LOG_RECORD_REQUEST,
	FETCH_LOG_RECORD_FAILURE,
	FETCH_LOG_RECORD_SUCCESS,
} from '../../types/managerLogs';
import { getApi } from '../api';

export const fetchLogRecordRequest = () => ({
	type: FETCH_LOG_RECORD_REQUEST,
});

export const fetchLogRecordSuccess = (data) => ({
	type: FETCH_LOG_RECORD_FAILURE,
	data: data,
});

export const fetchLogRecordFailure = (error) => ({
	type: FETCH_LOG_RECORD_SUCCESS,
	error,
});

export const fetchLogRecord = (formData) => (dispatch, getState) => {
	dispatch(fetchLogRecordRequest());

	return getApi(`bshift/api/logrecord/${formData}`, formData)
		.then((data) => {
			dispatch(fetchLogRecordSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchLogRecordFailure(error));
		});
};
