import React, { Component } from 'react';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';

import { fetchTodoActivity } from '../../actions/todo/activity';
import {
	activityTimeFormat,
	convertUTC,
	getTodayDate,
	isValidArray,
} from '../../modules/utils';

export class TodoActivity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDetailOpen: false,
		};
	}

	componentDidMount() {
		const { restaurantId } = this.props;

		this.props.fetchTodoActivity({ id: restaurantId });
	}

	renderActivityMessage = (data) => {
		const message = () => {
			if (data?.activity_type === 'CMT') {
				return (
					<p>
						Under <strong>{data?.todo?.title}:</strong>{' '}
						{data?.assigned_from?.full_name} added a commment to{' '}
						<a href="">{data?.todo_task?.title} </a>
					</p>
				);
			} else if (data?.activity_type === 'ASN') {
				return (
					<p>
						Under <strong>{data?.todo?.title}:</strong>{' '}
						{data?.assigned_from?.full_name} tagged{' '}
						{data?.assigned_to?.full_name} in assignment
						<a href="javascript:void(0)" role="button">
							{data?.todo_task?.title}{' '}
						</a>
					</p>
				);
			}
			return '';
		};

		return (
			<div className="task_ComplateGride p-3" key={data?.id}>
				<div className="author_avatar">
					<Avatar
						name={data?.assigned_from?.full_name || ''}
						size="25"
						round="50px"
					/>
					{/* <div className="tbl__avatar green">
						<span className="white--text-cust">DR</span>
					</div> */}
				</div>
				{message()}
				<span className="avatarname">{convertUTC(data?.created)}</span>
			</div>
		);
	};

	groupByActivity = () => {
		const { todoActivity } = this.props;

		if (isValidArray(todoActivity?.results)) {
			// this gives an object with dates as keys
			const groups = todoActivity?.results.reduce((groups, activity) => {
				const date = activity?.created?.split('T')[0];
				if (!groups[date]) {
					groups[date] = [];
				}
				groups[date].push(activity);
				return groups;
			}, {});

			// Edit: to add it in the array format instead
			return Object.keys(groups).map((date) => {
				return {
					date,
					activity: groups[date],
				};
			});
		}
	};

	render() {
		const todoActivity = this.groupByActivity();

		return (
			<React.Fragment>
				<h5>Activity</h5>
				<div className="Grid_Card">
					{isValidArray(todoActivity) &&
						todoActivity?.map((activityGroup, i) => (
							<React.Fragment key={i}>
								<div className="task_top">
									<h6>{activityTimeFormat(activityGroup?.date)}</h6>
								</div>
								{isValidArray(activityGroup?.activity) &&
									activityGroup?.activity?.map((activity) =>
										this.renderActivityMessage(activity),
									)}
							</React.Fragment>
						))}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		todoActivityLoading: state.todoActivity.isLoading,
		todoActivity: state.todoActivity.data,
	};
};

const mapDispatchToProps = { fetchTodoActivity };

export default connect(mapStateToProps, mapDispatchToProps)(TodoActivity);
