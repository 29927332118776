import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';

import TodoCard from './TodoCard';

import { fetchTodoList } from '../../actions/todo/list';
import { isValidArray } from '../../modules/utils';

export class Assignments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			restaurantId: '',
		};
	}

	componentDidMount() {
		this.setState({ restaurantId: this.getTodoList() }, () =>
			this.getTodoList(),
		);
	}

	getRestaurantId = () => {
		const { match } = this.props;
		return match && match.params && match.params.restaurantId
			? match.params.restaurantId
			: '';
	};

	getTodoList = () => {
		this.props.fetchTodoList({ id: this.getRestaurantId() });
	};

	render() {
		const { todoList, globalSearch } = this.props;

		return (
			<div className="col-12 col-sm-6">
				<h5>Assignments</h5>
				{isValidArray(todoList?.results) &&
					todoList?.results
						.filter((d) =>
							globalSearch ? d.title?.toLowerCase().includes(globalSearch) : d,
						)
						.map((todo) => (
							<div className="Grid_Card mb-2" key={todo?.id}>
								<TodoCard
									key={todo?.id}
									data={todo}
									getTodoList={this.getTodoList}
								/>
							</div>
						))}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		todoListIsLoading: state.todoList.isLoading,
		todoList: state.todoList.data,

		globalSearch: state.globalSearch.searchQuery,
	};
};

const mapDispatchToProps = { fetchTodoList };

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(Assignments);
