import {
  FETCH_ANNOUNCEMENTS_REQUEST,
  FETCH_ANNOUNCEMENTS_SUCCESS,
  FETCH_ANNOUNCEMENTS_FAILURE,
} from "../../types/announcements";
import { getApi } from "../api";

export const fetchAnnouncementsRequest = () => ({
  type: FETCH_ANNOUNCEMENTS_REQUEST,
});

export const fetchAnnouncementsSuccess = (data) => ({
  type: FETCH_ANNOUNCEMENTS_SUCCESS,
  data: data,
});

export const fetchAnnouncementsFailure = (error) => ({
  type: FETCH_ANNOUNCEMENTS_FAILURE,
  error,
});

export const fetchAnnouncements = (params) => (dispatch, getState) => {
  dispatch(fetchAnnouncementsRequest());
  // if (params?.page) {
  return getApi(`bshift/api/announcements`, params)
    .then((data) => {
      dispatch(fetchAnnouncementsSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchAnnouncementsFailure(error));
    });
  // } else {
  //   return getApi(`bshift/api/announcements`)
  //     .then((data) => {
  //       dispatch(fetchAnnouncementsSuccess(data));
  //     })
  //     .catch((error) => {
  //       dispatch(fetchAnnouncementsFailure(error));
  //     });
  // }
};
