import { toast } from "react-toastify";
import * as types from "../../types/restaurantGroup";
import { postApi } from "../api";

export const createRestaurantGroupRequest = () => ({
  type: types.FETCH_RESTAURANT_GROUP_CREATE_REQUEST,
});

export const createRestaurantGroupSuccess = (data) => ({
  type: types.FETCH_RESTAURANT_GROUP_CREATE_SUCCESS,
  data: data,
});

export const createRestaurantGroupFailure = (err) => ({
  type: types.FETCH_RESTAURANT_GROUP_CREATE_FAILURE,
  data: err,
});

export const createRestaurantGroup = (apiData) => async (
  dispatch,
  getState
) => {
  dispatch(createRestaurantGroupRequest());
  try {
    const data = await postApi(`bshift/api/restaurantbrand`, apiData);
    dispatch(createRestaurantGroupSuccess(data.data));
    toast.success("Restaurant Group created successfully!!!");
  } catch (err) {
    dispatch(createRestaurantGroupFailure(err));
    toast.error("Something went wrong in creating restaurant group!!!");
  }
};
