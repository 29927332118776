import {
  FETCH_MANAGER_LOGS_REQUEST,
  FETCH_MANAGER_LOGS_FAILURE,
  FETCH_MANAGER_LOGS_SUCCESS,
} from "../../types/managerLogs";
import { getApi } from "../api";

export const fetchManagerLogsRequest = () => ({
  type: FETCH_MANAGER_LOGS_REQUEST,
});

export const fetchManagerLogsSuccess = (data) => ({
  type: FETCH_MANAGER_LOGS_SUCCESS,
  data: data,
});

export const fetchManagerLogsFailure = (error) => ({
  type: FETCH_MANAGER_LOGS_FAILURE,
  error,
});

export const fetchManagerLogs = (formData) => (dispatch, getState) => {
  dispatch(fetchManagerLogsRequest());

  const {
    itemPerPage,
    page,
    search,
    mustSort,
    multiSort,
    date,
    restaurant,
    sort_field,
    sort_type,
  } = formData;

  let queryParams = [];
  if (search || search === "") {
    queryParams = [...queryParams, `search=${search}`];
  }
  if (page) {
    queryParams = [...queryParams, `page=${page}`];
  }
  if (itemPerPage !== "") {
    queryParams = [...queryParams, `itemsPerPage=${itemPerPage}`];
  }
  if (mustSort || !mustSort) {
    queryParams = [...queryParams, `mustSort=${mustSort}`];
  }
  if (multiSort || !multiSort) {
    queryParams = [...queryParams, `multiSort=${multiSort}`];
  }
  if (date) {
    queryParams = [...queryParams, `date=${date}`];
  }
  if (restaurant) {
    queryParams = [...queryParams, `restaurants[]=${restaurant}`];
  }
  if (sort_field) {
    queryParams = [...queryParams, `sortBy[]=${sort_field}`];
  }
  if (sort_field) {
    queryParams = [...queryParams, `sortDesc[]=${sort_type}`];
  }
  return getApi(`bshift/api/logrecord?${queryParams.join("&")}`)
    .then((data) => {
      dispatch(fetchManagerLogsSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchManagerLogsFailure(error));
    });
};
