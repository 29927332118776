import { toast } from 'react-toastify';
import {
	FETCH_EDIT_GROUPS_REQUEST,
	FETCH_EDIT_GROUPS_SUCCESS,
	FETCH_EDIT_GROUPS_FAILURE,
} from '../../types/groups';
import { putApi } from '../api';

export const fetchEditGroupRequest = () => ({
	type: FETCH_EDIT_GROUPS_REQUEST,
});

export const fetchEditGroupSuccess = (data) => ({
	type: FETCH_EDIT_GROUPS_SUCCESS,
	data: data,
});

export const fetchEditGroupFailure = (error) => ({
	type: FETCH_EDIT_GROUPS_FAILURE,
	error,
});

export const fetchEditGroup = (id, formData) => (dispatch, getState) => {
	dispatch(fetchEditGroupRequest());
	return putApi(`users/api/groups/${id}`, formData)
		.then((data) => {
			toast.success('Record updated successfully!');
			dispatch(fetchEditGroupSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchEditGroupFailure(error));
		});
};
