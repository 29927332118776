import { toast } from "react-toastify";
import {
  EMPLOYEE_CSV_FAILURE,
  EMPLOYEE_CSV_SUCCESS,
  EMPLOYEE_CSV_REQUEST,
} from "../../types/employee";
import { postApi } from "../api";

export const employeeCsvRequest = () => ({
  type: EMPLOYEE_CSV_REQUEST,
});

export const employeeCsvSuccess = (data) => ({
  type: EMPLOYEE_CSV_SUCCESS,
  data: data,
});

export const employeeCsvFailure = (error) => ({
  type: EMPLOYEE_CSV_FAILURE,
  error,
});

export const employeeCsvEvent = (data) => (dispatch, getState) => {
  dispatch(employeeCsvRequest());
  return postApi("users/api/importemploye", data)
    .then((data) => {
      if (data?.data?.status_code == 200) {
        toast.success("Employee added successfully!");
        dispatch(employeeCsvSuccess(data));
      } else {
        toast.error(data?.data?.status_code);
      }
    })
    .catch((error) => {
      toast.error("Something went to wrong");
      dispatch(employeeCsvFailure(error));
    });
};
