import {
	FETCH_CREATE_PICKUP_REQUEST_REQUEST,
	FETCH_CREATE_PICKUP_REQUEST_SUCCESS,
	FETCH_CREATE_PICKUP_REQUEST_FAILURE,
} from '../../types/schedule';
import { postApi } from '../api';

export const fetchCreatePickupRequestRequest = () => ({
	type: FETCH_CREATE_PICKUP_REQUEST_REQUEST,
});

export const fetchCreatePickupRequestSuccess = (data) => ({
	type: FETCH_CREATE_PICKUP_REQUEST_SUCCESS,
	data: data,
});

export const fetchCreatePickupRequestFailure = (error) => ({
	type: FETCH_CREATE_PICKUP_REQUEST_FAILURE,
	error,
});

export const fetchCreatePickupRequest = (data) => (dispatch, getState) => {
	dispatch(fetchCreatePickupRequestRequest());

	return postApi(`bshift/api/schedule_pickup_request`, data)
		.then((data) => {
			dispatch(fetchCreatePickupRequestSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchCreatePickupRequestFailure(error));
		});
};
