import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { fetchMyQuiz } from "../../actions/training/myQuiz";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import moment from "moment";

import QuizModal from "./modal/QuizModal";
import { customStyles } from "../../modules/utils";

export class MyQuizzes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      quizData: null,
      page: 1,
      search: "",
      itemsPerPage: 10,
      sort_field: "title",
      sort_type: false,
    };
  }

  componentDidMount() {
    this.getList();
    this.props.fetchRestaurants();
  }

  getList = () => {
    const { page, itemsPerPage, search, sort_field, sort_type } = this.state;
    return this.props.fetchMyQuiz(
      page,
      itemsPerPage,
      search,
      sort_field,
      sort_type
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page,
      },
      () => {
        this.getList();
      }
    );
  };

  handleRowPerPage = (itemsPerPage) => {
    this.setState(
      {
        itemsPerPage,
      },
      () => {
        this.getList();
      }
    );
  };

  handleSort = (e) => {
    this.setState(
      {
        sort_field: e.name,
        // sort_field: e.selector,
        sort_type: !this.state.sort_type,
      },
      () => {
        this.getList();
      }
    );
  };

  getUserListing = () => {
    const { myQuizData, restaurantsData, globalSearch } = this.props;

    return myQuizData?.results
      ?.map((d) => {
        const restaurant = restaurantsData?.results.find((i) => {
          if (i.id === d.restaurant) return i;
        });

        return {
          id: d.id || "",
          title: d.title || "",
          restaurant: restaurant?.title || "",
          due_date: d.due_date ? moment(d.due_date).format("MM/DD/YYYY") : "",
          completed_date: d.completed_date
            ? moment(d.completed_date).format("MM/DD/YYYY, hh:mm")
            : "",
          quiz_status: d.quiz_status || "",
          rating: d.rating || "",
        };
      })
      .filter((d) =>
        globalSearch
          ? d.title?.toLowerCase().includes(globalSearch) ||
            d.restaurant?.toLowerCase().includes(globalSearch) ||
            d.due_date?.toLowerCase().includes(globalSearch) ||
            d.completed_date?.toLowerCase().includes(globalSearch) ||
            d.quiz_status?.toLowerCase().includes(globalSearch) ||
            d.rating?.toString().includes(globalSearch)
          : d
      );
  };

  getTableColumns = () => {
    return [
      {
        name: "title",
        // name: "TITLE",
        selector: (row) => row.title,
        sortable: true,
      },
      {
        name: "restaurant",
        // name: "RESTAURANT",
        selector: (row) => row.restaurant,
        sortable: true,
      },
      {
        name: "due date",
        // name: "DUE DATE",
        selector: (row) => row.due_date,
        sortable: true,
      },
      {
        name: "completed",
        // name: "COMPLETED",
        selector: (row) => row.completed_date,
        sortable: true,
      },
      {
        name: "status",
        // name: "STATUS",
        selector: (row) => row.quiz_status,
        sortable: true,
      },
      {
        name: "rating%",
        // name: "RATING,%",
        selector: (row) => row.rating,
        sortable: true,
      },
      {
        name: "ACTIONS",
        selector: (row) => row.actions,
        sortable: false,
        cell: (row) => (
          <React.Fragment>
            <span>
              <button
                className="btn sm btn-outline-primary btn-sm"
                onClick={() => this.handleView(row.id)}
              >
                VIEW
              </button>
            </span>
          </React.Fragment>
        ),
      },
    ];
  };

  handleView = (id) => {
    const { myQuizData } = this.props;
    const field_data = myQuizData?.results?.find((d) => {
      if (d.id === id) return d;
    });
    this.setState({
      quizData: field_data,
      isOpen: !this.state.isOpen,
    });
  };

  toggleQuizModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      quizData: null,
    });
  };

  render() {
    const { isOpen, quizData } = this.state;
    const { myQuizData, myQuizDataLoading } = this.props;
    const paginationTotalRows = myQuizData?.count;
    return (
      <div className="main_box">
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div className="container-fluid">
                <div className="row mt-5  justify-content-between page_title">
                  <div className="col-md-6">
                    <h2>My Quizzes</h2>
                  </div>
                </div>
                <div className="table_header_line data-table mt-5">
                  <DataTable
                    responsive={true}
                    striped={true}
                    progressPending={myQuizDataLoading}
                    columns={this.getTableColumns()}
                    data={this.getUserListing()}
                    pagination={true}
                    paginationServer={true}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    paginationTotalRows={paginationTotalRows}
                    onChangePage={this.handlePageChange}
                    onChangeRowsPerPage={this.handleRowPerPage}
                    onSort={this.handleSort}
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <QuizModal
            isOpen={isOpen}
            toggle={this.toggleQuizModal}
            quizData={quizData}
            getList={this.getList}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myQuizDataLoading: state.myQuizData.isLoading,
    myQuizData: state.myQuizData.data,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    globalSearch: state.globalSearch.searchQuery,
  };
};
const mapDispatchToProps = {
  fetchMyQuiz,
  fetchRestaurants,
};
export default connect(mapStateToProps, mapDispatchToProps)(MyQuizzes);
