import {
	FETCH_RESTAURANT_UPDATE_REQUEST,
	FETCH_RESTAURANT_UPDATE_SUCCESS,
	FETCH_RESTAURANT_UPDATE_FAILURE,
} from '../../types/restaurants';

const updateRestaurantReducer = (
	state = {
		data: null,
		error: null,
		isLoading: false,
	},
	action,
) => {
	switch (action.type) {
		case FETCH_RESTAURANT_UPDATE_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_RESTAURANT_UPDATE_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_RESTAURANT_UPDATE_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default updateRestaurantReducer;
