import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Formik, Form } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';

import { fetchTaskUserList } from '../../actions/users/taskUserList';
import { fetchCreateTodoTask } from '../../actions/todoTask/create';

import { isValidArray } from '../../modules/utils';
import { displayFormErrors } from '../../modules/validation';

class AddTaskPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			commentList: [],
			comment: '',
		};
	}

	componentDidMount() {
		this.props.fetchTaskUserList();
	}

	getUserOptions = () => {
		const { taskUserList } = this.props;

		if (isValidArray(taskUserList?.results)) {
			return taskUserList?.results.map((user) => {
				return {
					label: user?.full_name,
					value: user?.id,
				};
			});
		}

		return [];
	};

	doSubmitForm = (values) => {
		const { data, toggle, getTodoList } = this.props;

		const apiData = {
			title: values?.title,
			notes: values?.notes,
			due_date: values?.due_date,
			assigned: values?.assigned,
			todo: data?.id,
			completed: false,
			notified_user: values?.notified_user || [],
		};

		this.props.fetchCreateTodoTask(apiData).then(() => {
			const { todoTaskCreate } = this.props;

			if (todoTaskCreate) {
				getTodoList();
				toggle();
			}
		});
	};

	render() {
		return (
			<Modal isOpen={true} size="lg" centered>
				<ModalHeader toggle={this.props.toggle}>
					<h5 className="modal-title" id="exampleModalLabel">
						Add Task
					</h5>
				</ModalHeader>
				<ModalBody>
					<Formik
						initialValues={{}}
						validationSchema={Yup.object().shape({
							title: Yup.string().required('This field is required.'),
							notes: Yup.string().required('This field is required.'),
							due_date: Yup.string().required('This field is required.'),
							assigned: Yup.string().required('This field is required.'),
						})}
						onSubmit={this.doSubmitForm}
					>
						{({
							values,
							errors,
							touched,
							submitCount,
							handleChange,
							handleSubmit,
							setFieldValue,
						}) => {
							const showError = (key) =>
								displayFormErrors(key, errors, touched, submitCount);
							const setAssignedUser = (data) => {
								setFieldValue('assigned', data?.value);
							};
							const setNotifiedUser = (data) => {
								const userArr = data?.map((d) => d?.value);
								setFieldValue('notified_user', userArr);
							};
							return (
								<Form>
									<div className="form-group">
										<label for="recipient-name" className="col-form-label">
											Title:
										</label>
										<input
											type="text"
											className="form-control"
											id="recipient-name"
											name="title"
											value={values?.title}
											onChange={handleChange}
										/>
									</div>
									<div className="form-group">
										<label for="message-text" className="col-form-label">
											Notes:
										</label>
										<textarea
											className="form-control"
											id="message-text"
											name="notes"
											value={values?.notes}
											onChange={handleChange}
										></textarea>
									</div>
									<div className="form-group">
										<label for="recipient-name" className="col-form-label">
											Assigned To:
										</label>
										<Select
											className="basic-single"
											classNamePrefix="select"
											isClearable={true}
											isSearchable={true}
											name="color"
											options={this.getUserOptions()}
											onChange={setAssignedUser}
										/>
									</div>
									<div className="form-group">
										<label for="recipient-name" className="col-form-label">
											When done, notify:
										</label>
										<Select
											className="basic-single"
											classNamePrefix="select"
											isClearable={true}
											isSearchable={true}
											name="color"
											options={this.getUserOptions()}
											onChange={setNotifiedUser}
											isMulti
										/>
									</div>
									<div className="form-group">
										<label for="recipient-name" className="col-form-label">
											Due Date:
										</label>
										<input
											type="datetime-local"
											className="form-control"
											id="due_date"
											name="due_date"
											value={values?.due_date}
											onChange={handleChange}
										/>
									</div>
									<div className="form-group">
										<button
											type="button"
											className="btn btn-primary"
											onClick={handleSubmit}
										>
											Add
										</button>
									</div>
								</Form>
							);
						}}
					</Formik>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		taskUserListIsLoading: state.taskUserList.isLoading,
		taskUserList: state.taskUserList.data,

		todoTaskCreateIsLoading: state.todoTaskCreate.isLoading,
		todoTaskCreate: state.todoTaskCreate.data,
	};
};
const mapDispatchToProps = {
	fetchTaskUserList,
	fetchCreateTodoTask,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddTaskPopup);
