import {
	UPDATE_LOG_RECORD_REQUEST,
	UPDATE_LOG_RECORD_FAILURE,
	UPDATE_LOG_RECORD_SUCCESS,
} from '../../types/managerLogs';
import { putApi } from '../api';

export const updateLogRecordRequest = () => ({
	type: UPDATE_LOG_RECORD_REQUEST,
});

export const updateLogRecordSuccess = (data) => ({
	type: UPDATE_LOG_RECORD_FAILURE,
	data: data,
});

export const updateLogRecordFailure = (error) => ({
	type: UPDATE_LOG_RECORD_SUCCESS,
	error,
});

export const updateLogRecord = (id, formData) => (dispatch, getState) => {
	dispatch(updateLogRecordRequest());

	return putApi(`bshift/api/logrecord/${id}`, formData)
		.then((data) => {
			dispatch(updateLogRecordSuccess(data));
		})
		.catch((error) => {
			dispatch(updateLogRecordFailure(error));
		});
};
