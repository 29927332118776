import {
	UPDATE_LOG_RECORD_VALUE_REQUEST,
	UPDATE_LOG_RECORD_VALUE_FAILURE,
	UPDATE_LOG_RECORD_VALUE_SUCCESS,
} from '../../types/managerLogs';

const updateLogRecordValueReducer = (
	state = {
		data: null,
		error: null,
		isLoading: false,
	},
	action,
) => {
	switch (action.type) {
		case UPDATE_LOG_RECORD_VALUE_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case UPDATE_LOG_RECORD_VALUE_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case UPDATE_LOG_RECORD_VALUE_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default updateLogRecordValueReducer;
