import React, { useEffect, useState } from "react";
import { getRoundValue } from "../../modules/utils";

export default function DecliningBudget({
  // propsTargetFoodSales,
  propsTargetFc,
  totalPurchase,
  foodSalesThisWeek,
}) {
  const [inputValues, setInputValues] = useState({
    actualFoodSales: 0,
    targetFoodSales: 0,
    salesVariance: 0,
    actualFoodPurchase: 0,
    targetFoodPurchase: 0,
    purchaseVariance: 0,
    actualFc: 0,
    targetFc: 0,
    fcVariance: 0,
  });

  const [propsTargetFoodSales, setPropsTargetFoodSales] = useState(0);

  useEffect(() => {
    const updatedData = {
      actualFoodSales: getRoundValue(foodSalesThisWeek),
      targetFoodSales: getRoundValue(propsTargetFoodSales),
      salesVariance: getRoundValue(propsTargetFoodSales - foodSalesThisWeek),
      actualFoodPurchase: getRoundValue(totalPurchase),
      targetFoodPurchase: getRoundValue(
        (Number(propsTargetFoodSales) * Number(propsTargetFc)) / 100
      ),
      purchaseVariance: getRoundValue(
        (Number(propsTargetFoodSales) * Number(propsTargetFc)) / 100 -
          totalPurchase
      ),
      actualFc:
        foodSalesThisWeek != 0
          ? getRoundValue(
              (Number(totalPurchase) * 100) / Number(foodSalesThisWeek)
            )
          : 0,
      targetFc: getRoundValue(propsTargetFc),
      fcVariance:
        foodSalesThisWeek != 0
          ? getRoundValue(
              (Number(totalPurchase) * 100) / Number(foodSalesThisWeek) -
                propsTargetFc
            )
          : 0,
    };
    setInputValues(updatedData);
  }, [propsTargetFoodSales, propsTargetFc, totalPurchase, foodSalesThisWeek]);

  return (
    <div className="table-responsive">
      <table
        className="table table-hover text-center table-bordered"
        style={{
          boxShadow: "5px 5px 10px #4b4b4b",
          backgroundColor: "#dadae5 ",
        }}
      >
        <thead className="custom-table-calculator">
          <tr className="calc_tr">
            <th style={{ width: "25%" }}>Declining Budget</th>
            <th style={{ width: "25%" }}></th>
          </tr>
        </thead>
        <tbody className="custom-table-calculator">
          <tr className="calc_tr">
            {/* <td className="calc_td text-center">
              ${inputValues?.targetFoodSales}
            </td>  */}
            <td className="calc_td">
              <b>Target Food Sales Projection</b>
            </td>
            <td className="calc_td text-center">
              <input
                type="number"
                className="text-center"
                placeholder="Enter Value"
                defaultValue={0}
                onChange={(e) => setPropsTargetFoodSales(e.target.value)}
              />
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Food purchase budget (28 % )</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.targetFoodPurchase}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Spent to Date</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.actualFoodPurchase}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Declining Budget</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.purchaseVariance}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Estimated FC %</b>
            </td>
            <td className="calc_td text-center">{inputValues?.targetFc}%</td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Target Food Sales</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.targetFoodSales}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Actual Food Sales</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.actualFoodSales}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Variance</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.salesVariance}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Target Food Purchases</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.targetFoodPurchase}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Actual Food purchases</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.actualFoodPurchase}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Variance</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.purchaseVariance}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Target FC</b>
            </td>
            <td className="calc_td text-center">{inputValues?.targetFc}%</td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Actual FC</b>
            </td>
            <td className="calc_td text-center">{inputValues?.actualFc}%</td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Variance</b>
            </td>
            <td className="calc_td text-center">{inputValues?.fcVariance}%</td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Weekly FOOD SALES</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.actualFoodSales}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Weekly Spending</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.actualFoodPurchase}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Weekly FOOD COST</b>
            </td>
            <td className="calc_td text-center">{inputValues?.actualFc}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
