import {
	FETCH_PICKUP_REQUEST_REQUEST,
	FETCH_PICKUP_REQUEST_SUCCESS,
	FETCH_PICKUP_REQUEST_FAILURE,
} from '../../types/schedule';
import { getApi } from '../api';

export const fetchPickupRequestRequest = () => ({
	type: FETCH_PICKUP_REQUEST_REQUEST,
});

export const fetchPickupRequestSuccess = (data) => ({
	type: FETCH_PICKUP_REQUEST_SUCCESS,
	data: data,
});

export const fetchPickupRequestFailure = (error) => ({
	type: FETCH_PICKUP_REQUEST_FAILURE,
	error,
});

export const fetchPickupRequest = (data) => (dispatch, getState) => {
	dispatch(fetchPickupRequestRequest());

	const { restaurant, category, position } = data;

	let queryParams = [];
	if (restaurant) {
		queryParams = [...queryParams, `restaurant=${restaurant}`];
	}
	if (category) {
		queryParams = [...queryParams, `category=${category}`];
	}
	if (position) {
		queryParams = [...queryParams, `position=${position}`];
	}

	return getApi(`bshift/api/schedule_pickup_request?${queryParams.join('&')}`)
		.then((data) => {
			dispatch(fetchPickupRequestSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchPickupRequestFailure(error));
		});
};
