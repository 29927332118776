import {
	UPDATE_SHIFT_TIME_REQUEST,
	UPDATE_SHIFT_TIME_FAILURE,
	UPDATE_SHIFT_TIME_SUCCESS,
} from '../../types/schedule';
import { putApi } from '../api';

export const updateShiftTimeRequest = () => ({
	type: UPDATE_SHIFT_TIME_REQUEST,
});

export const updateShiftTimeSuccess = (data) => ({
	type: UPDATE_SHIFT_TIME_SUCCESS,
	data: data,
});

export const updateShiftTimeFailure = (error) => ({
	type: UPDATE_SHIFT_TIME_FAILURE,
	error,
});

export const updateShiftTime = (data) => (dispatch, getState) => {
	dispatch(updateShiftTimeRequest());

	return putApi(`bshift/api/shifttime/${data.id}`, data)
		.then((data) => {
			dispatch(updateShiftTimeSuccess(data));
		})
		.catch((error) => {
			dispatch(updateShiftTimeFailure(error));
		});
};
