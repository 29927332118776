import { toastSuccess } from "../../modules/utils";
import {
  FETCH_EDIT_PROFILE_REQUEST,
  FETCH_EDIT_PROFILE_SUCCESS,
  FETCH_EDIT_PROFILE_FAILURE,
} from "../../types/profile";
import { putApi } from "../api";

export const fetchEditProfileRequest = () => ({
  type: FETCH_EDIT_PROFILE_REQUEST,
});

export const fetchEditProfileSuccess = (data) => ({
  type: FETCH_EDIT_PROFILE_SUCCESS,
  data: data,
});

export const fetchEditProfileFailure = (error) => ({
  type: FETCH_EDIT_PROFILE_FAILURE,
  error,
});

export const fetchEditProfile = (formData, id) => (dispatch, getState) => {
  dispatch(fetchEditProfileRequest());
  return putApi(`users/api/users/${id}`, formData)
    .then((data) => {
      dispatch(fetchEditProfileSuccess(data));
      toastSuccess("Your profile has been updated successfully.");
    })
    .catch((error) => {
      dispatch(fetchEditProfileFailure(error));
    });
};
