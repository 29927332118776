import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
} from "../../types/users";
import { getApi } from "../api";

export const fetchUsersRequest = () => ({
  type: FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = (data) => ({
  type: FETCH_USERS_SUCCESS,
  data: data,
});

export const fetchUsersFailure = (error) => ({
  type: FETCH_USERS_FAILURE,
  error,
});

export const fetchUsers = (data) => (dispatch, getState) => {
  //const { page, itemsPerPage, search } = data;
  dispatch(fetchUsersRequest());

  let queryParams = [];
  if (data) {
    const { page, itemsPerPage, search, restaurants, groups } = data;

    if (restaurants) {
      queryParams = [...queryParams, `restaurants=${restaurants}`];
    }
    if (groups) {
      queryParams = [...queryParams, `groups=${groups}`];
    }
    if (page) {
      queryParams = [...queryParams, `page=${page}`];
    }
    if (itemsPerPage) {
      queryParams = [...queryParams, `itemsPerPage=${itemsPerPage}`];
    }
    if (search) {
      queryParams = [...queryParams, `search=${search}`];
      // queryParams = [...queryParams, `?search=${search}`];
    }
  }

  //if add new param, add & join in API url.
  return getApi(`users/api/users?${queryParams.join("&")}`)
    .then((data) => {
      dispatch(fetchUsersSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchUsersFailure(error));
    });
};
