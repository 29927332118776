import {
	FETCH_DELETE_SCHEDULE_SHIFT_REQUEST,
	FETCH_DELETE_SCHEDULE_SHIFT_SUCCESS,
	FETCH_DELETE_SCHEDULE_SHIFT_FAILURE,
} from '../../types/schedule';
import { deleteApiWithData } from '../api';

export const fetchDeleteScheduleShiftRequest = () => ({
	type: FETCH_DELETE_SCHEDULE_SHIFT_REQUEST,
});

export const fetchDeleteScheduleShiftSuccess = (data) => ({
	type: FETCH_DELETE_SCHEDULE_SHIFT_SUCCESS,
	data: data,
});

export const fetchDeleteScheduleShiftFailure = (error) => ({
	type: FETCH_DELETE_SCHEDULE_SHIFT_FAILURE,
	error,
});

export const fetchDeleteScheduleShift = (formData) => (dispatch, getState) => {
	dispatch(fetchDeleteScheduleShiftRequest());

	return deleteApiWithData(`bshift/api/schedule`, formData)
		.then((data) => {
			dispatch(fetchDeleteScheduleShiftSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchDeleteScheduleShiftFailure(error));
		});
};
