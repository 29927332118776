import React, { useState, useEffect } from "react";
import RowComponent from "./RowComponent";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getDateShortFormatted, getRoundValue } from "../../modules/utils";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
} from "react-icons/fa";
import InventoryCalc from "./InventoryCalc";
import DecliningBudget from "./DecliningBudget";
import FoodCostInv from "./FoodCostInv";
import { fetchRestaurantGroups } from "../../actions/restaurantGroups/fetchRestaurantGroups";
import Select from "react-select";

export default function Calculator() {
  const dispatch = useDispatch();

  // RESTAURANTS START

  const restaurantsDataList = useSelector(
    (state) => state?.restaurantGroups?.data?.results
  );
  const restaurantsGroupLoading = useSelector(
    (state) => state?.restaurantGroups?.isLoading
  );

  useEffect(() => {
    dispatch(fetchRestaurantGroups());
  }, []);

  const [resturantGroupOptions, setRestaurantGroupOptions] = useState([]);
  const [selectedResturant, setSelectedRestaurant] = useState(0);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [restaurantLoading, setRestaurantLoading] = useState(false);

  useEffect(() => {
    if (restaurantsDataList) {
      const options = restaurantsDataList?.map((group) => {
        return {
          label: group?.brand_name,
          value: group?.id,
        };
      });
      setRestaurantGroupOptions(options);
    }
  }, [restaurantsDataList]);

  const changeRestaurantGroup = (e) => {
    setRestaurantLoading(true);
    const options = restaurantsDataList
      ?.filter((item) => item?.id == e?.value)[0]
      ?.restaurants?.map((item) => {
        return { label: item?.title, value: item?.id };
      });
    setRestaurantLoading(false);
    setRestaurantOptions(options);
  };

  const changeRestaurant = (e) => {
    setSelectedRestaurant(e);
  };

  // RESTAURANTS END

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const [weekStartDate, setWeekStartDate] = useState(null);
  const [weekEndDate, setWeekEndDate] = useState(null);
  const [rowsData, setRowsData] = useState([
    {
      item: "VROLA",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "DEBRAGGA",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "GOFFLE",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "Halperns",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "SAMUELS",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "POINT LOBSTER",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "ATLANTIC OFFSHORE",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "BALDOR",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "GARGIULO",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "DAIRYLAND",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "SYSCO",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "ROYAL BAKERY",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "LORE Pasta",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "A Piece of Cake ",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "Harvest Drop",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "Four Five Coffee",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
    {
      item: "SEA BREEZE",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    },
  ]);

  const [colTotal, setColTotal] = useState({
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
    total: 0,
  });

  const [finalData, setFinalData] = useState({});

  const [thisWeekSales, setThisWeekSales] = useState({
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
    total: 0,
  });

  const [lastWeekSales, setLastWeekSales] = useState({
    monday: 3226.1,
    tuesday: 4815.47,
    wednesday: 4638.13,
    thursday: 6377.1,
    friday: 11987.45,
    saturday: 13174.46,
    sunday: 5649.9,
    total: 49868.61,
  });

  const [dailyCost, setDailyCost] = useState({
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
    total: 0,
  });

  const [variance, setVariance] = useState({
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    sunday: 0,
    total: 0,
  });

  const addTableRows = () => {
    const rowsInput = {
      item: "",
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
      total: 0,
    };

    setRowsData([...rowsData, rowsInput]);
  };

  const [inventoryVariance, setInventoryVariance] = useState(0);

  const [beginInv, setBeginInv] = useState(0);

  const [endInv, setEndInv] = useState(0);

  // DATE RELATED START

  const day_start = () => {
    const currentDate = moment(weekStartDate);
    const weekStart = currentDate.clone().weekday(1);
    return weekStart._d;
  };

  const fetchNewTable = () => {};

  const incWeek = (delta) => {
    let d = new Date(weekStartDate);
    d.setDate(d.getDate() + delta * 7);
    setWeekStartDate(d.toISOString(), fetchNewTable());
  };

  const formatDateISO = (d = null) => {
    if (!d) return null;

    let date = new Date(d);
    let [year, month, day] = [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
    ];
    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;
    return `${year}-${month}-${day}`;
  };

  const getDay = (day) => {
    return days[day - 1];
  };

  const getDates = () => {
    const currentDate = new Date();

    const first =
      currentDate.getDate() -
      currentDate.getDay() +
      (currentDate.getDay() === 0 ? -6 : 1);
    const last = first + 6;

    const firstDay = new Date(currentDate.setDate(first)).toISOString();
    const lastDay = new Date(currentDate.setDate(last)).toISOString();

    setWeekStartDate(firstDay);
    setWeekEndDate(lastDay);
  };

  useEffect(() => {
    let mondayData = 0;
    let tuesdayData = 0;
    let wednesdayData = 0;
    let thursdayData = 0;
    let fridayData = 0;
    let satdayData = 0;
    let sundayData = 0;
    let totalData = 0;

    rowsData.map((day, index) => {
      mondayData = Number(mondayData) + Number(day.monday);
      tuesdayData = Number(tuesdayData) + Number(day.tuesday);
      wednesdayData = Number(wednesdayData) + Number(day.wednesday);
      thursdayData = Number(thursdayData) + Number(day.thursday);
      fridayData = Number(fridayData) + Number(day.friday);
      satdayData = Number(satdayData) + Number(day.saturday);
      sundayData = Number(sundayData) + Number(day.sunday);
      totalData = Number(totalData) + Number(day.total);
    });

    setColTotal({
      monday: getRoundValue(mondayData),
      tuesday: getRoundValue(tuesdayData),
      wednesday: getRoundValue(wednesdayData),
      thursday: getRoundValue(thursdayData),
      friday: getRoundValue(fridayData),
      saturday: getRoundValue(satdayData),
      sunday: getRoundValue(sundayData),
      total: getRoundValue(totalData),
    });
  }, [rowsData]);

  useEffect(() => {
    getDates();
  }, []);

  // DATE RELATED END

  const changeHandler = (index, event) => {
    const { name, value } = event.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;

    const monArr = rowsInput.map((item, index) => {
      return {
        item: item.item,
        price: item.monday,
        date: moment(
          getDateShortFormatted(1, formatDateISO(day_start()), 0)
        ).format(),
      };
    });
    const tueArr = rowsInput.map((item, index) => {
      return {
        item: item.item,
        price: item.tuesday,
        date: moment(
          getDateShortFormatted(2, formatDateISO(day_start()), 0)
        ).format(),
      };
    });
    const wedArr = rowsInput.map((item, index) => {
      return {
        item: item.item,
        price: item.wednesday,
        date: moment(
          getDateShortFormatted(3, formatDateISO(day_start()), 0)
        ).format(),
      };
    });
    const thurArr = rowsInput.map((item, index) => {
      return {
        item: item.item,
        price: item.thursday,
        date: moment(
          getDateShortFormatted(4, formatDateISO(day_start()), 0)
        ).format(),
      };
    });
    const friArr = rowsInput.map((item, index) => {
      return {
        item: item.item,
        price: item.friday,
        date: moment(
          getDateShortFormatted(5, formatDateISO(day_start()), 0)
        ).format(),
      };
    });
    const satArr = rowsInput.map((item, index) => {
      return {
        item: item.item,
        price: item.saturday,
        date: moment(
          getDateShortFormatted(6, formatDateISO(day_start()), 0)
        ).format(),
      };
    });
    const sunArr = rowsInput.map((item, index) => {
      return {
        item: item.item,
        price: item.sunday,
        date: moment(
          getDateShortFormatted(7, formatDateISO(day_start()), 0)
        ).format(),
      };
    });

    const finalObject = {
      monArr,
      tueArr,
      wedArr,
      thurArr,
      friArr,
      satArr,
      sunArr,
    };

    setFinalData(finalObject);

    rowsInput[index]["total"] = getRoundValue(
      Number(rowsInput[index]["monday"]) +
        Number(rowsInput[index]["tuesday"]) +
        Number(rowsInput[index]["wednesday"]) +
        Number(rowsInput[index]["thursday"]) +
        Number(rowsInput[index]["friday"]) +
        Number(rowsInput[index]["saturday"]) +
        Number(rowsInput[index]["sunday"])
    );
    setRowsData(rowsInput);
  };

  const thisWeekSalesHandler = (e) => {
    const thisWeekData = { ...thisWeekSales };
    thisWeekData[e.target.name] = e.target.value;
    thisWeekData["total"] = getRoundValue(
      Number(thisWeekData.monday) +
        Number(thisWeekData.tuesday) +
        Number(thisWeekData.wednesday) +
        Number(thisWeekData.thursday) +
        Number(thisWeekData.friday) +
        Number(thisWeekData.saturday) +
        Number(thisWeekData.sunday)
    );

    const dailyCostData = { ...dailyCost };

    if (e.target.value == 0) {
      dailyCostData[e.target.name] = 0;
    } else {
      dailyCostData[e.target.name] = getRoundValue(
        (Number(colTotal[e.target.name]) * 100) /
          Number(thisWeekData[e.target.name])
      );
    }
    dailyCostData["total"] = getRoundValue(
      Number(colTotal["total"] * 100) / Number(thisWeekData["total"])
    );

    const varianceData = { ...variance };

    varianceData[e.target.name] = getRoundValue(
      Number(thisWeekData[e.target.name]) - Number(lastWeekSales[e.target.name])
    );

    varianceData["total"] = getRoundValue(
      Number(varianceData.monday) +
        Number(varianceData.tuesday) +
        Number(varianceData.wednesday) +
        Number(varianceData.thursday) +
        Number(varianceData.friday) +
        Number(varianceData.saturday) +
        Number(varianceData.sunday)
    );

    setThisWeekSales(thisWeekData);
    setDailyCost(dailyCostData);
    setVariance(varianceData);
  };

  const submitHandler = (e) => {
    console.log("Final Data:", finalData);
  };

  return (
    <div className="main_box">
      <div className="pull_left full_section_details">
        <div className="main_container">
          <div className="wrapper">
            <div className="container-fluid">
              {/* BUTTONS */}
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 btn_schedule_group justify-content-start btn_custom_calc">
                  <button
                    className="btn  shadow-lg"
                    style={{
                      backgroundColor: "#38425B",
                      color: "white",
                    }}
                    onClick={addTableRows}
                  >
                    Add Row
                  </button>
                  <button
                    className="btn  shadow-lg mx-3"
                    style={{
                      backgroundColor: "#38425B",
                      color: "white",
                    }}
                    onClick={submitHandler}
                  >
                    Save
                  </button>
                </div>
                <div className=" col-sm-12 col-md-12 col-lg-6 btn_schedule_group btn_custom_calc">
                  <div className="BTnGroupScha next_prev_btn d-flex">
                    <button
                      type="button"
                      className="btn  btn_Pre d-flex flex-row align-items-center"
                      onClick={() => {
                        incWeek(-1);
                      }}
                    >
                      <FaArrowCircleLeft size={20} />
                      Previous Week
                    </button>

                    <button
                      type="button"
                      className="btn  btn_NextSchedual btn_Pre d-flex flex-row align-items-center"
                      onClick={() => {
                        incWeek(1);
                      }}
                    >
                      Next Week
                      <FaArrowCircleRight size={20} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-3 ">
                  <Select
                    options={resturantGroupOptions}
                    onChange={changeRestaurantGroup}
                    placeholder="Select Restaurant Group"
                    isLoading={restaurantsGroupLoading}
                  />
                </div>
                <div className="col-md-3 ">
                  <Select
                    options={restaurantOptions}
                    onChange={changeRestaurant}
                    placeholder="Select Restaurant"
                    isDisabled={restaurantOptions?.length == 0 ? true : false}
                    isLoading={restaurantLoading}
                  />
                </div>
              </div>

              {/* MAIN TABLE */}
              {selectedResturant !== 0 && (
                <div className="row mt-4 mb-4">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table
                        className="table table-hover text-center table-bordered "
                        style={{
                          boxShadow: "5px 5px 10px #4b4b4b",
                          backgroundColor: "#dadae5 ",
                        }}
                      >
                        <thead className="custom-table-calculator">
                          <tr className="calc_tr">
                            <th style={{ width: "15%" }}>Purchased Items</th>
                            {[...Array(7)].map((x, i) => (
                              <th style={{ width: "10.625%" }} key={i}>
                                <div
                                  className="text-center font-weight-bold body-1"
                                  style={{ width: "100%" }}
                                >
                                  {getDay(i + 1)}{" "}
                                </div>
                                <span className="">
                                  {getDateShortFormatted(
                                    i + 1,
                                    formatDateISO(day_start()),
                                    0
                                  )}
                                </span>
                              </th>
                            ))}
                            <th style={{ width: "11.11%" }}>Total</th>
                          </tr>
                        </thead>
                        <tbody className="custom-table-calculator">
                          <RowComponent
                            rowsData={rowsData}
                            changeHandler={changeHandler}
                          />
                          <tr className="calc_tr">
                            <td className="calc_td">
                              <b>Total Purchases By Day</b>
                            </td>
                            <td className="calc_td">${colTotal.monday}</td>
                            <td className="calc_td">${colTotal.tuesday}</td>
                            <td className="calc_td">${colTotal.wednesday}</td>
                            <td className="calc_td">${colTotal.thursday}</td>
                            <td className="calc_td">${colTotal.friday}</td>
                            <td className="calc_td">${colTotal.saturday}</td>
                            <td className="calc_td">${colTotal.sunday}</td>
                            <td className="calc_td">${colTotal.total}</td>
                          </tr>

                          {/* TABLE 2 */}
                          <tr className="calc_tr">
                            <td className="calc_td">
                              <b>This Week's Food Sale</b>
                            </td>

                            <td className="calc_td">
                              <input
                                type="number"
                                name="monday"
                                className="text-center"
                                placeholder="Enter Value"
                                defaultValue={0}
                                onChange={(e) => thisWeekSalesHandler(e)}
                              />
                            </td>
                            <td className="calc_td">
                              <input
                                type="number"
                                name="tuesday"
                                className="text-center"
                                placeholder="Enter Value"
                                defaultValue={0}
                                onChange={(e) => thisWeekSalesHandler(e)}
                              />
                            </td>
                            <td className="calc_td">
                              <input
                                type="number"
                                name="wednesday"
                                className="text-center"
                                placeholder="Enter Value"
                                defaultValue={0}
                                onChange={(e) => thisWeekSalesHandler(e)}
                              />
                            </td>
                            <td className="calc_td">
                              <input
                                type="number"
                                name="thursday"
                                className="text-center"
                                placeholder="Enter Value"
                                defaultValue={0}
                                onChange={(e) => thisWeekSalesHandler(e)}
                              />
                            </td>
                            <td className="calc_td">
                              <input
                                type="number"
                                name="friday"
                                className="text-center"
                                placeholder="Enter Value"
                                defaultValue={0}
                                onChange={(e) => thisWeekSalesHandler(e)}
                              />
                            </td>
                            <td className="calc_td">
                              <input
                                type="number"
                                name="saturday"
                                className="text-center"
                                placeholder="Enter Value"
                                defaultValue={0}
                                onChange={(e) => thisWeekSalesHandler(e)}
                              />
                            </td>
                            <td className="calc_td">
                              <input
                                type="number"
                                name="sunday"
                                className="text-center"
                                placeholder="Enter Value"
                                defaultValue={0}
                                onChange={(e) => thisWeekSalesHandler(e)}
                              />
                            </td>
                            <td className="calc_td">${thisWeekSales.total}</td>
                          </tr>
                          <tr className="calc_tr">
                            <td className="calc_td">
                              <b>DAILY COST</b>
                            </td>

                            <td className="calc_td">{dailyCost?.monday}%</td>
                            <td className="calc_td">{dailyCost?.tuesday}%</td>
                            <td className="calc_td">{dailyCost?.wednesday}%</td>
                            <td className="calc_td">{dailyCost?.thursday}%</td>
                            <td className="calc_td">{dailyCost?.friday}%</td>
                            <td className="calc_td">{dailyCost?.saturday}%</td>
                            <td className="calc_td">{dailyCost?.sunday}%</td>
                            <td className="calc_td">{dailyCost?.total}%</td>
                          </tr>
                          <tr className="calc_tr">
                            <td className="calc_td">
                              <b>Last Week's Food Sale</b>
                            </td>

                            <td className="calc_td">
                              ${lastWeekSales?.monday}
                            </td>
                            <td className="calc_td">
                              ${lastWeekSales?.tuesday}
                            </td>
                            <td className="calc_td">
                              ${lastWeekSales?.wednesday}
                            </td>
                            <td className="calc_td">
                              ${lastWeekSales?.thursday}
                            </td>
                            <td className="calc_td">
                              ${lastWeekSales?.friday}
                            </td>
                            <td className="calc_td">
                              ${lastWeekSales?.saturday}
                            </td>
                            <td className="calc_td">
                              ${lastWeekSales?.sunday}
                            </td>
                            <td className="calc_td">${lastWeekSales.total}</td>
                          </tr>
                          <tr className="calc_tr">
                            <td className="calc_td">
                              <b>Variance</b>
                            </td>

                            <td className="calc_td">{variance?.monday}</td>
                            <td className="calc_td">{variance?.tuesday}</td>
                            <td className="calc_td">{variance?.wednesday}</td>
                            <td className="calc_td">{variance?.thursday}</td>
                            <td className="calc_td">{variance?.friday}</td>
                            <td className="calc_td">{variance?.saturday}</td>
                            <td className="calc_td">{variance?.sunday}</td>
                            <td className="calc_td">{variance?.total}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}

              {/* MAIN TABLE END*/}
              {selectedResturant !== 0 && (
                <InventoryCalc
                  restaurant={selectedResturant}
                  startDate={weekStartDate}
                  endDate={weekEndDate}
                  setInventoryVariance={setInventoryVariance}
                  setBeginInv={setBeginInv}
                  setEndInv={setEndInv}
                />
              )}
              {/* INVENTORY */}
              <div className="row my-4">
                <div className="col-md-6">
                  {/* Declining  */}
                  {selectedResturant !== 0 && (
                    <DecliningBudget
                      propsTargetFc={28}
                      totalPurchase={colTotal?.total}
                      foodSalesThisWeek={thisWeekSales?.total}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {/* FoodCostInv */}
                  {selectedResturant !== 0 && (
                    <FoodCostInv
                      restaurant={selectedResturant}
                      purchaseOfTheWeek={colTotal?.total}
                      foodCostBeforeWeek={dailyCost?.total}
                      inventoryVariance={inventoryVariance}
                      salesOfTheWeek={thisWeekSales?.total}
                      beginningInventory={beginInv}
                      endingInventory={endInv}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
