import { toastError, toastSuccess } from '../../modules/utils';
import {
	FETCH_TODO_DELETE_REQUEST,
	FETCH_TODO_DELETE_FAILURE,
	FETCH_TODO_DELETE_SUCCESS,
} from '../../types/todo';
import { deleteApi } from '../api';

export const fetchTodoDeleteRequest = () => ({
	type: FETCH_TODO_DELETE_REQUEST,
});

export const fetchTodoDeleteSuccess = (data) => ({
	type: FETCH_TODO_DELETE_SUCCESS,
	data: data,
});

export const fetchTodoDeleteFailure = (error) => ({
	type: FETCH_TODO_DELETE_FAILURE,
	error,
});

export const fetchTodoDelete = (id) => (dispatch) => {
	dispatch(fetchTodoDeleteRequest());
	return deleteApi(`restaurant/api/todo_delete/${id}`)
		.then((data) => {
			toastSuccess('Record deleted successfully.');
			dispatch(fetchTodoDeleteSuccess(data));
		})
		.catch((error) => {
			toastError('Something went wrong!');
			dispatch(fetchTodoDeleteFailure(error));
		});
};
