import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import DataTable from "react-data-table-component";
import { FormGroup, Input } from "reactstrap";
import { FaPlus } from "react-icons/fa";

import { fetchShiftDayPart } from "../../actions/schedule/shiftDayPart";
import { fetchCategories } from "../../actions/categories/categories";
import { fetchGroups } from "../../actions/groups/groups";
import { fetchShiftTime } from "../../actions/schedule/shiftTime";
import { deleteShiftTime } from "../../actions/schedule/deleteShiftTime";
import { createShiftTime } from "../../actions/schedule/createShiftTime";
import { updateShiftTime } from "../../actions/schedule/updateShiftTime";
import AddEditTimeShift from "./modal/AddEditTimeShift";
import DeleteModal from "../common/DeleteModal";
import { customStyles, isValidArray } from "../../modules/utils";

class ShiftTime extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: "",
      isOpenDeleteModal: false,
      isOpenEditModal: false,
      editData: "",
    };
  }

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = () => {
    this.props.fetchGroups();
    this.props.fetchShiftTime();
    this.props.fetchShiftDayPart();
    // this.props.fetchCategories();
  };

  filterCategory = (e) => {
    const category = e.target.value;
    this.setState({ category: category });
    this.props.fetchShiftTime();
    this.getTimeShiftData();
  };

  addEditModalToggle = (data = "") => {
    this.setState({
      isOpenEditModal: !this.state.isOpenEditModal,
      editData: data,
    });
  };

  addEditTimeShift = (data = {}, id = "") => {
    if (id) {
      this.props.updateShiftTime(data).then(() => {
        this.addEditModalToggle();
        this.getInitialData();
      });
    } else {
      this.props.createShiftTime(data).then(() => {
        this.addEditModalToggle();
        this.getInitialData();
      });
    }
  };

  deleteModalToggle = (data = "") => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
      deleteData: data,
    });
  };

  deleteShiftTime = () => {
    const { deleteData } = this.state;
    this.props.deleteShiftTime(deleteData).then(() => {
      this.deleteModalToggle();
      this.props.fetchShiftTime();
    });
  };

  getTimeShiftData = () => {
    const { shiftTimeList, shiftDayPartList, groupsDataList } = this.props;
    const { category } = this.state;

    return isValidArray(shiftTimeList)
      ? shiftTimeList
          ?.filter((data) => data.group === parseInt(category))
          .map((d) => {
            return {
              id: d.id,
              groupName:
                isValidArray(groupsDataList) &&
                groupsDataList?.map((data) => {
                  if (data.id === d.group) return data.name;
                }),
              dayPartName:
                isValidArray(shiftDayPartList) &&
                shiftDayPartList?.map((data) => {
                  if (data.id === d.day_part) return data.title;
                }),
              shift_break: d.shift_break,
              group: d.group,
              day_part: d.day_part,
              time_start:
                moment(d?.time_start, "HH:mm:ss").format("hh:mm A") || "",
              time_end: moment(d?.time_end, "HH:mm:ss").format("hh:mm A") || "",
              note: d.note || "",
              colorName:
                <div style={{ color: `${d.color}` }}>{d.color}</div> || "",
              color: d.color,
            };
          })
      : [];
  };

  getTableColumns = () => {
    return [
      {
        name: "SCHEDULE  CATEGORY",
        // selector: "groupName",
        selector: (row) => row.groupName,
        sortable: true,
      },
      {
        name: "DAY PART",
        // selector: "dayPartName",
        selector: (row) => row.dayPartName,
        sortable: true,
        // right: true,
        center: true,
      },
      {
        name: "TIME START",
        // selector: "time_start",
        selector: (row) => row.time_start,
        sortable: true,
        right: true,
      },
      {
        name: "TIME END",
        // selector: "time_end",
        selector: (row) => row.time_end,
        sortable: true,
        right: true,
      },
      {
        name: "NOTE",
        selector: (row) => row.note,
        // selector: "note",
        sortable: true,
        right: true,
      },
      {
        name: "COLOR",
        selector: (row) => row.colorName,
        // selector: "colorName",
        sortable: true,
        right: true,
      },
      {
        name: "ACTIONS",
        // selector: "actions",
        selector: (row) => row.actions,
        sortable: false,
        center: true,
        cell: (row) => (
          <span>
            <button
              className="btn sm btn-outline-primary btn-sm"
              onClick={() => this.addEditModalToggle(row)}
            >
              EDIT
            </button>{" "}
            <button
              value={row.id}
              className="btn btn-outline-secondary btn-sm ml-1"
              onClick={() => this.deleteModalToggle(row)}
            >
              DELETE
            </button>
          </span>
        ),
      },
    ];
  };

  render() {
    const {
      groupsDataList,
      shiftTimeLoading,
      categoriesDataLoading,
      shiftDayPartList,
    } = this.props;
    const { editData, isOpenDeleteModal, isOpenEditModal } = this.state;

    return (
      <div className="main_box">
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div className="container-fluid">
                <div className="row mt-5 page_title shift_time_title">
                  <div className="col-md-4">
                    <h2>Shift Times</h2>
                  </div>
                  <div className="col-md-4 Schedule_category_form">
                    <FormGroup>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        onClick={(e) => {
                          this.filterCategory(e);
                        }}
                      >
                        <option>Schedule category</option>
                        {groupsDataList &&
                          groupsDataList.map((d, id) => {
                            return (
                              <option value={d.id} key={id}>
                                {d.name}
                              </option>
                            );
                          })}
                      </Input>
                    </FormGroup>
                  </div>
                  <div className="  col-md-4">
                    <div className="main_info_select_1 new_shift_btn ">
                      <button
                        type="button"
                        className="btn  "
                        onClick={this.addEditModalToggle}
                      >
                        <span className="close_icon_report">
                          {" "}
                          <FaPlus />{" "}
                        </span>
                        NEW SHIFT TIME
                      </button>
                    </div>
                  </div>
                </div>
                <div className="table_header_line data-table mt-5">
                  <DataTable
                    columns={this.getTableColumns()}
                    data={this.getTimeShiftData()}
                    progressPending={shiftTimeLoading || categoriesDataLoading}
                    pagination
                    responsive
                    paginationServer={true}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    // theme="solarized"
                    striped
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* <LogReport isOpen={isOpenLogModal} toggle={this.logReportModal} />
				<AddNewLog
					isOpen={isOpenAddNewLogModal}
					toggle={this.newLogReport}
					restaurantsData={restaurantsData}
					createLogRequest={this.createLogRequest}
				/> */}
        <DeleteModal
          isOpen={isOpenDeleteModal}
          toggle={this.deleteModalToggle}
          deleteLog={this.deleteShiftTime}
          deleteShiftTIme={true}
        />

        {isOpenEditModal && (
          <AddEditTimeShift
            isOpen={isOpenEditModal}
            toggle={this.addEditModalToggle}
            groupsDataList={groupsDataList}
            shiftDayPartList={shiftDayPartList}
            addEditTimeShift={this.addEditTimeShift}
            editData={editData}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoriesDataLoading: state.categoriesData.isLoading,
    categoriesDataList: state.categoriesData?.data?.results || [],

    groupsDataLoading: state.groupsData.isLoading,
    groupsDataList: state.groupsData?.data?.results || [],

    // restaurantsDataLoading: state.restaurantsData.isLoading,
    // restaurantsDataList: state.restaurantsData?.data?.results || [],

    shiftTimeLoading: state.shiftTime.isLoading,
    shiftTimeList: state.shiftTime?.data?.results || [],

    shiftDayPartLoading: state.shiftDayPart.isLoading,
    shiftDayPartList: state.shiftDayPart?.data?.results || [],

    // usersDataLoading: state.usersData.isLoading,
    // usersDataList: state.usersData?.data?.results || [],

    // scheduleLoading: state.schedule.isLoading,
    // scheduleList: state.schedule?.data || [],

    deleteShiftTimeDataLoading: state.deleteShiftTimeData.isLoading,
    deleteShiftTimeData: state.deleteShiftTimeData?.data || [],

    createShiftTimeDataLoading: state.createShiftTimeData.isLoading,
    createShiftTimeData: state.createShiftTimeData?.data || [],

    updateShiftTimeDataLoading: state.updateShiftTimeData.isLoading,
    updateShiftTimeData: state.updateShiftTimeData?.data || [],
  };
};
const mapDispatchToProps = {
  fetchCategories,
  fetchGroups,
  // fetchRestaurants,
  fetchShiftDayPart,
  fetchShiftTime,
  deleteShiftTime,
  createShiftTime,
  updateShiftTime,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShiftTime);
