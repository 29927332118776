import {
	FETCH_RESTAURANT_DELETE_REQUEST,
	FETCH_RESTAURANT_DELETE_SUCCESS,
	FETCH_RESTAURANT_DELETE_FAILURE,
} from '../../types/restaurants';
import { deleteApi } from '../api';

export const fetchDeleteRestaurantRequest = () => ({
	type: FETCH_RESTAURANT_DELETE_REQUEST,
});

export const fetchDeleteRestaurantSuccess = (data) => ({
	type: FETCH_RESTAURANT_DELETE_SUCCESS,
	data: data,
});

export const fetchDeleteRestaurantFailure = (error) => ({
	type: FETCH_RESTAURANT_DELETE_FAILURE,
	error,
});

export const fetchDeleteRestaurant = (id) => (dispatch, getState) => {
	dispatch(fetchDeleteRestaurantRequest());
	return deleteApi(`bshift/api/restaurants/${id}`)
		.then((data) => {
			dispatch(fetchDeleteRestaurantSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchDeleteRestaurantFailure(error));
		});
};
