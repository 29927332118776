import {
  FETCH_SCHEDULE_REQUEST,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILURE,
} from "../../types/schedule";

const initialState = {
  data: {},
  error: null,
  isLoading: false,
};

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHEDULE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SCHEDULE_SUCCESS:
      let updatedData = {
        ...state.data,
        [action.date]: action.data.data,
      };

      return {
        ...state,
        isLoading: false,
        data: updatedData,
        error: null,
      };
    case FETCH_SCHEDULE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default scheduleReducer;
