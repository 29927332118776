import {
  FETCH_ANNOUNCEMENTS_DETAILS_REQUEST,
  FETCH_ANNOUNCEMENTS_DETAILS_SUCCESS,
  FETCH_ANNOUNCEMENTS_DETAILS_FAILURE,
} from "../../types/announcements";
import { getApi } from "../api";

export const fetchAnnouncementsDetailsRequest = () => ({
  type: FETCH_ANNOUNCEMENTS_DETAILS_REQUEST,
});

export const fetchAnnouncementsDetailsSuccess = (data) => ({
  type: FETCH_ANNOUNCEMENTS_DETAILS_SUCCESS,
  data: data,
});

export const fetchAnnouncementsDetailsFailure = (error) => ({
  type: FETCH_ANNOUNCEMENTS_DETAILS_FAILURE,
  error,
});

export const fetchAnnouncementDetails = (params) => (dispatch, getState) => {
  dispatch(fetchAnnouncementsDetailsRequest());
  // if (params?.page) {
  return getApi(`bshift/api/announcements`, params)
    .then((data) => {
      dispatch(fetchAnnouncementsDetailsSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchAnnouncementsDetailsFailure(error));
    });
};
