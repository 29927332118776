import React, { Component } from "react";

import {
  formatDateISO,
  getDate,
  getDateShortFormatted,
  getDay,
  testCurrent,
  invertHex,
  strTimeFormat,
  isValidArray,
  formatCost,
  formatDate,
  getPermission,
  isValidObject,
} from "../../modules/utils";
import { fetchCategories } from "../../actions/categories/categories";
import { fetchGroups } from "../../actions/groups/groups";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchSchedule } from "../../actions/schedule/schedule";
import { fetchProfile } from "../../actions/profile/profile";
import moment from "moment";
import { connect } from "react-redux";
import EditNoteImg from "../../assets/css/images/Group-1.svg";

import ShiftDropModal from "./modal/ShiftDropModal";
import EditNote from "./modal/EditNote";
import { fetchEditScheduleShift } from "../../actions/schedule/editScheduleShift";

export class WeekSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      week_count: 3,
      laborCostsTotal: 0,
      laborCostsCategory: {},
      laborCostsGroup: {},
      laborCostsDay: {},
      shiftData: {},
      isShiftDrop: false,
      shiftType: "",
      editNoteOpen: false,
      editNoteData: {},
    };
  }

  componentDidMount() {
    this.fetchSchedule();
  }

  componentDidUpdate(prevProps, prevState) {}

  getApiDate = () => {
    const { viewBy, date } = this.props;

    const val = moment(date);
    return viewBy === "week" ? formatDateISO(val._d) : date;
    // return viewBy === "week" ? formatDateISO(this.getDate(1)) : date;
  };

  fetchSchedule = () => {
    const { category, position, restaurant, viewBy, date } = this.props;
    this.props
      .fetchSchedule({
        date: this.getApiDate(),
        category,
        group: position,
        restaurant,
        viewBy,
      })
      .then(() => {
        let laborCostsTotal = 0;
        let laborCostsCategory = {};
        let laborCostsDay = [0, 0, 0, 0, 0, 0, 0, 0];

        const schedule_items = this.getItems();

        if (isValidArray(schedule_items)) {
          schedule_items.forEach((item) => {
            laborCostsTotal += item.cost;
            if (item.user.groups.length > 0) {
              if (!laborCostsCategory[item.user.groups[0].category])
                laborCostsCategory[item.user.groups[0].category] = {
                  id: item.user.groups[0].category,
                  total: 0,
                };
              laborCostsCategory[item.user.groups[0].category]["total"] +=
                item.cost;
            }
            let idx =
              new Date(item.date).getDay() - this.getDate(1).getDay() + 1;

            laborCostsDay[idx] += item.cost;
          });
        }

        this.setState({
          laborCostsTotal,
          laborCostsCategory,
          laborCostsDay,
        });
      });
  };

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    // console.log("laborCostsTotal", this.props.laborCostsTotal);
    // console.log("showLaborCost", this.props.showLaborCost);
    if (
      prevProps.laborCostsTotal !== this.props.laborCostsTotal ||
      this.compareKeys(
        prevProps.laborCostsCategory,
        this.props.laborCostsCategory
      ) ||
      JSON.stringify(prevProps.laborCostsDay) !==
        JSON.stringify(this.props.laborCostsDay)
    ) {
      this.setState({
        laborCostsTotal: this.props.laborCostsTotal,
        laborCostsCategory: this.props.laborCostsCategory,
        laborCostsDay: this.props.laborCostsDay,
      });
    }
  }

  compareKeys = (a, b) => {
    const aKeys = Object.keys(a).sort();
    const bKeys = Object.keys(b).sort();
    return JSON.stringify(aKeys) !== JSON.stringify(bKeys);
  };

  getDate = (num) => {
    const { inWeekNum } = this.props;
    return getDate(num, formatDateISO(this.day_start()), inWeekNum);
  };

  day_start() {
    const { date } = this.props;

    // const val = moment(date);

    const currentDate = moment(date);
    const weekStart = currentDate.clone().weekday(1);

    return weekStart._d;

    // console.log("This is week start: ", weekStart._d);

    // let d = new Date(date);
    // let dd = d.getDay() || 7;
    // d.setDate(d.getDate() - dd + 1);
    // return d;
  }

  day_end() {
    const { week_count } = this.state;
    const { showMonth } = this.props;
    let d = new Date(this.day_start());
    let x = 0;
    if (showMonth) x = 7 * week_count;
    d.setDate(d.getDate() + 6 + x);

    return d;
  }

  currentDateFmt() {
    const { date } = this.props;
    return moment(date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY");
  }

  getItems = () => {
    let { scheduleList: schedule_items } = this.props;

    const date = this.getApiDate();

    return (schedule_items = isValidArray(schedule_items?.[date])
      ? schedule_items[date]
      : []);
  };

  getScheduleGroupedByCategory() {
    let {
      category: flt_category,
      position: flt_group,
      restaurant: flt_restaurant,
    } = this.props;

    let hash = {};
    let ret = [];

    let schedule_items = this.getItems();

    if (schedule_items.length) {
      schedule_items = schedule_items.filter(
        (item) =>
          (flt_group === 0 || item.group === flt_group) &&
          (flt_restaurant === 0 || item.restaurant === flt_restaurant) &&
          // (!flt_restaurant ||
          //   flt_restaurant === (item.restaurant ? item.restaurant : null)) &&
          (flt_category === 0 || flt_category === item.category?.id)
      );
    }
    const cat = {
      color: "#1976D2FF",
      created: "2020-03-23T07:48:21.423418Z",
      created_by: null,
      deleted: null,
      disabled: false,
      icon: null,
      id: 5,
      modified: "2020-07-02T08:26:47.793857Z",
      modified_by: null,
      so: 0,
      title: "FOH",
      visible: true,
    };

    if (isValidArray(schedule_items)) {
      schedule_items.forEach((schedule_item) => {
        // schedule_item.category = cat;

        if (hash[schedule_item?.category?.id] === undefined) {
          hash[schedule_item?.category?.id] = {
            instance: schedule_item.category,
            children: {},
          };
        }

        if (
          hash[schedule_item?.category?.id]["children"][schedule_item.group] ===
          undefined
        ) {
          hash[schedule_item.category?.id]["children"][schedule_item.group] = {
            instance: this.getGroup(schedule_item.group),
            children: {},
          };
        }
        if (
          hash[schedule_item.category?.id]["children"][schedule_item.group][
            "children"
          ][schedule_item.user?.id] === undefined
        ) {
          hash[schedule_item.category?.id]["children"][schedule_item.group][
            "children"
          ][schedule_item.user?.id] = {
            instance: schedule_item.user,
            children: {},
            cost: schedule_item.cost,
          };
        }

        if (
          hash[schedule_item.category?.id]["children"][schedule_item.group][
            "children"
          ][schedule_item.user?.id]["children"][schedule_item.date] === undefined
        ) {
          hash[schedule_item.category?.id]["children"][schedule_item.group][
            "children"
          ][schedule_item.user?.id]["children"][schedule_item.date] = {
            date: schedule_item.date,
            children: {},
          };
        }

        if (
          hash[schedule_item.category?.id]["children"][schedule_item.group][
            "children"
          ][schedule_item.user?.id]["children"][schedule_item.date]["children"][
            schedule_item.day_part?.id
          ] === undefined
        ) {
          const newData = {
            ...schedule_item.day_part,
            note: schedule_item.note,
          };
          hash[schedule_item.category?.id]["children"][schedule_item.group][
            "children"
          ][schedule_item.user?.id]["children"][schedule_item.date]["children"][
            schedule_item.day_part?.id
          ] = {
            instance: newData,
            // instance: schedule_item.day_part,
            children: [],
          };
        }

        hash[schedule_item.category?.id]["children"][schedule_item.group][
          "children"
        ][schedule_item.user?.id]["children"][schedule_item.date]["children"][
          schedule_item.day_part?.id
        ]["children"].push(schedule_item);
      });

      for (const category_id in hash) {
        ret.push({ type: "category", instance: hash[category_id]["instance"] });
        for (const position_id in hash[category_id]["children"]) {
          ret.push({
            type: "position",
            instance: hash[category_id]["children"][position_id]["instance"],
          });
          for (const user_id in hash[category_id]["children"][position_id][
            "children"
          ]) {
            ret.push({
              type: "user",
              instance:
                hash[category_id]["children"][position_id]["children"][user_id][
                  "instance"
                ],
              cost: this.getGroupUserCost(
                hash[category_id]["children"][position_id]["children"][user_id][
                  "children"
                ]
              ),
              shifts: this.getGroupUserDates(
                hash[category_id]["children"][position_id]["children"][user_id][
                  "children"
                ]
              ),
            });
          }
        }
      }
    }
    return ret;
  }

  getGroup(grp_id) {
    const { groupsDataList: items } = this.props;
    return items.find((itm) => itm?.id === grp_id);
  }

  getGroupUserCost(dates) {
    let ret = 0;
    for (const date in dates) {
      for (const day_part_id in dates[date]["children"]) {
        dates[date]["children"][day_part_id]["children"].forEach((item) => {
          ret += item.cost;
        });
      }
    }
    return ret.toFixed(2);
  }

  getGroupUserDates(dates) {
    let ret = [];
    for (let i = 1; i < 8; i++) {
      let date = this.getDateISOFormatted(i);
      ret.push(dates[date] !== undefined ? dates[date] : []);
    }

    return ret;
  }

  getDateISOFormatted(day_num) {
    return formatDateISO(this.getDate(day_num));
  }

  getCategoryName(category_id) {
    const { categoriesDataList } = this.props;

    if (category_id > -1) {
      if (categoriesDataList) {
        let x = categoriesDataList.find((rest) => rest?.id === category_id);
        if (x) return x["title"];
      }
    }
    return "-No category-";
  }

  getParentStart() {
    return formatDate(this.day_start());
  }

  getParentEnd() {
    return formatDate(this.day_end());
  }

  toggleShiftDrop = (shiftData = {}, type = "") => {
    this.setState({
      isShiftDrop: !this.state.isShiftDrop,
      shiftData,
      shiftType: type,
    });
  };

  openEditNote = (shift) => {
    const dates = [shift.date];
    const editNoteData = {
      id: shift?.id,
      color: shift?.color,
      created_by: shift?.created_by,
      date: shift?.date,
      dates: dates,
      modified_by: null,
      note: shift?.note,
      restaurant_id: shift?.restaurant,
      shift_time_id: shift?.shift_time?.id,
      user_id: shift?.user?.id,
    };

    this.setState({
      editNoteOpen: true,
      editNoteData: editNoteData,
    });
  };

  toggleEditNote = () => {
    this.setState({
      editNoteOpen: !this.state.editNoteOpen,
    });
  };

  render() {
    const {
      laborCostsTotal,
      laborCostsCategory,
      isShiftDrop,
      shiftData,
      note,
      shiftType,
      laborCostsDay,
      editNoteOpen,
    } = this.state;

    const { profile, inWeekNum, showLaborCost } = this.props;

    const scheduleData = this.getScheduleGroupedByCategory();

    return (
      <div className="col-md-12">
        {showLaborCost && (
          <div
            className="week d-flex flex-md-row flex-column pa-0  mt-3 mb-2"
            style={{ width: "100%" }}
          >
            <div
              className="col"
              style={{
                border: "1px solid",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <div>
                <strong>Labor costs</strong> Week Total:
                {/* {formatCost(laborCostsTotal)} */}
                <span
                  className={
                    formatCost(laborCostsTotal) < 0 ? "text-red" : "text-green"
                  }
                >
                  ${formatCost(laborCostsTotal)}
                </span>
              </div>
              {/* {isValidArray(laborCostsCategory) &&
								laborCostsCategory.map((cat) => {
									return (
										<div>
											{this.getCategoryName(cat.id)}: ${formatCost(cat.total)}
										</div>
									);
								})} */}
              {isValidObject(laborCostsCategory) &&
                Object.keys(laborCostsCategory).map((key, index) => {
                  return (
                    <div key={index}>
                      {this.getCategoryName(laborCostsCategory[key]?.id)}:{" "}
                      <span
                        className={
                          formatCost(laborCostsCategory[key]?.total) < 0
                            ? "text-red"
                            : "text-green"
                        }
                      >
                        ${formatCost(laborCostsCategory[key]?.total)}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        <div className="v-data-table text-center schedule-table theme--light">
          <div className="v-data-table__wrapper">
            <table>
              <thead>
                <tr>
                  <th
                    className="day-col text-left pa-0"
                    style={{ width: "12.5%" }}
                  >
                    &nbsp;
                  </th>
                  {[...Array(7)].map((x, i) => (
                    <th
                      className={`day-col text-center pa-0 ${testCurrent(
                        i + 1,
                        formatDateISO(this.day_start()),
                        inWeekNum
                      )}`}
                      style={{ width: "12.5%" }}
                      key={i}
                    >
                      <div
                        className="text-center font-weight-bold body-1"
                        style={{ width: "100%" }}
                      >
                        {getDay(i + 1)}{" "}
                        <span
                          className={
                            laborCostsDay[i + 1] < 0 ? "text-red" : "text-green"
                          }
                        >
                          {showLaborCost &&
                            `$${parseFloat(laborCostsDay[i + 1]).toFixed(2)}`}
                        </span>
                      </div>
                      <span className="font-weight-light">
                        {getDateShortFormatted(
                          i + 1,
                          formatDateISO(this.day_start()),
                          inWeekNum
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              {scheduleData.map((item, index) => {
                return (
                  <tbody key={index}>
                    {(item.type === "category" || item.type === "position") && (
                      <tr>
                        <td
                          colSpan="8"
                          className="day-col text-left"
                          style={{
                            backgroundColor: item?.instance?.color,
                          }}
                        >
                          <span
                            style={{
                              color: invertHex(item?.instance?.color),
                            }}
                          >
                            {item.type === "category"
                              ? item.instance.title
                              : item.instance?.name}
                          </span>
                        </td>
                      </tr>
                    )}
                    {item.type === "user" && (
                      <tr>
                        <td className="day-col text-left">
                          <div className="font-weight-bold body-2">
                            {item.instance?.full_name}

                            {showLaborCost && <span>- ${item?.cost}</span>}
                          </div>
                        </td>
                        {isValidArray(item?.shifts) &&
                          item?.shifts.map((shifts, index) => {
                            let shiftChild = shifts.children;

                            shiftChild =
                              shiftChild && Object.values(shiftChild);
                            return (
                              <td
                                className="day-col"
                                key={index}
                                draggable="true"
                                onDragStart={(e) => {
                                  console.log(
                                    "E: ",
                                    e,
                                    "New: ",
                                    e.target.value
                                  );
                                }}
                              >
                                {shiftChild &&
                                  shiftChild.map((date_part, index) => {
                                    return (
                                      <div key={index}>
                                        <span className="px-8 my-1 text-center text-uppercase subtitle-2 font-weight-bold v-chip v-chip--no-color theme--light v-size--default">
                                          <span className="v-chip__content">
                                            <div className="text-uppercase text-center button_custom mt-3">
                                              {date_part.instance.title}
                                            </div>
                                            {/* {date_part.instance.note && (
                                              <div
                                                className="text-uppercase text-center my-2"
                                                style={{
                                                  fontSize: "11px",
                                                  backgroundColor: "#b4defb",
                                                }}
                                              >
                                                Note : {date_part.instance.note}
                                              </div>
                                            )} */}
                                          </span>
                                        </span>

                                        {date_part.children.map(
                                          (shift, index) => {
                                            return (
                                              <React.Fragment key={index}>
                                                <div className="mb-2">
                                                  {getPermission(
                                                    "bshift.bs_can_manage_schedule",
                                                    profile
                                                  ) && (
                                                    <button
                                                      className="btn pl-2 pr-1 py-1 mt-2 mx-2"
                                                      style={{
                                                        backgroundColor:
                                                          "#38425B",
                                                        color: "white",
                                                        fontSize: "13px",
                                                      }}
                                                      onClick={() =>
                                                        this.openEditNote(shift)
                                                      }
                                                    >
                                                      {/* <img src={Test} alt="" /> */}

                                                      {shift?.note && (
                                                        <>
                                                          <img
                                                            src={EditNoteImg}
                                                            alt=""
                                                            style={{
                                                              height: "22px",
                                                              weight: "22px",
                                                            }}
                                                          />
                                                          {/* <span>Edit Note</span>  */}
                                                        </>
                                                      )}
                                                      {!shift?.note && (
                                                        <>
                                                          <img
                                                            src={EditNoteImg}
                                                            alt=""
                                                          />
                                                          {/* <span>Add Note</span>  */}
                                                        </>
                                                      )}
                                                    </button>
                                                  )}
                                                  {/* <button
                                                    className="btn px-1 py-1 mt-2 mx-2"
                                                    style={{
                                                      backgroundColor:
                                                        "#38425B",
                                                      color: "white",
                                                      fontSize: "13px",
                                                    }}
                                                    onClick={() =>
                                                      this.openEditNote(shift)
                                                    }
                                                  >
                                                    <img
                                                      src={Test}
                                                      alt=""
                                                      style={{
                                                        height: "22px",
                                                        weight: "22px",
                                                      }}
                                                    />
                                                  </button> */}
                                                  {shift.note && (
                                                    <div
                                                      className="text-uppercase text-center my-2 p-1  bg-secondary text-white "
                                                      style={{
                                                        fontSize: "12px",
                                                        // backgroundColor:
                                                        //   "#b4defb",
                                                      }}
                                                    >
                                                      {shift.note}
                                                    </div>
                                                  )}
                                                </div>
                                                <span
                                                  className="font-weight-light caption mx-auto UnderLineRed"
                                                  style={{
                                                    borderRadius: 0,
                                                    borderBottomWidth: "2px",
                                                    borderBottomColor:
                                                      shift.color,
                                                    borderBottomStyle: "solid",
                                                  }}
                                                >
                                                  {strTimeFormat(
                                                    shift.time_start
                                                  )}
                                                  -
                                                  {strTimeFormat(
                                                    shift.time_end
                                                  )}
                                                </span>
                                                <div className="mt-1  v-card v-sheet theme--light elevation-0">
                                                  <div>
                                                    {(shift &&
                                                      shift?.user &&
                                                      profile &&
                                                      shift?.user !==
                                                        undefined &&
                                                      shift?.user?.id ===
                                                        profile?.id &&
                                                      shift.shift_status ===
                                                        "") ||
                                                    shift.shift_status ===
                                                      "Drop Request" ? (
                                                      <button
                                                        type="button"
                                                        className="white--text btn px-2 py-1 mt-2"
                                                        onClick={() =>
                                                          this.toggleShiftDrop(
                                                            shift,
                                                            "drop"
                                                          )
                                                        }
                                                        disabled={
                                                          shift.shift_status ===
                                                          "Drop Request"
                                                        }
                                                        style={{
                                                          backgroundColor:
                                                            "#38425B",
                                                          color: "white",
                                                          fontSize: "13px",
                                                        }}
                                                      >
                                                        Give Up
                                                      </button>
                                                    ) : (
                                                      shift.shift_status ===
                                                        "Drop Confirmed" && (
                                                        <button
                                                          type="button"
                                                          className="black--text"
                                                          onClick={() =>
                                                            this.toggleShiftDrop(
                                                              shift,
                                                              "pickup"
                                                            )
                                                          }
                                                          disabled={
                                                            shift.shift_status ===
                                                            "Drop Request"
                                                          }
                                                        >
                                                          <span>
                                                            Open Shift
                                                            <br />
                                                            Pickup
                                                          </span>
                                                        </button>
                                                      )
                                                    )}
                                                  </div>
                                                </div>
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </div>
                                    );
                                  })}
                              </td>
                            );
                          })}
                      </tr>
                    )}
                  </tbody>
                );
              })}
            </table>
            {scheduleData.length === 0 && this.props.restaurant !== 0 && (
              <h1 className="my-5">
                No schedules available for this restaurant!!!
              </h1>
            )}
          </div>
        </div>
        {isShiftDrop && (
          <ShiftDropModal
            isOpen={isShiftDrop}
            toggle={this.toggleShiftDrop}
            shiftData={shiftData}
            fetchSchedule={this.fetchSchedule}
            shiftType={shiftType}
          />
        )}
        {editNoteOpen && (
          <EditNote
            isOpen={editNoteOpen}
            toggle={this.toggleEditNote}
            editNoteData={this.state.editNoteData}
            schedule={this.fetchSchedule}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    scheduleLoading: state.schedule.isLoading,
    scheduleList: state.schedule?.data || [],
  };
};
const mapDispatchToProps = {
  fetchEditScheduleShift,
  fetchCategories,
  fetchGroups,
  fetchRestaurants,
  fetchSchedule,
  fetchProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(WeekSchedule);
