import {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
} from "../../types/profile";
import { getApi } from "../api";

export const fetchProfileRequest = () => ({
  type: FETCH_PROFILE_REQUEST,
});

export const fetchProfileSuccess = (data) => ({
  type: FETCH_PROFILE_SUCCESS,
  data: data,
});

export const fetchProfileFailure = (error) => ({
  type: FETCH_PROFILE_FAILURE,
  error,
});

export const fetchProfile = (data) => (dispatch, getState) => {
  dispatch(fetchProfileRequest());
  return getApi(`users/api/profile`)
    .then((data) => {
      if (data?.data) {
        localStorage.setItem("loggedUserId", data.data.id);
      }
      dispatch(fetchProfileSuccess(data));
    })
    .catch((error) => {
      localStorage.clear();
      dispatch(fetchProfileFailure(error));
    });
};
