import {
	CREATE_SCHEDULE_TEMPLATE_REQUEST,
	CREATE_SCHEDULE_TEMPLATE_FAILURE,
	CREATE_SCHEDULE_TEMPLATE_SUCCESS,
} from '../../types/schedule';
import { postApi } from '../api';

export const createScheduleTemplateRequest = () => ({
	type: CREATE_SCHEDULE_TEMPLATE_REQUEST,
});

export const createScheduleTemplateSuccess = (data) => ({
	type: CREATE_SCHEDULE_TEMPLATE_SUCCESS,
	data: data,
});

export const createScheduleTemplateFailure = (error) => ({
	type: CREATE_SCHEDULE_TEMPLATE_FAILURE,
	error,
});

export const createScheduleTemplate = (data) => (dispatch, getState) => {
	dispatch(createScheduleTemplateRequest());

	return postApi(`bshift/api/schedule_template`, data)
		.then((data) => {
			dispatch(createScheduleTemplateSuccess(data));
		})
		.catch((error) => {
			dispatch(createScheduleTemplateFailure(error));
		});
};
