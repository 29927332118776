import {
  FETCH_PROFILE_ASSIGNED_QUIZ_REQUEST,
  FETCH_PROFILE_ASSIGNED_QUIZ_FAILURE,
  FETCH_PROFILE_ASSIGNED_QUIZ_SUCCESS,
} from "../../types/training";
import { getApi } from "../api";

export const fetchProfileAssignedQuizRequest = () => ({
  type: FETCH_PROFILE_ASSIGNED_QUIZ_REQUEST,
});

export const fetchProfileAssignedQuizSuccess = (data) => ({
  type: FETCH_PROFILE_ASSIGNED_QUIZ_SUCCESS,
  data: data,
});

export const fetchProfileAssignedQuizFailure = (error) => ({
  type: FETCH_PROFILE_ASSIGNED_QUIZ_FAILURE,
  error,
});

export const fetchProfileAssignedQuiz = (
  page,
  itemsPerPage,
  sort_field,
  sort_type,
  status,
  id
) => (dispatch, getState) => {
  dispatch(fetchProfileAssignedQuizRequest());

  let queryParams = [];
  if (page) {
    queryParams = [...queryParams, `page=${page}`];
  }
  if (itemsPerPage) {
    queryParams = [...queryParams, `itemsPerPage=${itemsPerPage}`];
  }
  if (sort_field) {
    queryParams = [...queryParams, `sortBy[]=${sort_field}`];
  }
  if (sort_field) {
    queryParams = [...queryParams, `sortDesc[]=${sort_type}`];
  }
  if (id) {
    queryParams = [...queryParams, `employee_id=${id}`];
  }
  if (status) {
    queryParams = [...queryParams, `quiz_status=${status}`];
  }

  return getApi(
    `bshift/api/training_assigned_quizzes?mustSort=${false}&mustSort=${false}&${queryParams.join(
      "&"
    )}`
  )
    .then((data) => {
      dispatch(fetchProfileAssignedQuizSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchProfileAssignedQuizFailure(error));
    });
};
