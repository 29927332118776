import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faStickyNote,
	faCalendarWeek,
	faUser,
	faBell,
} from '@fortawesome/free-solid-svg-icons';

import { fetchTodoTaskDetail } from '../../actions/todoTask/detail';
import { fetchTodoCommentList } from '../../actions/todoComment/list';
import { fetchCreateTodoComment } from '../../actions/todoComment/create';

import { convertUTC, isValidArray } from '../../modules/utils';

import './taskDetail.css';

class TaskDetailPopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			commentList: [],
			comment: '',
		};
	}

	componentDidMount() {
		this.getTaskDetail();
		this.getCommentList();
	}

	getTaskDetail = () => {
		const { data } = this.props;

		this.props.fetchTodoTaskDetail(data?.id);
	};

	getCommentList = () => {
		const { data } = this.props;

		this.props.fetchTodoCommentList({ id: data?.id }).then(() => {
			const { todoCommentList } = this.props;

			if (isValidArray(todoCommentList?.results)) {
				this.setState({
					commentList: todoCommentList?.results,
				});
			}
		});
	};

	handleChange = (value) => {
		this.setState({
			comment: value,
		});
	};

	handleKeyPress = (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			this.postComment();
		}
	};

	postComment = () => {
		const { comment } = this.state;
		const { todoTaskDetail } = this.props;

		const apiData = {
			comment: comment,
			todo_task: todoTaskDetail?.id,
			comment_by: parseInt(localStorage.getItem('loggedUserId')),
		};
		this.props.fetchCreateTodoComment(apiData).then(() => {
			const { todoCommentCreate } = this.props;
			if (todoCommentCreate) {
				this.setState(
					{
						comment: '',
					},
					() => this.getCommentList(),
				);
			}
		});
	};

	render() {
		const { commentList } = this.state;
		const { todoTaskDetail } = this.props;

		return (
			<Modal isOpen={true} size="lg" centered>
				<ModalHeader toggle={this.props.toggle}>
					<div onClick={this.props.toggle}>
						<h5>{todoTaskDetail?.title}</h5>
					</div>
				</ModalHeader>
				<ModalBody>
					<div className="author_bio_toggle_wrapper todo_detail">
						<div className="author_bio_wrap" style={{ display: 'block' }}>
							<div className="inner_grid">
								<span className="inner_title">
									<FontAwesomeIcon icon={faCalendarWeek} fixedWidth /> Added on:
								</span>
								<div className="inner_completby">
									<p className="addedby">
										{convertUTC(todoTaskDetail?.created)}{' '}
									</p>
								</div>
							</div>
							<div className="inner_grid">
								<span className="inner_title">
									<FontAwesomeIcon icon={faUser} fixedWidth /> Assigned to:
								</span>
								<div className="author_avatar">
									<Avatar
										name={todoTaskDetail?.assigned?.full_name || ''}
										size="25"
										round="50px"
									/>
									<span className="avatarname">
										{todoTaskDetail?.assigned?.full_name}
									</span>
								</div>
							</div>
							{isValidArray(todoTaskDetail?.notified_task) && (
								<div className="inner_grid">
									<span className="inner_title">
										<FontAwesomeIcon icon={faBell} fixedWidth /> When done,
										notify:
									</span>
									<div className="notified_users">
										{todoTaskDetail?.notified_task?.map((user) => (
											<div className="author_avatar">
												<Avatar
													name={user?.user?.full_name || ''}
													size="25"
													round="50px"
												/>
												<span className="avatarname">
													{user?.user?.full_name}
												</span>
											</div>
										))}
									</div>
								</div>
							)}
							<div className="inner_grid">
								<span className="inner_title">
									<FontAwesomeIcon icon={faCalendarWeek} fixedWidth /> Due on:
								</span>
								<div className="inner_completby">
									<i className="zmdi zmdi-calendar"></i>
									<span className="avatarname">
										{convertUTC(todoTaskDetail?.due_date)}
									</span>
								</div>
							</div>
							{todoTaskDetail?.notes && (
								<div className="inner_grid">
									<span className="inner_title">
										<FontAwesomeIcon icon={faStickyNote} fixedWidth /> Notes:
									</span>
									<textarea
										className="form-control mt-3"
										rows="8"
										value={todoTaskDetail?.notes}
										readOnly
									></textarea>
								</div>
							)}
							<div className="inner_grid">
								<span className="inner_title">
									Comment ({' '}
									{(isValidArray(commentList) && commentList.length) || 0} ):
								</span>
								<div className="CommentBox">
									<div className="author_avatar">
										<div className="tbl__avatar green">
											<span className="white--text-cust">DR</span>
										</div>
									</div>
									<div className="form-group">
										<textarea
											className="form-control"
											id="exampleFormControlTextarea1"
											rows="1"
											value={this.state.comment}
											onChange={(e) => this.handleChange(e.target.value)}
											onKeyPress={this.handleKeyPress}
										></textarea>
									</div>
								</div>
								{isValidArray(commentList) &&
									commentList.map((comment) => (
										<div
											className="row d-flex justify-content-center comment-main p-1"
											key={comment?.id}
										>
											<div className="col-md-12">
												<div className="card p-3 comment-card">
													<div className="d-flex justify-content-between align-items-center">
														<div className="user d-flex flex-row align-items-center">
															<Avatar
																className="user-img rounded-circle mr-2"
																name={comment?.comment_by?.full_name || ''}
																size="25"
																round="50px"
															/>
															<span className="comment_info">
																<small className="font-weight-bold text-primary">
																	{comment?.comment_by?.full_name}
																</small>
																<small className="font-weight-bold comment_text">
																	{comment?.comment}
																</small>
															</span>
														</div>
														<small className="comment_time font-weight-bold">
															{convertUTC(comment?.created)}
														</small>
													</div>
												</div>
											</div>
										</div>
									))}
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		todoTaskDetailIsLoading: state.todoTaskDetail.isLoading,
		todoTaskDetail: state.todoTaskDetail.data,

		todoCommentListIsLoading: state.todoCommentList.isLoading,
		todoCommentList: state.todoCommentList.data,

		todoCommentCreateIsLoading: state.todoCommentCreate.isLoading,
		todoCommentCreate: state.todoCommentCreate.data,
	};
};
const mapDispatchToProps = {
	fetchTodoTaskDetail,
	fetchTodoCommentList,
	fetchCreateTodoComment,
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailPopup);
