export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';

export const FETCH_CATEGORY_UPDATE_REQUEST = 'FETCH_CATEGORY_UPDATE_REQUEST';
export const FETCH_CATEGORY_UPDATE_FAILURE = 'FETCH_CATEGORY_UPDATE_FAILURE';
export const FETCH_CATEGORY_UPDATE_SUCCESS = 'FETCH_CATEGORY_UPDATE_SUCCESS';

export const FETCH_CATEGORY_DETAIL_REQUEST = 'FETCH_CATEGORY_DETAIL_REQUEST';
export const FETCH_CATEGORY_DETAIL_FAILURE = 'FETCH_CATEGORY_DETAIL_FAILURE';
export const FETCH_CATEGORY_DETAIL_SUCCESS = 'FETCH_CATEGORY_DETAIL_SUCCESS';

export const FETCH_CATEGORY_CREATE_REQUEST = 'FETCH_CATEGORY_CREATE_REQUEST';
export const FETCH_CATEGORY_CREATE_FAILURE = 'FETCH_CATEGORY_CREATE_FAILURE';
export const FETCH_CATEGORY_CREATE_SUCCESS = 'FETCH_CATEGORY_CREATE_SUCCESS';

export const FETCH_CATEGORY_DELETE_REQUEST = 'FETCH_CATEGORY_DELETE_REQUEST';
export const FETCH_CATEGORY_DELETE_FAILURE = 'FETCH_CATEGORY_DELETE_FAILURE';
export const FETCH_CATEGORY_DELETE_SUCCESS = 'FETCH_CATEGORY_DELETE_SUCCESS';
