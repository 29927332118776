export const FETCH_MY_QUIZ_REQUEST = 'FETCH_MY_QUIZ_REQUEST';
export const FETCH_MY_QUIZ_FAILURE = 'FETCH_MY_QUIZ_FAILURE';
export const FETCH_MY_QUIZ_SUCCESS = 'FETCH_MY_QUIZ_SUCCESS';

export const FETCH_ASSIGNED_QUIZ_REQUEST = 'FETCH_ASSIGNED_QUIZ_REQUEST';
export const FETCH_ASSIGNED_QUIZ_FAILURE = 'FETCH_ASSIGNED_QUIZ_FAILURE';
export const FETCH_ASSIGNED_QUIZ_SUCCESS = 'FETCH_ASSIGNED_QUIZ_SUCCESS';

export const FETCH_SAVE_DRAFT_REQUEST = 'FETCH_SAVE_DRAFT_REQUEST';
export const FETCH_SAVE_DRAFT_FAILURE = 'FETCH_SAVE_DRAFT_FAILURE';
export const FETCH_SAVE_DRAFT_SUCCESS = 'FETCH_SAVE_DRAFT_SUCCESS';

export const FETCH_TRAINING_QUIZ_REQUEST = 'FETCH_TRAINING_QUIZ_REQUEST';
export const FETCH_TRAINING_QUIZ_FAILURE = 'FETCH_TRAINING_QUIZ_FAILURE';
export const FETCH_TRAINING_QUIZ_SUCCESS = 'FETCH_TRAINING_QUIZ_SUCCESS';

export const FETCH_ADD_QUIZ_REQUEST = 'FETCH_ADD_QUIZ_REQUEST';
export const FETCH_ADD_QUIZ_FAILURE = 'FETCH_ADD_QUIZ_FAILURE';
export const FETCH_ADD_QUIZ_SUCCESS = 'FETCH_ADD_QUIZ_SUCCESS';

export const FETCH_PROFILE_ASSIGNED_QUIZ_REQUEST =
	'FETCH_PROFILE_ASSIGNED_QUIZ_REQUEST';
export const FETCH_PROFILE_ASSIGNED_QUIZ_FAILURE =
	'FETCH_PROFILE_ASSIGNED_QUIZ_FAILURE';
export const FETCH_PROFILE_ASSIGNED_QUIZ_SUCCESS =
	'FETCH_PROFILE_ASSIGNED_QUIZ_SUCCESS';
