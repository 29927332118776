export const FETCH_RESTAURANT_GROUPS_REQUEST =
  "FETCH_RESTAURANT_GROUPS_REQUEST";
export const FETCH_RESTAURANT_GROUPS_FAILURE =
  "FETCH_RESTAURANT_GROUPS_FAILURE";
export const FETCH_RESTAURANT_GROUPS_SUCCESS =
  "FETCH_RESTAURANT_GROUPS_SUCCESS";

export const FETCH_SINGLE_RESTAURANT_GROUPS_REQUEST =
  "FETCH_SINGLE_RESTAURANT_GROUPS_REQUEST";
export const FETCH_SINGLE_RESTAURANT_GROUPS_FAILURE =
  "FETCH_SINGLE_RESTAURANT_GROUPS_FAILURE";
export const FETCH_SINGLE_RESTAURANT_GROUPS_SUCCESS =
  "FETCH_SINGLE_RESTAURANT_GROUPS_SUCCESS";

export const FETCH_RESTAURANT_GROUP_CREATE_REQUEST =
  "FETCH_RESTAURANT_GROUP_CREATE_REQUEST";
export const FETCH_RESTAURANT_GROUP_CREATE_SUCCESS =
  "FETCH_RESTAURANT_GROUP_CREATE_SUCCESS";
export const FETCH_RESTAURANT_GROUP_CREATE_FAILURE =
  "FETCH_RESTAURANT_GROUP_CREATE_FAILURE";

export const FETCH_RESTAURANT_GROUP_UPDATE_REQUEST =
  "FETCH_RESTAURANT_GROUP_UPDATE_REQUEST";
export const FETCH_RESTAURANT_GROUP_UPDATE_SUCCESS =
  "FETCH_RESTAURANT_GROUP_UPDATE_SUCCESS";
export const FETCH_RESTAURANT_GROUP_UPDATE_FAILURE =
  "FETCH_RESTAURANT_GROUP_UPDATE_FAILURE";

export const FETCH_RESTAURANT_GROUP_DELETE_REQUEST =
  "FETCH_RESTAURANT_GROUP_DELETE_REQUEST";
export const FETCH_RESTAURANT_GROUP_DELETE_SUCCESS =
  "FETCH_RESTAURANT_GROUP_DELETE_SUCCESS";
export const FETCH_RESTAURANT_GROUP_DELETE_FAILURE =
  "FETCH_RESTAURANT_GROUP_DELETE_FAILURE";
