export const FETCH_DROP_REQUEST_REQUEST = "FETCH_DROP_REQUEST_REQUEST";
export const FETCH_DROP_REQUEST_FAILURE = "FETCH_DROP_REQUEST_FAILURE";
export const FETCH_DROP_REQUEST_SUCCESS = "FETCH_DROP_REQUEST_SUCCESS";

export const FETCH_PICKUP_REQUEST_REQUEST = "FETCH_PICKUP_REQUEST_REQUEST";
export const FETCH_PICKUP_REQUEST_FAILURE = "FETCH_PICKUP_REQUEST_FAILURE";
export const FETCH_PICKUP_REQUEST_SUCCESS = "FETCH_PICKUP_REQUEST_SUCCESS";

export const FETCH_SCHEDULE_TEMPLATE_REQUEST =
  "FETCH_SCHEDULE_TEMPLATE_REQUEST";
export const FETCH_SCHEDULE_TEMPLATE_FAILURE =
  "FETCH_SCHEDULE_TEMPLATE_FAILURE";
export const FETCH_SCHEDULE_TEMPLATE_SUCCESS =
  "FETCH_SCHEDULE_TEMPLATE_SUCCESS";

export const FETCH_SCHEDULE_REQUEST = "FETCH_SCHEDULE_REQUEST";
export const FETCH_SCHEDULE_FAILURE = "FETCH_SCHEDULE_FAILURE";
export const FETCH_SCHEDULE_SUCCESS = "FETCH_SCHEDULE_SUCCESS";

export const FETCH_SHIFT_TIME_REQUEST = "FETCH_SHIFT_TIME_REQUEST";
export const FETCH_SHIFT_TIME_FAILURE = "FETCH_SHIFT_TIME_FAILURE";
export const FETCH_SHIFT_TIME_SUCCESS = "FETCH_SHIFT_TIME_SUCCESS";

export const FETCH_SHIFT_DAY_PART_REQUEST = "FETCH_SHIFT_DAY_PART_REQUEST";
export const FETCH_SHIFT_DAY_PART_FAILURE = "FETCH_SHIFT_DAY_PART_FAILURE";
export const FETCH_SHIFT_DAY_PART_SUCCESS = "FETCH_SHIFT_DAY_PART_SUCCESS";

export const DELETE_SHIFT_TIME_REQUEST = "DELETE_SHIFT_TIME_REQUEST";
export const DELETE_SHIFT_TIME_FAILURE = "DELETE_SHIFT_TIME_FAILURE";
export const DELETE_SHIFT_TIME_SUCCESS = "DELETE_SHIFT_TIME_SUCCESS";

export const CREATE_SHIFT_TIME_REQUEST = "CREATE_SHIFT_TIME_REQUEST";
export const CREATE_SHIFT_TIME_FAILURE = "CREATE_SHIFT_TIME_FAILURE";
export const CREATE_SHIFT_TIME_SUCCESS = "CREATE_SHIFT_TIME_SUCCESS";

export const UPDATE_SHIFT_TIME_REQUEST = "UPDATE_SHIFT_TIME_REQUEST";
export const UPDATE_SHIFT_TIME_FAILURE = "UPDATE_SHIFT_TIME_FAILURE";
export const UPDATE_SHIFT_TIME_SUCCESS = "UPDATE_SHIFT_TIME_SUCCESS";

export const CREATE_SCHEDULE_TEMPLATE_REQUEST =
  "CREATE_SCHEDULE_TEMPLATE_REQUEST";
export const CREATE_SCHEDULE_TEMPLATE_FAILURE =
  "CREATE_SCHEDULE_TEMPLATE_FAILURE";
export const CREATE_SCHEDULE_TEMPLATE_SUCCESS =
  "CREATE_SCHEDULE_TEMPLATE_SUCCESS";

export const FETCH_EDIT_SCHEDULE_SHIFT_REQUEST =
  "FETCH_EDIT_SCHEDULE_SHIFT_REQUEST";
export const FETCH_EDIT_SCHEDULE_SHIFT_FAILURE =
  "FETCH_EDIT_SCHEDULE_SHIFT_FAILURE";
export const FETCH_EDIT_SCHEDULE_SHIFT_SUCCESS =
  "FETCH_EDIT_SCHEDULE_SHIFT_SUCCESS";

export const FETCH_DELETE_SCHEDULE_SHIFT_REQUEST =
  "FETCH_DELETE_SCHEDULE_SHIFT_REQUEST";
export const FETCH_DELETE_SCHEDULE_SHIFT_FAILURE =
  "FETCH_DELETE_SCHEDULE_SHIFT_FAILURE";
export const FETCH_DELETE_SCHEDULE_SHIFT_SUCCESS =
  "FETCH_DELETE_SCHEDULE_SHIFT_SUCCESS";

export const FETCH_ADD_USER_SHIFT_REQUEST = "FETCH_ADD_USER_SHIFT_REQUEST";
export const FETCH_ADD_USER_SHIFT_FAILURE = "FETCH_ADD_USER_SHIFT_FAILURE";
export const FETCH_ADD_USER_SHIFT_SUCCESS = "FETCH_ADD_USER_SHIFT_SUCCESS";

export const FETCH_CREATE_COPY_SCHEDULE_WEEK_REQUEST =
  "FETCH_CREATE_COPY_SCHEDULE_WEEK_REQUEST";
export const FETCH_CREATE_COPY_SCHEDULE_WEEK_FAILURE =
  "FETCH_CREATE_COPY_SCHEDULE_WEEK_FAILURE";
export const FETCH_CREATE_COPY_SCHEDULE_WEEK_SUCCESS =
  "FETCH_CREATE_COPY_SCHEDULE_WEEK_SUCCESS";

export const FETCH_DELETE_SCHEDULE_TEMPLATE_REQUEST =
  "FETCH_DELETE_SCHEDULE_TEMPLATE_REQUEST";
export const FETCH_DELETE_SCHEDULE_TEMPLATE_FAILURE =
  "FETCH_DELETE_SCHEDULE_TEMPLATE_FAILURE";
export const FETCH_DELETE_SCHEDULE_TEMPLATE_SUCCESS =
  "FETCH_DELETE_SCHEDULE_TEMPLATE_SUCCESS";

export const FETCH_LOAD_TEMPLATE_REQUEST = "FETCH_LOAD_TEMPLATE_REQUEST";
export const FETCH_LOAD_TEMPLATE_FAILURE = "FETCH_LOAD_TEMPLATE_FAILURE";
export const FETCH_LOAD_TEMPLATE_SUCCESS = "FETCH_LOAD_TEMPLATE_SUCCESS";

export const FETCH_CLEAR_SCHEDULE_WEEK_REQUEST =
  "FETCH_CLEAR_SCHEDULE_WEEK_REQUEST";
export const FETCH_CLEAR_SCHEDULE_WEEK_FAILURE =
  "FETCH_CLEAR_SCHEDULE_WEEK_FAILURE";
export const FETCH_CLEAR_SCHEDULE_WEEK_SUCCESS =
  "FETCH_CLEAR_SCHEDULE_WEEK_SUCCESS";

export const FETCH_CREATE_DROP_REQUEST_REQUEST =
  "FETCH_CREATE_DROP_REQUEST_REQUEST";
export const FETCH_CREATE_DROP_REQUEST_FAILURE =
  "FETCH_CREATE_DROP_REQUEST_FAILURE";
export const FETCH_CREATE_DROP_REQUEST_SUCCESS =
  "FETCH_CREATE_DROP_REQUEST_SUCCESS";

export const FETCH_SCHEDULE_COUNT_REQUEST = "FETCH_SCHEDULE_COUNT_REQUEST";
export const FETCH_SCHEDULE_COUNT_FAILURE = "FETCH_SCHEDULE_COUNT_FAILURE";
export const FETCH_SCHEDULE_COUNT_SUCCESS = "FETCH_SCHEDULE_COUNT_SUCCESS";

export const FETCH_CREATE_PICKUP_REQUEST_REQUEST =
  "FETCH_CREATE_PICKUP_REQUEST_REQUEST";
export const FETCH_CREATE_PICKUP_REQUEST_FAILURE =
  "FETCH_CREATE_PICKUP_REQUEST_FAILURE";
export const FETCH_CREATE_PICKUP_REQUEST_SUCCESS =
  "FETCH_CREATE_PICKUP_REQUEST_SUCCESS";

export const FETCH_UPDATE_PICKUP_REQUEST_REQUEST =
  "FETCH_UPDATE_PICKUP_REQUEST_REQUEST";
export const FETCH_UPDATE_PICKUP_REQUEST_FAILURE =
  "FETCH_UPDATE_PICKUP_REQUEST_FAILURE";
export const FETCH_UPDATE_PICKUP_REQUEST_SUCCESS =
  "FETCH_UPDATE_PICKUP_REQUEST_SUCCESS";

export const FETCH_CUSTOM_TIME_SHIFT_REQUEST =
  "FETCH_CUSTOM_TIME_SHIFT_REQUEST";
export const FETCH_CUSTOM_TIME_SHIFT_SUCCESS =
  "FETCH_CUSTOM_TIME_SHIFT_SUCCESS";
export const FETCH_CUSTOM_TIME_SHIFT_FAILURE =
  "FETCH_CUSTOM_TIME_SHIFT_FAILURE";
