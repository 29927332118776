import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  AiFillDashboard,
  AiFillCalendar,
  AiFillSchedule,
  AiFillFileImage,
  AiFillCalculator,
} from "react-icons/ai";
import { MdQuiz, MdNoteAlt, MdOutlineImage, MdFoodBank } from "react-icons/md";
import {
  BsFillPeopleFill,
  BsJournals,
  BsInfoCircleFill,
  BsFillFileEarmarkTextFill,
  BsCollectionPlayFill,
  BsFillChatLeftTextFill,
  BsFillGridFill,
  BsFillFileBarGraphFill,
} from "react-icons/bs";
import { FaCaretDown } from "react-icons/fa";
import { fetchProfile } from "../../actions/profile/profile";
import { getPermission } from "../../modules/utils";
import { FaBullhorn } from "react-icons/fa";
class Sidebar extends Component {
  componentDidMount() {
    this.props.fetchProfile();
  }
  render() {
    const { profileData } = this.props;
    return (
      <>
        <div className="pull_left side_box_outer">
          <div className="side_menu">
            <div className="side_menu_list">
              <ul>
                <Link to="/dashboard">
                  <li>
                    <AiFillDashboard className="d-inline mx-3 sidebar-icon" />
                    {/* <i className="zmdi zmdi-palette"></i>  */}
                    Dashboard
                  </li>
                </Link>
                {getPermission("bshift.bs_can_view_calendar", profileData) && (
                  <Link to="/calendar">
                    <li>
                      <AiFillCalendar className="d-inline mx-3 sidebar-icon" />
                      {/* <i className="zmdi zmdi-palette"></i>  */}
                      Calendar
                    </li>
                  </Link>
                )}

                <li className="py-0">
                  <FaCaretDown className="d-inline mx-3 sidebar-icon" />
                  Employees
                  {/* </Link> */}
                  <ul className="sub_menu2">
                    {getPermission(
                      "users.bs_can_view_employees",
                      profileData
                    ) && (
                      <Link to="/mainInfo" className="menu_sub_left">
                        <li>
                          <BsInfoCircleFill className="d-inline mx-3 sidebar-icon" />
                          Main info
                        </li>
                      </Link>
                    )}

                    <Link to="/categories" className="menu_sub_left">
                      <li>
                        <BsFillGridFill className="d-inline mx-3 sidebar-icon" />
                        Categories
                      </li>
                    </Link>
                    {getPermission(
                      "users.bs_can_view_employee_positions",
                      profileData
                    ) && (
                      <Link to="/positions" className="menu_sub_left">
                        <li>
                          <BsFillPeopleFill className="d-inline mx-3 sidebar-icon" />
                          Positions
                        </li>
                      </Link>
                    )}
                    {getPermission(
                      "bshift.bs_can_view_restaurants",
                      profileData
                    ) && (
                      <Link to="/restaurants" className="menu_sub_left">
                        <li>
                          <MdFoodBank className="d-inline mx-3 sidebar-icon-multiple" />
                          Restaurant
                        </li>
                      </Link>
                    )}
                    {getPermission(
                      "bshift.bs_can_view_restaurants",
                      profileData
                    ) && (
                      <Link
                        to="/restaurantgroups"
                        className="menu_sub_left d-flex  align-items-center"
                      >
                        <li className="bottom-none">
                          <MdFoodBank className="d-inline mx-3 sidebar-icon-multiple" />
                          Restaurant Groups
                        </li>
                      </Link>
                    )}
                  </ul>
                </li>
                {getPermission("bshift.bs_view_log_record", profileData) && (
                  <Link to="/managers-log">
                    <li>
                      <MdNoteAlt className="d-inline mx-3 sidebar-icon" />
                      Managers Log
                    </li>
                  </Link>
                )}
                {getPermission(
                  "bshift.bs_can_view_sales_check",
                  profileData
                ) && (
                  <Link to="/food-gallery">
                    <li>
                      <MdOutlineImage className="d-inline mx-3 sidebar-icon" />
                      Gallery
                    </li>
                  </Link>
                )}
                {getPermission(
                  "bshift.bs_can_view_sales_check",
                  profileData
                ) && (
                  <Link to="/salesData">
                    <li>
                      <BsFillFileBarGraphFill className="d-inline mx-3 sidebar-icon" />
                      Sales Data
                    </li>
                  </Link>
                )}

                {getPermission("bshift.bs_can_view_schedule", profileData) && (
                  <Link to="/schedule">
                    <li>
                      <AiFillSchedule className="d-inline mx-3 sidebar-icon" />
                      Schedule
                    </li>
                  </Link>
                )}

                {getPermission("bshift.bs_view_training", profileData) && (
                  <li>
                    <Link to="/my-quizzes">
                      <FaCaretDown className="d-inline mx-3 sidebar-icon" />
                      Training
                    </Link>
                    <ul className="sub_menu2">
                      <Link to="/my-quizzes" className="menu_sub_left">
                        <li>
                          <MdQuiz className="d-inline mx-3 sidebar-icon" />
                          My Quizzes
                        </li>
                      </Link>
                      {getPermission(
                        "bshift.bs_view_assigned_quizzes",
                        profileData
                      ) && (
                        <Link
                          to="/assigned-quizzes"
                          className="menu_sub_left d-flex  align-items-center"
                        >
                          <li>
                            <MdQuiz className="d-inline mx-3 sidebar-icon" />
                            Assigned Quizzes
                          </li>
                        </Link>
                      )}

                      {getPermission(
                        "bshift.bs_can_view_training_document",
                        profileData
                      ) && (
                        <Link
                          to="/documents"
                          className="menu_sub_left  d-flex  align-items-center"
                        >
                          <li>
                            <BsFillFileEarmarkTextFill className="d-inline mx-3 sidebar-icon" />
                            Document
                          </li>
                        </Link>
                      )}
                      {getPermission(
                        "bshift.bs_can_view_training_video",
                        profileData
                      ) && (
                        <Link
                          to="/videos"
                          className="menu_sub_left  d-flex  align-items-center"
                        >
                          <li>
                            <BsCollectionPlayFill className="d-inline mx-3 sidebar-icon" />
                            Videos
                          </li>
                        </Link>
                      )}
                      {getPermission(
                        "users.bs_can_view_employees_document",
                        profileData
                      ) && (
                        <Link
                          to="/employee-documents"
                          className="menu_sub_left d-flex  align-items-center"
                        >
                          <li>
                            <BsFillFileEarmarkTextFill className="d-inline mx-3 sidebar-icon-multiple" />
                            Employee Documents
                          </li>
                        </Link>
                      )}
                    </ul>
                  </li>
                )}

                <Link to="/restaurant-management">
                  <li>
                    <BsFillGridFill className="d-inline mx-3 sidebar-icon" />
                    Management
                  </li>
                </Link>

                {/* {getPermission("bshift.bs_can_view_schedule", profileData) && ( */}
                <Link to="/chat">
                  <li>
                    <BsFillChatLeftTextFill className="d-inline mx-3 sidebar-icon" />
                    Chat
                  </li>
                </Link>
                {/* )}  */}
                {profileData?.is_superuser && (
                  <Link to="/announcements-details">
                    <li>
                      <FaBullhorn
                        onClick={this.handleDock}
                        data-tip="Announcements"
                        data-for="announcement-tip"
                        className="d-inline mx-3 sidebar-icon"
                      />
                      Announcements
                    </li>
                  </Link>
                )}
                {getPermission("bshift.bs_view_log_record", profileData) && (
                  <Link to="/calculator">
                    <li>
                      <AiFillCalculator
                        // onClick={this.handleDock}
                        data-tip="Calculator"
                        data-for="calculator-tip"
                        className="d-inline mx-3 sidebar-icon"
                      />
                      Purchase Calculator
                    </li>
                  </Link>
                )}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileData: state.profileData.data,
  };
};
const mapDispatchToProps = {
  fetchProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
