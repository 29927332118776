import {
	FETCH_WHETHER_REQUEST,
	FETCH_WHETHER_FAILURE,
	FETCH_WHETHER_SUCCESS,
} from '../../types/whether';
import { getApi } from '../api';

export const fetchWhetherRequest = () => ({
	type: FETCH_WHETHER_REQUEST,
});

export const fetchWhetherSuccess = (data) => ({
	type: FETCH_WHETHER_SUCCESS,
	data: data,
});

export const fetchWhetherFailure = (error) => ({
	type: FETCH_WHETHER_FAILURE,
	error,
});

export const fetchWhether = (data) => (dispatch, getState) => {
	dispatch(fetchWhetherRequest());
	return getApi(`bshift/api/weather`)
		.then((data) => {
			dispatch(fetchWhetherSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchWhetherFailure(error));
		});
};
