import { toast } from "react-toastify";
import {
  FETCH_CREATE_ANNOUNCEMENTS_REQUEST,
  FETCH_CREATE_ANNOUNCEMENTS_SUCCESS,
  FETCH_CREATE_ANNOUNCEMENTS_FAILURE,
} from "../../types/announcements";
import { postApi } from "../api";

export const fetchCreateAnnouncementsRequest = () => ({
  type: FETCH_CREATE_ANNOUNCEMENTS_REQUEST,
});

export const fetchCreateAnnouncementsSuccess = (data) => ({
  type: FETCH_CREATE_ANNOUNCEMENTS_SUCCESS,
  data: data,
});

export const fetchCreateAnnouncementsFailure = (error) => ({
  type: FETCH_CREATE_ANNOUNCEMENTS_FAILURE,
  error,
});

export const fetchCreateAnnouncements = (apiData) => (dispatch, getState) => {
  dispatch(fetchCreateAnnouncementsRequest());
  return postApi(`bshift/api/announcements`, apiData)
    .then((data) => {
      toast.success("Announcement created successfully!");
      dispatch(fetchCreateAnnouncementsSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchCreateAnnouncementsFailure(error));
    });
};
