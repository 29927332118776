import {
	FETCH_ASSIGNED_QUIZ_REQUEST,
	FETCH_ASSIGNED_QUIZ_FAILURE,
	FETCH_ASSIGNED_QUIZ_SUCCESS,
} from '../../types/training';
import { getApi } from '../api';

export const fetchAssignedQuizRequest = () => ({
	type: FETCH_ASSIGNED_QUIZ_REQUEST,
});

export const fetchAssignedQuizSuccess = (data) => ({
	type: FETCH_ASSIGNED_QUIZ_SUCCESS,
	data: data,
});

export const fetchAssignedQuizFailure = (error) => ({
	type: FETCH_ASSIGNED_QUIZ_FAILURE,
	error,
});

export const fetchAssignedQuiz = (
	page,
	itemsPerPage,
	search,
	sort_field,
	sort_type,
) => (dispatch, getState) => {
	dispatch(fetchAssignedQuizRequest());

	let queryParams = [];
	if (page) {
		queryParams = [...queryParams, `page=${page}`];
	}
	if (itemsPerPage) {
		queryParams = [...queryParams, `itemsPerPage=${itemsPerPage}`];
	}
	if (search) {
		queryParams = [...queryParams, `search=${search}`];
	}
	if (sort_field) {
		queryParams = [...queryParams, `sortBy[]=${sort_field}`];
	}
	if (sort_field) {
		queryParams = [...queryParams, `sortDesc[]=${sort_type}`];
	}

	return getApi(
		`bshift/api/training_assigned_quizzes?mustSort=${false}&mustSort=${false}&${queryParams.join(
			'&',
		)}`,
	)
		.then((data) => {
			dispatch(fetchAssignedQuizSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchAssignedQuizFailure(error));
		});
};
