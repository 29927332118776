import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import DeleteModal from "../common/DeleteModal";
import { Button, FormGroup} from "reactstrap";
import Select from "react-select";

import RowComponent from "./RowComponent";

import { fetchUsers } from "../../actions/users/users";
import { fetchSingleUser } from "../../actions/users/singleUser";
import { fetchDeleteUser } from "../../actions/users/delete";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchGroups } from "../../actions/groups/groups";
import { fetchCategories } from "../../actions/categories/categories";
import { fetchProfile } from "../../actions/profile/profile";
import { employeeCsvEvent } from "../../actions/employee/employee";

import { map } from "bluebird";
import { FaPlus } from "react-icons/fa";
import { customStyles, isValidArray, isValidObject } from "../../modules/utils";
import { getPermission } from "../../modules/utils";
import { CSVLink } from "react-csv";

export class MainInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isOpenDeleteModal: false,
      deleteId: 0,
      page: 1,
      itemsPerPage: 10,
      restaurant: "",
      sort_field: "name",
      sort_type: "ASC",
      selectedRestaurant: "",
      selectedGroup: "",
      restaurantId: null,
      groupId: null,
    };
  }
  importRef = React.createRef(null);

  componentDidMount() {
    this.getInitialData();
    this.props.fetchProfile();
    this.props.fetchRestaurants();
    this.props.fetchGroups();
    this.props.fetchCategories();
  }

  componentDidUpdate(prevProps, prevState) {
    const { page, itemsPerPage } = this.state;
    if (prevProps.globalSearch != this.props.globalSearch) {
      if (this.props.globalSearch) {
        if (this.props.globalSearch.length >= 3) {
          this.props.fetchUsers({
            search: this.props.globalSearch,
            page: page,
            itemsPerPage: itemsPerPage,
          });
        }
      } else {
        this.fetchUserData();
      }
    }
  }

  getInitialData = () => {
    const { fetchRestaurants, fetchGroups, fetchCategories } = this.props;
    this.fetchUserData();
    // fetchRestaurants();
    // fetchGroups();
    // fetchCategories();
  };

  categoryAddModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  fetchUserData = () => {
    const { page, itemsPerPage, restaurantId, groupId } = this.state; 
    this.props.fetchUsers({
      page: page,
      itemsPerPage: itemsPerPage,
      restaurants: restaurantId?.value,
      groups: groupId?.value,
    });
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page,
      },
      () => {
        this.fetchUserData();
      }
    );
  };

  handleRowPerPage = (itemsPerPage) => {
    this.setState(
      {
        itemsPerPage,
      },
      () => {
        this.fetchUserData();
      }
    );
  };

  getRestaurants = (d) => {
    const { restaurantsData } = this.props;
    const restaurant = d.restaurants?.map((i) => {
      const value = restaurantsData?.results.find((data) => {
        if (data.id === i) return data;
      });
      return value?.title;
    });
    return restaurant?.toString();
  };

  getPosition = (d) => {
    const { groupsData } = this.props;
    const position = d.groups?.map((i) => {
      const value = groupsData?.results.find((data) => {
        if (data.id === i) return data;
      });
      return value?.name;
    });
    return position?.toString();
  };

  getCategory = (d) => {
    const { groupsData, categoriesData } = this.props;
    let category = d.groups
      ?.map((i) => {
        const value =
          isValidArray(groupsData?.results) &&
          groupsData?.results.find((data) => {
            if (data.id === i) return data;
          });
        return value?.category;
      })
      .map((i) => {
        const category =
          isValidArray(categoriesData?.results) &&
          categoriesData?.results?.find((data) => {
            if (data.id === i) return data;
          });
        return category?.title;
      });

    category = category?.filter((d) => d !== null && d !== undefined);
    return category?.toString();
  };

  getUserListing = () => {
    let { usersData, globalSearch } = this.props;
    const {
      restaurant,
      sort_field,
      sort_type,
      selectedRestaurant,
    } = this.state;

    if (isValidObject(selectedRestaurant)) {
      return usersData?.results
        ?.filter((data) =>
          data?.restaurants?.includes(parseInt(selectedRestaurant?.value))
        )
        .map((d) => {
          return {
            id: d.id,
            avatar: (
              <Avatar
                name={d.full_name.split(" (")[0] || ""}
                size="32"
                round="50px"
                color="#38425B"
                // color="#0079c4"
              />
            ),
            name: d.full_name || "",
            email: d.email || "",
            phone: d.phone || "",
            position: this.getPosition(d),
            category: this.getCategory(d),
            restaurants: this.getRestaurants(d),
          };
        })
        .sort((a, b) =>
          sort_type === "ASC"
            ? a?.sort_field?.localeCompare(b?.sort_field)
            : b?.sort_field?.localeCompare(a?.sort_field)
        );
    } else {
      return usersData?.results
        ?.map((d) => {
          return {
            id: d.id,
            avatar: d.avatar ? (
              <img src={d.avatar} className="profile_image" />
            ) : (
              <Avatar
                name={d.full_name.split(" (")[0] || ""}
                size="32"
                round="50px"
                color="#38425B"
                // color="#0079c4"
              />
            ),
            name: d.full_name || "",
            email: d.email || "",
            phone: d.phone || "",
            position: this.getPosition(d),
            category: this.getCategory(d),
            restaurants: this.getRestaurants(d),
          };
        })
        .sort((a, b) =>
          sort_type === "ASC"
            ? a[sort_field].localeCompare(b[sort_field])
            : b[sort_field].localeCompare(a[sort_field])
        );
    }
  };

  handleSort = (e) => {
    this.setState({
      sort_field: e.name,
      // sort_field: e.selector,
      sort_type: this.state.sort_type === "ASC" ? "DESC" : "ASC",
    });
  };

  handleEdit = (id) => {
    const { fetchSingleUser } = this.props;

    fetchSingleUser(id).then((data) => {
      const { history, singleUserData } = this.props;
      history.push({
        pathname: `add-employee/${id}`,
        state: {
          id: id,
          singleUserData: singleUserData,
        },
      });
    });
  };
  filterRestaurant = (e) => {
    const restaurant = e.target.value;
    this.setState({ restaurant: restaurant });
    this.getInitialData();
  };

  deleteModalToggle = (id = "") => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
      deleteId: id,
    });
  };

  deleteLog = () => {
    this.props.fetchDeleteUser(this.state.deleteId).then(() => {
      this.deleteModalToggle();
      this.setState({
        deleteId: 0,
      });
      this.fetchUserData();
    });
  };

  getTableColumns = () => {
    const { profileData } = this.props;
    return [
      {
        name: "",
        // selector: 'avatar',
        selector: (row) => row.avatar,
        sortable: false,
      },
      {
        name: "name",
        // name: "Name",
        // selector: "name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "email",
        // name: "Email",
        // selector: "email",
        selector: (row) => row.email,
        sortable: true,
        cell: (row) =>
          getPermission("users.bs_can_delete_employees", profileData) ? (
            <div
              onClick={() => this.handleEdit(row.id)}
              style={{
                cursor: "pointer",
                color: "#38425B",
                // color: "#0079c4"
              }}
            >
              {row.email}
            </div>
          ) : (
            row.email
          ),
      },
      {
        name: "phone",
        // name: "Phone",
        // selector: "phone",
        selector: (row) => row.phone,
        sortable: true,
      },
      {
        name: "position",
        // name: "Position",
        // selector: "position",
        selector: (row) => row.position,
        sortable: true,
        maxWidth: "210px",
      },
      {
        name: "category",
        // name: "Category",
        // selector: "category",
        selector: (row) => row.category,
        sortable: true,
        maxWidth: "210px",
      },
      {
        name: "restaurants",
        // name: "Restaurants",
        // selector: "restaurants",
        selector: (row) => row.restaurants,
        sortable: true,
        maxWidth: "210px",
      },
      {
        name: "ACTIONS",
        selector: (row) => row.actions,
        sortable: false,
        center: true,
        cell: (row) => (
          <React.Fragment>
            <span className="d-flex">
              {getPermission("users.bs_can_edit_employees", profileData) && (
                <button
                  className="btn sm btn-outline-primary btn-sm"
                  onClick={() => this.handleEdit(row.id)}
                >
                  EDIT
                </button>
              )}
              {getPermission("users.bs_can_delete_employees", profileData) && (
                <button
                  value={row.id}
                  className="btn btn-outline-secondary btn-sm ml-1"
                  onClick={() => this.deleteModalToggle(row.id)}
                >
                  DELETE
                </button>
              )}
            </span>
          </React.Fragment>
        ),
      },
    ];
  };

  handleChangeRestaurant = (selectedRestaurant) => { 
    this.setState({ restaurantId: selectedRestaurant });
    this.props.fetchUsers({
      page: this.state.page,
      itemsPerPage: this.state.itemsPerPage,
      restaurants: selectedRestaurant?.value,
      groups: this.state.groupId?.value,
    });
  };

  hndleChangeGroup = (selectedGroup) => {
    this.setState({ groupId: selectedGroup });
    this.props.fetchUsers({
      page: this.state.page,
      itemsPerPage: this.state.itemsPerPage,
      restaurants: this.state.restaurantId?.value,
      groups: selectedGroup?.value,
    });
    // this.getInitialData();
  };

  uploadCsvData = (datacsv) => {
    try {
      const data = new FormData();
      data.append("employeefile", datacsv);
      this.props.employeeCsvEvent(data);
      this.importRef.reset();
    } catch (error) {
      this.importRef.current.value = null;
      // this.importRef.reset();
    }
  };

  render() {
    const {
      restaurantsData,
      usersDataLoading,
      restaurantsDataLoading,
      categoriesDataLoading,
      groupsDataLoading,
      usersData,
      profileData,
      groupsData,
    } = this.props;
    const { restaurantId, groupId } = this.state;
    const paginationTotalRows = usersData?.count;
    const optionRestaurant =
      restaurantsData &&
      restaurantsData?.results.map((d) => {
        return {
          value: d.id,
          label: d.title,
        };
      });
    const optionGroups =
      groupsData &&
      groupsData?.results.map((d) => {
        return {
          value: d.id,
          label: d.name,
        };
      });

    const csvData = [
      [
        "first_name",
        "last_name",
        "email",
        "phone",
        "address",
        "city",
        "state",
        "zipcode",
        "salary",
        "password",
        "confirmPassword",
        "restaurants",
        "groups",
        "birthday",
        "hire_date",
        "is_active(true/false)",
        "is_superuser(true/false)",
      ],
    ];
    return (
      <div className="main_box">
        {/* <Navbar /> 
				<div className="middle_contain ">
					<Sidebar /> */}
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div className="container-fluid">
                <div className="row mt-5 justify-content-between page_title main_align">
                  <div className="col-md-6">
                    <h2>
                      <i className="zmdi zmdi-long-arrow-left"></i>Main
                      Information
                    </h2>
                  </div>
                  <div className="col-md-6">
                    <div className="main_info_select">
                      {/* <FormGroup>
                        <Select
                          options={optionRestaurant}
                          value={selectedRestaurant}
                          onChange={this.handleChangeRestaurant}
                          placeholder="Select Restaurant"
                          isClearable
                          className="form_select "
                          menuShouldBlockScroll={true}
                        />
                      </FormGroup> */}

                      {getPermission(
                        "users.bs_can_add_employees",
                        profileData
                      ) && (
                        <Link
                          className="btn  ml-2 emp_add_btn"
                          to="/add-employee"
                        >
                          <span className="close_icon_report">
                            <FaPlus />
                          </span>
                          New Employee
                        </Link>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row px-5">
                  <div className="col-lg-2 col-md-12 d-flex flex-column justify-content-center align-items-center">
                    <input
                      type="file"
                      accept=".csv"
                      title="Upload csv"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        this.uploadCsvData(file);
                      }}
                      ref={this.importRef}
                      hidden
                    />
                    <Button
                      className="btn btn-primary ml-2 emp_add_btn mr2 mb-2"
                      style={{
                        backgroundColor: "#38425B",
                        // backgroundColor: "#0079c4"
                      }}
                      type="primary"
                      onClick={() => this.importRef.current.click()}
                      // className="mr2"
                    >
                      <span className="close_icon_report">CSV Upload</span>
                    </Button>
                    <CSVLink
                      data={csvData}
                      filename={"SampleFormat.csv"}
                      className="mb-2"
                    >
                      Download Sample CSV
                    </CSVLink>
                  </div>
                  <div className="col-lg-3 cold-md-12">
                    <FormGroup>
                      <Select
                        options={optionRestaurant}
                        value={restaurantId}
                        onChange={this.handleChangeRestaurant}
                        placeholder="Select Restaurant"
                        isClearable
                        className="form_select "
                        menuShouldBlockScroll={true}
                      />
                    </FormGroup>
                  </div>
                  <div className="col-lg-3 cold-md-12">
                    <FormGroup>
                      <Select
                        options={optionGroups}
                        value={groupId}
                        onChange={this.hndleChangeGroup}
                        placeholder="Select Group"
                        isClearable
                        className="form_select "
                        menuShouldBlockScroll={true}
                      />
                    </FormGroup>
                  </div>
                </div>

                <div className="main_info_header avt_icon data-table mt-5">
                  <DataTable
                    // className="table"
                    responsive={true}
                    striped={true}
                    progressPending={
                      usersDataLoading
                      //restaurantsDataLoading &&
                      //categoriesDataLoading &&
                      //groupsDataLoading
                    }
                    columns={this.getTableColumns()}
                    data={this.getUserListing()}
                    pagination={true}
                    paginationServer={true}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    paginationTotalRows={paginationTotalRows}
                    expandOnRowClicked={true}
                    expandableRows={true}
                    expandableRowsComponent={RowComponent}
                    onSort={this.handleSort}
                    customStyles={customStyles}
                    onChangePage={this.handlePageChange}
                    onChangeRowsPerPage={this.handleRowPerPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteModal
          isOpen={this.state.isOpenDeleteModal}
          toggle={this.deleteModalToggle}
          deleteLog={this.deleteLog}
          employeeDelete={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoriesDataLoading: state.categoriesData.isLoading,
    categoriesData: state.categoriesData.data,

    usersDataLoading: state.usersData.isLoading,
    usersData: state.usersData.data,

    singleUserDataLoading: state.singleUserData.isLoading,
    singleUserData: state.singleUserData.data,

    deleteUserDataLoading: state.deleteUserData.isLoading,
    deleteUserData: state.deleteUserData.data,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    groupsDataLoading: state.groupsData.isLoading,
    groupsData: state.groupsData.data,

    globalSearch: state.globalSearch.searchQuery,

    profileData: state.profileData.data,
  };
};
const mapDispatchToProps = {
  fetchUsers,
  fetchSingleUser,
  fetchDeleteUser,
  fetchRestaurants,
  fetchGroups,
  fetchCategories,
  fetchProfile,
  employeeCsvEvent,
};
export default connect(mapStateToProps, mapDispatchToProps)(MainInfo);

// export default Categories;
