import {
  FETCH_CHAT_LIST_REQUEST,
  FETCH_CHAT_LIST_SUCCESS,
  FETCH_CHAT_LIST_FAILURE,
} from "../../types/chat";

const chatListReducer = (
  state = {
    data: null,
    error: null,
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_CHAT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CHAT_LIST_SUCCESS:
      const newArr = action.data.data.data;

      newArr.sort(function (a, b) {
        return new Date(b?.created_date) - new Date(a?.created_date);
      });

      return {
        ...state,
        isLoading: false,
        data: { data: newArr },
        // data: action.data.data,
        error: null,
      };
    case FETCH_CHAT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default chatListReducer;
