import { toast } from "react-toastify";
import * as types from "../../types/restaurantGroup";
import { deleteApi } from "../api";

export const deleteRestaurantGroupRequest = () => ({
  type: types.FETCH_RESTAURANT_GROUP_DELETE_REQUEST,
});

export const deleteRestaurantGroupSuccess = (data) => ({
  type: types.FETCH_RESTAURANT_GROUP_DELETE_SUCCESS,
  data: data,
});

export const deleteRestaurantGroupFailure = (err) => ({
  type: types.FETCH_RESTAURANT_GROUP_DELETE_FAILURE,
  data: err,
});

export const deleteRestaurantGroup = (id, apiData) => async (
  dispatch,
  getState
) => {
  dispatch(deleteRestaurantGroupRequest());
  try {
    const data = await deleteApi(`bshift/api/restaurantbrand/${id}`);
    dispatch(deleteRestaurantGroupSuccess(data.data));
    toast.success("Restaurant Group deleted successfully!!!");
  } catch (err) {
    dispatch(deleteRestaurantGroupFailure(err));
    toast.error("Remove all restaurant from this group before deleting!!!");
  }
};
