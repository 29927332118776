import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'ladda/dist/ladda-themeless.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.css';
import './assets/css/custom.css';
import './assets/css/custom_one.css';

import reportWebVitals from './reportWebVitals';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
