import React, { useState } from "react";
import DataTable from "react-data-table-component";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Select from "react-select";
import { fetchSalesList } from "../../actions/sales/list";
import { customStyles, isValidArray } from "../../modules/utils";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { Calendar } from "react-multi-date-picker";

export default function SalesData() {
  const dispatch = useDispatch();

  const salesList = useSelector((state) => state.salesList.data);
  const salesDataLoading = useSelector((state) => state.salesList.isLoading);
  const globalSearch = useSelector((state) => state.globalSearch.searchQuery);
  const restaurantData = useSelector((state) => state.restaurantsData.data);

  const [graphData, setGraphData] = useState([]);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState({});

  const [newReportView, setNewReportView] = useState(true);

  useEffect(() => {
    dispatch(fetchRestaurants());
  }, []);

  useEffect(() => {
    if (selectedRestaurant?.value) {
      dispatch(fetchSalesList({ restaurant: selectedRestaurant?.value }));
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    getGraphData();
  }, [salesList?.results]);

  useEffect(() => {
    if (isValidArray(restaurantData?.results)) {
      const resOptions = restaurantData?.results?.map((item) => {
        return {
          value: item.id,
          label: item.title,
        };
      });
      setRestaurantOptions(resOptions);
      setSelectedRestaurant(resOptions[0]);
    }
  }, [restaurantData]);

  const getSalesData = () => {
    return salesList?.results
      ?.map((data) => {
        return {
          location: data?.restaurant__title,
          date: data?.closed_at_date,
          gross: data?.gross,
          discount: data?.discount,
          net: data?.net,
          tax: data?.tax,
          tip: data?.tip,
          total: data?.total,
        };
      })
      .filter((d) =>
        globalSearch
          ? d.location?.toLowerCase().includes(globalSearch) ||
            d.gross?.toLowerCase().includes(globalSearch) ||
            d.discount?.toLowerCase().includes(globalSearch) ||
            d.net?.toLowerCase().includes(globalSearch) ||
            d.tax?.toLowerCase().includes(globalSearch) ||
            d.tip?.toLowerCase().includes(globalSearch) ||
            d.total?.toLowerCase().includes(globalSearch)
          : d
      );
  };

  const getTableColumns = () => {
    return [
      {
        name: "Restaurant",
        selector: (row) => row.location,
        sortable: true,
      },
      {
        name: "Date",
        selector: (row) => row.date,
        sortable: true,
      },
      {
        name: "Gross",
        selector: (row) => row.gross,
        sortable: true,
      },
      {
        name: "Discount",
        selector: (row) => row.discount,
        sortable: true,
      },
      {
        name: "Net",
        selector: (row) => row.net,
        sortable: true,
      },
      {
        name: "Tax",
        selector: (row) => row.tax,
        sortable: true,
      },
      {
        name: "Tip",
        selector: (row) => row.tip,
        sortable: true,
      },
      {
        name: "Total",
        selector: (row) => row.total,
        sortable: true,
      },
    ];
  };

  const columnData = [
    {
      name: "Menu Item",
      selector: (row) => row.item,
      sortable: true,
    },
    {
      name: "ITEMS SOLD Qty",
      selector: (row) => row.itemSoldQty,
      sortable: true,
    },
    {
      name: "GROSS SALES Total$",
      selector: (row) => (
        <>
          {row?.grossSalesTotal && "$"} {row.grossSalesTotal}
        </>
      ),
      sortable: true,
    },
    {
      name: "DISCOUNT AMOUNT Total$",
      selector: (row) => (
        <>
          {row?.discountTotal && "$"} {row.discountTotal}
        </>
      ),
      sortable: true,
    },
    {
      name: "Total$",
      selector: (row) => (
        <>
          {row?.total && "$"} {row.total}
        </>
      ),
      sortable: true,
    },
    {
      name: "Avg / Item Sold",
      selector: (row) => (
        <>
          {row?.avgItemSold && "$"} {row.avgItemSold}
        </>
      ),
      sortable: true,
    },
    {
      name: "NET SALES %",
      selector: (row) => (
        <>
          {row.netSales} {row?.netSales && "%"}
        </>
      ),
      sortable: true,
    },
  ];

  const data = [
    { item: "APPETIZER" },
    {
      item: "Tuna Tartare",
      itemSoldQty: "15",
      grossSalesTotal: "254.25",
      discountTotal: "10.18",
      total: "244.07",
      avgItemSold: "16.27",
      netSales: "5.41",
    },
    {
      item: "Shrimp Spring Roll",
      itemSoldQty: "8",
      grossSalesTotal: "127.6",
      discountTotal: "",
      total: "127.6",
      avgItemSold: "15.95",
      netSales: "2.83",
    },
    {
      item: "Chicken Meatballs",
      itemSoldQty: "7",
      grossSalesTotal: "104.65",
      discountTotal: "",
      total: "104.65",
      avgItemSold: "14.95",
      netSales: "2.12",
    },
    {
      item: "Crab Cakes",
      itemSoldQty: "4",
      grossSalesTotal: "95.80",
      discountTotal: "",
      total: "95.80",
      avgItemSold: "23.95",
      netSales: "2.12",
    },
  ];

  const getGraphData = () => {
    if (isValidArray(salesList?.results)) {
      const finalData = salesList?.results.map((item) => {
        return {
          value: Number(item?.total),
          date: item?.closed_at_date,
        };
      });

      const graph = {
        label: selectedRestaurant?.label,
        data: finalData,
      };

      setGraphData(graph);
    }
  };

  const handleChangeRestaurant = (restaurant) => {
    setSelectedRestaurant(restaurant);
  };

  const newReportHandler = () => {
    setNewReportView(true);
  };

  const savedReportHandler = () => {
    setNewReportView(false);
  };

  return (
    <div className="main_box">
      <div className="pull_left full_section_details">
        <div className="main_container">
          <div className="wrapper">
            <div className="container-fluid">
              {/* <div className="row mt-5  justify-content-between page_title">
                <div className="col-md-6">
                  <h2>Sales Data</h2>
                </div>
                <div className="col-md-6">
                  <div className="main_info_select">
                    <Select
                      options={restaurantOptions}
                      value={selectedRestaurant}
                      onChange={handleChangeRestaurant}
                      placeholder="Select Restaurant"
                      className="form_select "
                      menuShouldBlockScroll={true}
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="data-table table_header_line mt-5">
                <DataTable
                  responsive={true}
                  striped={true}
                  columns={getTableColumns()}
                  data={getSalesData()}
                  progressPending={salesDataLoading}
                  pagination={true}
                  paginationServer={false}
                  paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                  customStyles={customStyles}
                />
              </div> */}

              <div className="data-table mt-5">
                <div className="row">
                  <div className="col-md-3 sales-data-border p-0">
                    <h3 className="mb-3 p-3 sales-data-header">REPORT : </h3>
                    <div className="px-3">
                      <div className="pb-3 d-flex">
                        <div
                          className={`w-50 sales-data-calendar-buttons py-2 ${
                            newReportView && "text-underline 2px rounded-0"
                          }`}
                          onClick={newReportHandler}
                        >
                          NEW
                        </div>
                        <div
                          className={`w-50 sales-data-calendar-buttons py-2 ${
                            !newReportView && "text-underline 2px rounded-0"
                          }`}
                          onClick={savedReportHandler}
                        >
                          SAVED
                        </div>
                      </div>
                      <div>
                        <p>Report Type</p>
                        <div className="sales-data-select-input">
                          <Select
                            options={restaurantOptions}
                            value={selectedRestaurant}
                            // onChange={handleChangeRestaurant}
                            placeholder="Select Restaurant"
                            className="form_select "
                            menuShouldBlockScroll={true}
                          />
                        </div>
                        <p className="my-3">
                          Choose a report type from the field above or use the
                          button below.
                        </p>
                        <button className="btn btn-outline-primary w-100">
                          REPORT DETAILS
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 sales-data-border p-0">
                    <div className="mb-3 p-3 sales-data-header d-flex justify-content-between">
                      <h3 className="mb-0">TIME: Yesterday</h3>
                      <h3 className="mb-0">Reset</h3>
                    </div>
                    <div className="row px-3">
                      <div className="col-md-3">
                        <button className="btn btn-outline-secondary w-100 rounded-0 mb-1">
                          TODAY
                        </button>
                        <button className="btn btn-outline-secondary w-100 rounded-0 mb-1">
                          YESTERDAY
                        </button>
                        <button className="btn btn-outline-secondary w-100 rounded-0 mb-1">
                          CURRENT
                        </button>
                        <button className="btn btn-outline-secondary w-100 rounded-0 mb-1">
                          PREVIOUS
                        </button>
                        <button className="btn btn-outline-secondary w-100 rounded-0 mb-1">
                          ALL TIME
                        </button>
                      </div>
                      <div className="col-md-9">
                        <Calendar
                          numberOfMonths={2}
                          disableMonthPicker
                          disableYearPicker
                          style={{
                            width: "100%",
                            boxSizing: "border-box",
                          }}
                          containerStyle={{
                            width: "100%",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 p-0">
                    <h3 className="mb-3 p-3 sales-data-header">
                      LOCATION: 3 West
                    </h3>
                    <div className="px-3">
                      <h3>Hrvest Restaurants</h3>
                      <h3>3 west</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="data-table table_header_line my-5">
                <DataTable
                  responsive={true}
                  striped={true}
                  columns={columnData}
                  data={data}
                  progressPending={salesDataLoading}
                  pagination={true}
                  paginationServer={false}
                  paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                  customStyles={customStyles}
                />
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div className="sales-data-select-input">
                    <p className="mb-2">Business Date(s)</p>
                    <Select
                      options={restaurantOptions}
                      value={selectedRestaurant}
                      placeholder="Select Restaurant"
                      className="form_select "
                      menuShouldBlockScroll={true}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="sales-data-select-input">
                    <p className="mb-2"> Day Parts</p>
                    <Select
                      options={restaurantOptions}
                      value={selectedRestaurant}
                      placeholder="Select Restaurant"
                      className="form_select "
                      menuShouldBlockScroll={true}
                    />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="sales-data-select-input">
                    <p className="mb-2"> Order Types</p>
                    <Select
                      options={restaurantOptions}
                      value={selectedRestaurant}
                      placeholder="Select Restaurant"
                      className="form_select "
                      menuShouldBlockScroll={true}
                    />
                  </div>
                </div>
                <div className="col-md-3 mb-3 d-flex flex-column justify-content-center align-items-start">
                  <p className="mb-2">Check Timestamps</p>
                  <div className="d-flex mb-2">
                    <input
                      type="radio"
                      id="opened"
                      name="report"
                      className="mr-2"
                    />
                    <label htmlFor="opened" className="m-0">
                      Opened
                    </label>
                  </div>
                  <div className="d-flex">
                    <input
                      type="radio"
                      id="closed"
                      name="report"
                      className="mr-2"
                    />
                    <label htmlFor="closed" className="m-0">
                      Closed
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 px-5">
                  <div className="card">
                    <div className="card-body p-0">
                      <h5
                        className="card-title text-center pt-4 pb-5 text-white"
                        style={{
                          backgroundColor: "#38425b",
                        }}
                      >
                        $16,204.35
                      </h5>
                      <h2
                        className="text-center shadow-sm py-2"
                        style={{
                          width: "80%",
                          position: "relative",
                          top: "-35px",
                          margin: "auto",
                          backgroundColor: "#fff",
                        }}
                      >
                        NET SALES
                      </h2>
                      <div className="px-4 pb-5">
                        <div className="text-right mb-5">
                          <p className="mb-0">Gross Sales</p>
                          <h5 className="text-success">$16,552.30</h5>
                        </div>
                        <div className="text-right">
                          <p className="mb-0">Discounts</p>
                          <h5 className="text-danger">($347.95)</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 px-5 d-flex flex-column justify-content-between">
                  <div className="card">
                    <div className="card-body p-0">
                      <h5
                        className="card-title text-center py-4 text-white"
                        style={{
                          backgroundColor: "#38425b",
                        }}
                      >
                        CHECKS
                      </h5>
                      <div className="row px-4">
                        <div className="col-md-6 text-left">
                          <p>Total</p>
                          <h5>101</h5>
                        </div>
                        <div className="col-md-6 text-right">
                          <p>Average</p>
                          <h5>$160.44</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body p-0">
                      <h5
                        className="card-title text-center py-4 text-white"
                        style={{
                          backgroundColor: "#38425b",
                        }}
                      >
                        COVERS
                      </h5>
                      <div className="row px-4">
                        <div className="col-md-6 text-left">
                          <p>Total</p>
                          <h5>290</h5>
                        </div>
                        <div className="col-md-6 text-right">
                          <p>Average</p>
                          <h5>2.87</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 px-5">
                  <div className="card">
                    <div className="card-body p-0">
                      <h5
                        className="card-title text-center pt-4 pb-5 text-white"
                        style={{
                          backgroundColor: "#38425b",
                        }}
                      >
                        $19,318.64
                      </h5>
                      <h2
                        className="text-center shadow-sm py-2"
                        style={{
                          width: "80%",
                          position: "relative",
                          top: "-35px",
                          margin: "auto",
                          backgroundColor: "#fff",
                        }}
                      >
                        GROSS RECEIPTS
                      </h2>
                      <div className="px-4 pb-3">
                        <div className="d-flex justify-content-between">
                          <p>Net Sales</p> <p>$16,204.35</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p>Taxes</p> <p>$865.12</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p>Gratuity</p> <p>$0.00</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p>Tips</p> <p>$2,049.17</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-1">Prepayments</p>{" "}
                          <p className="mb-1">$200.00</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {graphData?.data && (
                <div className="sales_card">
                  <div className="sales_card_title pt-3 pl-3">
                    <h5>
                      {graphData?.label
                        ? graphData?.label
                        : selectedRestaurant?.label}
                    </h5>

                    <ResponsiveContainer width="100%" height={270}>
                      <LineChart
                        data={graphData?.data}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" />
                        {/* <Line yAxisId="left" type="monotone" dataKey="value" stroke="green" /> */}
                        <Line
                          yAxisId="right"
                          type="monotone"
                          dataKey="value"
                          stroke="#1976d2"
                        />
                        <Tooltip />
                        <Legend />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
