import {
	FETCH_EDIT_SCHEDULE_SHIFT_REQUEST,
	FETCH_EDIT_SCHEDULE_SHIFT_SUCCESS,
	FETCH_EDIT_SCHEDULE_SHIFT_FAILURE,
} from '../../types/schedule';
import { postApi } from '../api';

export const fetchEditScheduleShiftRequest = () => ({
	type: FETCH_EDIT_SCHEDULE_SHIFT_REQUEST,
});

export const fetchEditScheduleShiftSuccess = (data) => ({
	type: FETCH_EDIT_SCHEDULE_SHIFT_SUCCESS,
	data: data,
});

export const fetchEditScheduleShiftFailure = (error) => ({
	type: FETCH_EDIT_SCHEDULE_SHIFT_FAILURE,
	error,
});

export const fetchEditScheduleShift = (data) => (dispatch, getState) => {
	dispatch(fetchEditScheduleShiftRequest());

	return postApi(`bshift/api/schedule/${data?.date}`, data)
		.then((data) => {
			dispatch(fetchEditScheduleShiftSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchEditScheduleShiftFailure(error));
		});
};
