import {
	CREATE_SHIFT_TIME_REQUEST,
	CREATE_SHIFT_TIME_FAILURE,
	CREATE_SHIFT_TIME_SUCCESS,
} from '../../types/schedule';
import { postApi } from '../api';

export const createShiftTimeRequest = () => ({
	type: CREATE_SHIFT_TIME_REQUEST,
});

export const createShiftTimeSuccess = (data) => ({
	type: CREATE_SHIFT_TIME_SUCCESS,
	data: data,
});

export const createShiftTimeFailure = (error) => ({
	type: CREATE_SHIFT_TIME_FAILURE,
	error,
});

export const createShiftTime = (data) => (dispatch, getState) => {
	dispatch(createShiftTimeRequest());

	return postApi(`bshift/api/shifttime`, data)
		.then((data) => {
			dispatch(createShiftTimeSuccess(data));
		})
		.catch((error) => {
			dispatch(createShiftTimeFailure(error));
		});
};
