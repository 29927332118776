import {
	FETCH_PERMISSION_REQUEST,
	FETCH_PERMISSION_FAILURE,
	FETCH_PERMISSION_SUCCESS,
} from '../../types/permission';
import { getApi } from '../api';

export const fetchPermissionRequest = () => ({
	type: FETCH_PERMISSION_REQUEST,
});

export const fetchPermissionSuccess = (data) => ({
	type: FETCH_PERMISSION_SUCCESS,
	data: data,
});

export const fetchPermissionFailure = (error) => ({
	type: FETCH_PERMISSION_FAILURE,
	error,
});

export const fetchPermission = (formData) => (dispatch, getState) => {
	dispatch(fetchPermissionRequest());

	return getApi(`users/api/permissions`)
		.then((data) => {
			dispatch(fetchPermissionSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchPermissionFailure(error));
		});
};
