// http://thebusinessshifts.com/bshift/api/categories/8
import { toast } from 'react-toastify';
import {
	FETCH_CATEGORY_UPDATE_REQUEST,
	FETCH_CATEGORY_UPDATE_FAILURE,
	FETCH_CATEGORY_UPDATE_SUCCESS,
} from '../../types/categories';
import { putApi } from '../api';

export const fetchUpdateCategoryRequest = () => ({
	type: FETCH_CATEGORY_UPDATE_REQUEST,
});

export const fetchUpdateCategorySuccess = (data) => ({
	type: FETCH_CATEGORY_UPDATE_FAILURE,
	data: data,
});

export const fetchUpdateCategoryFailure = (error) => ({
	type: FETCH_CATEGORY_UPDATE_SUCCESS,
	error,
});

export const fetchUpdateCategory = (id, apiData) => (dispatch, getState) => {
	dispatch(fetchUpdateCategoryRequest());
	return putApi(`bshift/api/categories/${id}`, apiData)
		.then((data) => {
			toast.success('Record updated successfully!');
			dispatch(fetchUpdateCategorySuccess(data));
		})
		.catch((error) => {
			dispatch(fetchUpdateCategoryFailure(error));
		});
};
