export const FETCH_MANAGER_LOGS_REQUEST = "FETCH_MANAGER_LOGS_REQUEST";
export const FETCH_MANAGER_LOGS_FAILURE = "FETCH_MANAGER_LOGS_FAILURE";
export const FETCH_MANAGER_LOGS_SUCCESS = "FETCH_MANAGER_LOGS_SUCCESS";

export const CREATE_MANAGER_LOGS_REQUEST = "CREATE_MANAGER_LOGS_REQUEST";
export const CREATE_MANAGER_LOGS_FAILURE = "CREATE_MANAGER_LOGS_FAILURE";
export const CREATE_MANAGER_LOGS_SUCCESS = "CREATE_MANAGER_LOGS_SUCCESS";

export const FETCH_LOG_RECORDS_FIELD_REQUEST =
  "FETCH_LOG_RECORDS_FIELD_REQUEST";
export const FETCH_LOG_RECORDS_FIELD_FAILURE =
  "FETCH_LOG_RECORDS_FIELD_FAILURE";
export const FETCH_LOG_RECORDS_FIELD_SUCCESS =
  "FETCH_LOG_RECORDS_FIELD_SUCCESS";

export const CREATE_LOG_RECORD_VALUE_REQUEST =
  "CREATE_LOG_RECORD_VALUE_REQUEST";
export const CREATE_LOG_RECORD_VALUE_FAILURE =
  "CREATE_LOG_RECORD_VALUE_FAILURE";
export const CREATE_LOG_RECORD_VALUE_SUCCESS =
  "CREATE_LOG_RECORD_VALUE_SUCCESS";

export const FETCH_LOG_RECORD_VALUE_REQUEST = "FETCH_LOG_RECORD_VALUE_REQUEST";
export const FETCH_LOG_RECORD_VALUE_FAILURE = "FETCH_LOG_RECORD_VALUE_FAILURE";
export const FETCH_LOG_RECORD_VALUE_SUCCESS = "FETCH_LOG_RECORD_VALUE_SUCCESS";

export const FETCH_LOG_RECORD_REQUEST = "FETCH_LOG_RECORD_REQUEST";
export const FETCH_LOG_RECORD_FAILURE = "FETCH_LOG_RECORD_FAILURE";
export const FETCH_LOG_RECORD_SUCCESS = "FETCH_LOG_RECORD_SUCCESS";

export const FETCH_LOG_RECORD_VALUE_DETAIL_REQUEST =
  "FETCH_LOG_RECORD_VALUE_DETAIL_REQUEST";
export const FETCH_LOG_RECORD_VALUE_DETAIL_FAILURE =
  "FETCH_LOG_RECORD_VALUE_DETAIL_FAILURE";
export const FETCH_LOG_RECORD_VALUE_DETAIL_SUCCESS =
  "FETCH_LOG_RECORD_VALUE_DETAIL_SUCCESS";

export const DELETE_LOG_RECORD_REQUEST = "DELETE_LOG_RECORD_REQUEST";
export const DELETE_LOG_RECORD_FAILURE = "DELETE_LOG_RECORD_FAILURE";
export const DELETE_LOG_RECORD_SUCCESS = "DELETE_LOG_RECORD_SUCCESS";

export const DELETE_LOG_RECORD_VALUE_REQUEST =
  "DELETE_LOG_RECORD_VALUE_REQUEST";
export const DELETE_LOG_RECORD_VALUE_FAILURE =
  "DELETE_LOG_RECORD_VALUE_FAILURE";
export const DELETE_LOG_RECORD_VALUE_SUCCESS =
  "DELETE_LOG_RECORD_VALUE_SUCCESS";

export const UPDATE_LOG_RECORD_VALUE_REQUEST =
  "UPDATE_LOG_RECORD_VALUE_REQUEST";
export const UPDATE_LOG_RECORD_VALUE_FAILURE =
  "UPDATE_LOG_RECORD_VALUE_FAILURE";
export const UPDATE_LOG_RECORD_VALUE_SUCCESS =
  "UPDATE_LOG_RECORD_VALUE_SUCCESS";

export const UPDATE_LOG_RECORD_REQUEST = "UPDATE_LOG_RECORD_REQUEST";
export const UPDATE_LOG_RECORD_FAILURE = "UPDATE_LOG_RECORD_FAILURE";
export const UPDATE_LOG_RECORD_SUCCESS = "UPDATE_LOG_RECORD_SUCCESS";

export const FETCH_LOG_REPORT_REQUEST = "FETCH_LOG_REPORT_REQUEST";
export const FETCH_LOG_REPORT_FAILURE = "FETCH_LOG_REPORT_FAILURE";
export const FETCH_LOG_REPORT_SUCCESS = "FETCH_LOG_REPORT_SUCCESS";

export const SET_MANAGER_LOG_FILTER = "SET_FILTER";
