import React, { Component } from 'react';
import { connect } from 'react-redux';

import TaskDetailPopup from './TaskDetailPopup';

import { fetchTodoTaskDelete } from '../../actions/todoTask/delete';
import { fetchUpdateTodoTask } from '../../actions/todoTask/update';

export class TaskDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDetailOpen: false,
		};
	}

	toggleDetail = () => {
		const { isDetailOpen } = this.state;
		this.setState({
			isDetailOpen: !isDetailOpen,
		});
	};

	deleteTask = () => {
		const { data, getTodoList } = this.props;

		this.props.fetchTodoTaskDelete(data?.id).then(() => {
			getTodoList();
		});
	};

	handleChange = (e) => {
		const { data, getTodoList } = this.props;

		if (e.target.checked) {
			const apiData = { ...data, completed: true };

			this.props.fetchUpdateTodoTask(data?.id, apiData).then(() => {
				getTodoList();
			});
		}

		return null;
	};

	render() {
		const { isDetailOpen } = this.state;
		const { data } = this.props;

		return (
			<React.Fragment>
				<div className="author_bio_toggle_wrapper d-flex justify-content-between">
					<div className="d-flex align-items-center">
						{data?.completed ? (
							<input
								className="align-items-center"
								type="checkbox"
								style={{ minWidth: '30px' }}
								checked
								disabled
							/>
						) : (
							<input
								className="align-items-center"
								type="checkbox"
								style={{ minWidth: '30px' }}
								onChange={this.handleChange}
							/>
						)}
						<span
							className={`text-nowrap cursor-pointer ${
								data?.completed ? 'text-line-through' : ''
							}`}
							onClick={this.toggleDetail}
						>
							{data?.title}
						</span>
					</div>
					<div className="author_time cursor-pointer">
						<span className="text-danger" onClick={this.deleteTask}>
							Delete
						</span>
					</div>
				</div>
				{isDetailOpen && (
					<TaskDetailPopup toggle={this.toggleDetail} data={data} />
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		todoTaskDeleteIsLoading: state.todoTaskDelete.isLoading,
		todoTaskDelete: state.todoTaskDelete.data,
	};
};

const mapDispatchToProps = { fetchTodoTaskDelete, fetchUpdateTodoTask };

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetail);
