import {
  FETCH_DOCUMENT_REQUEST,
  FETCH_DOCUMENT_SUCCESS,
  FETCH_DOCUMENT_FAILURE,
} from "../../types/document";
import { toast } from "react-toastify";
import { getApi } from "../api";

export const fetchDocumentRequest = () => ({
  type: FETCH_DOCUMENT_REQUEST,
});

export const fetchDocumentSuccess = (data) => ({
  type: FETCH_DOCUMENT_SUCCESS,
  data: data,
});

export const fetchDocumentFailure = (error) => ({
  type: FETCH_DOCUMENT_FAILURE,
  error,
});

export const fetchDocument = (
  file_type,
  page,
  itemsPerPage,
  search,
  sort_field,
  sort_type,
  employee_flag
) => (dispatch, getState) => {
  dispatch(fetchDocumentRequest());

  let queryParams = [];
  if (file_type) {
    queryParams = [...queryParams, `file_type=${file_type}`];
  }
  if (page || 1) {
    queryParams = [...queryParams, `page=${page}`];
  }
  if (itemsPerPage !== "") {
    queryParams = [...queryParams, `itemsPerPage=${itemsPerPage}`];
  }
  if (search || "") {
    queryParams = [...queryParams, `search=${search}`];
  }
  if (sort_field) {
    queryParams = [...queryParams, `sortBy[]=${sort_field}`];
  }
  if (sort_field) {
    queryParams = [...queryParams, `sortDesc[]=${sort_type}`];
  }

  queryParams = [...queryParams, `employee_flag=${employee_flag}`];

  return getApi(
    `bshift/api/training_files?mustSort=${false}&mustSort=${false}&${queryParams.join(
      "&"
    )}`
  )
    .then((data) => {
      dispatch(fetchDocumentSuccess(data));
    })
    .catch((error) => {
      console.log(error);
      toast.error("Error");
      dispatch(fetchDocumentFailure(error));
    });
};
