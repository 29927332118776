import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { fetchSalesList } from "../actions/sales/list";
import { isValidArray } from "../modules/utils";
import { FormGroup } from "reactstrap";
import { fetchRestaurants } from "../actions/restaurants/restaurants";

const SalesData = () => {
  const dispatch = useDispatch();

  const restaurantData = useSelector((state) => state.restaurantsData.data);
  const salesList = useSelector((state) => state.salesList.data);
  const [graphData, setGraphData] = useState([]);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState({});

  useEffect(() => {
    dispatch(fetchRestaurants());
  }, []);

  useEffect(() => {
    if (selectedRestaurant?.value) {
      dispatch(fetchSalesList({ restaurant: selectedRestaurant?.value }));
    }
  }, [selectedRestaurant]);

  useEffect(() => {
    getGraphData();
  }, [salesList?.results]);

  useEffect(() => {
    if (isValidArray(restaurantData?.results)) {
      const resOptions = restaurantData?.results?.map((item) => {
        return {
          value: item.id,
          label: item.title,
        };
      });
      setRestaurantOptions(resOptions);
      setSelectedRestaurant(resOptions[0]);
    }
  }, [restaurantData]);

  const handleChangeRestaurant = (restaurant) => {
    setSelectedRestaurant(restaurant);
  };

  const getGraphData = () => {
    if (isValidArray(salesList?.results)) {
      const finalData = salesList?.results.map((item) => {
        return {
          value: Number(item?.total),
          date: item?.closed_at_date,
        };
      });

      const graph = {
        label: selectedRestaurant?.label,
        data: finalData,
      };

      setGraphData(graph);
    }
  };

  return (
    <div className="dashboard_image_carousel mt-5 main_info_header col mr-2">
      <div className="row mb-3">
        <div className="col-md-6">
          <h3>
            <i className="zmdi zmdi-long-arrow-left"></i>Sales Data
          </h3>
        </div>
      </div>
      <div className="dashboard_sales_select">
        <FormGroup>
          <Select
            options={restaurantOptions}
            value={selectedRestaurant}
            onChange={handleChangeRestaurant}
            placeholder="Select Restaurant"
            className="form_select "
            menuShouldBlockScroll={true}
          />
        </FormGroup>
      </div>
      {graphData && (
        <div className="sales_card dashboard_sales_card">
          <div className="sales_card_title pt-3 pl-3">
            <h5>
              {graphData?.label ? graphData?.label : selectedRestaurant?.label}
            </h5>
            <ResponsiveContainer width="100%" height={270}>
              <LineChart
                width={950}
                height={280}
                data={graphData?.data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 10,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="value" stroke="#1976d2" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );

  // const [graphData, setGraphData] = useState([]);
  // const [graphName, setGraphName] = useState({});
  // const [optionGraph, setOptionGraph] = useState({});

  // const { salesListLoading, salesList } = useSelector((state) => ({
  //   salesListLoading: state.salesList.isLoading,
  //   salesList: state.salesList.data || [],
  // }));

  // useEffect(() => {
  //   dispatch(fetchSalesList());
  // }, []);

  // useEffect(() => {
  //   const graphData = getGraphData();
  //   const optionGraph =
  //     isValidArray(graphData) &&
  //     graphData.map((d) => {
  //       return {
  //         value: d?.label,
  //         label: d?.label,
  //       };
  //     });
  //   setOptionGraph(optionGraph);
  // }, [salesList]);

  // useEffect(() => {
  //   if (isValidArray(optionGraph)) {
  //     setGraphName(optionGraph[0]);
  //   }
  // }, [optionGraph]);

  // useEffect(() => {
  //   const graphData =
  //     isValidArray(getGraphData()) &&
  //     getGraphData().filter((d) => d?.label === graphName?.value);

  //   setGraphData(graphData);
  // }, [graphName]);

  // const getGraphData = () => {
  //   if (isValidArray(salesList?.results)) {
  //     const groups = salesList?.results.reduce((groups, graph) => {
  //       const name = graph?.restaurant__title;
  //       if (!groups[name]) {
  //         groups[name] = [];
  //       }
  //       groups[name].push({
  //         value: parseInt(graph?.total),
  //         date: graph?.closed_at_date,
  //       });
  //       return groups;
  //     }, {});

  //     return Object.keys(groups).map((name) => {
  //       return {
  //         label: name,
  //         data: groups[name],
  //       };
  //     });
  //   }
  // };

  // return (
  //   <div className="dashboard_image_carousel mt-5 main_info_header col mr-2">
  //     <div className="row mb-3">
  //       <div className="col-md-6">
  //         <h3>
  //           <i className="zmdi zmdi-long-arrow-left"></i>Sales Data
  //         </h3>
  //       </div>
  //     </div>

  //     <div className="dashboard_sales_select">
  //       <FormGroup>
  //         <Select
  //           options={optionGraph}
  //           value={graphName}
  //           onChange={(val) => setGraphName(val)}
  //           placeholder="Select Restaurant"
  //           menuShouldBlockScroll={true}
  //         />
  //       </FormGroup>
  //     </div>

  //     {graphData &&
  //       graphData.map((data, index) => (
  //         <div key={index} className="sales_card dashboard_sales_card">
  //           <div className="sales_card_title pt-3 pl-3">
  //             <h5>{data?.label}</h5>
  //             <ResponsiveContainer width="100%" height={270}>
  //               <LineChart
  //                 // width={950}
  //                 // height={280}
  //                 data={data?.data}
  //                 margin={{
  //                   top: 5,
  //                   right: 30,
  //                   left: 10,
  //                   bottom: 5,
  //                 }}
  //               >
  //                 <CartesianGrid strokeDasharray="3 3" />
  //                 <XAxis dataKey="date" />
  //                 <YAxis />
  //                 <Tooltip />
  //                 <Legend />
  //                 <Line type="monotone" dataKey="value" stroke="#1976d2" />
  //               </LineChart>
  //             </ResponsiveContainer>
  //           </div>
  //         </div>
  //       ))}
  //   </div>
  // );
};

export default SalesData;
