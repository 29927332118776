import { toast } from 'react-toastify';
import {
	FETCH_SAVE_DRAFT_REQUEST,
	FETCH_SAVE_DRAFT_SUCCESS,
	FETCH_SAVE_DRAFT_FAILURE,
} from '../../types/training';
import { putApi } from '../api';

export const fetchSaveDraftRequest = () => ({
	type: FETCH_SAVE_DRAFT_REQUEST,
});

export const fetchSaveDraftSuccess = (data) => ({
	type: FETCH_SAVE_DRAFT_SUCCESS,
	data: data,
});

export const fetchSaveDraftFailure = (error) => ({
	type: FETCH_SAVE_DRAFT_FAILURE,
	error,
});

export const fetchSaveDraft = (id, formData) => (dispatch, getState) => {
	dispatch(fetchSaveDraftRequest());

	return putApi(`bshift/api/training_assigned_quizzes/${id}`, formData)
		.then((data) => {
			toast.success('Draft saved successfully!');
			dispatch(fetchSaveDraftSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchSaveDraftFailure(error));
		});
};
