import React from "react";

export default function TermsAndConditions() {
  return (
    <div className="container">
      <div className="main-cms-container text-center my-3 mx-5">
        <p style={{ fontSize: "32px" }}>Terms And Conditions</p>
        <div className="cms-content" style={{ fontSize: "20px" }}>
          <div className="text-left">
            <h3>Terms of Use Agreement</h3>
            <br />
            <h3> Updated March 29, 2023 </h3>
            <br />
            <p className="text-justify">
              thebusinessshifts.com Terms and Conditions. These terms of use
              govern the use of the Website and apply to all internet users
              visiting or using the Website and/or mobile application (The App).
              By access or using the Website or App in any way, including using
              the services and resources available or enabled via the Website
              and/or Application, by clicking on the "Next" button during
              registration, completing the registration process and/or browsing
              the Website or downloading the App, you represent that (1) you
              have read, understand, and agree to be bound by the Terms and
              Conditions, (2) you are of legal age to form a binding contract
              with Company and (3) you hve the authority to enter into the Terms
              and Conditions personally or on behalf of the entity you have
              named as the User, and to bind that entity to the Terms and
              Conditions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
