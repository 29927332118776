import React, { useEffect, useState } from "react";
import userImgPlaceHolder from "../../assets/css/images/user_placeholder.png";
import UserChat from "./UserChat";

export default function Chat() {
  const [currentUser, setCurrentUser] = useState();
  const [chatType, setChatType] = useState(0);
  //   const [isCurrentUser, setCurrentUser] = useState(false);

  return (
    <div className="main_box">
      <div className="middle_contain chat_height">
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div
                className="container-fluid box"
                style={{ background: "none" }}
              >
                <main className="content" style={{ border: "1px solid #ddd" }}>
                  <div className="p-0">
                    {/* <h1 className="h3 mb-3">Messages</h1> */}
                    <div className="card" style={{ border: "none" }}>
                      <div className="row g-0 chatbox chatboxheight">
                        <div
                          className="col-12 col-lg-5 col-xl-3 chat-sidebar-name"
                          style={{ height: "inherit" }}
                        >
                          <div className="chat-side">
                            <div className="px-4 d-none d-md-block">
                              <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                  <input
                                    type="text"
                                    className="form-control mt-3 mb-0"
                                    placeholder="Search..."
                                    // onChange={(e) =>
                                    //   setuserSearch(e.target.value)
                                    // }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="w-10/12 my-3 mx-auto d-flex justify-between justify-content-between w-75  custom_chat_buttons">
                              <button
                                className="btn mr-2 py-1 custom_background_color custom_chat_button"
                                onClick={() => setChatType(0)}
                              >
                                Direct
                              </button>
                              <button
                                className="btn mr-2 custom_background_color custom_chat_button"
                                onClick={() => setChatType(1)}
                              >
                                Group
                              </button>
                              {/* )} */}
                              <button
                                className="btn ml-2 custom_background_color custom_chat_button"
                                onClick={() => setChatType(2)}
                              >
                                New
                              </button>
                            </div>

                            {chatType === 0 && (
                              <div className="m-auto w-auto chat_user">
                                <div className="list-group-item list-group-item-action p-0 position-static chat-border chat_user_list_item">
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                    onClick={() => setCurrentUser(true)}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test User
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test User
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test User
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test User
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test User
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test User
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test User
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}

                            {chatType === 1 && (
                              <div className="m-auto w-auto chat_user">
                                <div className="list-group-item list-group-item-action p-0 position-static chat-border chat_user_list_item">
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                    onClick={() => setCurrentUser(true)}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test Restaurant
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        Restaurnat Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test Restaurant
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test Restaurant
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test Restaurant
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test Restaurant
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test Restaurant
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test Restaurant
                                      </span>
                                      <div className="small">
                                        <span className="fas fa-circle chat-online"></span>{" "}
                                        This is Message
                                      </div>
                                    </div>
                                    <span className="text-center  d-flex align-items-center ">
                                      <span className="badge badge-info">
                                        2
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                            {chatType === 2 && (
                              <div className="m-auto w-auto chat_user">
                                <div className="list-group-item list-group-item-action p-0 position-static chat-border chat_user_list_item">
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                    onClick={() => setCurrentUser(true)}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test Restaurant
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test Restaurant
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test Restaurant
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="flex-grow-1 d-flex px-4 py-2"
                                    style={{
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.125)",
                                    }}
                                  >
                                    <img
                                      src={userImgPlaceHolder}
                                      className="rounded-circle mr-1 w-14 h-14 mr-3"
                                      style={{
                                        height: "60px",
                                        width: "60px",
                                      }}
                                    />
                                    <div className="w-75 pt-1">
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                      >
                                        Test Restaurant
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {currentUser ? (
                          <UserChat />
                        ) : (
                          <div className="d-flex col-12 col-lg-7 col-xl-9 justify-content-center align-items-center items-center chat_home">
                            <h2>Select a conversation</h2>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
