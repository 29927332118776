import { toastError, toastSuccess } from '../../modules/utils';
import {
	FETCH_CREATE_TODO_REQUEST,
	FETCH_CREATE_TODO_FAILURE,
	FETCH_CREATE_TODO_SUCCESS,
} from '../../types/todo';
import { postApi } from '../api';

export const fetchCreateTodoRequest = () => ({
	type: FETCH_CREATE_TODO_REQUEST,
});

export const fetchCreateTodoSuccess = (data) => ({
	type: FETCH_CREATE_TODO_SUCCESS,
	data: data,
});

export const fetchCreateTodoFailure = (error) => ({
	type: FETCH_CREATE_TODO_FAILURE,
	error,
});

export const fetchCreateTodo = (apiData) => (dispatch) => {
	dispatch(fetchCreateTodoRequest());
	return postApi(`restaurant/api/todo_create`, apiData)
		.then((data) => {
			toastSuccess('Record created successfully.');
			dispatch(fetchCreateTodoSuccess(data));
		})
		.catch((error) => {
			toastError('Something went wrong!');
			dispatch(fetchCreateTodoFailure(error));
		});
};
