import React from "react";

export default function AboutUs() {
  return (
    <div className="container">
      <div className="main-cms-container text-center my-3 mx-5">
        <p style={{ fontSize: "32px" }}>About Us</p>
        <div className="cms-content text-justify" style={{ fontSize: "20px" }}>
          <p>
            At The Business Shift, we’re a group of hospitality veterans who
            started from the ground up to build a successful restaurant group.
            With that, we experienced the inefficiencies, the food wasted, the
            dollars overspent, the employee schedules misaligning and
            miscommunication between the front of the house (FOH) and back of
            the house (BOH). We took those pain points and created a central hub
            where all of our data could live in one place.
          </p>
          <p>
            Things like: employee scheduling, compensation, sales data, HR and
            training, task management, incident management, kitchen operations,
            calendar and events, communication, and overall budget tracking.
          </p>
          <p>
            We automated everything into one platform. A platform that could
            provide real-time, customizable reports, integrate our point-of-sale
            (POS) system and open APIs so we could connect as many other
            applications as we wanted. And, we put it in the cloud, meaning we
            could access it from our computers or mobile devices, wherever and
            whenever.
          </p>
          <p>
            We now had the data to easily track our current progress, as well as
            identify room for future improvements and productivity. We had a
            tool that helped us make efficient decisions, ultimately leading to
            an increase in performance in all areas of our business.
          </p>
          <p>Today, we call it The Business Shift.</p>
        </div>
      </div>
    </div>
  );
}
