import {
  FETCH_SINGLE_USER_REQUEST,
  FETCH_SINGLE_USER_SUCCESS,
  FETCH_SINGLE_USER_FAILURE,
} from "../../types/users";
import { getApi } from "../api";

export const fetchSingleUserRequest = () => ({
  type: FETCH_SINGLE_USER_REQUEST,
});

export const fetchSingleUserSuccess = (data) => ({
  type: FETCH_SINGLE_USER_SUCCESS,
  data: data,
});

export const fetchSingleUserFailure = (error) => ({
  type: FETCH_SINGLE_USER_FAILURE,
  error,
});

export const fetchSingleUser = (id) => (dispatch, getState) => {
  dispatch(fetchSingleUserRequest());
  return getApi(`users/api/users/${id}`)
    .then((data) => {
      dispatch(fetchSingleUserSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchSingleUserFailure(error));
    });
};
