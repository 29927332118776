export const FETCH_CHATMESSAGES_REQUEST = "FETCH_CHATMESSAGES_REQUEST";
export const FETCH_CHATMESSAGES_FAILURE = "FETCH_CHATMESSAGES_FAILURE";
export const FETCH_CHATMESSAGES_SUCCESS = "FETCH_CHATMESSAGES_SUCCESS";

export const FETCH_SEND_MESSAGE_REQUEST = "FETCH_SEND_MESSAGE_REQUEST";
export const FETCH_SEND_MESSAGE_SUCCESS = "FETCH_SEND_MESSAGE_SUCCESS";
export const FETCH_SEND_MESSAGE_FAILURE = "FETCH_SEND_MESSAGE_FAILURE";

export const FETCH_CHAT_LIST_REQUEST = "FETCH_CHAT_LIST_REQUEST";
export const FETCH_CHAT_LIST_SUCCESS = "FETCH_CHAT_LIST_SUCCESS";
export const FETCH_CHAT_LIST_FAILURE = "FETCH_CHAT_LIST_FAILURE";

export const FETCH_CHAT_COUNT_REQUEST = "FETCH_CHAT_COUNT_REQUEST";
export const FETCH_CHAT_COUNT_SUCCESS = "FETCH_CHAT_COUNT_SUCCESS";
export const FETCH_CHAT_COUNT_FAILURE = "FETCH_CHAT_COUNT_FAILURE";
