export const FETCH_ANNOUNCEMENTS_REQUEST = "FETCH_ANNOUNCEMENTS_REQUEST";
export const FETCH_ANNOUNCEMENTS_FAILURE = "FETCH_ANNOUNCEMENTS_FAILURE";
export const FETCH_ANNOUNCEMENTS_SUCCESS = "FETCH_ANNOUNCEMENTS_SUCCESS";

export const FETCH_CREATE_ANNOUNCEMENTS_REQUEST =
  "FETCH_CREATE_ANNOUNCEMENTS_REQUEST";
export const FETCH_CREATE_ANNOUNCEMENTS_FAILURE =
  "FETCH_CREATE_ANNOUNCEMENTS_FAILURE";
export const FETCH_CREATE_ANNOUNCEMENTS_SUCCESS =
  "FETCH_CREATE_ANNOUNCEMENTS_SUCCESS";

export const FETCH_EMAIL_ANNOUNCEMENTS_REQUEST =
  "FETCH_EMAIL_ANNOUNCEMENTS_REQUEST";
export const FETCH_EMAIL_ANNOUNCEMENTS_SUCCESS =
  "FETCH_EMAIL_ANNOUNCEMENTS_SUCCESS";
export const FETCH_EMAIL_ANNOUNCEMENTS_FAILURE =
  "FETCH_EMAIL_ANNOUNCEMENTS_FAILURE";

export const GET_EMAIL_ANNOUNCEMENTS_REQUEST =
  "GET_EMAIL_ANNOUNCEMENTS_REQUEST";
export const GET_EMAIL_ANNOUNCEMENTS_SUCCESS =
  "GET_EMAIL_ANNOUNCEMENTS_SUCCESS";
export const GET_EMAIL_ANNOUNCEMENTS_FAILURE =
  "GET_EMAIL_ANNOUNCEMENTS_FAILURE";

export const FETCH_ANNOUNCEMENTS_BYID_REQUEST =
  "FETCH_ANNOUNCEMENTS_BYID_REQUEST";
export const FETCH_ANNOUNCEMENTS_BYID_SUCCESS =
  "FETCH_ANNOUNCEMENTS_BYID_SUCCESS";
export const FETCH_ANNOUNCEMENTS_BYID_FAILURE =
  "FETCH_ANNOUNCEMENTS_BYID_FAILURE";

export const FETCH_ANNOUNCEMENTS_DETAILS_REQUEST =
  "FETCH_ANNOUNCEMENTS_DETAILS_REQUEST";
export const FETCH_ANNOUNCEMENTS_DETAILS_SUCCESS =
  "FETCH_ANNOUNCEMENTS_DETAILS_SUCCESS";
export const FETCH_ANNOUNCEMENTS_DETAILS_FAILURE =
  "FETCH_ANNOUNCEMENTS_DETAILS_FAILURE";

export const FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_REQUEST =
  "FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_REQUEST";
export const FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_SUCCESS =
  "FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_SUCCESS";
export const FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_FAILURE =
  "FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_FAILURE";
