import React, { useState, useEffect } from "react";
import userImgPlaceHolder from "../../assets/css/images/user_placeholder.png";
import Picker from "emoji-picker-react";
import { CgAttachment } from "react-icons/cg";
import { BsEmojiSmile } from "react-icons/bs";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useRef } from "react";
import DocImage from "../../assets/css/images/DOC_1.svg";
import { useDispatch, useSelector } from "react-redux";
import ImageDisplay from "./ImageDisplay";
import { toast } from "react-toastify";

export default function UserChat() {
  const validExt = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/pdf",
    "video/mp4",
    "text/csv",
  ];

  const validImageTypes = ["image/gif", "image/jpeg", "image/png", "image/jpg"];

  const [inputChatBox, setInputChatBox] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [fileError, setFileError] = useState("");
  const [isFileError, isSetFileError] = useState(false);
  const [fileUpload, setFileUpload] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isImage, setIsImage] = useState();

  const inputRef = useRef();

  const onEmojiClick = (emojiObject, event) => {
    setInputChatBox((prevInput) => prevInput + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const handleUploadFile = (e) => {
    const fileSizeKiloBytes = e.target.files[0].size / 1024;
    if (fileSizeKiloBytes > 5120) {
      isSetFileError(true);
      toast.error("File size should be less than 5 MB");
    } else {
      setFileUpload(e.target.files[0]);
      setImageUrl((prev) => {
        const fileType = e?.target?.files[0]["type"];
        if (validImageTypes.includes(fileType)) {
          setIsImage(true);
          setImageUrl(URL?.createObjectURL(e?.target?.files[0]));
        } else {
          setIsImage(false);
          // setImageUrl("");
          setImageUrl(e.target.files[0]?.name);
        }
      });
    }

    // console.log("E: ", e.target.file[0]);
  };

  const handleKeypress = (e) => {
    if (e.charCode == 13) {
      sendMessage();
    }
  };

  const sendMessage = (e) => {
    console.log("MESSAGE: ", inputChatBox);
  };

  const chatMessages = (
    <div
      id="chat-div-custom"
      className="chat-messages chat-messages-height p-4"
      //   onScroll={onScroll}
      //   ref={listInnerRef}
    >
      {/* My Messages */}
      <div className="d-flex chat-message-right pb-4 place-content-end row justify-content-end ml-2">
        <p
          className="col-12 text-right mb-1"
          style={{ color: "rgb(56 66 91)", fontSize: "10px" }}
        >
          01/20/2023 | 3:45 PM
        </p>
        <div
          className="flex-shrink-1 rounded py-2 px-3 mr-3"
          style={{
            backgroundColor: "#FFF",
            color: "rgb(56 66 91)",
            boxShadow: "0 4px 8px 0 rgb(34 41 47 / 12%)",
          }}
        >
          <p className="chat-text-bottom">Right Message</p>
        </div>
      </div>
      <div className="d-flex chat-message-right pb-4 place-content-end row justify-content-end ml-2">
        <p
          className="col-12 text-right mb-1"
          style={{ color: "rgb(56 66 91)", fontSize: "10px" }}
        >
          01/20/2023 | 3:45 PM
        </p>

        <a href="www.google.com" target="blank" className="mr-2">
          <img
            src={userImgPlaceHolder}
            style={{ height: "150px", width: "150px" }}
            alt="Image"
          />
        </a>
      </div>
      <div className="d-flex chat-message-right pb-4 place-content-end row justify-content-end ml-2">
        <p
          className="col-12 text-right mb-1"
          style={{ color: "rgb(56 66 91)", fontSize: "10px" }}
        >
          01/20/2023 | 3:45 PM
        </p>

        <a href="www.google.com" target="blank" className="mr-2">
          <img src={DocImage} />
        </a>
      </div>

      {/* Reciever Messages */}
      <div className="flex chat-message-left pb-4 place-content-start row mr-2">
        <p
          className="col-12 text-left mb-1"
          // color: "#6e6e6e",
          style={{ color: "rgb(56 66 91)", fontSize: "10px" }}
        >
          Manager user, 01/20/2023 | 3:45 PM
        </p>

        <div
          className="flex-shrink-1 rounded py-2 px-3 ml-3"
          style={{
            backgroundColor: "#FFF",
            color: "rgb(56 66 91)",
            boxShadow: "0 4px 8px 0 rgb(34 41 47 / 12%)",
          }}
        >
          <p className="chat-text-bottom">Left message</p>
        </div>
      </div>
      <div className="flex chat-message-left pb-4 place-content-start row mr-2">
        <p
          className=" col-12 text-left mb-1"
          // color: "#6e6e6e",
          style={{ color: "rgb(56 66 91)", fontSize: "10px" }}
        >
          Manager user, 01/20/2023 | 3:45 PM
        </p>

        <a href="www.google.com" target="blank" className="ml-3">
          <img
            src={userImgPlaceHolder}
            style={{ height: "150px", width: "150px" }}
            alt="Image"
          />
        </a>
      </div>
      <div className="flex chat-message-left pb-4 place-content-start row mr-2">
        <p
          className=" col-12 text-left mb-1"
          // color: "#6e6e6e",
          style={{ color: "rgb(56 66 91)", fontSize: "10px" }}
        >
          Manager user, 01/20/2023 | 3:45 PM
        </p>

        <a href="www.google.com" target="blank" className="ml-3">
          <img src={DocImage} alt="Image" />
        </a>
      </div>
    </div>
  );

  return (
    <div className="col-12 col-lg-6 col-xl-9">
      <div className="chat-view">
        <div className="py-2 px-4 border-bottom  d-lg-block">
          <div className="d-flex align-items-center py-1">
            <div
              className="position-relative custom-scroll-bottom"
              id="chat-outside"
            >
              <img
                src={userImgPlaceHolder}
                style={{ height: "60px", width: "60px" }}
                className="rounded-circle mr-1 w-14 h-14"
                alt="Avtar"
              />
            </div>
            <div className="flex-grow-1 pl-3">
              <strong>Test User</strong>
            </div>
          </div>
        </div>
        <div
          className="position-relative"
          style={{ backgroundColor: "rgb(225 224 224)" }}
        >
          {chatMessages}
        </div>

        {inputRef?.current?.value && fileUpload && (
          <ImageDisplay
            image={imageUrl}
            setImageUrl={setImageUrl}
            setFileUpload={setFileUpload}
            inputRef={inputRef}
            isImage={isImage}
          />
        )}

        <div
          className="flex-grow-0 py-3 px-2 border-top chat_mobile_css"
          style={{
            position: "absolute",
            left: "0",
            right: "20px",
            bottom: "10px",
          }}
        >
          <div className="input-group d-flex align-items-center">
            <div className="image-upload mx-2">
              <input
                type="file"
                hidden={true}
                ref={inputRef}
                onChange={(e) => {
                  handleUploadFile(e);
                }}
              />
              <i className="cursor-pointer">
                <CgAttachment
                  style={{ height: "40px", width: "25px" }}
                  onClick={() => inputRef.current.click()}
                />
              </i>
            </div>

            <input
              id="message"
              type="text"
              className="form-control mx-1"
              placeholder="Type your message"
              value={inputChatBox}
              onChange={(e) => setInputChatBox(e.target.value)}
              onKeyPress={handleKeypress}
            />
            <BsEmojiSmile
              onClick={() => setShowEmojiPicker((val) => !val)}
              className="emojiPickerIcon"
            />

            <Modal isOpen={showEmojiPicker} centered>
              <ModalHeader toggle={() => setShowEmojiPicker((val) => !val)}>
                Select Emoji
              </ModalHeader>
              <ModalBody className="p-0">
                <Picker
                  searchDisabled="false"
                  skinTonesDisabled="false"
                  autoFocusSearch="false"
                  onEmojiClick={onEmojiClick}
                  height={500}
                  width={400}
                />
              </ModalBody>
            </Modal>

            <button
              className="btn custom_background_color mx-2"
              onClick={() => sendMessage()}
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
