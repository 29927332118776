import React, { useEffect, useState } from "react";
import { getRoundValue } from "../../modules/utils";

export default function FoodCostInv({
  purchaseOfTheWeek,
  foodCostBeforeWeek,
  inventoryVariance,
  salesOfTheWeek,
  beginningInventory,
  endingInventory,
  restaurant,
}) {
  const [inputValues, setInputValues] = useState({
    purchaseBeforeInventory: 0,
    foodCostBeforeInventory: 0,
    inventoryVariance: 0,
    purchaseAfterInventory: 0,
    fooodCostAfterInventory: 0,
    salesForTheWeek: 0,
    beginningInventory: 0,
    purchaseOfTheWeek: 0,
    endingInventory: 0,
    totalPurchases: 0,
    estimatedFoodCostOfTheWeek: 0,
  });

  useEffect(() => {
    const updatedData = {
      purchaseBeforeInventory: getRoundValue(purchaseOfTheWeek),
      foodCostBeforeInventory: getRoundValue(foodCostBeforeWeek),
      inventoryVariance: getRoundValue(inventoryVariance),
      purchaseAfterInventory: getRoundValue(
        Number(purchaseOfTheWeek) + Number(inventoryVariance)
      ),
      fooodCostAfterInventory:
        salesOfTheWeek != 0
          ? getRoundValue(
              ((Number(purchaseOfTheWeek) + Number(inventoryVariance)) * 100) /
                Number(salesOfTheWeek)
            )
          : 0,
      salesForTheWeek: getRoundValue(salesOfTheWeek),
      beginningInventory: getRoundValue(beginningInventory),
      purchaseOfTheWeek: getRoundValue(purchaseOfTheWeek),
      endingInventory: getRoundValue(endingInventory),
      totalPurchases: getRoundValue(
        Number(purchaseOfTheWeek) + Number(inventoryVariance)
      ),
      estimatedFoodCostOfTheWeek:
        salesOfTheWeek != 0
          ? getRoundValue(
              ((Number(purchaseOfTheWeek) + Number(inventoryVariance)) * 100) /
                Number(salesOfTheWeek)
            )
          : 0,
    };
    setInputValues(updatedData);
  }, [
    purchaseOfTheWeek,
    foodCostBeforeWeek,
    inventoryVariance,
    salesOfTheWeek,
    beginningInventory,
    endingInventory,
  ]);

  return (
    <div className="table-responsive">
      <table
        className="table table-hover text-center table-bordered"
        style={{
          boxShadow: "5px 5px 10px #4b4b4b",
          backgroundColor: "#dadae5 ",
        }}
      >
        <thead className="custom-table-calculator">
          <tr className="calc_tr">
            <th style={{ width: "25%" }}>Food Cost</th>
            <th style={{ width: "25%" }}></th>
          </tr>
        </thead>
        <tbody className="custom-table-calculator">
          <tr className="calc_tr">
            <td className="calc_td">
              <b>PURCHASES BEFORE INVENTORY</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.purchaseBeforeInventory}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>FOOD COST BEFORE INVENTORY</b>
            </td>
            <td className="calc_td text-center">
              {inputValues?.foodCostBeforeInventory}%
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Inventory Variance (+ / -)</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.inventoryVariance}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>PURCHASES AFTER INVENTORY</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.purchaseAfterInventory}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>FOOD COST AFTER INVENTORY</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.fooodCostAfterInventory}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>{restaurant?.label} Food Sales for the week</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.salesForTheWeek}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Beginning Inventory ( +)</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.beginningInventory}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>(+) Purchases for the week</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.purchaseOfTheWeek}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>( - ) Ending Inventory</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.endingInventory}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>Total Purchases</b>
            </td>
            <td className="calc_td text-center">
              ${inputValues?.totalPurchases}
            </td>
          </tr>
          <tr className="calc_tr">
            <td className="calc_td">
              <b>{restaurant?.label} Estimated Food Cost for the Week</b>
            </td>
            <td className="calc_td text-center">
              {inputValues?.estimatedFoodCostOfTheWeek}%
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
