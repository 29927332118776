import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSchedule } from "../actions/schedule/schedule";
import { formatDateISO, getDate, isValidArray } from "../modules/utils";
import Avatar from "react-avatar";

import { FaDollarSign, FaClock } from "react-icons/fa";
import moment from "moment";
import Loader from "react-loader-spinner";

const LaborCost = () => {
  const dispatch = useDispatch();

  const [laborCost, setLaborCost] = useState(0);
  const [hour, setHour] = useState(0);
  const [view, setView] = useState("week");
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));

  const { scheduleLoading, scheduleList } = useSelector((state) => ({
    scheduleLoading: state.schedule.isLoading,
    scheduleList: state.schedule?.data || [],
  }));

  const [announcementsData, setAnnouncementsData] = useState([]);

  const { data, isLoading, error } = useSelector(
    (state) => state?.announcementsData
  );

  useEffect(() => {
    setAnnouncementsData(data?.results);
  }, [data]);

  useEffect(() => {
    fetchSchedules();
  }, []);

  const getApiDate = () => {
    return view == "week" ? formatDateISO(getDates(1)) : date;
  };

  const getItems = () => {
    let schedule_items = scheduleList;

    const dateVar = getApiDate();

    if (view == "week") {
      return (schedule_items = isValidArray(schedule_items?.[dateVar])
        ? schedule_items[dateVar]
        : []);
    } else {
      return (schedule_items = isValidArray(schedule_items?.[dateVar])
        ? schedule_items[dateVar]
        : []);
      //   return (schedule_items = isValidArray(schedule_items?.[dateVar]?.results)
      //     ? schedule_items[dateVar]?.results
      //     : []);
    }
  };

  const fetchSchedules = () => {
    dispatch(
      fetchSchedule({
        date: getApiDate(),
        viewBy: view,
      })
    );
  };

  const day_start = () => {
    let d = new Date(date);
    let dd = d.getDay() || 7;
    d.setDate(d.getDate() - dd + 1);
    return d;
  };

  useEffect(() => {
    let laborCostsTotal = 0;
    let laborCostsCategory = {};
    let laborCostsDay = [0, 0, 0, 0, 0, 0, 0, 0];
    let hour = 0;
    const schedule_items = getItems();

    if (isValidArray(schedule_items)) {
      schedule_items.forEach((item) => {
        hour += item.time;
        laborCostsTotal += item.cost;
        if (item.user.groups.length > 0) {
          if (!laborCostsCategory[item.user.groups[0].category])
            laborCostsCategory[item.user.groups[0].category] = {
              id: item.user.groups[0].category,
              total: 0,
            };
          laborCostsCategory[item.user.groups[0].category]["total"] +=
            item.cost;
        }
        let idx = new Date(item.date).getDay() - getDates(1).getDay() + 1;

        laborCostsDay[idx] += item.cost;
      });
    }

    setLaborCost(laborCostsTotal);
    setHour(hour);
  }, [scheduleList]);

  const getDates = (num) => {
    return getDate(num, formatDateISO(day_start()));
  };

  useEffect(() => {
    fetchSchedules();
  }, [view]);

  const truncate = (str) => {
    return str.length > 80 ? str.substring(0, 80) + "..." : str;
  };

  const formatDate = (date) => {
    return moment(date).format("ddd, MM/DD");
  };

  return (
    <div className="dashboard-labor-cost mt-5 main_info_header row mr-0 ml-0">
      <div className="dashboard_labor_main d-flex  col-md-6 col-sm-12">
        <div className="dashboard_labor_hours dashboard_labor">
          <div>
            <div className="dashboard_labor_head">VALUE</div>
            <div className="dashboard_labor_value">
              ${parseFloat(laborCost).toFixed(2) || 0.0}
            </div>
          </div>

          <div className="dashboard_labor_svg">
            <FaDollarSign />
          </div>
        </div>
        <div className="dashboard_labor_hours dashboard_labor">
          <div>
            <div className="dashboard_labor_head">TOTAL HOURS</div>
            <div className="dashboard_labor_value">
              {parseFloat(hour).toFixed(2) || 0}
            </div>
          </div>
          <div className="dashboard_labor_svg">
            <FaClock />
          </div>
        </div>
      </div>
      <div className="d-flex dashboard_announcement_main flex-column col-md-6 col-sm-12">
        {isLoading ? (
          <Loader
            type="TailSpin"
            color="#38425B"
            height={50}
            className="my-1"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          />
        ) : (
          announcementsData
            ?.slice(0, 3)
            ?.map((announcement, index, { length }) => {
              return (
                <div
                  className={`${
                    length - 1 === index
                      ? "dashboard_announcement"
                      : "dashboard_announcement_border"
                  } d-flex justify-content-between align-items-center`}
                >
                  <div>
                    <div className="dashboard_announcement_header d-flex align-items-center mb-2">
                      <Avatar
                        name={
                          announcement.created_by_details.full_name.split(
                            " ("
                          )[0]
                        }
                        size="30"
                        round="50px"
                        className="mr-2"
                        color="#38425B"
                      />{" "}
                      {announcement?.created_by_details?.full_name.toUpperCase()}{" "}
                      :
                    </div>
                    <div className="dashboard_announcement_title">
                      {announcement?.title?.charAt(0).toUpperCase() +
                        announcement?.title?.slice(1)}
                    </div>
                    <div className="dashboard_announcement_message">
                      {truncate(announcement?.message)}
                    </div>
                  </div>
                  <div className="dashboard_announcement_date">
                    {formatDate(announcement?.created)}
                  </div>
                </div>
              );
            })
        )}
      </div>
    </div>
  );
};

export default LaborCost;
