import {
	FETCH_FOOD_GALLERY_REQUEST,
	FETCH_FOOD_GALLERY_SUCCESS,
	FETCH_FOOD_GALLERY_FAILURE,
} from '../../types/foodGallery';

const foodGalleryReducer = (
	state = {
		data: null,
		error: null,
		isLoading: false,
	},
	action,
) => {
	switch (action.type) {
		case FETCH_FOOD_GALLERY_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_FOOD_GALLERY_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_FOOD_GALLERY_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default foodGalleryReducer;
