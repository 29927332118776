import {
	FETCH_CATEGORIES_REQUEST,
	FETCH_CATEGORIES_SUCCESS,
	FETCH_CATEGORIES_FAILURE,
} from '../../types/categories';
import { getApi } from '../api';

export const fetchCategoriesRequest = () => ({
	type: FETCH_CATEGORIES_REQUEST,
});

export const fetchCategoriesSuccess = (data) => ({
	type: FETCH_CATEGORIES_SUCCESS,
	data: data,
});

export const fetchCategoriesFailure = (error) => ({
	type: FETCH_CATEGORIES_FAILURE,
	error,
});

export const fetchCategories = (data) => (dispatch, getState) => {
	dispatch(fetchCategoriesRequest());
	return getApi('bshift/api/categories')
		.then((data) => {
			dispatch(fetchCategoriesSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchCategoriesFailure(error));
		});
};
