import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CgAttachment } from "react-icons/cg";
import { fetchChatMessages } from "../../actions/chat/ChatMessages";
import { fetchSendMessage } from "../../actions/chat/sendMessage";
import ImageUpload from "./ImageUpload";
import userImgPlaceHolder from "../../assets/css/images/user_placeholder.png";
import Loader from "react-loader-spinner";
import moment from "moment";
import DocImage from "../../assets/css/images/DOC_1.svg";
import Picker from "emoji-picker-react";
import { BsEmojiSmile } from "react-icons/bs";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const ChatBox = (props) => {
  let inputRef = useRef();
  const listInnerRef = useRef();
  const dispatch = useDispatch();

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const [imageupload, setimageupload] = useState("");
  const [userInputChatbox, setuserInputChatbox] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [customScrollHeight, setCustomScrollHeight] = useState(0);
  const [isImage, setIsImage] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const [finalChatList, setFinalChatList] = useState([]);
  const [newArr, setNewArr] = useState([]);

  const [imageUrl, setImageUrl] = useState("");

  const { ChatMessages } = useSelector((state) => ({
    ChatMessages: state.chatMessages.data || [],
  }));
  const { isLoading } = useSelector((state) => state.chatMessages);

  const sendMessageData = useSelector((state) => state.sendMessageData?.data);

  const loggedinid = localStorage.getItem("loggedUserId");

  useEffect(() => {
    setImageUrl("");
    setimageupload("");
    setuserInputChatbox("");
    inputRef.current.value = null;
    setNewArr([]);
    if (props.userObject) {
      setFinalChatList([]);
      setCurrPage(1);
      if (props?.userObject?.first_name) {
        dispatch(fetchChatMessages(props?.userObject?.user_id, 0, 1));
      } else if (props?.userObject?.full_name) {
        dispatch(fetchChatMessages(props?.userObject?.id, 0, 1));
      } else if (props?.userObject?.title) {
        dispatch(fetchChatMessages(props?.userObject?.id, 1, 1));
      } else {
      }
      const objDiv = document.getElementById("chat-div-custom");
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }, [props.userObject]);

  useEffect(() => {
    if (ChatMessages?.results?.length > 0) {
      const objDiv = document.getElementById("chat-div-custom");
      if (currPage === 1 && ChatMessages?.results) {
        setFinalChatList(ChatMessages?.results);
        objDiv.scrollTop = objDiv.scrollHeight;
      } else if (currPage > 1 && ChatMessages?.results) {
        setFinalChatList([
          ...finalChatList.reverse(),
          ...ChatMessages?.results,
        ]);
        objDiv.scrollTop = customScrollHeight;
      }
    }
  }, [ChatMessages]);

  useEffect(() => {
    if (finalChatList.length > 0) {
      setNewArr(finalChatList.reverse());
    }
  }, [finalChatList]);

  useEffect(() => {
    if (sendMessageData) {
      if (props?.userObject?.first_name) {
        dispatch(fetchChatMessages(props?.userObject?.user_id, 0, 1));
      } else if (props?.userObject?.full_name) {
        dispatch(fetchChatMessages(props?.userObject?.id, 0, 1));
      } else if (props?.userObject?.title) {
        dispatch(fetchChatMessages(props?.userObject?.id, 1, 1));
      } else {
      }
    }
  }, [sendMessageData]);

  const sendMessage = () => {
    let inputData = new FormData();
    inputData.append("sender_id", loggedinid);

    setImageUrl("");
    inputRef.current.value = null;
    // setuserInputChatbox("");
    document.getElementById("message").value = "";
    if (props.userObject?.full_name || props.userObject?.first_name) {
      if (props.userObject?.first_name) {
        inputData.append("receiver_id", props.userObject?.user_id);
      } else {
        inputData.append("receiver_id", props.userObject?.id);
      }

      if (imageupload) {
        const fileType = imageupload["type"];
        if (validImageTypes.includes(fileType)) {
          inputData.append("message", "image");
          inputData.append("attachment_file", imageupload);
        } else {
          inputData.append("message", "file");
          inputData.append("attachment_file", imageupload);
        }
        inputData.append("type", false);
      } else {
        inputData.append("message", userInputChatbox);
      }
    } else if (props.userObject?.title) {
      inputData.append("group_id", props.userObject?.id);
      // if (imageupload) {
      //   inputData.append("message", imageupload);
      //   inputData.append("type", "image");
      // } else {
      //   inputData.append("message", userInputChatbox);
      // }
      if (imageupload) {
        const fileType = imageupload["type"];
        if (validImageTypes.includes(fileType)) {
          inputData.append("message", "image");
          inputData.append("attachment_file", imageupload);
        } else {
          inputData.append("message", "file");
          inputData.append("attachment_file", imageupload);
        }
        inputData.append("type", false);
      } else {
        inputData.append("message", userInputChatbox);
      }
    }
    inputData.append("unread_message", true);
    inputData.append("notification_sent", false);
    inputData.append("sent_by", loggedinid);
    setimageupload("");
    setuserInputChatbox("");

    setFinalChatList([]);
    setCurrPage(1);
    dispatch(fetchSendMessage(inputData)).then(() => {});
    // .then(() => {
    // });
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      sendMessage();
    }
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop === 0 && scrollHeight > clientHeight) {
        if (props?.userObject?.first_name) {
          dispatch(
            fetchChatMessages(props?.userObject?.user_id, 0, currPage + 1)
          );
        } else if (props?.userObject?.full_name) {
          dispatch(fetchChatMessages(props?.userObject?.id, 0, currPage + 1));
        } else if (props?.userObject?.title) {
          dispatch(fetchChatMessages(props?.userObject?.id, 1, currPage + 1));
        } else {
        }

        // setCustomScrollHeight((prev) => prev + clientHeight);
        setCustomScrollHeight(clientHeight);
        setCurrPage(currPage + 1);
      }
    }
  };

  const onEmojiClick = (emojiObject, event) => {
    setuserInputChatbox((prevInput) => prevInput + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  const chatMessages = (
    <div
      id="chat-div-custom"
      className="chat-messages chat-messages-height p-4"
      onScroll={onScroll}
      ref={listInnerRef}
    >
      {isLoading && (
        <Loader
          type="TailSpin"
          color="#38425B"
          height={50}
          className="my-1"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        />
      )}
      {newArr?.map((item, id) => {
        const dateTime = moment(item.created_date).format(
          "MM/DD/YYYY | h:mm a"
        );

        return item.sender_id === loggedinid ? (
          <div
            className="d-flex chat-message-right pb-4 place-content-end row justify-content-end"
            key={id}
          >
            <p
              className="px-2 col-12 text-right mb-2"
              style={{ color: "rgb(56 66 91)", fontSize: "10px" }}
            >
              {/* {time.toString()} | {date.toString()} */}
              {dateTime}
            </p>
            {props?.userObject.title ? (
              <div className="font-weight-bold mb-1"></div>
            ) : null}
            {item?.attachment_file ? (
              item?.message === "image" ? (
                <a href={item.attachment_file} target="blank">
                  <img
                    src={item.attachment_file}
                    style={{ height: "150px", width: "150px" }}
                    alt="Image"
                  />
                </a>
              ) : (
                <a href={item?.attachment_file} target="blank" className="px-2">
                  {item?.attachment_file?.split("/")?.slice(-1)[0] ? (
                    <img src={DocImage} />
                  ) : (
                    "File"
                  )}
                </a>
              )
            ) : (
              <div
                className="flex-shrink-1 rounded py-2 px-3 mr-2 "
                style={{
                  backgroundColor: "#FFF",
                  color: "rgb(56 66 91)",
                  boxShadow: "0 4px 8px 0 rgb(34 41 47 / 12%)",
                }}
                // style={{ backgroundColor: "rgba(157, 216, 239, 0.77)" }}
              >
                <p className="chat-text-bottom">{item?.message}</p>
              </div>
            )}
          </div>
        ) : (
          <div
            className="flex chat-message-left pb-4 place-content-start row"
            key={id}
          >
            <p
              className="px-2 col-12 text-left"
              // color: "#6e6e6e",
              style={{ color: "rgb(56 66 91)", fontSize: "10px" }}
            >
              {props?.groupId && (
                <span className="text-dark">
                  {item?.sender_name?.split(" ")[0]},{" "}
                </span>
              )}
              {/* {time.toString()} | {date.toString()} */}
              {dateTime}
            </p>
            {item?.attachment_file ? (
              item?.message === "image" ? (
                <a href={item.attachment_file} target="blank">
                  <img
                    src={item.attachment_file}
                    style={{ height: "150px", width: "150px" }}
                    alt="image"
                  />
                </a>
              ) : (
                <a href={item?.attachment_file} target="blank" className="px-2">
                  <img src={DocImage} />
                  {/* {item?.attachment_file?.split("/")?.slice(-1)[0]
                    ? item?.attachment_file?.split("/").slice(-1)[0]
                    : "File"} */}
                </a>
              )
            ) : (
              <div
                className="flex-shrink-1 rounded py-2 px-3 ml-2"
                style={{
                  backgroundColor: "#FFF",
                  color: "rgb(56 66 91)",
                  boxShadow: "0 4px 8px 0 rgb(34 41 47 / 12%)",
                }}
              >
                <p className="chat-text-bottom">{item?.message}</p>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      <div className="col-12 col-lg-6 col-xl-9">
        <div className="chat-view">
          <div className="py-2 px-4 border-bottom  d-lg-block">
            <div className="d-flex align-items-center py-1">
              <div
                className="position-relative custom-scroll-bottom"
                id="chat-outside"
              >
                <img
                  src={
                    props?.userObject?.avatar
                      ? props?.userObject?.avatar
                      : userImgPlaceHolder
                  }
                  style={{ height: "60px", width: "60px" }}
                  className="rounded-circle mr-1 w-14 h-14"
                  alt="Avtar"
                />
              </div>
              <div className="flex-grow-1 pl-3">
                <strong>
                  {props?.userObject?.full_name
                    ? props?.userObject.full_name
                    : props?.userObject.title}
                </strong>
              </div>
            </div>
          </div>
          <div
            className="position-relative"
            style={{ backgroundColor: "rgb(225 224 224)" }}
          >
            {chatMessages}
          </div>
          {inputRef?.current?.value && imageupload && (
            <ImageUpload
              image={imageUrl}
              setImageUrl={setImageUrl}
              setimageupload={setimageupload}
              inputRef={inputRef}
              isImage={isImage}
            />
          )}

          <div
            className="flex-grow-0 py-3 px-2 border-top chat_mobile_css"
            style={{
              position: "absolute",
              left: "0",
              right: "20px",
              bottom: "10px",
            }}
          >
            <div className="input-group d-flex align-items-center">
              <div className="image-upload mx-2">
                <input
                  type="file"
                  hidden={true}
                  ref={inputRef}
                  // ref={(refParam) => (inputRef = refParam)}
                  onChange={(e) => {
                    setimageupload(e.target.files[0]);
                    setImageUrl((prev) => {
                      const fileType = e?.target?.files[0]["type"];
                      if (validImageTypes.includes(fileType)) {
                        setIsImage(true);
                        setImageUrl(URL?.createObjectURL(e?.target?.files[0]));
                      } else {
                        setIsImage(false);
                        setImageUrl(e.target.files[0]?.name);
                        // console.log("E: ", e.target.files[0]);
                      }
                    });
                  }} // for image preview
                />
                <i
                  className="cursor-pointer"
                  // onClick={() => inputRef.click()}
                >
                  <CgAttachment
                    style={{ height: "40px", width: "25px" }}
                    onClick={() => inputRef.current.click()}
                  />
                </i>
              </div>

              <input
                id="message"
                type="text"
                className="form-control mx-1"
                placeholder="Type your message"
                value={userInputChatbox}
                // disabled={imageupload ? true : false}
                onChange={(e) => setuserInputChatbox(e.target.value)}
                onKeyPress={handleKeypress}
              />
              <BsEmojiSmile
                onClick={() => setShowEmojiPicker((val) => !val)}
                className="emojiPickerIcon"
              />

              <Modal isOpen={showEmojiPicker} centered>
                <ModalHeader toggle={() => setShowEmojiPicker((val) => !val)}>
                  Select Emoji
                </ModalHeader>
                <ModalBody className="p-0">
                  <Picker
                    searchDisabled="false"
                    skinTonesDisabled="false"
                    autoFocusSearch="false"
                    onEmojiClick={onEmojiClick}
                    height={500}
                    width={400}
                  />
                </ModalBody>
              </Modal>

              <button
                className="btn custom_background_color mx-2"
                onClick={() => sendMessage()}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBox;
