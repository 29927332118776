import {
	FETCH_SHIFT_DAY_PART_REQUEST,
	FETCH_SHIFT_DAY_PART_SUCCESS,
	FETCH_SHIFT_DAY_PART_FAILURE,
} from '../../types/schedule';
import { getApi } from '../api';

export const fetchShiftDayPartRequest = () => ({
	type: FETCH_SHIFT_DAY_PART_REQUEST,
});

export const fetchShiftDayPartSuccess = (data) => ({
	type: FETCH_SHIFT_DAY_PART_SUCCESS,
	data: data,
});

export const fetchShiftDayPartFailure = (error) => ({
	type: FETCH_SHIFT_DAY_PART_FAILURE,
	error,
});

export const fetchShiftDayPart = (data) => (dispatch, getState) => {
	dispatch(fetchShiftDayPartRequest());

	return getApi(`bshift/api/shiftdaypart`)
		.then((data) => {
			dispatch(fetchShiftDayPartSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchShiftDayPartFailure(error));
		});
};
