import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchMyQuiz } from '../actions/training/myQuiz';
import { fetchRestaurants } from '../actions/restaurants/restaurants';
import { isValidArray } from '../modules/utils';

export class QuizList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			quizData: null,
			page: 1,
			search: '',
			itemsPerPage: 5,
			sort_field: 'title',
			sort_type: false,
			completed: [],
		};
	}

	componentDidMount() {
		// this.getList();
	}

	getList = () => {
		const { page, itemsPerPage, search, sort_field, sort_type } = this.state;
		return this.props.fetchMyQuiz(
			page,
			itemsPerPage,
			search,
			sort_field,
			sort_type,
		);
	};

	getFilteredList = () => {
		const { myQuizData } = this.props;
		const localCompleted = JSON.parse(localStorage.getItem('completedQuiz'));
		const currDate = new Date();

		return myQuizData?.results
			?.map((d) => {
				return {
					id: d.id || '',
					title: d.title || '',
					due_date: d.due_date || '',
				};
			})
			.filter((d) => !this.state.completed.includes(d.id))
			.filter((d) => (localCompleted ? !localCompleted.includes(d.id) : d))
			.filter((d) => currDate < new Date(d?.due_date))
			.slice(0, 5);
	};

	handleChange = (id) => {
		this.setState({
			completed: [...this.state.completed, id],
		});
		const localCompleted = JSON.parse(localStorage.getItem('completedQuiz'));
		const localCompleted_ = JSON.stringify(
			localCompleted ? [...localCompleted, id] : [id],
		);
		localStorage.setItem('completedQuiz', localCompleted_);
	};

	render() {
		const listData = this.getFilteredList();
		return (
			<div className="dashboard_image_carousel mt-5 main_info_header col ml-2">
				<div className="row mb-3">
					<div className="col-md-6">
						<h3>
							<i className="zmdi zmdi-long-arrow-left"></i>My Quizzes
						</h3>
					</div>
				</div>
				{isValidArray(listData) &&
					listData.map((quiz) => (
						<div
							className="author_bio_toggle_wrapper d-flex justify-content-between"
							style={{ background: '#d9d9d9' }}
							key={quiz?.id}
						>
							<div className="d-flex align-items-center">
								<input
									className="align-items-center"
									type="checkbox"
									style={{ minWidth: '30px' }}
									onChange={() => this.handleChange(quiz?.id)}
								/>
								<span className="text-nowrap cursor-pointer">
									{quiz?.title}
								</span>
							</div>
						</div>
					))}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		myQuizDataLoading: state.myQuizData.isLoading,
		myQuizData: state.myQuizData.data,

		restaurantsDataLoading: state.restaurantsData.isLoading,
		restaurantsData: state.restaurantsData.data,

		globalSearch: state.globalSearch.searchQuery,
	};
};
const mapDispatchToProps = {
	fetchMyQuiz,
	fetchRestaurants,
};
export default connect(mapStateToProps, mapDispatchToProps)(QuizList);
