import React, { Component } from "react";
import { connect } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";

import { createCalendarEvent } from "../../actions/calendar/createCalendarEvent";
import { deleteCalendarEvent } from "../../actions/calendar/deleteCalendarEvent";
import { fetchCalendarEventKind } from "../../actions/calendar/fetchCalenderEventKind";
import { fetchCalendarEvent } from "../../actions/calendar/fetchCalendarEvent";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchProfile } from "../../actions/profile/profile";

import { getPermission, isValidArray } from "../../modules/utils";
import AddEditEvent from "./AddEditEvent";
import EventPreview from "./EventPreview";
import { fetchUsersDropdown } from "../../actions/users/userDropdownData";
export class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      restaurantId: [],
      eventKindId: [],
      addEditEventModal: false,
      eventPreviewModal: false,
      passProps: {},
      dateArgs: {},
    };
  }

  calendarRef = React.createRef();

  componentDidMount() {
    this.getInitialData();
    this.props.fetchProfile();
  }

  getInitialData = () => {
    this.props.fetchCalendarEventKind().then(() => {
      const { fetchCalendarEventKindData } = this.props;
      let eventIds = [];
      if (isValidArray(fetchCalendarEventKindData?.results)) {
        fetchCalendarEventKindData?.results.map((d) => {
          return (eventIds = [...eventIds, d.id]);
        });
      }

      this.setState({
        eventKindId: eventIds,
      });
    });
    this.props.fetchRestaurants().then(() => {
      const { restaurantsData } = this.props;

      let rIds = [];
      if (isValidArray(restaurantsData?.results)) {
        restaurantsData?.results.map((d) => {
          return (rIds = [...rIds, d.id]);
        });
      }

      this.setState({
        restaurantId: rIds,
      });
    });

    this.getCalendarEvent();
    this.fetchUserData();
  };

  fetchUserData = () => {
    // this.props.fetchUsers();
    this.props.fetchUsersDropdown();
  };

  getCalendarEvent = () => {
    this.props.fetchCalendarEvent();
  };

  getCalendarEventKind = () => {
    this.props.fetchCalendarEventKind();
  };

  getRestaurants = () => {
    this.props.fetchRestaurants();
  };

  restaurantFilter = (restaurantIds) => {
    const { restaurantId } = this.state;
    if (!restaurantId.includes(restaurantIds)) {
      this.setState({
        restaurantId: [...this.state.restaurantId, restaurantIds],
      });
    } else {
      this.setState({
        restaurantId: restaurantId.filter((d) => {
          return d !== restaurantIds;
        }),
      });
    }
  };

  eventKindFilter = (eventKindIds) => {
    const { eventKindId } = this.state;
    if (!eventKindId.includes(eventKindIds)) {
      this.setState({
        eventKindId: [...this.state.eventKindId, eventKindIds],
      });
    } else {
      this.setState({
        eventKindId: eventKindId.filter((d) => {
          return d !== eventKindIds;
        }),
      });
    }
  };

  allEventKindFilter = () => {
    const { eventKindId } = this.state;
    if (eventKindId?.length) {
      this.setState({ eventKindId: [] });
    } else {
      const { fetchCalendarEventKindData } = this.props;
      let eventIds = [];
      if (isValidArray(fetchCalendarEventKindData?.results)) {
        fetchCalendarEventKindData?.results.map((d) => {
          return (eventIds = [...eventIds, d.id]);
        });
      }

      this.setState({
        eventKindId: eventIds,
      });
    }
  };

  allRestaurantFilter = () => {
    const { restaurantId } = this.state;
    if (restaurantId?.length) {
      this.setState({ restaurantId: [] });
    } else {
      const { restaurantsData } = this.props;
      let rIds = [];
      if (isValidArray(restaurantsData?.results)) {
        restaurantsData?.results.map((d) => {
          return (rIds = [...rIds, d.id]);
        });
      }

      this.setState({
        restaurantId: rIds,
      });
    }
  };

  formatEvents = () => {
    const { fetchCalendarEventData } = this.props;
    const { restaurantId, eventKindId } = this.state;

    return fetchCalendarEventData?.results
      ?.filter(
        (d) =>
          restaurantId.includes(d.restaurant) && eventKindId.includes(d.kind)
      )
      .map((appointment) => {
        const { title, date_end, date_start } = appointment;

        let startTime = new Date(date_start);
        let endTime = new Date(date_end);

        return {
          title,
          start: startTime,
          end: endTime,
          extendedProps: { ...appointment },
        };
      });
  };

  toggleAddEditEvent = () => {
    this.setState({
      addEditEventModal: !this.state.addEditEventModal,
    });
  };

  addCalendarEvent = (passData = {}) => {
    this.props.createCalendarEvent(passData).then(() => {
      this.toggleAddEditEvent();
      this.getCalendarEvent();
    });
  };

  updateCalendarEvent = (passData = {}, id = "") => {
    this.props.createCalendarEvent(passData, id).then(() => {
      // this.toggleAddEditEvent();
      this.getCalendarEvent();
    });
  };

  toggleEventPreview = (passProps = {}) => {
    this.setState({
      passProps: passProps,
      eventPreviewModal: !this.state.eventPreviewModal,
    });
  };

  deleteCalendarEvent = (id = "") => {
    this.props.deleteCalendarEvent(id).then(() => {
      this.getCalendarEvent();
      this.toggleEventPreview();
    });
  };

  handleDateClick = (dateClickInfo) => {
    this.calendarRef.current
      .getApi()
      .changeView("timeGridDay", dateClickInfo.date);
  };

  render() {
    const {
      restaurantsData,
      fetchCalendarEventKindData,
      // usersData,
      usersDropdownData,
      profileData,
    } = this.props;
    const {
      restaurantId,
      eventKindId,
      addEditEventModal,
      eventPreviewModal,
      passProps,
    } = this.state;

    const customButtons = {
      addNewBtn: {
        text: "+ New",
        click: this.toggleAddEditEvent,
      },
    };

    return (
      <div className="main_box">
        {/* <Navbar />
				<Sidebar /> */}
        <div className="container-fluid">
          <div className="row calander_row">
            <div className="col-md-3 col-sm-12 calander_col">
              <div className="cal_right_side">
                <ul>
                  <div className="cal_list_heading_top">
                    <div className="cal_title_list">
                      <h4>
                        <li>Locations filter</li>
                      </h4>
                    </div>
                    <div
                      className="cal_input_list"
                      onClick={() => this.allRestaurantFilter()}
                    >
                      <input
                        type="checkbox"
                        className="check_list_box"
                        checked={
                          restaurantsData?.results?.length ===
                          restaurantId?.length
                        }
                        readOnly={true}
                      />
                    </div>
                  </div>
                  {isValidArray(restaurantsData?.results) &&
                    restaurantsData?.results?.map((d) => {
                      const checked = restaurantId.includes(d.id);

                      return (
                        <React.Fragment key={d.id}>
                          <li className="abc" key={d.id}>
                            <div
                              onClick={() => this.restaurantFilter(d.id)}
                              className="list_check_cal"
                            >
                              <input
                                type="checkbox"
                                className="check_list_box"
                                // defaultChecked={true}
                                checked={checked}
                                readOnly={true}
                              />
                              <span>{d.title}</span>
                            </div>
                          </li>
                        </React.Fragment>
                      );
                    })}
                </ul>
                <ul>
                  <div className="cal_list_heading cal_list_heading_top">
                    <div className="cal_title_list">
                      <h4>
                        <li>Kind filter</li>
                      </h4>
                    </div>
                    <div
                      className="cal_input_list"
                      onClick={() => this.allEventKindFilter()}
                    >
                      <input
                        type="checkbox"
                        className="check_list_box"
                        checked={
                          fetchCalendarEventKindData?.results?.length ===
                          eventKindId?.length
                        }
                        readOnly={true}
                      />
                    </div>
                  </div>
                  {isValidArray(fetchCalendarEventKindData?.results) &&
                    fetchCalendarEventKindData?.results?.map((d) => {
                      const checked = eventKindId.includes(d.id);
                      return (
                        <React.Fragment key={d.id}>
                          <li>
                            <div onClick={() => this.eventKindFilter(d.id)}>
                              <input
                                type="checkbox"
                                className="check_list_box"
                                checked={checked}
                                readOnly={true}
                                // defaultChecked={true}
                              />
                              <span>{d.title}</span>
                            </div>
                          </li>
                        </React.Fragment>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-md-8 col-sm-12 calander_col">
              <div className="calendar_left">
                <div className="card">
                  <FullCalendar
                    defaultView="dayGridMonth"
                    //plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    datesSet={(arg) => this.setState({ dateArgs: arg })}
                    editable={true}
                    headerToolbar={{
                      left: "today dayGridMonth,timeGridWeek,timeGridDay",
                      right: getPermission(
                        "bshift.bs_can_add_calendar",
                        profileData
                      )
                        ? "prev next addNewBtn"
                        : "prev next",
                      center: "sas",
                    }}
                    onClick={(e) => this.handleDateClick(e)}
                    customButtons={customButtons}
                    eventBorderColor={""}
                    // editable={false}
                    events={this.formatEvents()}
                    eventClick={(e) =>
                      this.toggleEventPreview(e.event?._def?.extendedProps)
                    }
                    navLinkDayClick={(e) => alert(e)}
                    // dateClick={(e) => alert(e)}
                    // {...calendarProps}
                    eventBackgroundColor={"#007bff"}
                    eventDisplay={"white"}
                    eventTextColor={"white"}
                    dateClick={this.handleDateClick}
                    ref={this.calendarRef}
                    eventTimeFormat={{
                      hour: "numeric",
                      minute: "2-digit",
                      meridiem: "short",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-1 col-sm-12 col_date"></div>
          </div>
        </div>
        {addEditEventModal && usersDropdownData?.length > 0 && (
          <AddEditEvent
            isOpen={addEditEventModal}
            toggle={this.toggleAddEditEvent}
            fetchCalendarEventKindData={fetchCalendarEventKindData?.results}
            restaurantsData={restaurantsData?.results}
            usersData={usersDropdownData}
            // usersData={usersData}
            addCalendarEvent={this.addCalendarEvent}
            dateArgs={this.state.dateArgs}
          />
        )}
        {eventPreviewModal && (
          <EventPreview
            isOpen={eventPreviewModal}
            toggle={this.toggleEventPreview}
            deleteCalendarEvent={this.deleteCalendarEvent}
            updateCalendarEvent={this.updateCalendarEvent}
            passProps={passProps}
            restaurantsData={restaurantsData?.results}
            fetchCalendarEventKindData={fetchCalendarEventKindData?.results}
            // usersData={usersData}
            usersData={usersDropdownData}
            toggleAddEditEvent={this.toggleAddEditEvent}
            profileData={profileData}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetchCalendarEventKindDataLoading:
      state.fetchCalendarEventKindData.isLoading,
    fetchCalendarEventKindData: state.fetchCalendarEventKindData.data,

    fetchCalendarEventDataLoading: state.fetchCalendarEventData.isLoading,
    fetchCalendarEventData: state.fetchCalendarEventData.data,

    deleteCalendarEventDataLoading: state.deleteCalendarEventData.isLoading,
    deleteCalendarEventData: state.deleteCalendarEventData.data,

    createCalendarEventDataLoading: state.createCalendarEventData.isLoading,
    createCalendarEventData: state.createCalendarEventData.data,

    restaurantsDataLoadingLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    // usersDataLoading: state.usersData.isLoading,
    // usersData: state.usersData.data?.results || [],
    usersDropdownData: state.usersDropdownData?.data?.results || [],

    globalSearch: state.globalSearch.searchQuery,

    profileData: state.profileData.data,
  };
};
const mapDispatchToProps = {
  createCalendarEvent,
  deleteCalendarEvent,
  fetchCalendarEventKind,
  fetchCalendarEvent,
  fetchRestaurants,
  // fetchUsers,
  fetchUsersDropdown,
  fetchProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
