import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";

import { fetchRestaurants } from "../../../actions/restaurants/restaurants";
import { fetchLogReport } from "../../../actions/managerLogs/logReport";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
class LogReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionLocation: [],
      locationValue: [],
      from_date: new Date(),
      to_date: new Date(),
      onDate: new Date(),
      selectedPeriod: "",
      selectedReport: "",
    };
  }

  componentDidMount() {
    this.props.fetchRestaurants();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.state?.selectedReport?.value === "download") {
    //   if (prevProps?.logReportData != this.props?.logReportData) {
    //     const blob = new Blob([this.props?.logReportData], {
    //       type: "application/pdf",
    //     });
    //     const fileURL = window.URL.createObjectURL(blob);
    //     // Setting various property values
    //     let alink = document.createElement("a");
    //     alink.href = fileURL;
    //     alink.download = "report.pdf";
    //     alink.click();
    //   }
    // } else if (this.state?.selectedReport?.value === "email") {
    //   if (this.props?.logReportData === "sent") {
    //     toast.success("Email sent");
    //   }
    // }
  }

  handleChangeLocation = (optionLocation) => {
    const locationValue = optionLocation.value;
    this.setState({ optionLocation, locationValue });
  };

  handleChange = (selectedPeriod) => {
    this.setState({ selectedPeriod });
  };

  handleChangeReport = (selectedReport) => {
    this.setState({ selectedReport });
  };

  handleGo = () => {
    //console.log('selectedReport', this.state.selectedReport)
    const {
      from_date,
      to_date,
      locationValue,
      selectedPeriod,
      selectedReport,
      onDate,
    } = this.state;

    this.props
      .fetchLogReport(
        locationValue,
        moment(from_date).format("YYYY-MM-DD"),
        moment(to_date).format("YYYY-MM-DD"),
        selectedPeriod?.value,
        selectedReport?.value,
        moment(onDate).format("YYYY-MM-DD")
      )
      .then((data) => {
        // this.props.toggle(false);
        console.log("Data: ", data);
      });
    toast.info("Your report is being prepared");

    this.props.toggle(false);
  };

  fromDateHandler = (e) => {
    this.setState({
      from_date: e.target.value,
    });
  };

  toDateHandler = (e) => {
    this.setState({
      to_date: e.target.value,
    });
  };

  onDateHandler = (e) => {
    this.setState({
      onDate: e.target.value,
    });
  };

  render() {
    const { isOpen, toggle, restaurantsData } = this.props;

    const { selectedPeriod, selectedReport, isOpenDatePicker } = this.state;
    const optionLocation = restaurantsData?.results?.map((d) => {
      return {
        value: d.id,
        label: d.title,
      };
    });

    const optionPeriod = [
      { value: "range", label: "Range" },
      { value: "today", label: "Today" },
      { value: "week", label: "Week" },
      { value: "date", label: "On Date" },
      { value: "month", label: "Month" },
      { value: "year", label: "year" },
    ];

    const optionReport = [
      { value: "download", label: "Download" },
      { value: "email", label: "Email" },
    ];
    return (
      <Modal isOpen={isOpen} className="report_model modal-dialog-centered">
        <ModalHeader toggle={toggle}>Whether</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12">
              <FormGroup>
                <Select
                  options={optionLocation}
                  name="optionLocation"
                  value={this.state.optionLocation}
                  onChange={(e) => this.handleChangeLocation(e)}
                  placeholder="Select location"
                />
              </FormGroup>
            </div>
            <div className="col-md-12">
              <FormGroup>
                <Select
                  options={optionPeriod}
                  value={selectedPeriod}
                  onChange={this.handleChange}
                  placeholder="Select Period"
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              {selectedPeriod?.value === "range" && (
                <FormGroup>
                  <input
                    type="date"
                    placeholder="from-date"
                    className="form-control px-2 py-3"
                    onChange={this.fromDateHandler}
                  />
                </FormGroup>
              )}
            </div>
            <div className="col-md-6">
              {selectedPeriod?.value === "range" && (
                <FormGroup>
                  <input
                    type="date"
                    placeholder="to-date"
                    className="form-control px-2 py-3"
                    onChange={this.toDateHandler}
                  />
                </FormGroup>
              )}
            </div>
            <div className="col-md-6">
              {selectedPeriod?.value === "date" && (
                <FormGroup>
                  <input
                    type="date"
                    placeholder="Date"
                    className="form-control px-2 py-3"
                    onChange={this.onDateHandler}
                  />
                </FormGroup>
              )}
            </div>
            {/* </div> */}
            <div className="col-md-12">
              <FormGroup>
                <Select
                  options={optionReport}
                  value={selectedReport}
                  onChange={this.handleChangeReport}
                  placeholder="Select Report"
                />
              </FormGroup>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="record_btn">
            <Button
              color=""
              onClick={this.handleGo}
              disabled={
                this.state.locationValue?.length === 0 ||
                this.state.selectedPeriod === "" ||
                this.state.selectedReport === ""
              }
            >
              GO
            </Button>
          </div>
          <div className="record_btn">
            <Button color="" onClick={toggle}>
              CANCEL
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    logReportDataLoading: state.logReportData.isLoading,
    logReportData: state.logReportData.data,
  };
};
const mapDispatchToProps = {
  fetchRestaurants,
  fetchLogReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(LogReport);
