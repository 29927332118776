import {
  FETCH_DELETE_DOCUMENT_REQUEST,
  FETCH_DELETE_DOCUMENT_SUCCESS,
  FETCH_DELETE_DOCUMENT_FAILURE,
} from "../../types/document";
import { toast } from "react-toastify";
import { deleteApi } from "../api";

export const fetchDeleteDocumentRequest = () => ({
  type: FETCH_DELETE_DOCUMENT_REQUEST,
});

export const fetchDeleteDocumentSuccess = (data) => ({
  type: FETCH_DELETE_DOCUMENT_SUCCESS,
  data: data,
});

export const fetchDeleteDocumentFailure = (error) => ({
  type: FETCH_DELETE_DOCUMENT_FAILURE,
  error,
});

export const fetchDeleteDocument = (id) => (dispatch, getState) => {
  dispatch(fetchDeleteDocumentRequest());

  return deleteApi(`bshift/api/training_files/${id}`)
    .then((data) => {
      dispatch(fetchDeleteDocumentSuccess(data));
    })
    .catch((error) => {
      console.log(error);
      toast.error("Error");
      dispatch(fetchDeleteDocumentFailure(error));
    });
};
