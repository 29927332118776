import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async (actionFun, loginFunction, history) => {
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("device_token", currentToken);
        actionFun(currentToken);
        loginFunction(currentToken);
      } else {
        toast.error("Something Went Wrong, please try again");
        history.push("/login");
      }
    })
    .catch((err) => {
      // console.log("An error occurred while retrieving token. ", err);
      // toast.info("Notification permission is not given");
      loginFunction();
    });
};

// Foreground listeners - only when user is on the web app tab
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Payload: ", payload);
      resolve(payload);
    });
  });
