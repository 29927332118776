import {
  FETCH_LOGIN_ACCESS_TOKEN_REQUEST,
  FETCH_LOGIN_ACCESS_TOKEN_FAILURE,
  FETCH_LOGIN_ACCESS_TOKEN_SUCCESS,
} from "../../types/loginAccessToken";
import { postApi } from "../api";
import { toast } from "react-toastify";

export const fetchLoginAccessTokenRequest = () => ({
  type: FETCH_LOGIN_ACCESS_TOKEN_REQUEST,
});

export const fetchLoginAccessTokenSuccess = (data) => ({
  type: FETCH_LOGIN_ACCESS_TOKEN_SUCCESS,
  data,
});

export const fetchLoginAccessTokenFailure = (error) => ({
  type: FETCH_LOGIN_ACCESS_TOKEN_FAILURE,
  error,
});

export const fetchLoginAccessToken = (data) => (dispatch, getState) => {
  dispatch(fetchLoginAccessTokenRequest());
  return postApi(`api-token-auth/`, data)
    .then((data) => {
      if (data?.data?.token) {
        localStorage.setItem("loginAccessToken", data.data.token);
        dispatch(fetchLoginAccessTokenSuccess(data));
      } else {
        toast.error("Something went wrong");
        dispatch(fetchLoginAccessTokenFailure());
      }
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch(fetchLoginAccessTokenFailure(error));
    });
};
