import { toast } from "react-toastify";
import {
  FETCH_ADD_QUIZ_REQUEST,
  FETCH_ADD_QUIZ_SUCCESS,
  FETCH_ADD_QUIZ_FAILURE,
} from "../../types/training";
import { postApi } from "../api";

export const fetchAddQuizRequest = () => ({
  type: FETCH_ADD_QUIZ_REQUEST,
});

export const fetchAddQuizSuccess = (data) => ({
  type: FETCH_ADD_QUIZ_SUCCESS,
  data: data,
});

export const fetchAddQuizFailure = (error) => ({
  type: FETCH_ADD_QUIZ_FAILURE,
  error,
});

export const fetchAddQuiz = (formData) => (dispatch, getState) => {
  dispatch(fetchAddQuizRequest());

  return postApi(`bshift/api/training_assign_quizzes`, formData)
    .then((data) => {
      toast.success("Training created successfully!");
      dispatch(fetchAddQuizSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchAddQuizFailure(error));
    });
};
