import {
	FETCH_LOG_TAG_SEARCH_REQUEST,
	FETCH_LOG_TAG_SEARCH_FAILURE,
	FETCH_LOG_TAG_SEARCH_SUCCESS,
} from '../../types/logTagSearch';
import { getApi } from '../api';

export const fetchLogTagSearchRequest = () => ({
	type: FETCH_LOG_TAG_SEARCH_REQUEST,
});

export const fetchLogTagSearchSuccess = (data) => ({
	type: FETCH_LOG_TAG_SEARCH_SUCCESS,
	data: data,
});

export const fetchLogTagSearchFailure = (error) => ({
	type: FETCH_LOG_TAG_SEARCH_FAILURE,
	error,
});

export const fetchLogTagSearch = (formData) => (dispatch, getState) => {
	dispatch(fetchLogTagSearchRequest());

	return getApi(`bshift/api/logtagsearch/${formData}`)
		.then((data) => {
			dispatch(fetchLogTagSearchSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchLogTagSearchFailure(error));
		});
};
