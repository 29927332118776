import { combineReducers } from "redux";

import announcementsReducer from "./announcements/announcements";
import announcementDetailsReducer from "./announcements/announcementDetails";
import announcementDetailsEmailReducer from "./announcements/announcementDetailsEmail";
import announcementsByIdReducer from "./announcements/annoucementById";
import createAnnouncementsReducer from "./announcements/create";

import categoriesReducer from "./categories/categories";
import categoryDetailReducer from "./categories/detail";
import categoryUpdateReducer from "./categories/update";
import categoryCreateReducer from "./categories/create";
import categoryDeleteReducer from "./categories/delete";

import groupsReducer from "./groups/groups";
import loginAccessTokenReducer from "./loginAccessToken/loginAccessToken";
import createLogsReducer from "./managerLogs/createLog";
import createLogRecordValueReducer from "./managerLogs/createLogRecordValue";
import fetchLogRecordValueReducer from "./managerLogs/fetchLogrRecordValue";
import logRecordsFieldReducer from "./managerLogs/logRecordField";
import managerLogsReducer from "./managerLogs/managerLogs";
import modulesReducer from "./modules/modules";
import profileReducer from "./profile/profile";
import whetherReducer from "./whether/whether";
import logReportReducer from "./managerLogs/logReport";
import managerLogFilterReducer from "./managerLogs/managerLogFilter";

import editProfileReducer from "./profile/edit";
import changePasswordReducer from "./profile/changePassword";

import usersReducer from "./users/users";
import singleUserReducer from "./users/singleUser";
import editUserReducer from "./users/edit";
import addUserReducer from "./users/add";
import deleteUserReducer from "./users/delete";

import restaurantsReducer from "./restaurants/restaurants";
import createRestaurantReducer from "./restaurants/create";
import updateRestaurantReducer from "./restaurants/update";
import deleteRestaurantReducer from "./restaurants/delete";

import todoActivityReducer from "./todo/activity";
import todoCreateReducer from "./todo/create";
import todoUpdateReducer from "./todo/update";
import todoDeleteReducer from "./todo/delete";
import todoListReducer from "./todo/list";
import todoDetailReducer from "./todo/detail";

import todoTaskCreateReducer from "./todoTask/create";
import todoTaskUpdateReducer from "./todoTask/update";
import todoTaskDeleteReducer from "./todoTask/delete";
import todoTaskListReducer from "./todoTask/list";
import todoTaskDetailReducer from "./todoTask/detail";

import todoCommentCreateReducer from "./todoComment/create";
import todoCommentUpdateReducer from "./todoComment/update";
import todoCommentDeleteReducer from "./todoComment/delete";
import todoCommentListReducer from "./todoComment/list";
import todoCommentDetailReducer from "./todoComment/detail";
import pickupRequestReducer from "./schedule/pickupRequest";
import dropRequestReducer from "./schedule/dropRequest";
import scheduleTemplateReducer from "./schedule/scheduleTemplate";
import scheduleReducer from "./schedule/schedule";
import deleteLogRecordReducer from "./managerLogs/deleteLogRecord";
import deleteLogRecordValueReducer from "./managerLogs/deleteLogRecordValue";
import updateLogRecordValueReducer from "./managerLogs/updateLogRecordValue";
import updateLogRecordReducer from "./managerLogs/updateLogRecord";
import fetchLogRecordReducer from "./managerLogs/fetchLogRecord";
import shiftTimeReducer from "./schedule/shiftTime";
import shiftDayPartReducer from "./schedule/shiftDayPart";
import editScheduleShiftReducer from "./schedule/editScheduleShift";
import deleteScheduleShiftReducer from "./schedule/deleteScheduleShift";
import deleteScheduleTemplateReducer from "./schedule/deleteScheduleTemplate";
import loadTemplateReducer from "./schedule/loadTemplate";
import clearScheduleWeekReducer from "./schedule/clearScheduleWeek";
import createDropRequestReducer from "./schedule/createDropRequest";
import scheduleCountReducer from "./schedule/scheduleCount";
import createPickupRequestReducer from "./schedule/createPickupRequest";
import updatePickupRequestReducer from "./schedule/updatePickupRequest";

import myQuizReducer from "./training/myQuiz";
import assignedQuizReducer from "../reducers/training/assignedQuiz";
import saveDraftReducer from "./training/saveDraft";
import trainingQuizReducer from "./training/trainingQuiz";
import addQuizReducer from "./training/addQuiz";
import profileAssignedQuiz from "./training/profileAssignedQuiz";

import fetchPermissionReducer from "./permission/fetchPermission";
import createGroupsReducer from "./groups/createGroup";
import deleteGroupsReducer from "./groups/deleteGroup";
import editGroupReducer from "./groups/editGroup";

import taskUserListReducer from "./users/taskUserList";
import fetchLogRecordValueDetailReducer from "./managerLogs/fetchLogRecordValueDetail";
import logTagSearchReducer from "./logTagSearch/fetchLogTagSearch";
import createCalendarEventReducer from "./calendar/createCalendarEvent";
import deleteCalendarEventReducer from "./calendar/deleteCalendarEvent";
import fetchCalendarEventReducer from "./calendar/fetchCalendarEvent";
import fetchCalendarEventKindReducer from "./calendar/fetchCalendarEventKind";

import salesListReducer from "./sales/list";
import globalSearchReducer from "./common/globalSearch";

import documentReducer from "./document/document";
import deleteDocumentReducer from "./document/delete";
import addDocumentReducer from "./document/add";
import editDocumentReducer from "./document/edit";
import deleteShiftTimeReducer from "./schedule/deleteShiftTime";
import createShiftTimeReducer from "./schedule/createShiftTime";
import updateShiftTimeReducer from "./schedule/updateShiftTime";
import createScheduleTemplateReducer from "./schedule/createScheduleTemplate";
import addUserShiftReducer from "./schedule/addUserShift";
import createCopyScheduleWeekReducer from "./schedule/createCopyScheduleWeek";

import foodGalleryReducer from "./foodGallery/foodGallery";
import newFoodGalleryReducer from "./foodGallery/newFoodGallery";

import usersDropdownReducer from "./users/userDropdownData";
import usersSearchList from "./users/userSearch";

import chatMessagesReducer from "./chat/chatMessages";
import chatListReducer from "./chat/userChatList";

import forgotPasswordReducer from "./forgotPassword/forgotPasswordReducer";
import getEmailReducer from "./announcements/getEmailAnnouncements";
import deviceTokenReducer from "./deviceTokenReducer";
import chatUserObjectReducer from "./chatUserObjectReducer";
import { fetchRestaurantGroupsReducer } from "./restaurantGroups/fetchRestaurantGroupsReducer";
import { createRestaurantGroupReducer } from "./restaurantGroups/createRestaurantGroupsReducer";
import sendMessageReducer from "./chat/sendMessage";
import chatCountReducer from "./chat/unreadCount";

const rootReducer = combineReducers({
  loginAccessToken: loginAccessTokenReducer,
  managerLogsData: managerLogsReducer,
  modulesData: modulesReducer,
  profileData: profileReducer,
  logTagSearchData: logTagSearchReducer,
  editProfileData: editProfileReducer,
  changePasswordData: changePasswordReducer,

  usersData: usersReducer,
  usersDropdownData: usersDropdownReducer,
  singleUserData: singleUserReducer,
  editUserData: editUserReducer,
  addUserData: addUserReducer,
  deleteUserData: deleteUserReducer,
  usersSearchList: usersSearchList,

  fetchCalendarEventKindData: fetchCalendarEventKindReducer,
  fetchCalendarEventData: fetchCalendarEventReducer,
  deleteCalendarEventData: deleteCalendarEventReducer,
  createCalendarEventData: createCalendarEventReducer,

  announcementsData: announcementsReducer,
  emailAnnouncementsData: getEmailReducer,
  announcementDetailsData: announcementDetailsReducer,
  announcementDetailsEmailData: announcementDetailsEmailReducer,
  announcementsByIdReducer: announcementsByIdReducer,
  createAnnouncementsData: createAnnouncementsReducer,

  restaurantsData: restaurantsReducer,
  createRestaurant: createRestaurantReducer,
  updateRestaurant: updateRestaurantReducer,
  deleteRestaurant: deleteRestaurantReducer,

  categoriesData: categoriesReducer,
  categoryDetails: categoryDetailReducer,
  categoryUpdateData: categoryUpdateReducer,
  categoryCreateData: categoryCreateReducer,
  categoryDeleteData: categoryDeleteReducer,

  groupsData: groupsReducer,
  createLogsData: createLogsReducer,
  whetherData: whetherReducer,
  logRecordsFieldData: logRecordsFieldReducer,
  createLogRecordValueData: createLogRecordValueReducer,
  fetchLogRecordValueData: fetchLogRecordValueReducer,
  fetchLogRecordData: fetchLogRecordReducer,
  deleteLogRecordData: deleteLogRecordReducer,
  deleteLogRecordValueData: deleteLogRecordValueReducer,
  updateLogRecordValueData: updateLogRecordValueReducer,
  updateLogRecordData: updateLogRecordReducer,
  fetchLogRecordValueDetailData: fetchLogRecordValueDetailReducer,
  logReportData: logReportReducer,

  myQuizData: myQuizReducer,
  assignedQuizData: assignedQuizReducer,
  saveDraftData: saveDraftReducer,
  trainingQuizData: trainingQuizReducer,
  addQuizData: addQuizReducer,
  profileAssignedData: profileAssignedQuiz,

  fetchPermissionData: fetchPermissionReducer,
  createGroupsData: createGroupsReducer,
  deleteGroupsData: deleteGroupsReducer,
  editGroupData: editGroupReducer,

  todoActivity: todoActivityReducer,
  todoCreate: todoCreateReducer,
  todoUpdate: todoUpdateReducer,
  todoDelete: todoDeleteReducer,
  todoList: todoListReducer,
  todoDetail: todoDetailReducer,

  todoTaskCreate: todoTaskCreateReducer,
  todoTaskUpdate: todoTaskUpdateReducer,
  todoTaskDelete: todoTaskDeleteReducer,
  todoTaskList: todoTaskListReducer,
  todoTaskDetail: todoTaskDetailReducer,

  todoCommentCreate: todoCommentCreateReducer,
  todoCommentUpdate: todoCommentUpdateReducer,
  todoCommentDelete: todoCommentDeleteReducer,
  todoCommentList: todoCommentListReducer,
  todoCommentDetail: todoCommentDetailReducer,

  pickupRequest: pickupRequestReducer,
  dropRequest: dropRequestReducer,
  scheduleTemplate: scheduleTemplateReducer,
  schedule: scheduleReducer,
  shiftTime: shiftTimeReducer,
  shiftDayPart: shiftDayPartReducer,
  taskUserList: taskUserListReducer,
  deleteShiftTimeData: deleteShiftTimeReducer,
  createShiftTimeData: createShiftTimeReducer,
  updateShiftTimeData: updateShiftTimeReducer,
  createScheduleTemplateData: createScheduleTemplateReducer,
  editScheduleShiftData: editScheduleShiftReducer,
  deleteScheduleShiftData: deleteScheduleShiftReducer,
  addUserShiftData: addUserShiftReducer,
  createCopyScheduleWeek: createCopyScheduleWeekReducer,
  deleteScheduleTemplateData: deleteScheduleTemplateReducer,
  loadTemplateData: loadTemplateReducer,
  clearScheduleWeekData: clearScheduleWeekReducer,
  createDropRequestData: createDropRequestReducer,
  scheduleCountData: scheduleCountReducer,
  createPickupRequestData: createPickupRequestReducer,
  updatePickupRequestData: updatePickupRequestReducer,

  salesList: salesListReducer,

  globalSearch: globalSearchReducer,

  documentData: documentReducer,
  deleteDocumentData: deleteDocumentReducer,
  addDocumentData: addDocumentReducer,
  editDocumentData: editDocumentReducer,

  foodGalleryData: foodGalleryReducer,
  newFoodGalleryData: newFoodGalleryReducer,

  chatMessages: chatMessagesReducer,
  chatListReducer: chatListReducer,
  forgotPassword: forgotPasswordReducer,

  deviceToken: deviceTokenReducer,
  chatUserObject: chatUserObjectReducer,

  restaurantGroups: fetchRestaurantGroupsReducer,
  createRestaurantGroup: createRestaurantGroupReducer,

  sendMessageData: sendMessageReducer,
  chatUnreadCount: chatCountReducer,

  managerLogFilter: managerLogFilterReducer,
});

export default rootReducer;
