import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfile } from "../../actions/profile/profile";
import { fetchRestaurantGroups } from "../../actions/restaurantGroups/fetchRestaurantGroups";
import { customStyles, getPermission } from "../../modules/utils";
import { FaPlus } from "react-icons/fa";
import DataTable from "react-data-table-component";
import DeleteModal from "../common/DeleteModal";
import AddRestaurantGroupModal from "./modals/AddRestaurantGroupModal";
import { deleteRestaurantGroup } from "../../actions/restaurantGroups/deleteRestaurantGroup";
import { GrRestaurant } from "react-icons/gr";

export default function RestaurantGroup() {
  // const [sortType, setSortType] = useState("ASC");
  // const [sortField, setSortField] = useState();

  const dispatch = useDispatch();
  const restaurantGroupsData = useSelector(
    (state) => state.restaurantGroups.data
  );
  const restaurantGroupsDataLoading = useSelector(
    (state) => state.restaurantGroups.isLoading
  );
  const profileData = useSelector((state) => state.profileData.data);
  // const profileDataLoading = useSelector(
  //   (state) => state.profileData.isLoading
  // );

  const globalSearch = useSelector((state) => state.globalSearch.searchQuery);

  const [actionType, setActionType] = useState("");
  const [activeId, setActiveId] = useState("");
  const [activeName, setActiveName] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  useEffect(() => {
    dispatch(fetchRestaurantGroups());
    dispatch(fetchProfile());
  }, []);

  const getTableColumns = () => {
    return [
      {
        name: "TITLE",
        selector: (row) => row.title,
        sortable: true,
        minWidth: "500px",
      },
      {
        name: "ACTIONS",
        selector: (row) => row.actions,
        sortable: false,
        center: true,

        cell: (row) => {
          return (
            <React.Fragment>
              {getPermission("bshift.bs_can_edit_restaurants", profileData) && (
                <span
                  className="btn sm btn-outline-primary btn-sm"
                  onClick={() => toggleModal("update", row?.id, row?.title)}
                >
                  EDIT
                </span>
              )}
              {getPermission(
                "bshift.bs_can_delete_restaurants",
                profileData
              ) && (
                <span
                  className="btn sm btn-outline-secondary btn-sm ml-1"
                  onClick={() => {
                    setDeleteIsOpen(true);
                    setDeleteId(row?.id);
                  }}
                >
                  DELETE
                </span>
              )}
            </React.Fragment>
          );
        },
      },
    ];
  };

  const getRestaurantGroupListing = () => {
    // const { restaurantsData, globalSearch } = this.props;

    return restaurantGroupsData?.results
      ?.map((d) => {
        return {
          id: d?.id,
          title: d?.brand_name || "",
          restaurants: d?.restaurants,
        };
      })
      .filter((d) =>
        globalSearch ? d.brand_name?.toLowerCase().includes(globalSearch) : d
      );
    // .sort((a, b) =>
    //   this.state.sort_type === "ASC"
    //     ? a.brand_name.localeCompare(b.brand_name)
    //     : b.brand_name.localeCompare(a.brand_name)
    // );
  };

  const fetchRestaurantGroupsData = () => {
    dispatch(fetchRestaurantGroups());
  };

  const toggleModal = (type = "", restaurantId = "", restaurantName = "") => {
    setActionType(type);
    setActiveId(restaurantId);
    setActiveName(restaurantName);
    setIsOpen(!isOpen);
  };

  const deleteModalToggle = (id = "") => {
    setDeleteIsOpen(!deleteIsOpen);
    setDeleteId(id);
  };

  const deleteRestaurantGroupFunction = () => {
    dispatch(deleteRestaurantGroup(deleteId)).then(() => {
      deleteModalToggle();
      fetchRestaurantGroupsData();
    });
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <table className="p-3 table table-hover">
        <tbody>
          {data?.restaurants?.map((res) => {
            return (
              <tr key={res?.id}>
                <td className="d-flex align-items-center">
                  <GrRestaurant className="mx-3" />
                  {res?.title}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div className="main_box">
      <div className="middle_contain ">
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div className="container-fluid">
                <div className=" row mt-5 mb-5 justify-content-between page_title main_align_1 ">
                  <div className="col-md-6">
                    <h2>
                      <i className="zmdi zmdi-long-arrow-left"></i>Restaurant
                      Groups
                    </h2>
                  </div>

                  {getPermission(
                    "bshift.bs_can_add_restaurants",
                    profileData
                  ) && (
                    <div className="col-md-3">
                      <div className="main_info_select">
                        <button
                          type="button"
                          className="btn"
                          onClick={() => toggleModal("create")}
                        >
                          <span className="close_icon_report">
                            <FaPlus />
                          </span>
                          New Restaurant Group
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="restaurant_main">
                  <DataTable
                    className="table"
                    responsive={true}
                    striped={true}
                    progressPending={restaurantGroupsDataLoading}
                    columns={getTableColumns()}
                    data={getRestaurantGroupListing()}
                    pagination={true}
                    paginationServer={false}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    customStyles={customStyles}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <AddRestaurantGroupModal
          toggle={toggleModal}
          isOpen={isOpen}
          restaurantId={activeId}
          restaurantName={activeName}
          actionType={actionType}
          fetchList={fetchRestaurantGroupsData}
        />
      )}
      {deleteIsOpen && (
        <DeleteModal
          isOpen={deleteIsOpen}
          toggle={deleteModalToggle}
          deleteLog={deleteRestaurantGroupFunction}
          restaurantGroupDelete={true}
        />
      )}
    </div>
  );
}
