import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { isValidObject } from '../../../modules/utils';

import { fetchUsers } from '../../../actions/users/users';
import { fetchSaveDraft } from '../../../actions/training/saveDraft';
import Completed from '../common/Completed';
import Draft from '../common/Draft';
export class QuizModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			quizDataFields: [],
		};
	}

	componentDidMount() {
		this.props.fetchUsers();
		this.setState({ quizDataFields: this.props.quizData?.fields || [] });
	}

	handleChange = (id, e) => {
		const { fields } = this.props.quizData;
		const fieldId = e.target.name.split('_');

		const updatedVal = fields.map((data) => {
			if (data.id === fieldId[1]) {
				data.answer = e.target.value;
			}
			return data;
		});

		this.setState({ quizDataFields: updatedVal });
	};

	handleSaveDraft = (flag) => {
		const { quizData, fetchSaveDraft, toggle, getList } = this.props;
		const formData = {
			...this.props.quizData,
			fields: this.state.quizDataFields,
			quiz_status: flag ? 'Pending' : 'Employee Draft',
		};
		fetchSaveDraft(quizData?.id, formData).then(() => {
			getList();
			toggle();
			this.setState({
				quizDataFields: [],
			});
		});
	};

	handleCancel = () => {
		this.setState(
			{
				quizDataFields: [],
			},
			this.props.toggle,
		);
	};

	getDisabled = () => {
		const { quizDataFields } = this.state;
		const result = quizDataFields?.find((d) => d.answer === null);
		if (result) {
			return true;
		}
		return false;
	};

	render() {
		const { isOpen, toggle, usersData, quizData } = this.props;
		const { quizDataFields } = this.state;

		const created_by =
			isValidObject(usersData) &&
			usersData?.results.find((d) => {
				if (d.id === quizData?.created_by) return d;
			});
		const employee =
			isValidObject(usersData) &&
			usersData?.results.find((d) => {
				if (d.id === quizData?.employee) return d;
			});
		return (
			<Modal isOpen={isOpen} centered className="add_quize_modal">
				<ModalHeader toggle={toggle}>Quizzes</ModalHeader>
				<ModalBody>
					<div className="body_header">
						<p>
							<b>Created:</b>{' '}
							{moment(quizData?.created).format('MM/DD/YYYY, hh:mm')},
							{created_by?.full_name || ''}
						</p>
						<p>
							<b>Employee:</b> {employee.full_name},{' '}
							{moment(quizData?.due_date).format('MM/DD/YYYY, hh:mm')}
						</p>
					</div>
					<div>
						<p style={{ fontSize: '20px' }}>
							<b>Quiz Questions:</b>
						</p>
					</div>
					<div className="quiz_accordion card accordion_card">
						{quizData?.quiz_status === 'Completed' && (
							<Completed
								quizData={quizData}
								quizDataFields={quizDataFields}
								flag={true}
							/>
						)}

						{(quizData?.quiz_status === 'Employee Draft' ||
							quizData?.quiz_status === 'Awaiting') && (
							<Draft
								quizDataFields={quizDataFields}
								handleChange={this.handleChange}
								quizData={quizData}
							/>
						)}
					</div>
				</ModalBody>
				<ModalFooter>
					<div className="record_btn" onClick={this.handleCancel}>
						<button>CANCEL</button>
					</div>
					{quizData?.quiz_status !== 'Completed' && (
						<>
							<div className="record_btn">
								<button onClick={() => this.handleSaveDraft(false)}>
									SAVE DRAFT
								</button>
							</div>
							<div className={this.getDisabled() ? 'cancle_btn' : 'record_btn'}>
								<button
									onClick={() => this.handleSaveDraft(true)}
									disabled={this.getDisabled()}
								>
									SUBMIT
								</button>
							</div>
						</>
					)}
				</ModalFooter>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		usersDataLoading: state.usersData.isLoading,
		usersData: state.usersData.data,

		saveDraftDataLoading: state.saveDraftData.isLoading,
		saveDraftData: state.saveDraftData.data,
	};
};
const mapDispatchToProps = {
	fetchUsers,
	fetchSaveDraft,
};
export default connect(mapStateToProps, mapDispatchToProps)(QuizModal);
