import React, { Component } from 'react';
import {
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap';
import { connect } from 'react-redux';

import { fetchRestaurants } from '../../../actions/restaurants/restaurants';
import { fetchCreateDropRequest } from '../../../actions/schedule/createDropRequest';
import { fetchCreatePickupRequest } from '../../../actions/schedule/createPickupRequest';
import { fetchScheduleCount } from '../../../actions/schedule/scheduleCount';

import { strTimeFormat } from '../../../modules/utils';

import moment from 'moment';

class ShiftDropModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			note: '',
			isNote: false,
		};
	}

	componentDidMount() {
		this.props.fetchRestaurants();
	}

	handleChange = (e) => {
		this.setState(
			{
				note: e.target.value,
			},
			() => {
				const { note } = this.state;
				this.setState({
					isNote: note ? false : true,
				});
			},
		);
	};

	getRestaurants = (d) => {
		const { restaurantsData } = this.props;
		const restaurant = d.map((i) => {
			const value = restaurantsData?.results.find((data) => {
				if (data.id === i) return data;
			});
			return value?.title;
		});
		return restaurant.toString();
	};

	getPosition = (d) => {
		const position = d.map((i) => {
			return i.name;
		});
		return position.toString();
	};

	handleSubmit = () => {
		const { note } = this.state;
		const { shiftData, shiftType } = this.props;
		if (note) {
			if (shiftType === 'drop') {
				const apiVal = {
					employee_drop_request_note: note,
					shift_status: 'Drop Request',
				};
				this.props.fetchCreateDropRequest(shiftData?.id, apiVal).then(() => {
					this.props.fetchSchedule();
					this.props.toggle();
					this.props.fetchScheduleCount();
				});
			} else {
				const apiVal = {
					schedule: shiftData?.id,
					employee_note: note,
					pickup_request_status: 'Awaiting',
				};
				this.props.fetchCreatePickupRequest(apiVal).then(() => {
					this.props.fetchSchedule();
					this.props.toggle();
					this.props.fetchScheduleCount();
				});
			}
		} else {
			this.setState({
				isNote: true,
			});
		}
	};

	render() {
		const { isOpen, toggle, shiftData, shiftType } = this.props;
		const { isNote } = this.state;
		return (
			<Modal isOpen={isOpen} className="load_template_modal">
				<ModalHeader toggle={toggle}>Shift Drop Request</ModalHeader>
				<ModalBody>
					<div className="space mt-2">
						Shift date :&nbsp;
						<span className="space">
							{moment(shiftData?.date).format('MM/DD/YYYY')}
						</span>
					</div>
					<div className="space mt-2">
						Employee :&nbsp;
						<span className="space">{shiftData?.user?.full_name}</span>
					</div>
					<div className="space mt-2">
						Restaurant :&nbsp;
						<span className="space">
							{this.getRestaurants(shiftData?.user?.restaurants)}
						</span>
					</div>
					<div className="space mt-2">
						Position :&nbsp;
						<span className="space">
							{this.getPosition(shiftData?.user?.groups)}
						</span>
					</div>
					<div className="space mt-2">
						Shift Time :&nbsp;
						<span className="space">
							{strTimeFormat(shiftData?.time_start)}-
							{strTimeFormat(shiftData?.time_end)}
						</span>
					</div>
					<div className="space mt-2">
						Shift break :&nbsp;
						<span className="space">{shiftData?.shift_break}</span>
					</div>
					<div className="space mt-2">
						Shift note :&nbsp;
						<span className="space">{shiftData?.note}</span>
					</div>
					<div className="mt-5">
						<input
							type="text"
							value={this.state.note}
							onChange={(e) => this.handleChange(e)}
							name="note"
							placeholder={
								shiftType === 'drop'
									? 'Shift drop request note'
									: 'Shift pickup request note'
							}
						/>
						{isNote && <p style={{ color: 'red' }}>This field is required.</p>}
					</div>
				</ModalBody>
				<ModalFooter>
					<div className="record_btn" onClick={toggle}>
						<button>CANCEL</button>
					</div>
					<div className="record_btn" onClick={this.handleSubmit}>
						<button>SUBMIT</button>
					</div>
				</ModalFooter>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		restaurantsDataLoading: state.restaurantsData.isLoading,
		restaurantsData: state.restaurantsData.data,
	};
};
const mapDispatchToProps = {
	fetchRestaurants,
	fetchCreateDropRequest,
	fetchCreatePickupRequest,
	fetchScheduleCount,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShiftDropModal);
