import { postApi } from "../api";
import {
  FETCH_SEND_MESSAGE_REQUEST,
  FETCH_SEND_MESSAGE_SUCCESS,
  FETCH_SEND_MESSAGE_FAILURE,
} from "../../types/chat";

export const fetchSendMessageRequest = () => ({
  type: FETCH_SEND_MESSAGE_REQUEST,
});

export const fetchSendMessageSuccess = (data) => ({
  type: FETCH_SEND_MESSAGE_SUCCESS,
  data: data,
});

export const fetchSendMessageFailure = (error) => ({
  type: FETCH_SEND_MESSAGE_FAILURE,
  error,
});

export const fetchSendMessage = (apiData) => (dispatch, getState) => {
  dispatch(fetchSendMessageRequest());
  return postApi(`bshift/api/chat`, apiData)
    .then((data) => {
      dispatch(fetchSendMessageSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchSendMessageFailure(error));
    });
};
