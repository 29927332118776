import {
  FETCH_EDIT_USER_REQUEST,
  FETCH_EDIT_USER_SUCCESS,
  FETCH_EDIT_USER_FAILURE,
} from "../../types/users";
import { putApi } from "../api";
import { toast } from "react-toastify";

export const fetchEditUserRequest = () => ({
  type: FETCH_EDIT_USER_REQUEST,
});

export const fetchEditUserSuccess = (data) => ({
  type: FETCH_EDIT_USER_SUCCESS,
  data: data,
});

export const fetchEditUserFailure = (error) => ({
  type: FETCH_EDIT_USER_FAILURE,
  error,
});

export const fetchEditUser = (id, apiData) => (dispatch, getState) => {
  dispatch(fetchEditUserRequest());
  return putApi(`users/api/users/${id}`, apiData)
    .then((data) => {
      dispatch(fetchEditUserSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchEditUserFailure(error));
    });
};
