import {
	FETCH_CREATE_TODO_COMMENT_REQUEST,
	FETCH_CREATE_TODO_COMMENT_SUCCESS,
	FETCH_CREATE_TODO_COMMENT_FAILURE,
} from '../../types/todo';
import { postApi } from '../api';

export const fetchCreateTodoCommentRequest = () => ({
	type: FETCH_CREATE_TODO_COMMENT_REQUEST,
});

export const fetchCreateTodoCommentSuccess = (data) => ({
	type: FETCH_CREATE_TODO_COMMENT_SUCCESS,
	data: data,
});

export const fetchCreateTodoCommentFailure = (error) => ({
	type: FETCH_CREATE_TODO_COMMENT_FAILURE,
	error,
});

export const fetchCreateTodoComment = (apiData) => (dispatch) => {
	dispatch(fetchCreateTodoCommentRequest());
	return postApi(`restaurant/api/todo_comment_create`, apiData)
		.then((data) => {
			dispatch(fetchCreateTodoCommentSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchCreateTodoCommentFailure(error));
		});
};
