import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createRestaurantGroup } from "../../../actions/restaurantGroups/createRestaurantGroup";
import { displayFormErrors } from "../../../modules/validation";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { updateRestaurantGroup } from "../../../actions/restaurantGroups/editRestaurantGroup";

export default function AddRestaurantGroupModal({
  isOpen,
  toggle,
  actionType,
  fetchList,
  restaurantId,
  restaurantName,
}) {
  const [name, setName] = useState(restaurantName);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    if (actionType === "update") {
      dispatch(
        updateRestaurantGroup(restaurantId, {
          brand_name: values.name,
        })
      ).then(() => {
        fetchList();
        toggle();
      });
    } else {
      dispatch(
        createRestaurantGroup({
          brand_name: values?.name,
        })
      ).then(() => {
        fetchList();
        toggle();
      });
    }
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        {actionType === "create" ? "New" : "Edit"} Restaurant Group
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            name: name,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("This field is required."),
          })}
          onSubmit={handleSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            submitCount,
          }) => {
            const showError = (key) =>
              displayFormErrors(key, errors, touched, submitCount);
            return (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group add_modal_input">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        onChange={handleChange}
                        value={values.name}
                      />
                    </div>
                    {showError("name")}
                  </div>
                  <div className="col-md-12">
                    <div className="row justify-content-end">
                      <div className="col-md-12 d-flex record_btn res_btn">
                        <button className="" onClick={toggle}>
                          CANCEL
                        </button>
                        <button className="" onClick={handleSubmit}>
                          SAVE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
}
