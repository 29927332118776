import {
	CREATE_MANAGER_LOGS_REQUEST,
	CREATE_MANAGER_LOGS_FAILURE,
	CREATE_MANAGER_LOGS_SUCCESS,
} from '../../types/managerLogs';
import { postApi } from '../api';

export const createManagerLogsRequest = () => ({
	type: CREATE_MANAGER_LOGS_REQUEST,
});

export const createManagerLogsSuccess = (data) => ({
	type: CREATE_MANAGER_LOGS_SUCCESS,
	data: data,
});

export const createManagerLogsFailure = (error) => ({
	type: CREATE_MANAGER_LOGS_FAILURE,
	error,
});

export const createManagerLogs = (formData) => (dispatch, getState) => {
	dispatch(createManagerLogsRequest());

	return postApi('bshift/api/logrecord', formData)
		.then((data) => {
			dispatch(createManagerLogsSuccess(data));
		})
		.catch((error) => {
			dispatch(createManagerLogsFailure(error));
		});
};
