import { toast } from "react-toastify";
import {
  FETCH_ADD_USER_REQUEST,
  FETCH_ADD_USER_SUCCESS,
  FETCH_ADD_USER_FAILURE,
} from "../../types/users";
import { postApi } from "../api";

export const fetchAddUserRequest = () => ({
  type: FETCH_ADD_USER_REQUEST,
});

export const fetchAddUserSuccess = (data) => ({
  type: FETCH_ADD_USER_SUCCESS,
  data: data,
});

export const fetchAddUserFailure = (error) => ({
  type: FETCH_ADD_USER_FAILURE,
  error,
});

export const fetchAddUser = (apiData) => (dispatch, getState) => {
  dispatch(fetchAddUserRequest());
  return postApi("users/api/users", apiData)
    .then((data) => {
      toast.success("User added successfully");
      dispatch(fetchAddUserSuccess(data));
    })
    .catch((error) => {
      //   console.log("This is error: ", error.response?.data.map(item=>));
      toast.error("Something went wrong in creating a new user");
      dispatch(fetchAddUserFailure(error));
    });
};
