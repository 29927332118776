import * as types from "../types/deviceToken";

const initialState = {
  token: "",
};

const deviceTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DEVICE_TOKEN:
      return {
        ...state,
        token: action.data,
      };
    default:
      return state;
  }
};

export default deviceTokenReducer;
