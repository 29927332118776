import {
	FETCH_SCHEDULE_TEMPLATE_REQUEST,
	FETCH_SCHEDULE_TEMPLATE_SUCCESS,
	FETCH_SCHEDULE_TEMPLATE_FAILURE,
} from '../../types/schedule';
import { getApi } from '../api';

export const fetchScheduleTemplateRequest = () => ({
	type: FETCH_SCHEDULE_TEMPLATE_REQUEST,
});

export const fetchScheduleTemplateSuccess = (data) => ({
	type: FETCH_SCHEDULE_TEMPLATE_SUCCESS,
	data: data,
});

export const fetchScheduleTemplateFailure = (error) => ({
	type: FETCH_SCHEDULE_TEMPLATE_FAILURE,
	error,
});

export const fetchScheduleTemplate = (data) => (dispatch, getState) => {
	dispatch(fetchScheduleTemplateRequest());

	return getApi(`bshift/api/schedule_template`)
		.then((data) => {
			dispatch(fetchScheduleTemplateSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchScheduleTemplateFailure(error));
		});
};
