import {
	FETCH_SHIFT_TIME_REQUEST,
	FETCH_SHIFT_TIME_SUCCESS,
	FETCH_SHIFT_TIME_FAILURE,
} from '../../types/schedule';
import { getApi } from '../api';

export const fetchShiftTimeRequest = () => ({
	type: FETCH_SHIFT_TIME_REQUEST,
});

export const fetchShiftTimeSuccess = (data) => ({
	type: FETCH_SHIFT_TIME_SUCCESS,
	data: data,
});

export const fetchShiftTimeFailure = (error) => ({
	type: FETCH_SHIFT_TIME_FAILURE,
	error,
});

export const fetchShiftTime = (data) => (dispatch, getState) => {
	dispatch(fetchShiftTimeRequest());

	return getApi(`bshift/api/shifttime`)
		.then((data) => {
			dispatch(fetchShiftTimeSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchShiftTimeFailure(error));
		});
};
