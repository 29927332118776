import {
  FETCH_ADD_USER_SHIFT_REQUEST,
  FETCH_ADD_USER_SHIFT_SUCCESS,
  FETCH_ADD_USER_SHIFT_FAILURE,
} from "../../types/schedule";
import { postApi } from "../api";

export const fetchAddUserShiftRequest = () => ({
  type: FETCH_ADD_USER_SHIFT_REQUEST,
});

export const fetchAddUserShiftSuccess = (data) => ({
  type: FETCH_ADD_USER_SHIFT_SUCCESS,
  data: data,
});

export const fetchAddUserShiftFailure = (error) => ({
  type: FETCH_ADD_USER_SHIFT_FAILURE,
  error,
});

export const fetchAddUserShift = (date, data) => (dispatch, getState) => {
  dispatch(fetchAddUserShiftRequest());

  return postApi(`bshift/api/schedule/${date}`, data)
    .then((data) => {
      dispatch(fetchAddUserShiftSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchAddUserShiftFailure(error));
    });
};
