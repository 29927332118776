import {
	FETCH_CREATE_DROP_REQUEST_REQUEST,
	FETCH_CREATE_DROP_REQUEST_SUCCESS,
	FETCH_CREATE_DROP_REQUEST_FAILURE,
} from '../../types/schedule';
import { putApi } from '../api';

export const fetchCreateDropRequestRequest = () => ({
	type: FETCH_CREATE_DROP_REQUEST_REQUEST,
});

export const fetchCreateDropRequestSuccess = (data) => ({
	type: FETCH_CREATE_DROP_REQUEST_SUCCESS,
	data: data,
});

export const fetchCreateDropRequestFailure = (error) => ({
	type: FETCH_CREATE_DROP_REQUEST_FAILURE,
	error,
});

export const fetchCreateDropRequest = (id, data) => (dispatch, getState) => {
	dispatch(fetchCreateDropRequestRequest());

	return putApi(`bshift/api/schedule_drop_request/${id}`, data)
		.then((data) => {
			dispatch(fetchCreateDropRequestSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchCreateDropRequestFailure(error));
		});
};
