import { toast } from 'react-toastify';
import {
	FETCH_RESTAURANT_UPDATE_REQUEST,
	FETCH_RESTAURANT_UPDATE_SUCCESS,
	FETCH_RESTAURANT_UPDATE_FAILURE,
} from '../../types/restaurants';
import { putApi } from '../api';

export const fetchUpdateRestaurantRequest = () => ({
	type: FETCH_RESTAURANT_UPDATE_REQUEST,
});

export const fetchUpdateRestaurantSuccess = (data) => ({
	type: FETCH_RESTAURANT_UPDATE_SUCCESS,
	data: data,
});

export const fetchUpdateRestaurantFailure = (error) => ({
	type: FETCH_RESTAURANT_UPDATE_FAILURE,
	error,
});

export const fetchUpdateRestaurant = (id, apiData) => (dispatch, getState) => {
	dispatch(fetchUpdateRestaurantRequest());
	return putApi(`bshift/api/restaurants/${id}`, apiData)
		.then((data) => {
			toast.success('Record updated successfully!');
			dispatch(fetchUpdateRestaurantSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchUpdateRestaurantFailure(error));
		});
};
