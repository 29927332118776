import {
	FETCH_DELETE_SCHEDULE_TEMPLATE_REQUEST,
	FETCH_DELETE_SCHEDULE_TEMPLATE_SUCCESS,
	FETCH_DELETE_SCHEDULE_TEMPLATE_FAILURE,
} from '../../types/schedule';
import { deleteApi } from '../api';

export const fetchDeleteScheduleTemplateRequest = () => ({
	type: FETCH_DELETE_SCHEDULE_TEMPLATE_REQUEST,
});

export const fetchDeleteScheduleTemplateSuccess = (data) => ({
	type: FETCH_DELETE_SCHEDULE_TEMPLATE_SUCCESS,
	data: data,
});

export const fetchDeleteScheduleTemplateFailure = (error) => ({
	type: FETCH_DELETE_SCHEDULE_TEMPLATE_FAILURE,
	error,
});

export const fetchDeleteScheduleTemplate = (id) => (dispatch, getState) => {
	dispatch(fetchDeleteScheduleTemplateRequest());

	return deleteApi(`bshift/api/schedule_template/${id}`)
		.then((data) => {
			dispatch(fetchDeleteScheduleTemplateSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchDeleteScheduleTemplateFailure(error));
		});
};
