import React, { useEffect, useState } from "react";
import moment from "moment";
import { getRoundValue } from "../../modules/utils";

export default function InventoryCalc({
  restaurant,
  startDate,
  endDate,
  setInventoryVariance,
  setBeginInv,
  setEndInv,
}) {
  const [lastInv, setLastInv] = useState({
    dairy: 0,
    meat: 0,
    fish: 0,
    grocery: 0,
    produce: 0,
    non_alc: 0,
    bread_and_pastry: 0,
    food: 0,
    total: 0,
  });

  const [newInv, setNewInv] = useState({
    dairy: 0,
    meat: 0,
    fish: 0,
    grocery: 0,
    produce: 0,
    non_alc: 0,
    bread_and_pastry: 0,
    food: 0,
    total: 0,
  });

  const [variance, setVariance] = useState({
    dairy: 0,
    meat: 0,
    fish: 0,
    grocery: 0,
    produce: 0,
    non_alc: 0,
    bread_and_pastry: 0,
    food: 0,
    total: 0,
  });

  useEffect(() => {
    setInventoryVariance(variance?.total);
  }, [variance]);

  useEffect(() => {
    setBeginInv(lastInv?.total);
  }, [lastInv]);

  useEffect(() => {
    setEndInv(newInv?.total);
  }, [newInv]);

  const lastInvHandler = (e) => {
    const newObj = { ...lastInv };

    newObj[e.target.name] = e.target.value;

    newObj["total"] = getRoundValue(
      Number(newObj.dairy) +
        Number(newObj.meat) +
        Number(newObj.fish) +
        Number(newObj.grocery) +
        Number(newObj.produce) +
        Number(newObj.non_alc) +
        Number(newObj.bread_and_pastry) +
        Number(newObj.food)
    );

    const varObj = { ...variance };

    varObj[e.target.name] = getRoundValue(
      Number(newObj[e.target.name]) - Number(newInv[e.target.name])
    );

    setLastInv(newObj);
    setVariance(varObj);
  };

  const newInvHandler = (e) => {
    const newObj = { ...newInv };
    newObj[e.target.name] = e.target.value;

    const varObj = { ...variance };

    newObj["total"] = getRoundValue(
      Number(newObj.dairy) +
        Number(newObj.meat) +
        Number(newObj.fish) +
        Number(newObj.grocery) +
        Number(newObj.produce) +
        Number(newObj.non_alc) +
        Number(newObj.bread_and_pastry) +
        Number(newObj.food)
    );

    varObj[e.target.name] = getRoundValue(
      Number(lastInv[e.target.name]) - Number(newObj[e.target.name])
    );

    varObj["total"] = getRoundValue(
      Number(varObj.dairy) +
        Number(varObj.meat) +
        Number(varObj.fish) +
        Number(varObj.grocery) +
        Number(varObj.produce) +
        Number(varObj.non_alc) +
        Number(varObj.bread_and_pastry) +
        Number(varObj.food)
    );
    setNewInv(newObj);
    setVariance(varObj);
  };

  return (
    <div className="row my-4">
      <div className="col-md-12">
        <div className="table-responsive">
          <table
            className="table table-hover text-center table-bordered"
            style={{
              boxShadow: "5px 5px 10px #4b4b4b",
              backgroundColor: "#dadae5 ",
            }}
          >
            <thead className="custom-table-calculator">
              <tr className="calc_tr">
                <th style={{ width: "25%" }}>
                  Restaurant
                  <br />
                  {restaurant?.label}
                </th>
                <th style={{ width: "25%" }}>
                  Last Week's Inventory <br />{" "}
                  {moment(startDate).format("MM/DD/YYYY")}
                </th>
                <th style={{ width: "25%" }}>
                  This Week's Inventory <br />{" "}
                  {moment(endDate).format("MM/DD/YYYY")}
                </th>
                <th style={{ width: "25%" }}>Variance</th>
              </tr>
            </thead>
            <tbody className="custom-table-calculator">
              <tr className="calc_tr">
                <td className="calc_td">
                  <b>Dairy 5101-000</b>
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="dairy"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => lastInvHandler(e)}
                  />
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="dairy"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => newInvHandler(e)}
                  />
                </td>
                <td className="calc_td">{variance?.dairy}</td>
              </tr>
              <tr className="calc_tr">
                <td className="calc_td">
                  <b>Meat 5102-000</b>
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="meat"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => lastInvHandler(e)}
                  />
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="meat"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => newInvHandler(e)}
                  />
                </td>
                <td className="calc_td">{variance?.meat}</td>
              </tr>
              <tr className="calc_tr">
                <td className="calc_td">
                  <b>Fish 5103-000</b>
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="fish"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => lastInvHandler(e)}
                  />
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="fish"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => newInvHandler(e)}
                  />
                </td>
                <td className="calc_td">{variance?.fish}</td>
              </tr>
              <tr className="calc_tr">
                <td className="calc_td">
                  <b>Grocery 5104-000</b>
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="grocery"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => lastInvHandler(e)}
                  />
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="grocery"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => newInvHandler(e)}
                  />
                </td>
                <td className="calc_td">{variance?.grocery}</td>
              </tr>
              <tr className="calc_tr">
                <td className="calc_td">
                  <b>Produce 5105-000</b>
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="produce"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => lastInvHandler(e)}
                  />
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="produce"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => newInvHandler(e)}
                  />
                </td>
                <td className="calc_td">{variance?.produce}</td>
              </tr>
              <tr className="calc_tr">
                <td className="calc_td">
                  <b>Non Alc 5106-000</b>
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="non_alc"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => lastInvHandler(e)}
                  />
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="non_alc"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => newInvHandler(e)}
                  />
                </td>
                <td className="calc_td">{variance?.non_alc}</td>
              </tr>
              <tr className="calc_tr">
                <td className="calc_td">
                  <b>Bread and Pastry 5107-000</b>
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="bread_and_pastry"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => lastInvHandler(e)}
                  />
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="bread_and_pastry"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => newInvHandler(e)}
                  />
                </td>
                <td className="calc_td">{variance?.bread_and_pastry}</td>
              </tr>
              <tr className="calc_tr">
                <td className="calc_td">
                  <b>Food 5100-000</b>
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="food"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => lastInvHandler(e)}
                  />
                </td>
                <td className="calc_td">
                  <input
                    type="number"
                    name="food"
                    placeholder="Enter Value"
                    className="text-center"
                    min={0}
                    onChange={(e) => newInvHandler(e)}
                  />
                </td>
                <td className="calc_td">{variance?.food}</td>
              </tr>
              <tr className="calc_tr">
                <td className="calc_td">
                  <b>Total</b>
                </td>
                <td>{lastInv?.total}</td>
                <td>{newInv?.total}</td>
                <td>{variance?.total}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
