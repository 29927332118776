export const FETCH_GROUPS_REQUEST = 'FETCH_GROUPS_REQUEST';
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';

export const CREATE_GROUPS_REQUEST = 'CREATE_GROUPS_REQUEST';
export const CREATE_GROUPS_FAILURE = 'CREATE_GROUPS_FAILURE';
export const CREATE_GROUPS_SUCCESS = 'CREATE_GROUPS_SUCCESS';

export const DELETE_GROUPS_REQUEST = 'DELETE_GROUPS_REQUEST';
export const DELETE_GROUPS_FAILURE = 'DELETE_GROUPS_FAILURE';
export const DELETE_GROUPS_SUCCESS = 'DELETE_GROUPS_SUCCESS';

export const FETCH_EDIT_GROUPS_REQUEST = 'FETCH_EDIT_GROUPS_REQUEST';
export const FETCH_EDIT_GROUPS_FAILURE = 'FETCH_EDIT_GROUPS_FAILURE';
export const FETCH_EDIT_GROUPS_SUCCESS = 'FETCH_EDIT_GROUPS_SUCCESS';
