import React from "react";

const Draft = ({ quizDataFields, handleChange, quizData }) => {
  return (
    <>
      {quizDataFields.map((d, i) => {
        return (
          <>
            <label className="tab-label" htmlFor="rd1">
              {d.title}
            </label>
            {d.field_type === "dropdown" ? (
              <div className="form-group select_body" key={d.id}>
                <label htmlFor="exampleFormControlSelect1">Select Option</label>
                <select
                  name={`name_${d.id}`}
                  className="form-control"
                  value={d.answer}
                  onChange={(e) => handleChange(d.id, e)}
                >
                  <option value="">Select Option</option>
                  {d.choices.split("\r\n").map((d) => {
                    return <option value={d}>{d}</option>;
                  })}
                </select>
              </div>
            ) : (
              <div className="form-group" key={d.id}>
                <label htmlFor="exampleFormControlTextarea1">Answer</label>
                <textarea
                  className="form-control"
                  // id={d.id}
                  name={`name_${d.id}`}
                  // rows="3"
                  value={d.answer}
                  onChange={(e) => handleChange(d.id, e)}
                >
                  {d.answer}
                </textarea>
              </div>
            )}
          </>
        );
      })}
    </>
  );
};

export default Draft;
