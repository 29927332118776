import { toastError, toastSuccess } from '../../modules/utils';
import {
	FETCH_UPDATE_TODO_TASK_REQUEST,
	FETCH_UPDATE_TODO_TASK_SUCCESS,
	FETCH_UPDATE_TODO_TASK_FAILURE,
} from '../../types/todo';
import { putApi } from '../api';

export const fetchUpdateTodoTaskRequest = () => ({
	type: FETCH_UPDATE_TODO_TASK_REQUEST,
});

export const fetchUpdateTodoTaskSuccess = (data) => ({
	type: FETCH_UPDATE_TODO_TASK_SUCCESS,
	data: data,
});

export const fetchUpdateTodoTaskFailure = (error) => ({
	type: FETCH_UPDATE_TODO_TASK_FAILURE,
	error,
});

export const fetchUpdateTodoTask = (id, apiData) => (dispatch) => {
	dispatch(fetchUpdateTodoTaskRequest());
	return putApi(`restaurant/api/todo_task_update/${id}`, apiData)
		.then((data) => {
			toastSuccess('Record updated successfully.');
			dispatch(fetchUpdateTodoTaskSuccess(data));
		})
		.catch((error) => {
			toastError('Something went wrong!');
			dispatch(fetchUpdateTodoTaskFailure(error));
		});
};
