import {
	FETCH_TODO_ACTIVITY_REQUEST,
	FETCH_TODO_ACTIVITY_FAILURE,
	FETCH_TODO_ACTIVITY_SUCCESS,
} from '../../types/todo';
import { getApi } from '../api';

export const fetchTodoActivityRequest = () => ({
	type: FETCH_TODO_ACTIVITY_REQUEST,
});

export const fetchTodoActivitySuccess = (data) => ({
	type: FETCH_TODO_ACTIVITY_SUCCESS,
	data: data,
});

export const fetchTodoActivityFailure = (error) => ({
	type: FETCH_TODO_ACTIVITY_FAILURE,
	error,
});

export const fetchTodoActivity = (apiData) => (dispatch) => {
	dispatch(fetchTodoActivityRequest());
	return getApi(`restaurant/api/activity_list`, apiData)
		.then((data) => {
			dispatch(fetchTodoActivitySuccess(data));
		})
		.catch((error) => {
			dispatch(fetchTodoActivityFailure(error));
		});
};
