import React, { Component } from "react";

import {
  Input,
  InputGroup,
} from "reactstrap";
// import { CKEditor } from "ckeditor4-react";
// import TagsInput from 'react-tagsinput';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-tagsinput/react-tagsinput.css";
import { fetchUsersDropdown } from "../../../actions/users/userDropdownData";

import { fetchLogTagSearch } from "../../../actions/logTagSearch/fetchLogTagSearch";

import { isValidArray, isValidObject } from "../../../modules/utils";
import { FaTrash } from "react-icons/fa";

import Loader from "react-loader-spinner";
import EditRecordModal from "./EditRecordModal";
import AddRecordModal from "./AddRecordModal";
// const API_URL = "http://3.132.219.157:8000/bshift/api";
// const UPLOAD_ENDPOINT = "editorimage";
export class TextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      initData: "",
      tags: [],
      selectedFile: "",
      imageFile: "",
      deleteIds: [],
      optionTag: [],
      tagValue: [],
      option: [],
      fileArray: [],
      fileInputKey: Date.now(),
      load: false,
      editModal: null,
      addModal: null,
      editor: null,
      showButton: true,
      initialSelected: [],
    };
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { fetchLogRecordValueDetailData, id, restaurantId } = this.props;
    if (id === null) {
      this.setState({
        editModal: true,
        addModal: false,
      });
    } else {
      this.setState({
        editModal: false,
        addModal: true,
      });
    }

    this.props.fetchUsersDropdown(this.props.restaurantId);
    if (fetchLogRecordValueDetailData && id === null) {
      this.setState({
        value: id ? "" : fetchLogRecordValueDetailData?.value,
        tags: id
          ? ""
          : fetchLogRecordValueDetailData?.tags?.map((d) => {
              return d?.tag?.tag_name;
            }),
        optionTag: fetchLogRecordValueDetailData?.tags?.map((d) => {
          return {
            value: d?.tag?.id,
            label: d?.tag?.tag_name,
          };
        }),
        tagValue: fetchLogRecordValueDetailData?.tags?.map((d) => {
          return d?.tag?.id;
        }),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.usersDropdownData !== this.props.usersDropdownData) {
      this.setState({
        option: this.props.usersDropdownData.map((d, i) => {
          return {
            value: d.id,
            label: d.full_name,
          };
        }),
      });
    }
  }
  // editorText = (event) => {
  // 	const value = event.editor.getData();
  // 	this.setState({
  // 		value: value,
  // 	});
  // };

  onFileChange = (event) => {
    const files = event.target.files;
    this.setState({ selectedFile: files }, () => {
      this.getFileName();
    });
  };

  saveLogRecordValue = () => {
    const { value, tags, selectedFile, optionTag, imageFile } = this.state;
    const { log_id, id } = this.props;
    let formData = new FormData();

    if (imageFile) {
      formData.append("imageFileAttachment", imageFile);
    }
    formData.append("value", value);
    formData.append("modified_by", localStorage.getItem("loggedUserId"));
    formData.append("user", localStorage.getItem("loggedUserId"));
    formData.append("created_by", localStorage.getItem("loggedUserId"));
    formData.append("log_record", log_id);
    formData.append("log_field", id);
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append("added_files", selectedFile[i]);
    }

    optionTag?.forEach((tag) => {
      formData.append("tags[]", tag.label);
      // formData.append("tags[]", tag.value);
    });

    this.props.saveLogRecordValue(formData, log_id);

    // Add email here
    toast.success("Log sent successfully");
  };

  updateLogRecordValue = () => {
    const {
      value,
      tags,
      selectedFile,
      deleteIds,
      optionTag,
      imageFile,
    } = this.state;
    const { fetchLogRecordValueDetailData } = this.props;
    let formData = new FormData();

    if (imageFile) {
      formData.append("imageFileAttachment", imageFile);
    }
    formData.append("id", fetchLogRecordValueDetailData?.id);
    formData.append(
      "created_by",
      parseInt(localStorage.getItem("loggedUserId"))
    );
    formData.append(
      "modified_by",
      parseInt(localStorage.getItem("loggedUserId"))
    );
    formData.append("log_field", fetchLogRecordValueDetailData?.log_field);
    formData.append(
      "created_by_full_name",
      fetchLogRecordValueDetailData?.created_by_full_name
    );
    formData.append("files", "");
    formData.append("tags", "");
    formData.append("created", "");
    formData.append("modified", "2021-08-11T08:53:56.412465Z");
    formData.append("disabled", false);
    formData.append("visible", false);
    formData.append("value", value);
    formData.append("log_record", fetchLogRecordValueDetailData?.log_record);
    formData.append("user", fetchLogRecordValueDetailData?.created_by);
    for (let i = 0; i < selectedFile.length; i++) {
      formData.append("added_files", selectedFile[i]);
    }
    optionTag?.forEach((tag) => {
      formData.append("tags[]", tag.label);
      // formData.append("tags[]", tag.value);
    });
    deleteIds?.forEach((deleteId) => {
      formData.append("deleted_files", deleteId);
    });

    this.props.updateLogRecordValue(
      fetchLogRecordValueDetailData?.id,
      formData
    );
    toast.success("Log sent successfully");
  };

  deleteFiles = (deleteIds) => {
    this.setState({ deleteIds: [...this.state.deleteIds, deleteIds] });
  };

  openNewWindow = (url) => {
    window.open(url, "_blank");
  };

  getLogsListing = () => {
    const { fetchLogRecordValueDetailData } = this.props;
    const { deleteIds } = this.state;
    return (
      fetchLogRecordValueDetailData &&
      fetchLogRecordValueDetailData?.files
        ?.filter((data) => !deleteIds.includes(data.id))
        .map((d) => {
          return {
            id: d.id,
            name: d.file_name || "",
            attachment_file: d.attachment_file || "",
          };
        })
    );
  };

  getTableColumns = () => {
    return [
      {
        name: "FILE NAME",
        selector: "name",
        sortable: true,
        right: false,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            // onClick={() => this.openNewWindow(row.attachment_file)}
          >
            <a href={row.attachment_file} target="_blank">
              {row.name}
            </a>
          </div>
        ),
      },
      {
        name: "ACTIONS",
        selector: "actions",
        sortable: false,
        right: true,
        cell: (row) => (
          <span>
            <button
              value={row.id}
              className="btn btn-outline-secondary btn-sm"
              onClick={() => this.deleteFiles(row.id)}
            >
              <FaTrash />
            </button>
          </span>
        ),
      },
    ];
  };

  handleChangeOption = (optionTag) => {
    const tagValue = optionTag.map((d) => {
      return d.value;
    });
    this.setState({ optionTag, tagValue });
  };

  handleChangeInput = (e) => {
    if (e === "") {
      this.setState({
        option: this.props.usersDropdownData.map((d, i) => {
          return {
            value: d.id,
            label: d.full_name,
          };
        }),
      });
    } else {
      const { logTagSearchData } = this.props;
      this.props.fetchLogTagSearch(e).then(() => {
        this.setState({
          option: isValidArray(logTagSearchData?.results)
            ? logTagSearchData?.results.map((d, i) => {
                return {
                  value: d.tag_name,
                  label: d.tag_name,
                };
              })
            : [],
        });
      });
    }
  };

  getFileName = () => {
    const { selectedFile } = this.state;
    let fileArray = [];
    for (var i = 0; i < selectedFile.length; i++) {
      fileArray.push(selectedFile[i].name);
    }
    this.setState({ fileArray });
  };

  resetFile = () => {
    this.myRef.current.value = "";
  };

  handleClear = () => {
    this.setState({ selectedFile: "", fileInputKey: Date.now() });
  };

  // new
  // handleCk5change = (event, editor) => {
  //   const data = event.editor.getData();
  //   this.setState({
  //     value: data,
  //   });
  // };

  handleCk5change = (event, editor) => {
    const data = editor.getData();
    this.setState({
      value: data,
    });
  };

  render() {
    const {
      isOpen,
      toggle,
      id,
      fieldName,
      fetchLogRecordValueDetailDataLoading,
      logTagSearchData,
      logType,
      log_id,
    } = this.props;

    const { value, selectedFile, fileArray, load, showButton } = this.state;

    let fetchLogRecordValueDetailData;
    if (this.state.addModal) {
      fetchLogRecordValueDetailData = null;
    } else {
      fetchLogRecordValueDetailData = this.props.fetchLogRecordValueDetailData;
    }
    const setHideButton = () => {
      this.setState({
        showButton: false,
      });
    };

    const setShowButton = () => {
      this.setState({
        showButton: true,
      });
    };

    function uploadAdapter(loader) {
      setHideButton();

      return {
        upload: () => {
          return new Promise((resolve, reject) => {
            const body = new FormData();

            loader.file.then((file) => {
              body.append("photo", file);

              if (log_id) {
                body.append("log_id", log_id);
              }

              //fetch(`http://3.132.219.157:8000/bshift/api/editorimage`, {
              fetch(
                `https://admin.thebusinessshifts.com/bshift/api/editorimage`,
                {
                  method: "post",
                  body: body,
                }
              )
                .then((res) => res.json())
                .then((res) => {
                  setShowButton();
                  resolve({
                    default: `${res.image}`,
                  });
                })
                .catch((err) => {
                  reject(err);
                });
            });
          });
        },
      };
    }

    function uploadPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
      };
    }

    return (
      <>
        {isOpen && this.state.editModal && value && (
          <EditRecordModal>
            <>
              <div className="custom_modal_header">{fieldName}</div>
              <div className="custom_modal_body">
                <div>
                  <CKEditor
                    config={{
                      extraPlugins: [uploadPlugin],
                    }}
                    editor={ClassicEditor}
                    data={value}
                    // onInit={(editor) => {}}
                    onReady={(editor) => {}}
                    onChange={this.handleCk5change}
                    className="ckeditor-custom"
                  />
                </div>
                <div className="mt-4 add_tag_border">
                  <div className="select_input">
                    <Select
                      options={this.state.option}
                      isMulti
                      // value={this.state.optionTag}
                      defaultValue={this.state.optionTag}
                      onChange={(e) => this.handleChangeOption(e)}
                      onInputChange={(e) => this.handleChangeInput(e)}
                      placeholder="Select User"
                      isDisabled={!this.props.usersDropdownData.length > 0}
                      isSearchable={false}
                    />
                  </div>
                </div>
                <div className="mt-4 add_tag_border">
                  <InputGroup>
                    <Input
                      type="file"
                      onChange={this.onFileChange}
                      multiple
                      placeholder=" Select files to attach to your message"
                      ref={this.myRef}
                      key={this.state.fileInputKey}
                    />
                  </InputGroup>
                  {isValidObject(selectedFile) &&
                    fileArray.map((d) => {
                      return (
                        <p style={{ color: "gray" }} key={d}>
                          {d}
                        </p>
                      );
                    })}
                  {isValidObject(selectedFile) && (
                    <button
                      className="btn btn-primary"
                      onClick={this.handleClear}
                    >
                      Clear
                    </button>
                  )}
                </div>

                {isValidArray(fetchLogRecordValueDetailData?.files) &&
                  logType === "edit" && (
                    <div className="table_header_line data-table mt-5">
                      <DataTable
                        columns={this.getTableColumns()}
                        data={this.getLogsListing()}
                        progressPending={fetchLogRecordValueDetailDataLoading}
                        responsive
                        striped
                        pagination={true}
                        paginationServer={false}
                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                      />
                    </div>
                  )}
                <div className="custom_modal_footer">
                  <div className="record_btn " onClick={toggle}>
                    <button className="text-white">CANCEL</button>
                  </div>
                  {!this.state.showButton && (
                    <Loader
                      type="TailSpin"
                      color="#38425B"
                      // color="#2196f3"
                      height={30}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  )}
                  <div
                    className="record_btn"
                    onClick={
                      fetchLogRecordValueDetailData?.id
                        ? this.updateLogRecordValue
                        : this.saveLogRecordValue
                    }
                  >
                    <button
                      disabled={!this.state.showButton}
                      className={`${
                        !this.state.showButton
                          ? "custom_save_disable"
                          : "custom_save_enable"
                      }`}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </div>
            </>
          </EditRecordModal>
        )}

        {isOpen && this.state.addModal && (
          <AddRecordModal>
            <>
              <div className="custom_modal_header">{fieldName}</div>
              <div className="custom_modal_body">
                <div>
                  <CKEditor
                    config={{
                      extraPlugins: [uploadPlugin],
                    }}
                    editor={ClassicEditor}
                    data={value}
                    onReady={(editor) => {}}
                    // onInit={(editor) => {}}
                    onChange={this.handleCk5change}
                    className="ckeditor-custom"
                  />
                </div>
                <div className="mt-4 add_tag_border">
                  <div className="select_input">
                    <Select
                      options={this.state.option}
                      isMulti
                      // value={this.state.optionTag}
                      onChange={(e) => this.handleChangeOption(e)}
                      onInputChange={(e) => this.handleChangeInput(e)}
                      placeholder="Select User"
                      isDisabled={!this.props.usersDropdownData.length > 0}
                      isSearchable={false}
                    />
                  </div>
                </div>
                <div className="mt-4 add_tag_border">
                  <InputGroup>
                    <Input
                      type="file"
                      onChange={this.onFileChange}
                      multiple
                      placeholder=" Select files to attach to your message"
                      ref={this.myRef}
                      key={this.state.fileInputKey}
                    />
                  </InputGroup>
                  {isValidObject(selectedFile) &&
                    fileArray.map((d) => {
                      return (
                        <p style={{ color: "gray" }} key={d}>
                          {d}
                        </p>
                      );
                    })}
                  {isValidObject(selectedFile) && (
                    <button
                      className="btn btn-primary"
                      onClick={this.handleClear}
                    >
                      Clear
                    </button>
                  )}
                </div>

                {isValidArray(fetchLogRecordValueDetailData?.files) &&
                  logType === "edit" && (
                    <div className="table_header_line data-table mt-5">
                      <DataTable
                        columns={this.getTableColumns()}
                        data={this.getLogsListing()}
                        progressPending={fetchLogRecordValueDetailDataLoading}
                        responsive
                        striped
                        pagination={true}
                        paginationServer={false}
                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                      />
                    </div>
                  )}

                <div className="custom_modal_footer">
                  <div className="record_btn" onClick={toggle}>
                    <button>CANCEL</button>
                  </div>
                  {!this.state.showButton && (
                    <>
                      <p className="text-primary py-2 mb-0">
                        Image is uploading...
                      </p>
                      <Loader
                        type="TailSpin"
                        color="#38425B"
                        // color="#2196f3"
                        height={30}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      />
                    </>
                  )}
                  <div
                    className="record_btn"
                    onClick={
                      fetchLogRecordValueDetailData?.id
                        ? this.updateLogRecordValue
                        : this.saveLogRecordValue
                    }
                  >
                    <button
                      disabled={!this.state.showButton}
                      className={`${
                        !this.state.showButton
                          ? "custom_save_disable"
                          : "custom_save_enable"
                      }`}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </div>
            </>
          </AddRecordModal>
        )}

        {/* NEWWWWWWWWWWWWWWWWWWWWWWW */}
        {/* Edit Modal */}
        {/* {isOpen && this.state.editModal && value && (
          <div
            // isOpen={this.state.textEditorToggle}
            className="text_edit_model edit-model new_edit_modal"
          >
            <div className="custom_modal_header">{fieldName}</div>
            <div className="custom_modal_body">
              <div>
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={ClassicEditor}
                  data={value}
                  // onInit={(editor) => {}}
                  onReady={(editor) => {}}
                  onChange={this.handleCk5change}
                  className="ckeditor-custom"
                />
              </div>
              <div className="mt-4 add_tag_border">
                <div className="select_input">
                  <Select
                    options={this.state.option}
                    isMulti
                    value={this.state.optionTag}
                    onChange={(e) => this.handleChangeOption(e)}
                    onInputChange={(e) => this.handleChangeInput(e)}
                    placeholder="Select option tag"
                    isDisabled={!this.props.usersDropdownData.length > 0}
                  />
                </div>
              </div>
              <div className="mt-4 add_tag_border">
                <InputGroup>
                  <Input
                    type="file"
                    onChange={this.onFileChange}
                    multiple
                    placeholder=" Select files to attach to your message"
                    ref={this.myRef}
                    key={this.state.fileInputKey}
                  />
                </InputGroup>
                {isValidObject(selectedFile) &&
                  fileArray.map((d) => {
                    return (
                      <p style={{ color: "gray" }} key={d}>
                        {d}
                      </p>
                    );
                  })}
                {isValidObject(selectedFile) && (
                  <button
                    className="btn btn-primary"
                    onClick={this.handleClear}
                  >
                    Clear
                  </button>
                )}
              </div>

              {isValidArray(fetchLogRecordValueDetailData?.files) &&
                logType === "edit" && (
                  <div className="table_header_line data-table mt-5">
                    <DataTable
                      columns={this.getTableColumns()}
                      data={this.getLogsListing()}
                      progressPending={fetchLogRecordValueDetailDataLoading}
                      responsive
                      striped
                      pagination={true}
                      paginationServer={false}
                      paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    />
                  </div>
                )}
            </div>
            <div className="custom_modal_footer">
              <div className="record_btn" onClick={toggle}>
                <button>CANCEL</button>
              </div>
              {!this.state.showButton && (
                <Loader
                  type="TailSpin"
                  color="#38425B"
                  // color="#2196f3"
                  height={30}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                />
              )}
              <div
                className="record_btn"
                onClick={
                  fetchLogRecordValueDetailData?.id
                    ? this.updateLogRecordValue
                    : this.saveLogRecordValue
                }
              >
                <button
                  disabled={!this.state.showButton}
                  className={`${
                    !this.state.showButton
                      ? "custom_save_disable"
                      : "custom_save_enable"
                  }`}
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        )} */}

        {/* NEWWWWWWWWWWWWWWWWWWWWWWW */}
        {/* Add Modal */}
        {/* {isOpen && !this.state.editModal && ( */}
        {/* {isOpen && this.state.addModal && (
          <div
            // isOpen={this.state.textEditorToggle}
            className="text_edit_model edit-model new_edit_modal"
          >
            <div className="custom_modal_header">{fieldName}</div>
            <div className="custom_modal_body">
              <div>
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugin],
                  }}
                  editor={ClassicEditor}
                  data={value}
                  onReady={(editor) => {}}
                  // onInit={(editor) => {}}
                  onChange={this.handleCk5change}
                  className="ckeditor-custom"
                />
              </div>
              <div className="mt-4 add_tag_border">
                <div className="select_input">
                  <Select
                    options={this.state.option}
                    isMulti
                    value={this.state.optionTag}
                    onChange={(e) => this.handleChangeOption(e)}
                    onInputChange={(e) => this.handleChangeInput(e)}
                    placeholder="Select option tag"
                    isDisabled={!this.props.usersDropdownData.length > 0}
                  />
                </div>
              </div>
              <div className="mt-4 add_tag_border">
                <InputGroup>
                  <Input
                    type="file"
                    onChange={this.onFileChange}
                    multiple
                    placeholder=" Select files to attach to your message"
                    ref={this.myRef}
                    key={this.state.fileInputKey}
                  />
                </InputGroup>
                {isValidObject(selectedFile) &&
                  fileArray.map((d) => {
                    return (
                      <p style={{ color: "gray" }} key={d}>
                        {d}
                      </p>
                    );
                  })}
                {isValidObject(selectedFile) && (
                  <button
                    className="btn btn-primary"
                    onClick={this.handleClear}
                  >
                    Clear
                  </button>
                )}
              </div>

              {isValidArray(fetchLogRecordValueDetailData?.files) &&
                logType === "edit" && (
                  <div className="table_header_line data-table mt-5">
                    <DataTable
                      columns={this.getTableColumns()}
                      data={this.getLogsListing()}
                      progressPending={fetchLogRecordValueDetailDataLoading}
                      responsive
                      striped
                      pagination={true}
                      paginationServer={false}
                      paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    />
                  </div>
                )}

              <div className="custom_modal_footer">
                <div className="record_btn" onClick={toggle}>
                  <button>CANCEL</button>
                </div>
                {!this.state.showButton && (
                  <>
                    <p className="text-primary py-2 mb-0">
                      Image is uploading...
                    </p>
                    <Loader
                      type="TailSpin"
                      color="#38425B"
                      // color="#2196f3"
                      height={30}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    />
                  </>
                )}
                <div
                  className="record_btn"
                  onClick={
                    fetchLogRecordValueDetailData?.id
                      ? this.updateLogRecordValue
                      : this.saveLogRecordValue
                  }
                >
                  <button
                    disabled={!this.state.showButton}
                    className={`${
                      !this.state.showButton
                        ? "custom_save_disable"
                        : "custom_save_enable"
                    }`}
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        )} */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logTagSearchDataLoading: state.logTagSearchData.isLoading,
    logTagSearchData: state.logTagSearchData.data,
    usersDropdownData: state.usersDropdownData?.data?.results || [],
  };
};
const mapDispatchToProps = {
  fetchLogTagSearch,
  fetchUsersDropdown,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);
