import React from "react";

export default function PrivacyPolicies() {
  return (
    <div className="container">
      <div className="main-cms-container text-center my-3 mx-5">
        <p style={{ fontSize: "32px" }}>Privacy Policy</p>
        <div className="cms-content" style={{ fontSize: "20px" }}>
          <p className="text-justify">
            The Business Shift is an easy-to-use and integrate software platform
            that works to increase performance in all areas of business. It's a
            central hub that houses your critical data – from employee
            scheduling to team trainings to labor costs to product inventory to
            budgeting to announcements to calendar events and even a chat
            function – and provides a simple, customizable report based on these
            areas enabling you to make efficient, data-informed decisions that
            ultimately support the bottom line.
          </p>
        </div>
      </div>
    </div>
  );
}
