import { toast } from "react-toastify";
import {
  FETCH_ADD_DOCUMENT_REQUEST,
  FETCH_ADD_DOCUMENT_SUCCESS,
  FETCH_ADD_DOCUMENT_FAILURE,
} from "../../types/document";
import { postApi } from "../api";

export const fetchAddDocumentRequest = () => ({
  type: FETCH_ADD_DOCUMENT_REQUEST,
});

export const fetchAddDocumentSuccess = (data) => ({
  type: FETCH_ADD_DOCUMENT_SUCCESS,
  data: data,
});

export const fetchAddDocumentFailure = (error) => ({
  type: FETCH_ADD_DOCUMENT_FAILURE,
  error,
});

export const fetchAddDocument = (formData) => (dispatch, getState) => {
  dispatch(fetchAddDocumentRequest());

  return postApi(`bshift/api/training_files`, formData)
    .then((data) => {
      toast.success("Document added successfully!");
      dispatch(fetchAddDocumentSuccess(data));
    })
    .catch((error) => {
      toast.error("The fields restaurant, title must make a unique set.");
      dispatch(fetchAddDocumentFailure(error));
    });
};
