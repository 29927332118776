export const FETCH_RESTAURANTS_REQUEST = 'FETCH_RESTAURANTS_REQUEST';
export const FETCH_RESTAURANTS_FAILURE = 'FETCH_RESTAURANTS_FAILURE';
export const FETCH_RESTAURANTS_SUCCESS = 'FETCH_RESTAURANTS_SUCCESS';

export const FETCH_RESTAURANT_CREATE_REQUEST =
	'FETCH_RESTAURANT_CREATE_REQUEST';
export const FETCH_RESTAURANT_CREATE_SUCCESS =
	'FETCH_RESTAURANT_CREATE_SUCCESS';
export const FETCH_RESTAURANT_CREATE_FAILURE =
	'FETCH_RESTAURANT_CREATE_FAILURE';

export const FETCH_RESTAURANT_UPDATE_REQUEST =
	'FETCH_RESTAURANT_UPDATE_REQUEST';
export const FETCH_RESTAURANT_UPDATE_SUCCESS =
	'FETCH_RESTAURANT_UPDATE_SUCCESS';
export const FETCH_RESTAURANT_UPDATE_FAILURE =
	'FETCH_RESTAURANT_UPDATE_FAILURE';

export const FETCH_RESTAURANT_DELETE_REQUEST =
	'FETCH_RESTAURANT_DELETE_REQUEST';
export const FETCH_RESTAURANT_DELETE_SUCCESS =
	'FETCH_RESTAURANT_DELETE_SUCCESS';
export const FETCH_RESTAURANT_DELETE_FAILURE =
	'FETCH_RESTAURANT_DELETE_FAILURE';
