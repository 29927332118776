export const FETCH_DOCUMENT_REQUEST = 'FETCH_DOCUMENT_REQUEST';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_FAILURE = 'FETCH_DOCUMENT_FAILURE';

export const FETCH_DELETE_DOCUMENT_REQUEST = 'FETCH_DELETE_DOCUMENT_REQUEST';
export const FETCH_DELETE_DOCUMENT_SUCCESS = 'FETCH_DELETE_DOCUMENT_SUCCESS';
export const FETCH_DELETE_DOCUMENT_FAILURE = 'FETCH_DELETE_DOCUMENT_FAILURE';

export const FETCH_ADD_DOCUMENT_REQUEST = 'FETCH_ADD_DOCUMENT_REQUEST';
export const FETCH_ADD_DOCUMENT_SUCCESS = 'FETCH_ADD_DOCUMENT_SUCCESS';
export const FETCH_ADD_DOCUMENT_FAILURE = 'FETCH_ADD_DOCUMENT_FAILURE';

export const FETCH_EDIT_DOCUMENT_REQUEST = 'FETCH_EDIT_DOCUMENT_REQUEST';
export const FETCH_EDIT_DOCUMENT_SUCCESS = 'FETCH_EDIT_DOCUMENT_SUCCESS';
export const FETCH_EDIT_DOCUMENT_FAILURE = 'FETCH_EDIT_DOCUMENT_FAILURE';
