import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FaEyeSlash, FaEye } from "react-icons/fa";

import {
  changePasswordValidation,
  displayFormErrors,
} from "../../modules/validation";

import { fetchChangePassword } from "../../actions/profile/changePassword";
import { toast } from "react-toastify";

export class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewPassword: false,
      viewCPassword: false,
    };
  }

  onHandleSubmit = (values) => {
    const { fetchChangePassword } = this.props;

    const apiVal = {
      password: values.password,
    };

    fetchChangePassword(apiVal).then(() => {
      toast.success("Your password has been changed successfully.");
      this.props.history.push("/");
    });
  };

  getInitialValue = () => {
    return {
      password: "",
      confirmPassword: "",
    };
  };

  render() {
    const { viewCPassword, viewPassword } = this.state;
    return (
      <div className="main_box">
        {/* <Navbar />
				<div className="middle_contain ">
					<Sidebar /> */}
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="container-fluid">
              <div className="">
                <div className="dashboard Profile_title row mt-5">
                  <h2>
                    <i className="zmdi zmdi-long-arrow-left"></i>Change Password
                  </h2>
                </div>
                <div
                  className="user_content personal_detail employee_detail"
                  style={{ maxWidth: "500px" }}
                >
                  <Formik
                    onSubmit={this.onHandleSubmit}
                    initialValues={this.getInitialValue()}
                    validationSchema={changePasswordValidation}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      submitCount,
                      setFieldValue,
                    }) => {
                      const showError = (key) =>
                        displayFormErrors(key, errors, touched, submitCount);

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-lg-12 col-md-12 detail_address mt-3">
                              <label>Password</label>
                              <div className="add_emp_password">
                                <div className="password_inner">
                                  <input
                                    value={values.password}
                                    onChange={handleChange}
                                    type={viewPassword ? "text" : "password"}
                                    name="password"
                                  />
                                </div>
                                <div className="password_icon">
                                  {viewPassword ? (
                                    <FaEye
                                      onClick={() => {
                                        this.setState({
                                          viewPassword: !viewPassword,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <FaEyeSlash
                                      onClick={() => {
                                        this.setState({
                                          viewPassword: !viewPassword,
                                        });
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              {showError("password")}
                            </div>
                            <div className="col-lg-12 col-md-12 mt-3">
                              <label>Confirm Password</label>
                              <div className="add_emp_password">
                                <div className="password_inner">
                                  <input
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    type={viewCPassword ? "text" : "password"}
                                    name="confirmPassword"
                                  />
                                </div>

                                <div className="password_icon">
                                  {viewCPassword ? (
                                    <FaEye
                                      onClick={() => {
                                        this.setState({
                                          viewCPassword: !viewCPassword,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <FaEyeSlash
                                      onClick={() => {
                                        this.setState({
                                          viewCPassword: !viewCPassword,
                                        });
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              {showError("confirmPassword")}
                            </div>
                            <div className="add_employee_btn">
                              <div className="record_btn">
                                <button type="submit">SAVE</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    changePasswordDataLoading: state.changePasswordData.isLoading,
    changePasswordData: state.changePasswordData.data,
  };
};
const mapDispatchToProps = {
  fetchChangePassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
