import React, { Component } from "react";
import moment from "moment";
import {
  Button,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";

class ScheduleTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      title: e.target.value,
    });
  };

  saveTemplate = () => {
    const { saveAsTemplate, inEnd, inStart } = this.props;
    const { title } = this.state;
    const passData = {
      title: title,
      date: moment(inStart).subtract(1, "days").format("YYYY-MM-DD"),
    };
    saveAsTemplate(passData);
  };

  render() {
    const { isOpen, toggle, inStart, inEnd, saveTemplate } = this.props;

    return (
      <Modal isOpen={isOpen} className="schedule_template_modal">
        <ModalHeader toggle={toggle}>
          Save week: {inStart} - {inEnd} as template
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12 as_chbx">
              <FormGroup>
                <Input
                  type="text"
                  name="title"
                  onChange={(e) => this.handleChange(e)}
                  placeholder="Template name"
                  autoComplete="off"
                ></Input>
              </FormGroup>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary CanCle_Btn" onClick={toggle}>
            CANCEL
          </Button>
          <div className="record_btn" onClick={this.saveTemplate}>
            <button>SAVE TEMPLATE</button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleTemplate);
