import {
  FETCH_FORGOT_PASSWORD_REQUEST,
  FETCH_FORGOT_PASSWORD_SUCCESS,
  FETCH_FORGOT_PASSWORD_FAILURE,
} from "../../types/forgotPassword";

import { postApi } from "../api";

import { toast } from "react-toastify";

const fetchForgotPasswordRequest = () => ({
  type: FETCH_FORGOT_PASSWORD_REQUEST,
});

const fetchForgotPasswordSuccess = (data) => ({
  type: FETCH_FORGOT_PASSWORD_SUCCESS,
  data: data,
});

const fetchForgotPasswordFailure = () => ({
  type: FETCH_FORGOT_PASSWORD_FAILURE,
});

export const fetchForgotPasswordAction = (data) => (dispatch, getState) => {
  dispatch(fetchForgotPasswordRequest());

  return postApi(`users/api/forgetpassword`, data)
    .then((data) => {
      dispatch(fetchForgotPasswordSuccess(data));
      toast.success("New Password is sent to your email address");
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch(fetchForgotPasswordFailure(error));
    });
};
