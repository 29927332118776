import React, { Component } from "react";
import { connect } from "react-redux";

import Assignments from "./Assignments";
import AddTodoPopup from "./AddTodoPopup";
import TodoActivity from "./TodoActivity";

import { fetchTodoList } from "../../actions/todo/list";

export class Todo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      restaurantId: null,
      isTodoCreateOpen: false,
    };
  }

  componentDidMount() {
    this.setState({
      restaurantId: this.getRestaurantId(),
    });
  }

  getRestaurantId = () => {
    const { match } = this.props;
    return match && match.params && match.params.restaurantId
      ? match.params.restaurantId
      : "";
  };

  toggleTodoCreatePopup = () => {
    this.setState({
      isTodoCreateOpen: !this.state.isTodoCreateOpen,
    });
  };

  getTodoList = () => {
    const { restaurantId } = this.state;
    this.props.fetchTodoList({ id: restaurantId });
  };

  render() {
    const { restaurantId, isTodoCreateOpen } = this.state;

    return (
      <div className="page main_box">
        <div className="pull_left right_sidercustom">
          <div className="main_container"></div>
          <div className="main-assignment">
            <div className="wrapper_header">
              <div className="WraPerLeft">{/* <h4>Addam's Tavern</h4> */}</div>
              <div className="WraPerRight">
                <div className="add_remove">
                  <button
                    type="button"
                    className="add_removebtn btn"
                    onClick={this.toggleTodoCreatePopup}
                  >
                    Add Todo
                  </button>
                </div>
              </div>
            </div>
            <div className="Assignments_GriD">
              <div className="row">
                <Assignments restaurantId={this.getRestaurantId()} />
                {/* <MyTask restaurantId={restaurantId} /> */}
                <div className="col-12 col-sm-6">
                  <TodoActivity restaurantId={this.getRestaurantId()} />
                  {/* <CompletedTask restaurantId={restaurantId} /> */}
                  {/* <div className="activity_cover">
										</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isTodoCreateOpen && (
          <AddTodoPopup
            restaurantId={this.getRestaurantId()}
            toggle={this.toggleTodoCreatePopup}
            getTodoList={this.getTodoList}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,
  };
};

const mapDispatchToProps = { fetchTodoList };

export default connect(mapStateToProps, mapDispatchToProps)(Todo);
