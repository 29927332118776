import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import TaskDetail from './TaskDetail';
import AddTaskPopup from './AddTaskPopup';

import { fetchTodoTaskList } from '../../actions/todoTask/list';
import { fetchTodoDelete } from '../../actions/todo/delete';

import { isValidArray } from '../../modules/utils';

export class TodoCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isPopupOpen: false,
		};
	}

	togglePopup = () => {
		const { isPopupOpen } = this.state;

		this.setState({
			isPopupOpen: !isPopupOpen,
		});
	};

	deleteTodo = () => {
		const { data } = this.props;

		this.props.fetchTodoDelete(data?.id).then(() => {
			const { getTodoList } = this.props;

			getTodoList();
		});
	};

	getCompletedTaskCount = () => {
		const { data } = this.props;

		if (isValidArray(data?.tasks)) {
			const taskCount = data?.tasks?.filter((task) => task?.completed);

			return taskCount?.length || 0;
		}

		return 0;
	};

	render() {
		const { isPopupOpen } = this.state;
		const { data, getTodoList } = this.props;

		return (
			<React.Fragment>
				<div className="Grid_CardBody">
					<span>
						{this.getCompletedTaskCount()}/{data?.tasks?.length || 0} completed
					</span>
					<div className="task_top">
						<h6>{data?.title}</h6>
						<div>
							<button
								type="button"
								className="Assign_Btn btn btn-success"
								onClick={this.togglePopup}
							>
								<FontAwesomeIcon icon={faPlus} fixedWidth />
							</button>
							<button
								type="button"
								className="Assign_Btn btn btn-danger ml-1"
								onClick={this.deleteTodo}
							>
								<FontAwesomeIcon icon={faTrash} fixedWidth />
							</button>
						</div>
					</div>
					{isValidArray(data?.tasks) &&
						data?.tasks.map((task) => (
							<TaskDetail
								key={task?.id}
								data={task}
								getTodoList={getTodoList}
							/>
						))}
				</div>
				{isPopupOpen && (
					<AddTaskPopup
						toggle={this.togglePopup}
						data={data}
						getTodoList={getTodoList}
					/>
				)}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		todoTaskListIsLoading: state.todoTaskList.isLoading,
		todoTaskList: state.todoTaskList.data,
	};
};

const mapDispatchToProps = { fetchTodoTaskList, fetchTodoDelete };

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
)(TodoCard);
