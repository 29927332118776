import React, { Component } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";

import { fetchGroups } from "../../actions/groups/groups";
import { fetchCategories } from "../../actions/categories/categories";
import { fetchPermission } from "../../actions/permission/fetchPermission";
import { createGroups } from "../../actions/groups/createGroup";
import { deleteGroups } from "../../actions/groups/deleteGroup";
import { fetchProfile } from "../../actions/profile/profile";

import AddPositionModal from "./modals/AddPositionModal";
import DeleteModal from "../common/DeleteModal";
import { FaPlus } from "react-icons/fa";
import { customStyles } from "../../modules/utils";
import { getPermission } from "../../modules/utils";
export class Positions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      isOpenDeleteModal: false,
      deleteId: "",
      id: "",
      name: "",
      category: "",
      color: "",
      permissionId: "",
      data: [],
      sort_field: "name",
      sort_type: "ASC",
    };
  }

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = () => {
    const {
      fetchPermission,
      fetchProfile,
      fetchCategories,
      fetchGroups,
    } = this.props;
    fetchPermission();
    fetchProfile();
    fetchCategories();
    fetchGroups();
  };

  getCategoryListing = () => {
    const { groupsData, categoriesData, globalSearch } = this.props;

    return groupsData?.results
      ?.map((d) => {
        const category = categoriesData?.results?.find((data) => {
          if (data.id === d.category) return data.title;
        });
        return {
          id: d.id,
          name: d.name || "",
          category: category?.title || "",
          color: <div style={{ color: `${d.color}` }}>{d.color}</div> || "",
          permissionId: d.permissions,
          colorId: d.color,
        };
      })
      .filter((d) =>
        globalSearch
          ? d.name?.toLowerCase().includes(globalSearch) ||
            d.category?.toLowerCase().includes(globalSearch) ||
            d.color?.props?.style?.color?.toLowerCase().includes(globalSearch)
          : d
      )
      .sort((a, b) =>
        this.state.sort_field !== "color"
          ? this.state.sort_type === "ASC"
            ? a[this.state.sort_field].localeCompare(b[this.state.sort_field])
            : b[this.state.sort_field].localeCompare(a[this.state.sort_field])
          : this.state.sort_type === "ASC"
          ? a["color"]["props"]["style"][this.state.sort_field].localeCompare(
              b["color"]["props"]["style"][this.state.sort_field]
            )
          : b["color"]["props"]["style"][this.state.sort_field].localeCompare(
              a["color"]["props"]["style"][this.state.sort_field]
            )
      );
  };

  handleSort = (e) => {
    this.setState({
      sort_field: e.name,
      // sort_field: e.selector,
      sort_type: this.state.sort_type === "ASC" ? "DESC" : "ASC",
    });
  };

  getTableColumns = () => {
    const { profileData } = this.props;

    return [
      {
        name: "name",
        // name: "NAME",
        // selector: "name",
        selector: (row) => row.name,
        sortable: true,
        minWidth: "300px",
      },
      {
        name: "category",
        // name: "CATEGORY",
        // selector: "category",
        selector: (row) => row.category,
        sortable: true,
        left: true,
        minWidth: "300px",
      },
      {
        name: "color",
        // name: "COLOR",
        // selector: "color",
        selector: (row) => row.color,
        sortable: true,
        left: true,
        minWidth: "300px",
      },
      {
        name: "ACTIONS",
        selector: (row) => row.actions,
        sortable: false,
        center: true,
        cell: (row) => (
          <React.Fragment>
            <span>
              {getPermission(
                "users.bs_can_edit_employee_positions",
                profileData
              ) && (
                <button
                  className="btn sm btn-outline-primary btn-sm"
                  onClick={() =>
                    this.editPosition(
                      row.id,
                      row.name,
                      row.category,
                      row.colorId,
                      row.permissionId
                    )
                  }
                >
                  EDIT
                </button>
              )}
              {getPermission(
                "users.bs_can_delete_employee_positions",
                profileData
              ) && (
                <button
                  value={row.id}
                  className="btn btn-outline-secondary btn-sm ml-1"
                  onClick={() => this.deleteModalToggle(row.id)}
                >
                  DELETE
                </button>
              )}
            </span>
          </React.Fragment>
        ),
      },
    ];
  };

  deleteModalToggle = (id = "") => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
      deleteId: id,
    });
  };

  deletePosition = () => {
    const { deleteGroups, fetchGroups } = this.props;
    deleteGroups(this.state.deleteId).then(() => {
      fetchGroups();
      this.deleteModalToggle();
    });
  };

  editPosition = (
    id = "",
    name = "",
    category = "",
    color = "",
    permissionId = ""
  ) => {
    this.setState(
      {
        data: [id, name, category, color, permissionId],
      },
      this.toggleModal()
    );
  };

  createGroups = (data = {}) => {
    const { createGroups, fetchGroups } = this.props;
    createGroups(data).then(() => {
      fetchGroups();
      this.toggleModal();
    });
  };

  toggleModal = () => {
    const { isModalOpen } = this.state;

    this.setState(
      {
        isModalOpen: !isModalOpen,
        data: [],
      },
      () => {
        this.props.fetchGroups();
      }
    );
  };

  render() {
    const { isModalOpen, isOpenDeleteModal, deleteId, data } = this.state;
    const {
      categoriesData,
      fetchPermissionData,
      profileData,
      groupsDataLoading,
      categoriesDataLoading,
    } = this.props;

    return (
      <div className="main_box">
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div className="container-fluid">
                <div className="row mt-5 justify-content-between page_title main_align">
                  <div className="col-md-6">
                    <h2>
                      <i className="zmdi zmdi-long-arrow-left"></i>Positions
                    </h2>
                  </div>
                  {getPermission(
                    "users.bs_can_add_employee_positions",
                    profileData
                  ) && (
                    <div className="col-md-6">
                      <div className="main_info_select">
                        <button
                          type="button"
                          className="btn"
                          onClick={this.toggleModal}
                        >
                          <span className="close_icon_report">
                            <FaPlus />
                          </span>
                          New Position
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="main_info_header ata-table mt-5">
                  <DataTable
                    className="table"
                    responsive={true}
                    striped={true}
                    progressPending={groupsDataLoading && categoriesDataLoading}
                    columns={this.getTableColumns()}
                    data={this.getCategoryListing()}
                    pagination={true}
                    paginationServer={false}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    onSort={this.handleSort}
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isModalOpen && (
          <AddPositionModal
            header="Add"
            toggle={this.toggleModal}
            isOpen={isModalOpen}
            categoriesData={categoriesData}
            fetchPermissionData={fetchPermissionData}
            createGroups={this.createGroups}
            data={data}
          />
        )}

        <DeleteModal
          isOpen={isOpenDeleteModal}
          toggle={this.deleteModalToggle}
          deleteLog={this.deletePosition}
          positionDelete={true}
          data={data}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groupsDataLoading: state.groupsData.isLoading,
    groupsData: state.groupsData.data,

    categoriesDataLoading: state.categoriesData.isLoading,
    categoriesData: state.categoriesData.data,

    fetchPermissionDataLoading: state.fetchPermissionData.isLoading,
    fetchPermissionData: state.fetchPermissionData.data,

    createGroupsDataLoading: state.createGroupsData.isLoading,
    createGroupsData: state.createGroupsData.data,

    deleteGroupsDataLoading: state.deleteGroupsData.isLoading,
    deleteGroupsData: state.deleteGroupsData.data,

    globalSearch: state.globalSearch.searchQuery,

    profileData: state.profileData.data,
  };
};
const mapDispatchToProps = {
  fetchGroups,
  fetchCategories,
  fetchPermission,
  createGroups,
  deleteGroups,
  fetchProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(Positions);
