import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";

import AddCategoryModal from "./modals/AddCategoryModal";

import { fetchCategories } from "../../actions/categories/categories";
import { fetchDeleteCategory } from "../../actions/categories/delete";
import { FaPlus } from "react-icons/fa";
import DeleteModal from "../common/DeleteModal";
import { customStyles } from "../../modules/utils";

export class Categories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      categoryAction: "",
      activeId: "",
      sort_field: "name",
      sort_type: "ASC",
    };
  }

  componentDidMount() {
    this.fetchCategoryData();
  }

  toggleModal = (type = "", categoryId = "") => {
    this.setState({
      categoryAction: type,
      activeId: categoryId,
      isOpen: !this.state.isOpen,
    });
  };

  fetchCategoryData = () => {
    this.props.fetchCategories();
  };

  getCategoryListing = () => {
    const { categoriesData, globalSearch } = this.props;

    return categoriesData?.results
      ?.map((d) => {
        return {
          id: d.id,
          name: d.title || "",
          color: <div style={{ color: `${d.color}` }}>{d.color}</div> || "",
        };
      })
      .filter((d) =>
        globalSearch
          ? d.name?.toLowerCase().includes(globalSearch) ||
            d.color?.props?.style?.color?.toLowerCase().includes(globalSearch)
          : d
      )
      .sort((a, b) =>
        this.state.sort_field !== "color"
          ? this.state.sort_type === "ASC"
            ? a[this.state.sort_field].localeCompare(b[this.state.sort_field])
            : b[this.state.sort_field].localeCompare(a[this.state.sort_field])
          : this.state.sort_type === "ASC"
          ? a["color"]["props"]["style"][this.state.sort_field].localeCompare(
              b["color"]["props"]["style"][this.state.sort_field]
            )
          : b["color"]["props"]["style"][this.state.sort_field].localeCompare(
              a["color"]["props"]["style"][this.state.sort_field]
            )
      );
  };

  getTableColumns = () => {
    return [
      {
        name: "name",
        // name: "NAME",
        // selector: "name",
        selector: (row) => row.name,
        sortable: true,
        minWidth: "450px",
      },
      {
        name: "color",
        // name: "COLOR",
        // selector: "color",
        selector: (row) => row.color,
        sortable: true,
        left: true,
        minWidth: "450px",
        cell: (row) => <span>{row.color}</span>,
      },
      {
        name: "ACTIONS",
        selector: (row) => row.actions,
        sortable: false,
        center: true,
        cell: (row) => (
          <React.Fragment>
            <span
              className="btn sm btn-outline-primary btn-sm edit_delet_btn"
              onClick={() => this.toggleModal("update", row.id)}
            >
              EDIT
            </span>
            <span
              className="btn sm btn-outline-secondary btn-sm ml-1 edit_delet_btn"
              onClick={() => this.deleteModalToggle(row?.id)}
            >
              DELETE
            </span>
          </React.Fragment>
        ),
      },
    ];
  };

  handleSort = (e) => {
    this.setState({
      sort_field: e.name,
      // sort_field: e.selector,
      sort_type: this.state.sort_type === "ASC" ? "DESC" : "ASC",
    });
  };

  deleteModalToggle = (id = "") => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
      deleteId: id,
    });
  };

  deleteCategory = (id) => {
    const { deleteId } = this.state;
    this.props.fetchDeleteCategory(deleteId).then(() => {
      this.deleteModalToggle();
      this.fetchCategoryData();
    });
  };

  render() {
    const { isOpen, isOpenDeleteModal, categoriesData } = this.state;
    const paginationTotalRows = categoriesData?.count;

    return (
      <div className="main_box">
        {/* <Navbar />
				<div className="middle_contain ">
					<Sidebar /> */}
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div className="container-fluid">
                <div className="row mt-5 justify-content-between page_title main_align">
                  <div className="col-md-6">
                    <h2>
                      <i className="zmdi zmdi-long-arrow-left"></i>Categories
                    </h2>
                  </div>
                  <div className="col-md-6">
                    <div className="main_info_select">
                      <button
                        type="button"
                        className="btn "
                        onClick={() => this.toggleModal("create")}
                      >
                        <span className="close_icon_report">
                          <FaPlus />
                        </span>
                        New category
                      </button>
                    </div>
                  </div>
                </div>
                <div className="main_info_header data-table mt-5">
                  <DataTable
                    className="table"
                    responsive={true}
                    striped={true}
                    progressPending={this.props.categoriesDataLoading}
                    columns={this.getTableColumns()}
                    data={this.getCategoryListing()}
                    pagination={true}
                    paginationServer={false}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    onSort={this.handleSort}
                    paginationTotalRows={paginationTotalRows}
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {isOpen && (
          <AddCategoryModal
            isOpen={isOpen}
            toggle={this.toggleModal}
            categoryId={this.state.activeId}
            header={this.state.categoryAction}
            actionType={this.state.categoryAction}
            fetchCategoryData={this.fetchCategoryData}
          />
        )}

        <DeleteModal
          isOpen={isOpenDeleteModal}
          toggle={this.deleteModalToggle}
          deleteLog={this.deleteCategory}
          categoryDelete={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoriesDataLoading: state.categoriesData.isLoading,
    categoriesData: state.categoriesData.data,

    globalSearch: state.globalSearch.searchQuery,
  };
};
const mapDispatchToProps = {
  fetchCategories,
  fetchDeleteCategory,
};
export default connect(mapStateToProps, mapDispatchToProps)(Categories);
