import React from "react";
import ReactDOM from "react-dom";

const OVERLAY_STYLES = {
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: 1000,
};

export default function AddRecordModal({ children }) {
  return ReactDOM.createPortal(
    <div style={OVERLAY_STYLES}>
      <div className="text_edit_model edit-model new_edit_modal">
        {children}
      </div>
    </div>,
    document.getElementById("portal")
  );
}
