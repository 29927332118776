import {
  DELETE_LOG_RECORD_VALUE_REQUEST,
  DELETE_LOG_RECORD_VALUE_FAILURE,
  DELETE_LOG_RECORD_VALUE_SUCCESS,
} from "../../types/managerLogs";
import { deleteApi } from "../api";

export const deleteLogRecordValueRequest = () => ({
  type: DELETE_LOG_RECORD_VALUE_REQUEST,
});

export const deleteLogRecordValueSuccess = (data) => ({
  type: DELETE_LOG_RECORD_VALUE_SUCCESS,
  data: data,
});

export const deleteLogRecordValueFailure = (error) => ({
  type: DELETE_LOG_RECORD_VALUE_FAILURE,
  error,
});

export const deleteLogRecordValue = (formData) => (dispatch, getState) => {
  dispatch(deleteLogRecordValueRequest());

  return deleteApi(`bshift/api/logrecord_values_detail/${formData}`)
    .then((data) => {
      dispatch(deleteLogRecordValueSuccess(data));
    })
    .catch((error) => {
      dispatch(deleteLogRecordValueFailure(error));
    });
};
