import React from "react";
import { useState } from "react";

export default function RowComponent({ rowsData, changeHandler }) {
  return (
    <>
      {rowsData.map((item, index) => {
        return (
          <tr key={index} scope="row">
            <td className="calc_td">
              <input
                type="text"
                name="item"
                // placeholder="Enter Item"
                defaultValue={item?.item}
                className="text-center"
                min={0}
                disabled={item?.item ? true : false}
                // onChange={(e) => changeHandler(index, e)}
              />
              {/* <input
                type="text"
                name="item"
                placeholder="Enter Item"
                className="text-center"
                min={0}
                onChange={(e) => changeHandler(index, e)}
              /> */}
            </td>
            <td className="calc_td">
              <input
                type="number"
                name="monday"
                placeholder="Enter Value"
                className="text-center"
                min={0}
                onChange={(e) => changeHandler(index, e)}
              />
            </td>
            <td className="calc_td">
              <input
                type="number"
                name="tuesday"
                className="text-center"
                placeholder="Enter Value"
                min={0}
                onChange={(e) => changeHandler(index, e)}
              />
            </td>
            <td className="calc_td">
              <input
                type="number"
                name="wednesday"
                className="text-center"
                placeholder="Enter Value"
                min={0}
                onChange={(e) => changeHandler(index, e)}
              />
            </td>
            <td className="calc_td">
              <input
                type="number"
                name="thursday"
                className="text-center"
                placeholder="Enter Value"
                min={0}
                onChange={(e) => changeHandler(index, e)}
              />
            </td>
            <td className="calc_td">
              <input
                type="number"
                name="friday"
                className="text-center"
                placeholder="Enter Value"
                min={0}
                onChange={(e) => changeHandler(index, e)}
              />
            </td>
            <td className="calc_td">
              <input
                type="number"
                name="saturday"
                className="text-center"
                placeholder="Enter Value"
                min={0}
                onChange={(e) => changeHandler(index, e)}
              />
            </td>
            <td className="calc_td">
              <input
                type="number"
                name="sunday"
                className="text-center"
                placeholder="Enter Value"
                min={0}
                onChange={(e) => changeHandler(index, e)}
              />
            </td>
            <td className="calc_td">${item.total}</td>
          </tr>
        );
      })}
    </>
  );
}
