import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { isValidObject } from "../../../modules/utils";

export default class AddNewLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: moment(),
      focused: false,
    };
  }

  handleRestaurant = (e) => {
    const restaurantId = e.target.value;
    const { date } = this.state;
    const userId = localStorage.getItem("loggedUserId");
    const passData = {
      date: moment(date).format("yyyy-MM-DD"),
      restaurant: parseInt(restaurantId),
      records: [],
      created_by: parseInt(userId),
      user: parseInt(userId),
    };
    this.props.createLogRequest(passData);
  };

  render() {
    const { isOpen, toggle, restaurantsData } = this.props;
    const { date } = this.state;
    return (
      <Modal isOpen={isOpen} className="new_blog_model modal-dialog-centered">
        <ModalHeader toggle={toggle}>Select Log Date & Location:</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12 xyz">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Select Date"
                  variant="inline"
                  inputVariant="outlined"
                  value={date}
                  name="date"
                  onChange={(date) => this.setState({ date })}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className="restaurant mt-3">
            <div className="row overflow-scroll">
              {isValidObject(restaurantsData) &&
                restaurantsData?.results.map((d) => {
                  return (
                    <div key={d?.id} className="col-md-6 mt-4 restaurant-names">
                      <button
                        className="btn btn-block btn-primary"
                        onClick={(e) => this.handleRestaurant(e)}
                        value={d.id}
                      >
                        {d.title.toUpperCase()}
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            CANCEL
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
