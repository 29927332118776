import {
	FETCH_TASK_USERS_REQUEST,
	FETCH_TASK_USERS_SUCCESS,
	FETCH_TASK_USERS_FAILURE,
} from '../../types/users';
import { getApi } from '../api';

export const fetchTaskUserListRequest = () => ({
	type: FETCH_TASK_USERS_REQUEST,
});

export const fetchTaskUserListSuccess = (data) => ({
	type: FETCH_TASK_USERS_SUCCESS,
	data: data,
});

export const fetchTaskUserListFailure = (error) => ({
	type: FETCH_TASK_USERS_FAILURE,
	error,
});

export const fetchTaskUserList = () => (dispatch) => {
	dispatch(fetchTaskUserListRequest());
	return getApi(`restaurant/api/user_list`)
		.then((data) => {
			dispatch(fetchTaskUserListSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchTaskUserListFailure(error));
		});
};
