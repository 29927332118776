import {
  FETCH_USERS_SEARCH_REQUEST,
  FETCH_USERS_SEARCH_SUCCESS,
  FETCH_USERS_SEARCH_FAILURE,
} from "../../types/users";

const usersSearchReducer = (
  state = {
    data: null,
    error: null,
    isLoading: false,
  },
  action
) => {
  switch (action.type) {
    case FETCH_USERS_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USERS_SEARCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data.data,
        error: null,
      };
    case FETCH_USERS_SEARCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default usersSearchReducer;
