import React from "react";
import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Formik } from "formik";
import { displayFormErrors } from "../../../modules/validation";
import { useDispatch } from "react-redux";
import { fetchEditScheduleShift } from "../../../actions/schedule/editScheduleShift";

const EditNote = ({ isOpen, toggle, editNoteData, schedule }) => {
  const dispatch = useDispatch();
  const [finalData, setFinalData] = useState(editNoteData);

  const getInitialValue = () => {
    return {
      note: finalData?.note || "",
    };
  };

  const submitHandler = (value) => {
    const data = {
      ...finalData,
      note: value.note,
    };
    dispatch(fetchEditScheduleShift(data)).then(() => {
      toggle();
      schedule();
    });
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Edit Note</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={getInitialValue()}
          // validationSchema={addPositionValidation}
          onSubmit={submitHandler}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            submitCount,
            setFieldValue,
          }) => {
            const showError = (key) =>
              displayFormErrors(key, errors, touched, submitCount);
            return (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <label>Shift Note</label>
                    <input
                      type="text"
                      value={values.note}
                      name="note"
                      onChange={handleChange}
                    />
                    {/* {showError('name')} */}
                  </div>
                  <ModalFooter>
                    <div className="record_btn" onClick={toggle}>
                      <button>CANCEL</button>
                    </div>
                    <div className="record_btn" onClick={handleSubmit}>
                      <button>SAVE</button>{" "}
                    </div>
                  </ModalFooter>
                </div>
              </form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default EditNote;
