import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import moment from "moment";
import DeleteModal from "../common/DeleteModal";
import AddEditEvent from "./AddEditEvent";

import { getPermission } from "../../modules/utils";

export default class EventPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteToggleModal: false,
      editToggleModal: false,
    };
  }

  toggleDeleteModal = () => {
    this.setState({ deleteToggleModal: !this.state.deleteToggleModal });
  };

  toggleEditModal = () => {
    this.setState({ editToggleModal: !this.state.editToggleModal });
  };

  render() {
    const {
      isOpen,
      toggle,
      passProps,
      fetchCalendarEventKindData,
      restaurantsData,
      usersData,
      updateCalendarEvent,
      profileData,
    } = this.props;
    const { deleteToggleModal, editToggleModal } = this.state;
    const eventKind = fetchCalendarEventKindData?.find((d) => {
      if (d.id === passProps?.kind) return d;
    });
    const restaurantTitle = restaurantsData?.find((d) => {
      if (d.id === passProps?.restaurant) return d;
    });

    return (
      <Modal
        isOpen={isOpen}
        className="event_calandar_model modal-dialog-centered"
      >
        <ModalHeader toggle={toggle}>{passProps?.title}</ModalHeader>
        <ModalBody className="custom-modal-body-scroll">
          <div className="space">
            <div className="row">
              <div className="col-5">
                <b>Date Start :</b>
              </div>
              <div className="col-7">
                <span className="space">
                  {moment(passProps?.date_start).format("MM/DD/YYYY   hh:mm a")}
                </span>
              </div>
            </div>
          </div>
          <div className="space">
            <div className="row">
              <div className="col-5">
                <b>Date End :</b>
              </div>
              <div className="col-7">
                <span className="space">
                  {moment(passProps?.date_end).format("MM/DD/YYYY  hh:mm a")}
                </span>
              </div>
            </div>
          </div>
          <div className="space">
            <div className="row">
              <div className="col-5">
                <b>Event Kind :</b>
              </div>
              <div className="col-7">
                <span className="space">
                  <span className="space">{eventKind?.title}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="space">
            <div className="row">
              <div className="col-5">
                <b>Location :</b>
              </div>
              <div className="col-7">
                <span className="space">{restaurantTitle?.title}</span>
              </div>
            </div>
          </div>
          <div className="space">
            <div className="row">
              <div className="col-5">
                <b>Description :</b>
              </div>
              <div className="col-7">
                <div
                  dangerouslySetInnerHTML={{ __html: passProps?.body }}
                ></div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="record_btn" onClick={toggle}>
            <button>CANCEL</button>
          </div>
          {getPermission("bshift.bs_can_delete_calendar", profileData) && (
            <div className="record_btn" onClick={this.toggleDeleteModal}>
              <button>DELETE</button>
            </div>
          )}
          {getPermission("bshift.bs_can_edit_calendar", profileData) && (
            <div className="record_btn" onClick={this.toggleEditModal}>
              <button>EDIT</button>{" "}
            </div>
          )}
        </ModalFooter>
        {deleteToggleModal && (
          <DeleteModal
            isOpen={deleteToggleModal}
            toggle={this.toggleDeleteModal}
            deleteLog={() => this.props.deleteCalendarEvent(passProps?.id)}
          />
        )}

        {editToggleModal && (
          <AddEditEvent
            isOpen={editToggleModal}
            toggle={this.toggleEditModal}
            togglePreviewModal={toggle}
            usersData={usersData}
            passProps={passProps}
            restaurantsData={restaurantsData}
            fetchCalendarEventKindData={fetchCalendarEventKindData}
            updateCalendarEvent={updateCalendarEvent}
          />
        )}
      </Modal>
    );
  }
}
