import { toast } from "react-toastify";
import * as types from "../../types/restaurantGroup";
import { patchApi } from "../api";

export const updateRestaurantGroupRequest = () => ({
  type: types.FETCH_RESTAURANT_GROUP_UPDATE_REQUEST,
});

export const updateRestaurantGroupSuccess = (data) => ({
  type: types.FETCH_RESTAURANT_GROUP_UPDATE_SUCCESS,
  data: data,
});

export const updateRestaurantGroupFailure = (err) => ({
  type: types.FETCH_RESTAURANT_GROUP_UPDATE_FAILURE,
  data: err,
});

export const updateRestaurantGroup = (id, apiData) => async (
  dispatch,
  getState
) => {
  dispatch(updateRestaurantGroupRequest());
  try {
    const data = await patchApi(`bshift/api/restaurantbrand/${id}`, apiData);
    dispatch(updateRestaurantGroupSuccess(data.data));
    toast.success("Restaurant Group updated successfully!!!");
  } catch (err) {
    dispatch(updateRestaurantGroupFailure(err));
    toast.error("Something went wrong in updating restaurant group!!!");
  }
};
