import { Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import DataTable from "react-data-table-component";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { Dropdown } from "react-bootstrap";

import { fetchRestaurantGroups } from "../../actions/restaurantGroups/fetchRestaurantGroups";
import { fetchGroups } from "../../actions/groups/groups";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchSingleUser } from "../../actions/users/singleUser";
import { fetchEditUser } from "../../actions/users/edit";
import { fetchAddUser } from "../../actions/users/add";
import { fetchProfileAssignedQuiz } from "../../actions/training/profileAssignedQuiz";

import {
  AddEmployeeValidation,
  EditEmployeeValidation,
  displayFormErrors,
} from "../../modules/validation";
import { customStyles, fileToBase64 } from "../../modules/utils";

import "../../assets/css/employee.css";
import userImgPlaceHolder from "../../assets/css/images/user_placeholder.png";
import AddDocumentModal from "../training/modal/AddDocumentModal";
import { getApi } from "../../actions/api";
import { toast } from "react-toastify";

class AddEditEmployee extends Component {
  constructor(props) {
    super(props);

    this.state = {
      birthDate: new Date(),
      hireDate: new Date(),
      optionPosition: [],
      optionLocation: [],
      optionRestaurantGroup: [],
      positionValue: [],
      locationValue: [],
      viewPassword: false,
      viewCPassword: false,
      is_active: true,
      is_superuser: false,
      imageSrc: "",
      isLocation: false,
      page: 1,
      search: "",
      itemsPerPage: 10,
      sort_field: "title",
      sort_type: false,
      file_type: "Document",
      isOpen: false,
      editData: {},
      empId: null,
      selectedLocations: [],
      restaurantGroupValue: [],
      restaurantGroupLoading: false,
      isRestaurantGroupSelecetd: false,
      finalRestaurantOptions: [],
      finalRestaurantSelected: [],
      finalRestaurantValue: [],
      toggle: false,
    };
  }

  componentDidMount() {
    const { fetchGroups, fetchRestaurants } = this.props;
    fetchGroups();
    fetchRestaurants();
    this.props.fetchRestaurantGroups();
    this.getInitialStates();
    const id = this.getEmployeeId();
    if (id) {
      this.getAssignedQuiz();
    }
    this.setState({
      empId: this.props?.match?.params?.id,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.addDocumentdata != this.props.addDocumentdata) {
      const id = this.getEmployeeId();
      if (id) {
        this.props.fetchSingleUser(id);
      }
    }
  }

  getAssignedQuiz = () => {
    const { page, itemsPerPage, sort_field, sort_type } = this.state;
    const id = this.getEmployeeId();
    return this.props.fetchProfileAssignedQuiz(
      page,
      itemsPerPage,
      sort_field,
      sort_type,
      "Completed",
      id
    );
  };

  getInitialStates = () => {
    const id = this.getEmployeeId();
    if (id) {
      this.props.fetchSingleUser(id).then(() => {
        const { singleUserData, groupsData, restaurantsData } = this.props;

        const position = singleUserData?.groups.map((d) => {
          const value = groupsData?.results.find((i) => {
            if (i.id === d) return i;
          });
          return {
            value: value?.id,
            label: value?.name,
          };
        });
        const positionValue = position.map((d) => {
          return d.value;
        });
        const location = singleUserData?.restaurants.map((d) => {
          const value = restaurantsData?.results.find((i) => {
            if (i.id === d) return i;
          });
          return {
            value: value?.id,
            label: value?.title,
          };
        });
        const locationValue = location.map((d) => {
          return d.value;
        });
        const restaurantGroup = singleUserData?.restaurants_brand.map((d) => {
          return {
            value: d?.id,
            label: d?.brand_name,
          };
        });
        const groupValue = restaurantGroup?.map((d) => d?.value);

        this.setState({
          restaurantGroupValue: groupValue,
          optionRestaurantGroup: restaurantGroup,
        });

        // finalRestaurantOptions: [],
        // finalRestaurantSelected: [],
        // finalRestaurantValue: [],

        this.setState({
          optionPosition: position || [],
          // optionLocation: location || [],
          birthDate: singleUserData?.birthday
            ? new Date(singleUserData?.birthday)
            : new Date(),
          hireDate: singleUserData?.hire_date
            ? new Date(singleUserData?.hire_date)
            : new Date(),
          imageSrc: singleUserData?.avatar || "",
          is_active: singleUserData?.is_active || true,
          is_superuser: singleUserData?.is_superuser || false,
          positionValue,
          finalRestaurantSelected: location || [],
          finalRestaurantOptions: location || [],
          finalRestaurantValue: locationValue || [],
        });
      });
    }
  };

  getInitialValue = () => {
    let singleUserData;
    if (this.state.empId) {
      singleUserData = this.props?.singleUserData;
    } else {
      singleUserData = this.props?.location?.state?.singleUserData;
    }
    return {
      firstName: singleUserData?.first_name || "",
      lastName: singleUserData?.last_name || "",
      email: singleUserData?.email || "",
      phone: singleUserData?.phone || "",
      address: singleUserData?.address || "",
      city: singleUserData?.city || "",
      state: singleUserData?.state || "",
      zip: singleUserData?.zipcode || "",
      salary: singleUserData?.salary || "",
      password: "",
      confirmPassword: "",
      imageSrc: singleUserData?.avatar || "",
      birthday: singleUserData?.birthday || moment(),
      hire_date: singleUserData?.hire_date || moment(),
      toggle: singleUserData?.toggle || false,
    };
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page,
      },
      () => {
        this.getAssignedQuiz();
      }
    );
  };

  handleRowPerPage = (itemsPerPage) => {
    this.setState(
      {
        itemsPerPage,
      },
      () => {
        this.getAssignedQuiz();
      }
    );
  };

  handleSort = (e) => {
    this.setState(
      {
        sort_field: e.selector,
        sort_type: !this.state.sort_type,
      },
      () => {
        this.getAssignedQuiz();
      }
    );
  };

  getUserListing = () => {
    const { profileAssignedData, restaurantsData, usersData } = this.props;
    return profileAssignedData?.results?.map((d) => {
      const restaurant = restaurantsData?.results.find((i) => {
        if (i.id === d.restaurant) return i;
      });
      return {
        id: d.id || "",
        title: d.title || "",
        restaurant: restaurant?.title || "",
        completed_date: d.completed_date
          ? moment(d.completed_date).format("MM/DD/YYYY, hh:mm")
          : "",
        rating: d.rating || "",
      };
    });
  };

  getTableColumns = () => {
    return [
      {
        name: "TITLE",
        selector: (row) => row["title"],
        // selector: "title",
        sortable: true,
      },
      {
        name: "RESTAURANT",
        selector: (row) => row["restaurant"],
        // selector: "restaurant",
        sortable: true,
      },

      {
        name: "COMPLETED",
        selector: (row) => row["completed_date"],
        // selector: "completed_date",
        sortable: true,
      },

      {
        name: "RATING%",
        selector: (row) => row["rating"],
        // selector: "rating",
        sortable: true,
      },
    ];
  };

  getDocumentTableColumns = () => {
    return [
      {
        name: "File Type",
        selector: (row) => row.file_type,
      },
      {
        name: "Action",
        selector: (row) => row.actions,
        cell: (row) => (
          <React.Fragment>
            <span className="d-flex">
              {
                <button
                  value={row.id}
                  className="btn sm btn-outline-primary btn-sm"
                  onClick={() => this.handleview(row.attachment)}
                >
                  View
                </button>
              }
            </span>
          </React.Fragment>
        ),
      },
    ];
  };

  getDocumentListing = () => {
    const { singleUserData } = this.props;

    return singleUserData?.more_data?.map((data) => {
      return {
        id: data.id || "",
        file_type: data.file_type || "",
        attachment: data.attachment || "",
      };
    });
  };

  handleview = (attachments) => {
    window.open(attachments, "_blank").focus();
  };

  getEmployeeId = () => {
    if (this.props?.location?.state?.id) {
      return this.props?.location?.state?.id;
    }
    if (this.props?.match?.params?.id) {
      return this.props?.match?.params?.id;
    }
    return "";
  };

  handleChangeOption = (optionPosition) => {
    const positionValue = optionPosition.map((d) => {
      return d.value;
    });
    this.setState({ optionPosition, positionValue });
  };

  handleChangeLocation = (optionLocation) => {
    const locationValue = optionLocation.map((d) => {
      return d.value;
    });
    this.setState({ optionLocation, locationValue, isLocation: false });
  };

  handleFinalRestaurant = (finalRestaurantSelected) => {
    const finalRestaurantValue = finalRestaurantSelected?.map((d) => {
      return d.value;
    });
    this.setState({
      finalRestaurantValue,
      finalRestaurantSelected,
      isLocation: false,
    });
  };

  handleChangeRestaurantGroup = (optionRestaurantGroup) => {
    // FOR MULTIPLE SELECT
    // const restaurantGroupValue = optionRestaurantGroup?.map((d) => {
    //   console.log("D: ", d);
    //   return d.value;
    // });

    const restaurantGroupValue = optionRestaurantGroup?.value;
    this.setState({
      restaurantGroupLoading: true,
      finalRestaurantValue: [],
      finalRestaurantSelected: [],
    });

    // const finalRestaurantOptions = this.state.finalRestaurantOptions?.map(
    //   (d) => {
    //     return {
    //       value: d.id,
    //       label: d.title,
    //     };
    //   }
    // );

    getApi(`/bshift/api/restaurantbrand/${restaurantGroupValue}`)
      .then((data) => {
        this.setState({
          restaurantGroupLoading: false,
          // finalRestaurantOptions: data?.data?.restaurants,
          finalRestaurantOptions: data?.data?.restaurants?.map((d) => {
            return {
              value: d.id,
              label: d.title,
            };
          }),
        });
      })
      .catch((err) => {
        toast.error("Something went wrong during fetching restaurants");
        this.setState({
          restaurantGroupLoading: false,
        });
      });

    this.setState({
      optionRestaurantGroup,
      restaurantGroupValue,
      isRestaurantGroupSelecetd: true,
    });
  };

  onHandleSubmit = (values) => {
    const id = this.getEmployeeId();
    const {
      locationValue,
      positionValue,
      is_active,
      is_superuser,
      restaurantGroupValue,
      finalRestaurantValue,
    } = this.state;

    const apiData = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone: values.phone,
      address: values.address,
      city: values.city,
      state: values.state,
      zipcode: values.zip,
      salary: values.salary,
      password: values.password,
      confirmPassword: values.confirmPassword,
      restaurants: finalRestaurantValue,
      restaurant_brand: restaurantGroupValue,
      groups: positionValue,
      birthday: moment(values.birthday).format("YYYY-MM-DD"),
      hire_date: moment(values.hire_date).format("YYYY-MM-DD"),
      is_active: is_active,
      is_superuser: is_superuser,
      avatar: values.imageSrc,
      toggle: values?.toggle,
    };

    if (finalRestaurantValue?.length > 0) {
      if (id) {
        if (apiData.password === "") {
          delete apiData.password;
        }
        // delete apiData.password;
        this.props.fetchEditUser(id, apiData).then(() => {
          const { history } = this.props;
          history.push("/mainInfo");
        });
      } else {
        this.props.fetchAddUser(apiData).then(() => {
          const { history } = this.props;
          history.push("/mainInfo");
        });
      }
    } else {
      this.setState({
        isLocation: true,
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleActiveChange = () => {
    this.setState({
      is_active: !this.state.is_active,
    });
  };
  handleAdminChange = () => {
    this.setState({
      is_superuser: !this.state.is_superuser,
    });
  };

  onChangeFile = (event, setFieldValue) => {
    fileToBase64(event).then((res) => {
      setFieldValue("imageSrc", res.file);
    });
  };

  handleBirthDateChange = (date, setFieldValue) => {
    setFieldValue("birthday", date);
  };

  handleHireDateChange = (date, setFieldValue) => {
    setFieldValue("hire_date", date);
  };

  clearImg = (setFieldValue) => {
    setFieldValue("imageSrc", "");
  };

  toggleAddModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      editData: [],
    });
  };

  handleAddDocument = (type = "Document") => {
    const singleUserData = this.props?.location?.state?.singleUserData;

    const editData = {
      // id: ,
      attachment_file: "",
      groups: singleUserData?.groups,
      employees: [singleUserData?.id],
      title: "",
      all_groups: false,
      file_type: type,
      visible: false,
      restaurant: singleUserData?.restaurants[0],
    };

    this.setState(
      {
        editData: editData,
        file_type: type,
      },
      () => {
        this.setState({
          isOpen: !this.state.isOpen,
        });
      }
    );
  };

  render() {
    const {
      viewCPassword,
      viewPassword,
      isLocation,
      is_active,
      is_superuser,
      file_type,
      isOpen,
      editData,
      isRestaurantGroupSelecetd,
    } = this.state;
    const {
      restaurantsData,
      groupsData,
      history,
      profileAssignedDataLoading,
      profileAssignedData,
      restaurantGroupsData,
      profileData,
      singleUserData,
    } = this.props;

    const paginationTotalRows = profileAssignedData?.count;
    const optionRestaurantGroup = restaurantGroupsData?.results?.map((d) => {
      return {
        value: d.id,
        label: d.brand_name,
      };
    });
    // console.log("Option Location: ", this.state.optionLocation);
    // const optionLocation = this.state.optionLocation?.map((d) => {
    //   console.log("D: ", d);
    //   return {
    //     value: d.id,
    //     label: d.title,
    //   };
    // });

    // const finalRestaurantOptions = this.state.finalRestaurantOptions?.map(
    //   (d) => {
    //     return {
    //       value: d.id,
    //       label: d.title,
    //     };
    //   }
    // );

    const optionLocation = restaurantsData?.results.map((d) => {
      return {
        value: d.id,
        label: d.title,
      };
    });
    const optionPosition = groupsData?.results.map((d) => {
      return {
        value: d.id,
        label: d.name,
      };
    });

    const id = this.getEmployeeId();
    const csvData = [
      [
        "first_name",
        "last_name",
        "email",
        "phone",
        "address",
        "city",
        "state",
        "zipcode",
        "salary",
        "password",
        "confirmPassword",
        "restaurants",
        "groups",
        "birthday",
        "hire_date",
        "is_active(true/false)",
        "is_superuser(true/false)",
      ],
    ];
    return (
      <div className="main_box">
        {/* <Navbar />
				<div className="middle_contain ">
					<Sidebar /> */}
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div className="container-fluid">
                <div className="">
                  <div className="dashboard Profile_title row">
                    <h2>
                      <i className="zmdi zmdi-long-arrow-left"></i>
                      {!this.getEmployeeId() ? "Add Employee" : "Edit Employee"}
                    </h2>
                  </div>

                  {/* EDIT EMPLOYEE FORM */}
                  {this.props?.match?.params?.id && this.props.singleUserData && (
                    <>
                      <Formik
                        onSubmit={this.onHandleSubmit}
                        initialValues={this.getInitialValue()}
                        validationSchema={EditEmployeeValidation}
                        autoComplete="off"
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          submitCount,
                          setFieldValue,
                        }) => {
                          const showError = (key) =>
                            displayFormErrors(
                              key,
                              errors,
                              touched,
                              submitCount
                            );

                          const img = values.imageSrc
                            ? values.imageSrc
                            : userImgPlaceHolder;

                          return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                              <div className="user_content row personal_detail employee_detail">
                                <div className="col-lg-3 col-md-12 ">
                                  <div className="per_detail">
                                    <div className="pull_left">
                                      <div className="upload_user_photo float-left">
                                        <input
                                          type="file"
                                          id="file-btn4"
                                          className="d-none"
                                          ref={(ref) => (this.upload = ref)}
                                          onChange={(e) => {
                                            this.onChangeFile(e, setFieldValue);
                                          }}
                                          accept="image/*"
                                        />
                                        <img
                                          src={img}
                                          width="100px"
                                          height="100px"
                                          alt=""
                                          className="img-fluid profile_img"
                                        />
                                      </div>
                                    </div>
                                    <div className="pull_right avatar_link">
                                      <div
                                        onClick={() => {
                                          this.upload.click();
                                        }}
                                      >
                                        Edit Avatar
                                      </div>
                                      <div
                                        type="reset"
                                        onClick={() =>
                                          this.clearImg(setFieldValue)
                                        }
                                        // onClick={() =>
                                        //   setFieldValue("imageSrc", "")
                                        // }
                                      >
                                        Clear Avatar
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-2 col-md-12 mb-3 custom_group_check">
                                  <span className="custom_input_label_form">
                                    Active
                                  </span>
                                  <input
                                    type="checkbox"
                                    name="is_active"
                                    checked={is_active}
                                    onChange={this.handleActiveChange}
                                  />
                                </div>
                                {profileData?.is_superuser ? (
                                  <div className="col-lg-2 col-md-12 mb-3 custom_group_check">
                                    <span className="custom_input_label_form">
                                      Admin Access
                                    </span>
                                    <input
                                      onChange={this.handleAdminChange}
                                      checked={is_superuser}
                                      type="checkbox"
                                      name="is_superuser"
                                    />
                                  </div>
                                ) : (
                                  <div className="col-lg-3 col-md-12 mb-3 custom_group_check custom-switch "></div>
                                )}
                                {profileData?.is_superuser ? (
                                  <div className="col-lg-3 col-md-12 mb-3 custom_group_check custom-switch ">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="toggle"
                                      value={values.toggle}
                                      onChange={handleChange}
                                      name="toggle"
                                      defaultChecked={values.toggle}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor="toggle"
                                    >
                                      Daily emails of Managers Log
                                    </label>
                                  </div>
                                ) : (
                                  <div className="col-lg-3 col-md-12 mb-3 custom_group_check custom-switch "></div>
                                )}

                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    First Name :
                                  </label>
                                  <input
                                    type="text"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    name="firstName"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    Last Name :
                                  </label>
                                  <input
                                    value={values.lastName}
                                    onChange={handleChange}
                                    type="text"
                                    name="lastName"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 detail_address mb-3">
                                  <label className="custom_input_label_form">
                                    {" "}
                                    <span className="text-danger font-weight-bold h6">
                                      *{" "}
                                    </span>
                                    Email Address :
                                  </label>
                                  <input
                                    value={values.email}
                                    onChange={handleChange}
                                    type="email"
                                    name="email"
                                    className="mb-2 pt-0 pb-1 email_border"
                                  />
                                  {showError("email")}
                                </div>

                                <div className="col-lg-4 col-md-12 f_name pt-1 mb-3">
                                  <div className="d-flex simple_title_table">
                                    <label className="custom_input_label_form">
                                      Position :
                                    </label>
                                  </div>
                                  <div className="select_input mb-2">
                                    <Select
                                      options={optionPosition}
                                      isMulti
                                      value={this.state.optionPosition}
                                      onChange={(e) =>
                                        this.handleChangeOption(e)
                                      }
                                      placeholder="Select position"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12 f_name mb-3">
                                  <div className="d-flex simple_title_table ">
                                    <label className="custom_input_label_form">
                                      {" "}
                                      <span className="text-danger font-weight-bold h6">
                                        *{" "}
                                      </span>
                                      Restaurant Group :
                                    </label>
                                  </div>
                                  <div className="select_input mb-2">
                                    {/* <Select
                                      options={optionRestaurantGroup}
                                      isMulti
                                      name="optionRestaurantGroup"
                                      value={this.state.optionLocation}
                                      onChange={(e) =>
                                        this.handleChangeLocation(e)
                                      }
                                      placeholder="Select location"
                                    /> */}
                                    <Select
                                      options={optionRestaurantGroup}
                                      isMulti={false}
                                      name="optionRestaurantGroup"
                                      value={this.state.optionRestaurantGroup}
                                      onChange={(e) =>
                                        this.handleChangeRestaurantGroup(e)
                                      }
                                      placeholder="Select Restaurant Group"
                                    />
                                  </div>
                                  {this.state.is_superuser && (
                                    <p className="text-success mb-0">
                                      Superuser can access all the restaurant
                                      groups
                                    </p>
                                  )}
                                </div>
                                <div className="col-lg-4 col-md-12 f_name mb-3">
                                  <div className="d-flex simple_title_table ">
                                    <label className="custom_input_label_form">
                                      {" "}
                                      <span className="text-danger font-weight-bold h6">
                                        *{" "}
                                      </span>
                                      Restaurants :
                                    </label>
                                  </div>
                                  <div className="select_input mb-2">
                                    <Select
                                      options={
                                        this.state.finalRestaurantOptions
                                      }
                                      isMulti
                                      name="optionLocation"
                                      value={this.state.finalRestaurantSelected}
                                      isDisabled={
                                        !this.state.restaurantGroupValue &&
                                        !this.state.restaurantGroupLoading
                                      }
                                      onChange={(e) =>
                                        this.handleFinalRestaurant(e)
                                      }
                                      placeholder="Select Restaurant"
                                    />
                                  </div>
                                  {this.state.is_superuser && (
                                    <p className="text-success mb-0">
                                      Superuser can access all the restaurants
                                    </p>
                                  )}

                                  {isLocation ? (
                                    <p style={{ color: "red" }}>
                                      {isRestaurantGroupSelecetd ? (
                                        <span>This field is required </span>
                                      ) : (
                                        <span>Select Resturant Group</span>
                                      )}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                  {/* <div className="select_input mb-2">
                                    <Select
                                      options={optionLocation}
                                      isMulti
                                      name="optionLocation"
                                      value={this.state.optionLocation}
                                      onChange={(e) =>
                                        this.handleChangeLocation(e)
                                      }
                                      placeholder="Select location"
                                    />
                                  </div>
                                  {isLocation ? (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  ) : (
                                    ""
                                  )} */}
                                </div>

                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    {" "}
                                    <span className="text-danger font-weight-bold h6">
                                      *{" "}
                                    </span>
                                    Phone :
                                  </label>
                                  <input
                                    value={values.phone}
                                    onChange={handleChange}
                                    type="number"
                                    name="phone"
                                    min="0"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                  {showError("phone")}
                                </div>
                                <div className="col-lg-4 col-md-12  emp_bday mb-3">
                                  <label className="custom_input_label_form">
                                    Birthday :
                                  </label>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      // label="DateTimePicker"
                                      autoOk
                                      variant="inline"
                                      format="MM/dd/yyyy"
                                      // InputAdornmentProps={{
                                      //   position: "start",
                                      // }}
                                      inputVariant="outlined"
                                      value={values.birthday}
                                      name="birthday"
                                      onChange={(birthDate) =>
                                        this.handleBirthDateChange(
                                          birthDate,
                                          setFieldValue
                                        )
                                      }
                                      maxDate={new Date()}
                                      className="mb-2 pt-0 pb-1"
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-lg-4 col-md-12 emp_bday mb-3">
                                  <label className="custom_input_label_form">
                                    Hire Date :
                                  </label>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      autoOk
                                      variant="inline"
                                      format="MM/dd/yyyy"
                                      // InputAdornmentProps={{
                                      //   position: "start",
                                      // }}
                                      // label="DateTimePicker"
                                      inputVariant="outlined"
                                      value={values.hire_date}
                                      name="hire_date"
                                      onChange={(hireDate) =>
                                        this.handleHireDateChange(
                                          hireDate,
                                          setFieldValue
                                        )
                                      }
                                      className="mb-2 pt-0 pb-1"
                                    />
                                  </MuiPickersUtilsProvider>
                                  {/* <DatePicker
															selected={hireDate}
															onChange={(hireDate) =>
																this.setState({ hireDate })
															}
														/> */}
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    Address :
                                  </label>
                                  <input
                                    value={values.address}
                                    onChange={handleChange}
                                    type="text"
                                    name="address"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    City :
                                  </label>
                                  <input
                                    value={values.city}
                                    onChange={handleChange}
                                    type="text"
                                    name="city"
                                    autoComplete="off"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                  {showError("city")}
                                </div>
                                <div className="col-lg-4 col-md-12 detail_address mb-3">
                                  <label className="custom_input_label_form">
                                    Password :
                                  </label>
                                  <div className="add_emp_password">
                                    <div className="password_inner">
                                      <input
                                        value={values.password}
                                        onChange={handleChange}
                                        type={
                                          viewPassword ? "text" : "password"
                                        }
                                        style={{
                                          borderBottom: "1px solid black",
                                        }}
                                        name="password"
                                        className="pt-0 pb-1 password_border"
                                        autocomplete="off"
                                      />
                                    </div>
                                    <div className="password_icon">
                                      {viewPassword ? (
                                        <FaEye
                                          onClick={() => {
                                            this.setState({
                                              viewPassword: !viewPassword,
                                            });
                                          }}
                                        />
                                      ) : (
                                        <FaEyeSlash
                                          onClick={() => {
                                            this.setState({
                                              viewPassword: !viewPassword,
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {showError("password")}
                                  {/* {!this.getEmployeeId()
                                    ? showError("password")
                                    : null} */}
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    State :
                                  </label>
                                  <input
                                    value={values.state}
                                    onChange={handleChange}
                                    type="text"
                                    name="state"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                  {showError("state")}
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    Zip Code :
                                  </label>
                                  <input
                                    value={values.zip}
                                    onChange={handleChange}
                                    type="number"
                                    min="0"
                                    name="zip"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    Confirm Password :
                                  </label>
                                  <div className="add_emp_password">
                                    <div className="password_inner">
                                      <input
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        type={
                                          viewCPassword ? "text" : "password"
                                        }
                                        name="confirmPassword"
                                        className="pt-0 pb-1 password_border"
                                      />
                                    </div>

                                    <div className="password_icon">
                                      {viewCPassword ? (
                                        <FaEye
                                          onClick={() => {
                                            this.setState({
                                              viewCPassword: !viewCPassword,
                                            });
                                          }}
                                        />
                                      ) : (
                                        <FaEyeSlash
                                          onClick={() => {
                                            this.setState({
                                              viewCPassword: !viewCPassword,
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>

                                  {/* {values.password !== "" &&
                                    showError("confirmPassword")} */}
                                  {showError("confirmPassword")}
                                  {/* {!this.getEmployeeId()
                                    ? showError("confirmPassword")
                                    : null} */}
                                </div>

                                <div className="col-4 mb-3">
                                  <div className="d-flex simple_title_table">
                                    <label className="custom_input_label_form">
                                      {" "}
                                      <span className="text-danger font-weight-bold h6">
                                        *{" "}
                                      </span>
                                      Salary ( $ per hour):
                                    </label>
                                  </div>
                                  <input
                                    value={values.salary}
                                    onChange={handleChange}
                                    type="number"
                                    name="salary"
                                    min="0"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                  {showError("salary")}
                                </div>
                                <div className="col-6"></div>

                                {this.getEmployeeId() && (
                                  <div className="col-6 mt-4">
                                    <Dropdown className="upload_document-btn">
                                      <Dropdown.Toggle
                                        id="dropdown-basic"
                                        className="remove-border"
                                      >
                                        Upload Document
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            this.handleAddDocument("Video")
                                          }
                                        >
                                          Video
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            this.handleAddDocument("Document")
                                          }
                                        >
                                          Document
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                )}
                                <div className="add_employee_btn my-2">
                                  <div
                                    className="record_btn"
                                    onClick={() => history.push("/mainInfo")}
                                  >
                                    <button>CANCEL</button>
                                  </div>
                                  <div className="record_btn">
                                    <button type="submit">SAVE</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          );
                        }}
                      </Formik>
                    </>
                  )}

                  {/* ADD EMPLOYEE FORM */}
                  {!this.props?.match?.params?.id && (
                    <>
                      <Formik
                        onSubmit={this.onHandleSubmit}
                        initialValues={this.getInitialValue()}
                        validationSchema={AddEmployeeValidation}
                        autoComplete="off"
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          submitCount,
                          setFieldValue,
                        }) => {
                          const showError = (key) =>
                            displayFormErrors(
                              key,
                              errors,
                              touched,
                              submitCount
                            );

                          const img = values.imageSrc
                            ? values.imageSrc
                            : userImgPlaceHolder;

                          return (
                            <form onSubmit={handleSubmit} autoComplete="off">
                              <div className="user_content row personal_detail employee_detail">
                                <div className="col-lg-4 col-md-12 ">
                                  <div className="per_detail">
                                    <div className="pull_left">
                                      <div className="upload_user_photo float-left">
                                        <input
                                          type="file"
                                          id="file-btn4"
                                          className="d-none"
                                          ref={(ref) => (this.upload = ref)}
                                          onChange={(e) => {
                                            this.onChangeFile(e, setFieldValue);
                                          }}
                                          accept="image/*"
                                        />
                                        <img
                                          src={img}
                                          width="100px"
                                          height="100px"
                                          alt=""
                                          className="img-fluid profile_img"
                                        />
                                      </div>
                                    </div>
                                    <div className="pull_right avatar_link">
                                      <div
                                        onClick={() => {
                                          this.upload.click();
                                        }}
                                      >
                                        Edit Avatar
                                      </div>
                                      <div
                                        type="reset"
                                        onClick={() =>
                                          this.clearImg(setFieldValue)
                                        }
                                      >
                                        Clear Avatar
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                  <div className="group_check">
                                    <div className="checkbox_active active_box">
                                      <span className="custom_input_label_form">
                                        Active
                                      </span>
                                      <input
                                        type="checkbox"
                                        name="is_active"
                                        checked={is_active}
                                        onChange={this.handleActiveChange}
                                      />
                                    </div>
                                    {profileData?.is_superuser && (
                                      <div className="checkbox_active">
                                        <span className="custom_input_label_form">
                                          Admin Access
                                        </span>
                                        <input
                                          onChange={this.handleAdminChange}
                                          checked={is_superuser}
                                          type="checkbox"
                                          name="is_superuser"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12"></div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    First Name :
                                  </label>
                                  <input
                                    type="text"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    name="firstName"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    Last Name :
                                  </label>
                                  <input
                                    value={values.lastName}
                                    onChange={handleChange}
                                    type="text"
                                    name="lastName"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 detail_address mb-3">
                                  <label className="custom_input_label_form">
                                    <span className="text-danger font-weight-bold h6">
                                      *{" "}
                                    </span>
                                    Email Address :
                                  </label>
                                  <input
                                    value={values.email}
                                    onChange={handleChange}
                                    type="email"
                                    name="email"
                                    className="mb-2 pt-0 pb-1 email_border"
                                  />
                                  {showError("email")}
                                </div>

                                <div className="col-lg-4 col-md-12 f_name mb-3">
                                  <div className="d-flex simple_title_table">
                                    <label className="custom_input_label_form">
                                      Position :
                                    </label>
                                  </div>
                                  <div className="select_input mb-2">
                                    <Select
                                      options={optionPosition}
                                      isMulti
                                      value={this.state.optionPosition}
                                      onChange={(e) =>
                                        this.handleChangeOption(e)
                                      }
                                      placeholder="Select position"
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12 f_name mb-3">
                                  <div className="d-flex simple_title_table ">
                                    <label className="custom_input_label_form">
                                      <span className="text-danger font-weight-bold h6">
                                        *{" "}
                                      </span>
                                      Restaurant Group :
                                    </label>
                                  </div>
                                  <div className="select_input mb-2">
                                    <Select
                                      options={optionRestaurantGroup}
                                      isMulti={false}
                                      name="optionRestaurantGroup"
                                      value={this.state.optionRestaurantGroup}
                                      onChange={(e) =>
                                        this.handleChangeRestaurantGroup(e)
                                      }
                                      placeholder="Select Restaurant Group"
                                    />
                                  </div>
                                  {this.state.is_superuser && (
                                    <p className="text-success mb-0">
                                      Superuser can access all the restaurant
                                      groups
                                    </p>
                                  )}
                                </div>
                                <div className="col-lg-4 col-md-12 f_name mb-3">
                                  <div className="d-flex simple_title_table ">
                                    <label className="custom_input_label_form">
                                      <span className="text-danger font-weight-bold h6 mb-2">
                                        *{" "}
                                      </span>
                                      Restaurants :
                                    </label>
                                  </div>
                                  <div className="select_input mb-2">
                                    <Select
                                      options={
                                        this.state.finalRestaurantOptions
                                      }
                                      isMulti
                                      name="optionLocation"
                                      value={this.state.finalRestaurantSelected}
                                      isDisabled={
                                        !this.state.isRestaurantGroupSelecetd &&
                                        !this.state.restaurantGroupLoading
                                      }
                                      onChange={(e) =>
                                        this.handleFinalRestaurant(e)
                                      }
                                      placeholder="Select Restaurant"
                                    />
                                  </div>{" "}
                                  {this.state.is_superuser && (
                                    <p className="text-success mb-0">
                                      Superuser can access all the restaurants
                                    </p>
                                  )}
                                  {isLocation ? (
                                    <p style={{ color: "red" }}>
                                      {isRestaurantGroupSelecetd ? (
                                        <span>This field is required </span>
                                      ) : (
                                        <span>Select Resturant Group</span>
                                      )}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                {/* OLD */}
                                {/* <div className="col-lg-4 col-md-12 f_name mb-3">
                                  <div className="d-flex simple_title_table ">
                                    <label className="custom_input_label_form">
                                      <span className="text-danger font-weight-bold h6 mb-2">
                                        *{" "}
                                      </span>
                                      Restaurants :
                                    </label>
                                  </div>
                                  <div className="select_input">
                                    <Select
                                      options={optionLocation}
                                      isMulti
                                      name="optionLocation"
                                      value={this.state.optionLocation}
                                      onChange={(e) =>
                                        this.handleChangeLocation(e)
                                      }
                                      placeholder="Select location"
                                    />
                                  </div>
                                  {isLocation ? (
                                    <p style={{ color: "red" }}>
                                      This field is required
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </div> */}
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    {" "}
                                    <span className="text-danger font-weight-bold h6">
                                      *{" "}
                                    </span>
                                    Phone :
                                  </label>
                                  <input
                                    value={values.phone}
                                    onChange={handleChange}
                                    type="number"
                                    name="phone"
                                    min="0"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                  {showError("phone")}
                                </div>
                                <div className="col-lg-4 col-md-12  emp_bday mb-3">
                                  <label className="custom_input_label_form">
                                    Birthday :
                                  </label>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      autoOk
                                      variant="inline"
                                      format="MM/dd/yyyy"
                                      // inputVariant="outlined"
                                      value={values.birthday}
                                      name="birthday"
                                      onChange={(birthDate) =>
                                        this.handleBirthDateChange(
                                          birthDate,
                                          setFieldValue
                                        )
                                      }
                                      InputProps={{
                                        disableUnderline: true,
                                      }}
                                      maxDate={new Date()}
                                      className="mb-2"
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-lg-4 col-md-12 emp_bday mb-3">
                                  <label className="custom_input_label_form">
                                    Hire Date :
                                  </label>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      autoOk
                                      variant="inline"
                                      format="MM/dd/yyyy"
                                      // InputAdornmentProps={{
                                      //   position: "start",
                                      // }}
                                      // label="DateTimePicker"
                                      inputVariant="outlined"
                                      value={values.hire_date}
                                      name="hire_date"
                                      onChange={(hireDate) =>
                                        this.handleHireDateChange(
                                          hireDate,
                                          setFieldValue
                                        )
                                      }
                                      className="mb-2 pt-0 pb-1"
                                    />
                                  </MuiPickersUtilsProvider>
                                  {/* <DatePicker
															selected={hireDate}
															onChange={(hireDate) =>
																this.setState({ hireDate })
															}
														/> */}
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    Address :
                                  </label>
                                  <input
                                    value={values.address}
                                    onChange={handleChange}
                                    type="text"
                                    name="address"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    City :
                                  </label>
                                  <input
                                    value={values.city}
                                    onChange={handleChange}
                                    type="text"
                                    name="city"
                                    autoComplete="off"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                  {showError("city")}
                                </div>
                                <div className="col-lg-4 col-md-12 detail_address mb-3">
                                  <label className="custom_input_label_form">
                                    {" "}
                                    <span className="text-danger font-weight-bold h6">
                                      *{" "}
                                    </span>
                                    Password :
                                  </label>
                                  <div className="add_emp_password">
                                    <div className="password_inner mb-2">
                                      <input
                                        value={values.password}
                                        onChange={handleChange}
                                        type={
                                          viewPassword ? "text" : "password"
                                        }
                                        name="password"
                                        className="pt-0 pb-1 password_border"
                                      />
                                    </div>
                                    <div className="password_icon">
                                      {viewPassword ? (
                                        <FaEye
                                          onClick={() => {
                                            this.setState({
                                              viewPassword: !viewPassword,
                                            });
                                          }}
                                        />
                                      ) : (
                                        <FaEyeSlash
                                          onClick={() => {
                                            this.setState({
                                              viewPassword: !viewPassword,
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {/* {showError("password")} */}
                                  {!this.getEmployeeId()
                                    ? showError("password")
                                    : null}
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    State :
                                  </label>
                                  <input
                                    value={values.state}
                                    onChange={handleChange}
                                    type="text"
                                    name="state"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                  {showError("state")}
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    Zip Code :
                                  </label>
                                  <input
                                    value={values.zip}
                                    onChange={handleChange}
                                    type="number"
                                    min="0"
                                    name="zip"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mb-3">
                                  <label className="custom_input_label_form">
                                    {" "}
                                    <span className="text-danger font-weight-bold h6">
                                      *{" "}
                                    </span>
                                    Confirm Password :
                                  </label>
                                  <div className="add_emp_password">
                                    <div className="password_inner mb-2">
                                      <input
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        type={
                                          viewCPassword ? "text" : "password"
                                        }
                                        name="confirmPassword"
                                        className="pt-0 pb-1 password_border"
                                      />
                                    </div>

                                    <div className="password_icon">
                                      {viewCPassword ? (
                                        <FaEye
                                          onClick={() => {
                                            this.setState({
                                              viewCPassword: !viewCPassword,
                                            });
                                          }}
                                        />
                                      ) : (
                                        <FaEyeSlash
                                          onClick={() => {
                                            this.setState({
                                              viewCPassword: !viewCPassword,
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  {!this.getEmployeeId()
                                    ? showError("confirmPassword")
                                    : null}
                                </div>

                                <div className="col-4">
                                  <div className="d-flex simple_title_table">
                                    <label className="custom_input_label_form">
                                      {" "}
                                      <span className="text-danger font-weight-bold h6">
                                        *{" "}
                                      </span>
                                      Salary ( $ per hour) :
                                    </label>
                                  </div>
                                  <input
                                    value={values.salary}
                                    onChange={handleChange}
                                    type="number"
                                    name="salary"
                                    min="0"
                                    className="mb-2 pt-0 pb-1"
                                  />
                                  {showError("salary")}
                                </div>
                                <div className="col-6"></div>

                                {this.getEmployeeId() && (
                                  <div className="col-6">
                                    <Dropdown className="upload_document-btn">
                                      <Dropdown.Toggle
                                        id="dropdown-basic"
                                        className="remove-border"
                                      >
                                        Upload Document
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            this.handleAddDocument("Video")
                                          }
                                        >
                                          Video
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            this.handleAddDocument("Document")
                                          }
                                        >
                                          Document
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                )}
                                <div className="add_employee_btn my-2">
                                  <div
                                    className="record_btn"
                                    onClick={() => history.push("/mainInfo")}
                                  >
                                    <button>CANCEL</button>
                                  </div>
                                  <div className="record_btn">
                                    <button type="submit">SAVE</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          );
                        }}
                      </Formik>
                    </>
                  )}

                  {this.props?.match?.params?.id && this.props.singleUserData && (
                    <div className="my-3">
                      <h4>Restaurant Details</h4>
                      <div className="user_content personal_detail employee_detail mt-3">
                        <DataTable
                          responsive={true}
                          striped={true}
                          progressPending={profileAssignedDataLoading}
                          columns={this.getTableColumns()}
                          data={this.getUserListing()}
                          pagination={true}
                          paginationServer={false}
                          // paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                          paginationTotalRows={paginationTotalRows}
                          onChangePage={this.handlePageChange}
                          onChangeRowsPerPage={this.handleRowPerPage}
                          customStyles={customStyles}
                          onSort={this.handleSort}
                        />
                      </div>
                    </div>
                  )}

                  {id ? (
                    <>
                      <h4 className="mt-3">Document Details</h4>
                      <div className="user_content personal_detail employee_detail mt-3">
                        <DataTable
                          responsive={true}
                          striped={true}
                          progressPending={this.props.documentDataLoading}
                          columns={this.getDocumentTableColumns()}
                          data={this.getDocumentListing()}
                          pagination={false}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {isOpen && (
              <AddDocumentModal
                isOpen={isOpen}
                toggle={this.toggleAddModal}
                editData={editData}
                file_type={file_type}
                // getList={this.getList}
                fromEmployee={true}
                //flag={flag}
              />
            )}
          </div>
        </div>

        {/* </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    documentDataLoading: state.documentData.isLoading,
    documentData: state.documentData.data,

    groupsDataLoading: state.groupsData.isLoading,
    groupsData: state.groupsData.data,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    singleUserDataLoading: state.singleUserData.isLoading,
    singleUserData: state.singleUserData.data,

    editUserDataLoading: state.editUserData.isLoading,
    editUserData: state.editUserData.data,

    addUserDataLoading: state.addUserData.isLoading,
    addUserData: state.addUserData.data,

    addDocumentdata: state.addDocumentData.data,

    profileAssignedDataLoading: state.profileAssignedData.isLoading,
    profileAssignedData: state.profileAssignedData.data,
    restaurantGroupsData: state.restaurantGroups.data,
    profileData: state.profileData.data,
  };
};
const mapDispatchToProps = {
  fetchGroups,
  fetchRestaurants,
  fetchSingleUser,
  fetchEditUser,
  fetchAddUser,
  fetchProfileAssignedQuiz,
  fetchRestaurantGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditEmployee);
