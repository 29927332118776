import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export default class DeleteModal extends Component {
  render() {
    const {
      isOpen,
      toggle,
      deleteLog,
      deleteLogRecordValue,
      positionDelete,
      id,
      eventDelete,
      categoryDelete,
      restaurantDelete,
      deleteShiftTIme,
      employeeDelete,
      templateDelete,
      scheduleClear,
      restaurantGroupDelete,
    } = this.props;

    return (
      <Modal isOpen={isOpen} className="delet_model" centered>
        <ModalHeader toggle={toggle}>WARNING</ModalHeader>
        <ModalBody>
          {deleteLogRecordValue ? (
            <div>Deleted log record can not be recovered?</div>
          ) : positionDelete ? (
            <div>Are you sure you want to delete this record?</div>
          ) : scheduleClear ? (
            <div>Deleted schedules can not be recovered?</div>
          ) : employeeDelete ? (
            <div>Are you sure you want to delete this employee?</div>
          ) : eventDelete ? (
            <div>Are you sure you want to delete this event?</div>
          ) : categoryDelete ? (
            <div>Are you sure you want to delete this category?</div>
          ) : restaurantDelete ? (
            <div>Are you sure you want to delete this Restaurant?</div>
          ) : restaurantGroupDelete ? (
            <div>Are you sure you want to delete this Restaurant Group?</div>
          ) : deleteShiftTIme ? (
            <div>Are you sure you want to delete this Shift time?</div>
          ) : templateDelete ? (
            <div>Please confirm DELETE action.</div>
          ) : (
            <div>Deleted logs can not be recovered?</div>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="record_btn ">
            <Button onClick={deleteLog}>YES</Button>
          </div>{" "}
          <Button color="secondary" onClick={toggle} className="cancle_btn">
            CANCEL
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
