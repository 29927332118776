import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Formik } from "formik";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import { connect } from "react-redux";
import moment from "moment";
import {
  addEditEventValidation,
  displayFormErrors,
} from "../../modules/validation";
import { CKEditor } from "ckeditor4-react";
import Select from "react-select";
import { isValidArray, isValidObject } from "../../modules/utils";
import { fetchUsersDropdown } from "../../actions/users/userDropdownData";

class AddEditEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionEmployee: null,
      employeeValue: null,
      isOpenDatePicker: false,
      dateStart: moment(),
      dateEnd: moment().add(30, "m").toDate(),
      showMonth: false,
      locationValue: null,
    };
  }

  componentDidMount() {
    const { passProps, usersData, usersDropdownData } = this.props;
    if (isValidObject(passProps)) {
      this.setState({
        optionEmployee: usersDropdownData?.map((d) => {
          return {
            value: d.id ? d.id : "",
            label: d.full_name ? d.full_name : "",
          };
        }),
      });
    }
    if (passProps?.id) {
      this.props.fetchUsersDropdown(passProps?.restaurant);
    }
  }

  getInitialValue = () => {
    const {
      passProps,
      usersData,
      restaurantsData,
      fetchCalendarEventKindData,
      dateArgs,
    } = this.props;

    const restaurant =
      isValidArray(restaurantsData) &&
      restaurantsData?.find((d) => {
        if (d.id === passProps?.restaurant) {
          return d;
        }
      });
    const kind =
      isValidArray(fetchCalendarEventKindData) &&
      fetchCalendarEventKindData?.find((d) => {
        if (d.id === passProps?.kind) {
          return d;
        }
      });

    return {
      title: passProps?.title || "",
      kind: (kind && { value: kind?.id, label: kind?.title }) || null,
      restaurant:
        (restaurant && { value: restaurant?.id, label: restaurant?.title }) ||
        null,
      body: passProps?.body || "",
      date_start:
        passProps && moment(passProps?.date_start).format("YYYY-MM-DDTHH:mm"),
      date_end:
        passProps && moment(passProps?.date_end).format("YYYY-MM-DDTHH:mm"),
      invited_users: [],
      id: -1,
    };
  };

  handleSubmit = (values) => {
    const {
      passProps,
      addCalendarEvent,
      updateCalendarEvent,
      toggle,
      togglePreviewModal,
    } = this.props;

    const newStart = moment(values.date_start).format();
    const newEnd = moment(values.date_end).format();

    let passData = {
      title: values.title,
      kind: parseInt(values.kind.value),
      restaurant: values.restaurant.value,
      body: values.body,
      date_end: newEnd,
      date_start: newStart,
      invited_users: values.invited_users,
      // id: passProps?.id || -1,
    };

    passData = passProps?.id ? { ...passData, id: passProps.id } : passData;

    if (passProps) {
      updateCalendarEvent(passData, passProps?.id);
      toggle();
      togglePreviewModal();
    } else {
      addCalendarEvent(passData);
    }
  };

  editorText = (event, setFieldValue) => {
    const value = event.editor.getData();
    setFieldValue("body", value);
  };

  handleEmployee = (optionEmployee, setFieldValue) => {
    const employeeValue = optionEmployee?.map((d) => {
      return d.value;
    });
    setFieldValue("invited_users", employeeValue);
    this.setState({
      employeeValue: optionEmployee,
    });
  };
  handleEventKind = (optionEvent, setFieldValue) => {
    setFieldValue("kind", optionEvent);
  };

  handleRestaurant = (optionEvent, setFieldValue) => {
    const { usersData, usersDropdownData } = this.props;
    this.props.fetchUsersDropdown(optionEvent.value);

    setFieldValue("restaurant", optionEvent);
    this.setState({
      locationValue: optionEvent.value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.usersDropdownData !== this.props.usersDropdownData) {
      this.setState({
        optionEmployee: this.props.usersDropdownData?.map((d) => {
          return {
            value: d.id ? d.id : "",
            label: d.full_name ? d.full_name : "",
          };
        }),
      });
      if (this?.props?.passProps?.invited_users) {
        const selected_users = this.state?.optionEmployee?.filter((item) => {
          return this?.props?.passProps?.invited_users?.find((element) => {
            return element === item.value;
          });
        });
        this.setState({ employeeValue: selected_users });
      }
    }
  }

  toggleDatePicker() {
    this.setState({
      isOpenDatePicker: !this.state.isOpenDatePicker,
    });
  }

  handleDateStartChange(dateVal, setFieldValue) {
    setFieldValue("date_start", dateVal.target.value);
    // setFieldValue(
    //   "date_start",
    //   moment(dateVal.target.value).format("YYYY-MM-DDTHH:MM")
    // );
  }

  handleDateEndChange(dateVal, setFieldValue) {
    setFieldValue("date_end", dateVal.target.value);
    // setFieldValue(
    //   "date_end",
    //   moment(dateVal.target.value).format("YYYY-MM-DDTHH:MM")
    // );
  }

  render() {
    const {
      isOpen,
      toggle,
      fetchCalendarEventKindData,
      restaurantsData,
      passProps,
      togglePreviewModal,
    } = this.props;
    const { optionEmployee, employeeValue, dateStart, dateEnd } = this.state;

    const optionEventKind =
      isValidArray(fetchCalendarEventKindData) &&
      fetchCalendarEventKindData?.map((d) => {
        return {
          value: d.id,
          label: d.title,
        };
      });

    const optionLocation =
      isValidArray(restaurantsData) &&
      restaurantsData?.map((d) => {
        return {
          value: d.id,
          label: d.title,
        };
      });

    return (
      <Modal isOpen={isOpen} className="calender_new_model">
        <ModalHeader toggle={toggle}>
          {passProps ? "Edit Event" : "Create Event"}
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={this.getInitialValue()}
            validationSchema={addEditEventValidation}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              submitCount,
              setFieldValue,
            }) => {
              const showError = (key) =>
                displayFormErrors(key, errors, touched, submitCount);
              return (
                <form onSubmit={handleSubmit} autoComplete="on">
                  <div className="row">
                    <div className="col-md-12 calender_event_title">
                      <label>Event Title</label>
                      <input
                        type="text"
                        value={values.title}
                        name="title"
                        onChange={handleChange}
                        autoComplete="off"
                      />
                      {showError("title")}
                    </div>
                    <div className="col-md-6 calender_event_title mb-5">
                      <label>Event Kind</label>
                      <Select
                        options={optionEventKind}
                        name="kind"
                        value={values.kind}
                        onChange={(e) => this.handleEventKind(e, setFieldValue)}
                        placeholder="Select Event Kind"
                      />
                      {showError("kind")}
                    </div>
                    <div className="col-md-6 calender_event_title">
                      <label>Location</label>
                      <Select
                        options={optionLocation}
                        // isMulti="false"
                        name="restaurant"
                        value={values.restaurant}
                        onChange={(e) =>
                          this.handleRestaurant(e, setFieldValue)
                        }
                        placeholder="Select Restaurant"
                      />
                      {showError("restaurant")}
                      {/* <Select
                        options={optionLocation}
                        isMulti
                        name="optionLocation"
                        value={values.optionLocation}
                        onChange={(e) => this.handleRestaurant(e)}
                        placeholder="Select Restaurant"
                      /> */}
                    </div>
                    <div className="col-md-6 calendar_date_start">
                      <label>Date start</label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <input
                          type="datetime-local"
                          name="date_start"
                          onChange={(e) => {
                            this.handleDateStartChange(e, setFieldValue);
                          }}
                          value={values.date_start}
                          min={moment(new Date())
                            .add(5, "hours")
                            .format("YYYY-MM-DDTHH:mm")}
                          // min={moment().format("YYYY-MM-DDTHH:MM")}
                        />
                      </MuiPickersUtilsProvider>
                      {showError("date_start")}
                    </div>
                    <div className="col-md-6 calendar_date_start">
                      <label>Date end</label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <input
                          type="datetime-local"
                          name="date_end"
                          onChange={(e) =>
                            this.handleDateEndChange(e, setFieldValue)
                          }
                          value={values.date_end}
                          min={moment(new Date())
                            .add(5.5, "hours")
                            .format("YYYY-MM-DDTHH:mm")}
                        />
                      </MuiPickersUtilsProvider>
                      {showError("date_end")}
                    </div>
                    <div className="col-md-12 calender_event_title">
                      <label>Invited Employee</label>
                      <Select
                        options={optionEmployee}
                        // isDisabled={optionEmployee?.length ? false : true}
                        isMulti
                        name="invited_users"
                        value={employeeValue}
                        onChange={(e) => this.handleEmployee(e, setFieldValue)}
                        placeholder="Select employee"
                        defaultValue={[584]}
                      />
                    </div>
                    <div className="col-md-12 mt-4">
                      <CKEditor
                        config={{
                          extraPlugins: "colorbutton,colordialog",
                        }}
                        initData={values.body ? values.body : ""}
                        onChange={(event) => {
                          this.editorText(event, setFieldValue);
                        }}
                      />
                    </div>
                    <ModalFooter>
                      <div
                        className="record_btn"
                        onClick={passProps ? togglePreviewModal : toggle}
                      >
                        <button>CANCEL</button>
                      </div>
                      <div className="record_btn" onClick={handleSubmit}>
                        <button>SAVE</button>
                      </div>
                    </ModalFooter>
                    {/* <ModalFooter></ModalFooter> */}
                  </div>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usersDropdownData: state.usersDropdownData?.data?.results || [],
  };
};
const mapDispatchToProps = {
  fetchUsersDropdown,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddEditEvent);
