import {
	FETCH_TODO_COMMENT_LIST_REQUEST,
	FETCH_TODO_COMMENT_LIST_SUCCESS,
	FETCH_TODO_COMMENT_LIST_FAILURE,
} from '../../types/todo';
import { getApi } from '../api';

export const fetchTodoCommentListRequest = () => ({
	type: FETCH_TODO_COMMENT_LIST_REQUEST,
});

export const fetchTodoCommentListSuccess = (data) => ({
	type: FETCH_TODO_COMMENT_LIST_SUCCESS,
	data: data,
});

export const fetchTodoCommentListFailure = (error) => ({
	type: FETCH_TODO_COMMENT_LIST_FAILURE,
	error,
});

export const fetchTodoCommentList = (apiData) => (dispatch) => {
	dispatch(fetchTodoCommentListRequest());
	return getApi(`restaurant/api/todo_comment_list`, apiData)
		.then((data) => {
			dispatch(fetchTodoCommentListSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchTodoCommentListFailure(error));
		});
};
