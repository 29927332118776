import React, { Component } from "react";
import { FaEdit } from "react-icons/fa";
import { Card, CardBody, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";

import { fetchWhether } from "../../actions/whether/whether";
import { fetchLogRecordsField } from "../../actions/managerLogs/logRecordField";
import { createLogRecordValue } from "../../actions/managerLogs/createLogRecordValue";
import { fetchLogRecordValue } from "../../actions/managerLogs/fetchLogRecordValue";
import { deleteLogRecordValue } from "../../actions/managerLogs/deleteLogRecordValue";
import { updateLogRecordValue } from "../../actions/managerLogs/updateLogRecordValue";
import { updateLogRecord } from "../../actions/managerLogs/updateLogRecord";
import { fetchLogRecord } from "../../actions/managerLogs/fetchLogRecord";
import { fetchLogRecordValueDetail } from "../../actions/managerLogs/fetchLogRecordValueDetail";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import WhetherModal from "./modals/WhetherModal";
import TextEditor from "./modals/TextEditor";
import { isValidArray } from "../../modules/utils";
import DeleteModal from "../common/DeleteModal";

class AddEditLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenWhether: false,
      isOpenTextEditorModal: false,
      isOpenDeleteModal: false,
      isOpenRestaurantModal: false,
      log_id: "",
      restaurantId: "",
      logType: "",
      date: "",
      clicked: "0",
    };
  }
  calendarRef = React.createRef();

  toggle(index) {
    if (this.state.clicked === index) {
      return this.setState({ clicked: "0" });
    }
    this.setState({ clicked: index });
  }

  componentDidMount() {
    this.getInitialData();
  }
  getInitialData = () => {
    const {
      fetchWhether,
      fetchLogRecordsField,
      fetchLogRecordValue,
      fetchLogRecord,
      fetchRestaurants,
    } = this.props;
    const log_id = this.getLogRecordId();
    const restaurantId = this.getRestaurantId();
    this.setState({ log_id: log_id, restaurantId: restaurantId });
    fetchWhether();
    fetchLogRecordsField(restaurantId);
    fetchLogRecordValue({ log_id: log_id });
    fetchLogRecord(log_id);
    fetchRestaurants();
  };

  getLogRecord = () => {
    this.props.fetchLogRecord(this.state.log_id);
  };

  getRestaurantName = () => {
    const finalRes = this.props?.restaurantsData?.results?.filter(
      (data) => data.id == this.props.match.params.restaurantId
    );

    if (finalRes) {
      return finalRes[0].title;
    }
    return "";
  };

  getSelectedDate = () => {
    if (this.props?.location?.state?.date) {
      return this.props?.location?.state?.date;
    }
    return "";
  };

  getLogRecordId = () => {
    if (this.props.match.params.logId) {
      return this.props.match.params.logId;
    }
    // if (this.props?.location?.state?.log_record) {
    //   return this.props?.location?.state?.log_record;
    // }
    return "";
  };

  getRestaurantFilterId = () => {
    if (this.props?.location?.state?.restaurantFilterId) {
      return this.props?.location?.state?.restaurantFilterId;
    } else if (this.props?.match?.params?.restaurantFilterId) {
      return this.props?.match?.params?.restaurantFilterId;
    }
    return "";
  };

  getRestaurantId = () => {
    if (this.props?.location?.state?.restaurantId) {
      return this.props?.location?.state?.restaurantId;
    } else if (this.props?.match?.params?.restaurantId) {
      return this.props?.match?.params?.restaurantId;
    }
    return "";
  };

  whetherModal = () => {
    this.setState({
      isOpenWhether: !this.state.isOpenWhether,
    });
  };

  restaurantTimeModal = (type = "") => {
    this.setState({
      isOpenRestaurantModal: !this.state.isOpenRestaurantModal,
      modalType: type,
    });
  };

  openTextEditor = (id = "", name = "") => {
    this.setState({
      isOpenTextEditorModal: !this.state.isOpenTextEditorModal,
      id: id,
      fieldName: name,
      data: "",
      logType: "add",
    });
  };

  editModal = (id = "", field_name = "") => {
    this.props.fetchLogRecordValueDetail(id).then(() => {
      this.setState({
        isOpenTextEditorModal: !this.state.isOpenTextEditorModal,
        fieldName: field_name,
        id: null,
        logType: "edit",
      });
    });
  };

  getLogRecordValue = () => {
    const { log_id } = this.state;
    this.props.fetchLogRecordValue({ log_id: log_id });
  };

  saveLogRecordValue = (data = {}, log_id) => {
    this.props.createLogRecordValue(data, log_id).then(() => {
      this.getLogRecordValue();
    });
    this.openTextEditor();
  };

  updateLogRecordValue = (id = "", data = {}) => {
    this.props.updateLogRecordValue(id, data).then(() => {
      this.getLogRecordValue();
    });
    this.editModal();
  };

  updateLogRecord = (id = "", data = {}) => {
    this.props.updateLogRecord(id, data).then(() => {
      this.getLogRecord();
    });
    this.restaurantTimeModal();
  };

  deleteModal = (id = "") => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
      id: id,
    });
  };

  deleteSingleLogRecord = () => {
    this.props.deleteLogRecordValue(this.state.id).then(() => {
      this.deleteModal();
      const log_id = this.getLogRecordId();
      this.props.fetchLogRecordValue({ log_id: log_id });
    });
  };

  passValue = () => {
    const { fetchLogRecordData } = this.props;
    const { modalType } = this.state;
    if (modalType === "open") {
      return fetchLogRecordData?.loc_open;
    } else if (modalType === "mid") {
      return fetchLogRecordData?.loc_mid;
    } else if (modalType === "close") {
      return fetchLogRecordData?.loc_close;
    } else if (modalType === "whether") {
      return fetchLogRecordData?.loc_weather;
    }
    return "";
  };

  openFile = (url) => {
    window.open(url, "_blank");
  };

  render() {
    const {
      isOpenTextEditorModal,
      isOpenDeleteModal,
      isOpenRestaurantModal,
      id,
      log_id,
      modalType,
      restaurantId,
      fieldName,
      logType,
      date,
    } = this.state;

    if (isOpenTextEditorModal) {
      document.body.classList.add("custom-modal-open");
    }

    if (!isOpenTextEditorModal) {
      document.body.classList.remove("custom-modal-open");
    }

    const {
      logRecordsFieldData,
      createLogRecordValueData,
      fetchLogRecordValueData,
      fetchLogRecordData,
      whetherData,
      fetchLogRecordValueDetailData,
      fetchLogRecordValueDetailDataLoading,
      globalSearch,
    } = this.props;

    const passValue = this.passValue();
    const logRecordId = this.getLogRecordId();
    const restaurantFilterId = this.getRestaurantFilterId();

    return (
      <>
        <div className="main_box">
          {/* <Navbar /> */}
          {/* <div className="middle_contain"> */}
          {/* <Sidebar /> */}
          <div className="wrapper-pre">
            <div className="container">
              <div className="d-flex justify-start align-end log-title">
                <span className="log-main-title">
                  {this.getRestaurantName()}
                </span>
                <span className="log-sub-title">{this.getSelectedDate()}</span>
                {/* <a
                  className="btn btn-outline-primary btn-sm"
                  href="http://thebusinessshifts.com/admin/login/?next=/admin/bshift/logrecordtag/"
                  target="_blank"
                >
                  EDIT TAG TITLES
                </a>
                <a
                  className="btn btn-outline-primary btn-sm ml-4 mr-3"
                  href="http://thebusinessshifts.com/admin/login/?next=/admin/bshift/logrecordfield/"
                  target="_blank"
                >
                  EDIT SECTION TITLES
                </a> */}
              </div>
              <div className="add-edit-log-card">
                <Card>
                  <CardBody className="card-shadow">
                    <div className="row ">
                      <div className="col-md-12 whether ">
                        <div
                          className="whether_sec"
                          onClick={() => this.restaurantTimeModal("whether")}
                        >
                          {fetchLogRecordData?.loc_weather ? (
                            <p>
                              {/* {fetchLogRecordData?.loc_weather} */}
                              <Highlighter
                                // className="highlight_custom_color"
                                searchWords={[globalSearch]}
                                autoEscape={true}
                                textToHighlight={
                                  fetchLogRecordData?.loc_weather
                                }
                              />
                            </p>
                          ) : (
                            <p>Weather</p>
                          )}

                          <span className="float-right">
                            <FaEdit />
                          </span>
                        </div>
                      </div>

                      <div className="col-md-4  mt-5">
                        <div
                          className="open"
                          onClick={() => this.restaurantTimeModal("open")}
                        >
                          {fetchLogRecordData?.loc_open ? (
                            <p>
                              {/* {fetchLogRecordData?.loc_open} */}
                              <Highlighter
                                // className="highlight_custom_color"
                                searchWords={[globalSearch]}
                                autoEscape={true}
                                textToHighlight={fetchLogRecordData?.loc_open}
                              />
                            </p>
                          ) : (
                            <p>Open</p>
                          )}

                          <span className="float-right">
                            <FaEdit />
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 mt-5">
                        <div
                          className="open"
                          onClick={() => this.restaurantTimeModal("mid")}
                        >
                          {fetchLogRecordData?.loc_mid ? (
                            <p>{fetchLogRecordData?.loc_mid}</p>
                          ) : (
                            <p>Mid</p>
                          )}
                          <span className="float-right ">
                            <FaEdit />
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4 mt-5">
                        <div
                          className="open"
                          onClick={() => this.restaurantTimeModal("close")}
                        >
                          {fetchLogRecordData?.loc_close ? (
                            <p>{fetchLogRecordData?.loc_close}</p>
                          ) : (
                            <p>Close</p>
                          )}
                          <span className="float-right">
                            <FaEdit />
                          </span>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                {/* <Card>
                  <CardBody> */}
                <div>
                  {isValidArray(logRecordsFieldData?.results) &&
                    logRecordsFieldData?.results.map((d, index) => {
                      return (
                        <div className="input-cards mt-4 ml-0" key={index}>
                          <Card>
                            <CardBody>
                              <CardTitle className="record_title">
                                <Highlighter
                                  className="color accordion_manager_log"
                                  searchWords={[globalSearch]}
                                  autoEscape={true}
                                  textToHighlight={d.field_name}
                                />
                                {/* <span
                                  onClick={() => {
                                    this.toggle(index);
                                  }}
                                >
                                  {this.state.clicked != index ? (
                                    <AiFillCaretDown
                                      className="manager_log_arrow"
                                      style={{
                                        display: "inline",
                                        marginLeft: "10px",
                                        position: "absolute",
                                        right: "15px",
                                      }}
                                    />
                                  ) : (
                                    <AiFillCaretUp
                                      className="manager_log_arrow"
                                      style={{
                                        display: "inline",
                                        marginLeft: "10px",
                                        position: "absolute",
                                        right: "15px",
                                      }}
                                    />
                                  )}
                                </span> */}

                                {/* {d.field_name} */}
                              </CardTitle>
                              {isValidArray(fetchLogRecordValueData?.results) &&
                                fetchLogRecordValueData?.results
                                  .filter((data) => data.log_field === d.id)
                                  .map((item, index) => {
                                    return (
                                      <div className={`row mt-3`} key={index}>
                                        <div className="col-12 col-md-3 record_col">
                                          <div className="record_data">
                                            <p>
                                              <Highlighter
                                                className="color"
                                                searchWords={[globalSearch]}
                                                autoEscape={true}
                                                textToHighlight={
                                                  item.created_by_full_name
                                                }
                                              />
                                              {/* {item.created_by_full_name} */}
                                            </p>
                                            <span>
                                              <Highlighter
                                                className="color"
                                                searchWords={[globalSearch]}
                                                autoEscape={true}
                                                textToHighlight={moment(
                                                  item.created
                                                ).format("MM/DD/YYYY hh:mm")}
                                              />
                                              {/* {moment(
																											item.created,
																										).format(
																											'DD/MM/YYYY hh:mm',
																										)} */}
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-12  col-md-9 record_col record_css">
                                          <div className="record_data_1 record_btn">
                                            <Highlighter
                                              className="color"
                                              searchWords={[globalSearch]}
                                              autoEscape={true}
                                              textToHighlight={d.file_name}
                                            />
                                            <div
                                              className="w-100"
                                              dangerouslySetInnerHTML={{
                                                __html: item.value,
                                              }}
                                            ></div>
                                            <div className="mt-5">
                                              {item?.files.length > 0 ? (
                                                <span>Files</span>
                                              ) : (
                                                ""
                                              )}
                                              <ul>
                                                {item?.files.map((d, index) => {
                                                  return (
                                                    <li
                                                      className="mt-3"
                                                      key={index}
                                                    >
                                                      <a
                                                        href={d.attachment_file}
                                                        target="_blank"
                                                      >
                                                        <Highlighter
                                                          className="color"
                                                          searchWords={[
                                                            globalSearch,
                                                          ]}
                                                          autoEscape={true}
                                                          textToHighlight={
                                                            d.file_name
                                                          }
                                                        />
                                                        {/* {d.file_name} */}
                                                      </a>
                                                    </li>
                                                  );
                                                })}
                                              </ul>
                                            </div>
                                            <div className="add_delete">
                                              <div className="add_btn record_btn">
                                                <button
                                                  onClick={() => {
                                                    this.editModal(
                                                      item.id,
                                                      d.field_name
                                                    );
                                                  }}
                                                >
                                                  EDIT
                                                </button>
                                              </div>
                                              <div className="delete_btn record_btn">
                                                <button
                                                  onClick={() =>
                                                    this.deleteModal(item.id)
                                                  }
                                                >
                                                  DELETE
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}

                              <div
                                className="record_btn left mt-3 mb-4"
                                onClick={() =>
                                  this.openTextEditor(d.id, d.field_name)
                                }
                              >
                                <button>ADD RECORD</button>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      );
                    })}
                  <div className="col-md-12">
                    <div className="record_btn left mt-5 mb-4">
                      <Link
                        to={{
                          pathname: "/managers-log",
                          state: {
                            restaurantFilterId: restaurantFilterId,
                          },
                        }}
                      >
                        <button>CLOSE</button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* </CardBody>
                </Card> */}
              </div>
            </div>
          </div>
          {/* </div> */}
          {isOpenRestaurantModal && (
            <WhetherModal
              isOpen={isOpenRestaurantModal}
              toggle={this.restaurantTimeModal}
              modalType={modalType}
              updateLogRecord={this.updateLogRecord}
              log_id={log_id}
              restaurantId={restaurantId}
              passValue={passValue}
              whetherData={whetherData}
            />
          )}
          {isOpenTextEditorModal && (
            <TextEditor
              isOpen={isOpenTextEditorModal}
              toggle={this.openTextEditor}
              saveLogRecordValue={this.saveLogRecordValue}
              createLogRecordValueData={createLogRecordValueData}
              updateLogRecordValue={this.updateLogRecordValue}
              fieldName={fieldName}
              fetchLogRecordValueDetailData={fetchLogRecordValueDetailData}
              id={id}
              log_id={log_id}
              fetchLogRecordValueDetailDataLoading={
                fetchLogRecordValueDetailDataLoading
              }
              logType={logType}
              restaurantId={restaurantId}
            />
          )}
          <DeleteModal
            isOpen={isOpenDeleteModal}
            toggle={this.deleteModal}
            deleteLog={this.deleteSingleLogRecord}
            deleteLogRecordValue={true}
            id={id}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    whetherDataLoading: state.whetherData.isLoading,
    whetherData: state.whetherData.data,

    logRecordsFieldDataLoading: state.logRecordsFieldData.isLoading,
    logRecordsFieldData: state.logRecordsFieldData.data,

    createLogRecordValueDataLoading: state.createLogRecordValueData.isLoading,
    createLogRecordValueData: state.createLogRecordValueData.data,

    fetchLogRecordValueDataLoading: state.fetchLogRecordValueData.isLoading,
    fetchLogRecordValueData: state.fetchLogRecordValueData.data,

    deleteLogRecordValueDataLoading: state.deleteLogRecordValueData.isLoading,
    deleteLogRecordValueData: state.deleteLogRecordValueData.data,

    updateLogRecordValueDataLoading: state.updateLogRecordValueData.isLoading,
    updateLogRecordValueData: state.updateLogRecordValueData.data,

    updateLogRecordDataLoading: state.updateLogRecordData.isLoading,
    updateLogRecordData: state.updateLogRecordData.data,

    fetchLogRecordDataLoading: state.fetchLogRecordData.isLoading,
    fetchLogRecordData: state.fetchLogRecordData.data,

    fetchLogRecordValueDetailDataLoading:
      state.fetchLogRecordValueDetailData.isLoading,
    fetchLogRecordValueDetailData: state.fetchLogRecordValueDetailData.data,

    globalSearch: state.globalSearch.searchQuery,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,
  };
};
const mapDispatchToProps = {
  fetchRestaurants,
  fetchWhether,
  fetchLogRecordsField,
  createLogRecordValue,
  fetchLogRecordValue,
  deleteLogRecordValue,
  updateLogRecordValue,
  updateLogRecord,
  fetchLogRecord,
  fetchLogRecordValueDetail,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddEditLog);
