import React, { useEffect, useState } from "react";
import { ToastContainer, Zoom, toast } from "react-toastify";

import { Provider as ReduxProvider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import Routes from "./Routes";
import reducers from "./reducers";
import { setupInterceptors } from "./actions/api";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { compose } from "redux";
import { requestForToken, onMessageListener } from "./firebase";

// import ErrorBoundary from "./pages/common/ErrorBoundary";

// const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);

// const store = createStoreWithMiddleware(reducers);

// setupInterceptors(store);

const middlewares = [reduxThunk];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares))
);

const App = () => {
  // const [notification, setNotification] = useState({ title: "", body: "" });
  // const notify = () => toast.success(`${notification?.title}`);
  // // const notify = () => toast.success(<ToastDisplay />);

  // function ToastDisplay() {
  //   console.log("Inside toast: ", notification);
  //   return (
  //     <div>
  //       <p>
  //         <b>{notification?.title}</b>
  //       </p>
  //     </div>
  //   );
  // }

  // useEffect(() => {
  //   if (notification?.title) {
  //     notify();
  //   }
  // }, [notification]);

  // onMessageListener()
  //   .then((payload) => {
  //     console.log("In App payload: ", payload);
  //     // const messageBody = payload?.data?.message;
  //     setNotification({
  //       title: payload?.notification?.title,
  //       body: "This is custom",
  //     });
  //   })
  //   .catch((err) => console.log("failed: ", err));

  return (
    <ReduxProvider store={store}>
      <Routes />
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnHover
        transition={Zoom}
        className="toast-container-custom"
        pauseOnFocusLoss={false}
      />
    </ReduxProvider>
  );
};

export default App;
