import {
	FETCH_SCHEDULE_COUNT_REQUEST,
	FETCH_SCHEDULE_COUNT_SUCCESS,
	FETCH_SCHEDULE_COUNT_FAILURE,
} from '../../types/schedule';
import { getApi } from '../api';

export const fetchScheduleCountRequest = () => ({
	type: FETCH_SCHEDULE_COUNT_REQUEST,
});

export const fetchScheduleCountSuccess = (data) => ({
	type: FETCH_SCHEDULE_COUNT_SUCCESS,
	data: data,
});

export const fetchScheduleCountFailure = (error) => ({
	type: FETCH_SCHEDULE_COUNT_FAILURE,
	error,
});

export const fetchScheduleCount = (restaurant, category, position) => (
	dispatch,
	getState,
) => {
	dispatch(fetchScheduleCountRequest());

	let queryParams = [];
	if (restaurant) {
		queryParams = [...queryParams, `restaurant=${restaurant}`];
	}
	if (category) {
		queryParams = [...queryParams, `category=${category}`];
	}
	if (position) {
		queryParams = [...queryParams, `position=${position}`];
	}

	return getApi(`bshift/api/schedule_requests_count?${queryParams.join('&')}`)
		.then((data) => {
			dispatch(fetchScheduleCountSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchScheduleCountFailure(error));
		});
};
