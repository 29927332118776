import {
	FETCH_SCHEDULE_COUNT_REQUEST,
	FETCH_SCHEDULE_COUNT_SUCCESS,
	FETCH_SCHEDULE_COUNT_FAILURE,
} from '../../types/schedule';

const scheduleCountReducer = (
	state = {
		data: null,
		error: null,
		isLoading: false,
	},
	action,
) => {
	switch (action.type) {
		case FETCH_SCHEDULE_COUNT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_SCHEDULE_COUNT_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_SCHEDULE_COUNT_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default scheduleCountReducer;
