import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAnnouncements } from "../../actions/announcements/announcements";
import { fetchAnnouncementDetails } from "../../actions/announcements/announcementDetails";
import { fetchAnnouncementDetailsEmail } from "../../actions/announcements/announcementDetailsEmail";
import { fetchEmailAnnouncements } from "../../actions/announcements/getEmailAnnouncements";
import Avatar from "react-avatar";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import moment from "moment";
import { BsArrowLeftSquareFill, BsArrowRightSquareFill } from "react-icons/bs";

const AnnouncementsDetails = () => {
  const dispatch = useDispatch();

  const announcementsData = useSelector(
    (state) => state?.announcementDetailsData
  );
  const emailAnnoucementsData = useSelector(
    (state) => state?.announcementDetailsEmailData
  );

  // const announcementsData = useSelector((state) => state?.announcementsData);
  // const emailAnnoucementsData = useSelector(
  //   (state) => state?.emailAnnouncementsData
  // );

  useEffect(() => {
    dispatch(fetchAnnouncementDetails());
    dispatch(fetchAnnouncementDetailsEmail());
    // dispatch(fetchAnnouncements());
    // dispatch(fetchEmailAnnouncements());
  }, []);

  const [isEmailView, setEmailView] = useState(false);
  const [page, setPage] = useState(1);
  const [emailPage, emailSetPage] = useState(1);

  useEffect(() => {
    window.scroll(0, 0);
    // dispatch(fetchAnnouncements({ page: page }));
    dispatch(fetchAnnouncementDetails({ page: page }));
  }, [page]);

  useEffect(() => {
    window.scroll(0, 0);
    // dispatch(fetchEmailAnnouncements(emailPage));
    dispatch(fetchAnnouncementDetailsEmail({ page: emailPage }));
  }, [emailPage]);

  const previousAnnouncementHandler = () => {
    setPage((prev) => prev - 1);
  };

  const nextAnnouncementHandler = () => {
    setPage((prev) => prev + 1);
  };

  const previousEmailHandler = () => {
    emailSetPage((prev) => prev - 1);
  };
  const nextEmailHandler = () => {
    emailSetPage((prev) => prev + 1);
  };

  return (
    <div className="main_box">
      <div className="pull_left full_section_details">
        <div className="main_container">
          <div className="wrapper">
            <div className="container-fluid">
              <div className="dashboard-calender-heading my-3">
                <div
                  className="d-flex"
                  style={{ borderRight: "2px solid #38425B" }}
                >
                  <p
                    className={
                      !isEmailView
                        ? "text-underline-2px my-auto mx-2"
                        : "my-auto mx-2"
                    }
                    onClick={() => setEmailView(false)}
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    ANNOUNCEMENTS
                  </p>
                </div>
                <div className="d-flex">
                  <p
                    className={
                      isEmailView
                        ? "text-underline-2px my-auto mx-2"
                        : "my-auto mx-2"
                    }
                    onClick={() => setEmailView(true)}
                    style={{
                      fontSize: "16px",
                    }}
                  >
                    EMAIL ANNOUNCEMENTS
                  </p>
                </div>
              </div>
              {!isEmailView && announcementsData?.isLoading && (
                <div className="mt-3 justify-content-center text-center m-auto d-flex">
                  <h5 className="text-center mt-3 mb-3">
                    <Loader
                      type="TailSpin"
                      color="#38425B"
                      // color="#2196f3"
                      height="100"
                      width="100"
                    />
                  </h5>
                </div>
              )}
              {isEmailView && emailAnnoucementsData?.isLoading && (
                <div className="mt-3 justify-content-center text-center m-auto d-flex">
                  <h5 className="text-center mt-3 mb-3">
                    <Loader
                      type="TailSpin"
                      color="#38425B"
                      // color="#2196f3"
                      height="100"
                      width="100"
                    />
                  </h5>
                </div>
              )}

              {!isEmailView &&
                announcementsData?.data &&
                !announcementsData?.isLoading &&
                announcementsData?.data.results?.length == 0 && (
                  <div className="mt-3 justify-content-center text-center m-auto d-flex">
                    <div className="container mt-4">
                      <h1>No Announcements Yet!!!</h1>
                    </div>
                  </div>
                )}
              {isEmailView &&
                emailAnnoucementsData?.data &&
                !emailAnnoucementsData?.isLoading &&
                emailAnnoucementsData?.data?.results?.length == 0 && (
                  <div className="mt-3 justify-content-center text-center m-auto d-flex">
                    <div className="container mt-4">
                      <h1>No Announcements Yet!!!</h1>
                    </div>
                  </div>
                )}

              {!isEmailView &&
                announcementsData?.data &&
                announcementsData?.data?.results?.length > 0 && (
                  <div className="mt-3 justify-content-center text-center m-auto d-flex">
                    <div className="container mt-4">
                      {announcementsData?.data?.results?.map(
                        (item, i, newarr) => {
                          return (
                            <div
                              className="mb-3 py-3 px-4 text-left shadow-lg"
                              style={{ maxWidth: "600px", margin: "auto" }}
                              key={i}
                            >
                              <div className="row justify-content-between  align-items-center">
                                <div className="col-md-8 d-flex align-items-center mb-3">
                                  <Avatar
                                    name={
                                      item?.created_by_details?.full_name?.split(
                                        " ("
                                      )[0]
                                    }
                                    size="35"
                                    round="50px"
                                    color="#38425B"
                                    className="mr-3"
                                    // color="#1976d2"
                                  />
                                  <h6>
                                    {item?.created_by_details?.full_name}{" "}
                                  </h6>
                                </div>
                              </div>
                              <div>
                                <p>
                                  <span className="font-weight-bold">
                                    Date:
                                  </span>{" "}
                                  {moment(item?.created).format("MMM DD, YYYY")}
                                </p>
                                <p>
                                  <span className="font-weight-bold">
                                    Title:
                                  </span>{" "}
                                  {item?.title}
                                </p>
                                <p>
                                  <span className="font-weight-bold">
                                    Message:
                                  </span>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item?.message,
                                    }}
                                  ></span>
                                </p>
                                <p>
                                  <span className="font-weight-bold">
                                    Restaurants:
                                  </span>{" "}
                                  {item?.restaurants_printable}
                                </p>
                                <p>
                                  <span className="font-weight-bold">
                                    Employees:
                                  </span>{" "}
                                  {item?.employees_printable}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {isEmailView &&
                emailAnnoucementsData?.data &&
                emailAnnoucementsData?.data?.results?.length > 0 && (
                  <div className="mt-3 justify-content-center text-center m-auto d-flex">
                    <div className="container mt-4">
                      {emailAnnoucementsData?.data?.results?.map(
                        (item, i, newarr) => {
                          return (
                            <div
                              className="mb-3 py-3 px-4 text-left shadow-lg"
                              style={{ maxWidth: "600px", margin: "auto" }}
                              key={i}
                            >
                              <div className="row justify-content-between  align-items-center">
                                <div className="col-md-8 d-flex align-items-center mb-3">
                                  <Avatar
                                    name={
                                      item?.created_by_details?.full_name?.split(
                                        " ("
                                      )[0]
                                    }
                                    size="35"
                                    round="50px"
                                    color="#38425B"
                                    className="mr-3"
                                    // color="#1976d2"
                                  />
                                  <h6>
                                    {item?.created_by_details?.full_name}{" "}
                                  </h6>
                                </div>
                              </div>
                              <div>
                                <p>
                                  <span className="font-weight-bold">
                                    Date:
                                  </span>{" "}
                                  {moment(item?.created).format("MMM DD, YYYY")}
                                </p>
                                <p>
                                  <span className="font-weight-bold">
                                    Title:
                                  </span>{" "}
                                  {item?.title}
                                </p>
                                <p>
                                  <span className="font-weight-bold">
                                    Message:
                                  </span>{" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: item?.message,
                                    }}
                                  ></span>
                                </p>
                                <p>
                                  <span className="font-weight-bold">
                                    Restaurants:
                                  </span>{" "}
                                  {item?.restaurants_printable}
                                </p>
                                <p>
                                  <span className="font-weight-bold">
                                    Employees:
                                  </span>{" "}
                                  {item?.employees_printable}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {!isEmailView && (
                <div className="d-flex justify-content-center">
                  {announcementsData?.data?.previous && (
                    <BsArrowLeftSquareFill
                      className="announcement_pagination"
                      onClick={previousAnnouncementHandler}
                    />
                  )}
                  {announcementsData?.data?.next && (
                    <BsArrowRightSquareFill
                      className="announcement_pagination"
                      onClick={nextAnnouncementHandler}
                    />
                  )}
                </div>
              )}
              {isEmailView && (
                <div className="d-flex justify-content-center">
                  {emailAnnoucementsData?.data?.previous && (
                    <BsArrowLeftSquareFill
                      className="announcement_pagination"
                      onClick={previousEmailHandler}
                    />
                  )}
                  {emailAnnoucementsData?.data?.next && (
                    <BsArrowRightSquareFill
                      className="announcement_pagination"
                      onClick={nextEmailHandler}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementsDetails;
