import { toast } from "react-toastify";
import {
  FETCH_EDIT_DOCUMENT_REQUEST,
  FETCH_EDIT_DOCUMENT_SUCCESS,
  FETCH_EDIT_DOCUMENT_FAILURE,
} from "../../types/document";
import { putApi } from "../api";

export const fetchEditDocumentRequest = () => ({
  type: FETCH_EDIT_DOCUMENT_REQUEST,
});

export const fetchEditDocumentSuccess = (data) => ({
  type: FETCH_EDIT_DOCUMENT_SUCCESS,
  data: data,
});

export const fetchEditDocumentFailure = (error) => ({
  type: FETCH_EDIT_DOCUMENT_FAILURE,
  error,
});

export const fetchEditDocument = (id, formData) => (dispatch, getState) => {
  dispatch(fetchEditDocumentRequest());

  return putApi(`bshift/api/training_files/${id}`, formData)
    .then((data) => {
      toast.success("Record updated successfully!");
      dispatch(fetchEditDocumentSuccess(data));
    })
    .catch((error) => {
      console.log(error);
      toast.error("Error");
      dispatch(fetchEditDocumentFailure(error));
    });
};
