import {
  DELETE_SHIFT_TIME_REQUEST,
  DELETE_SHIFT_TIME_FAILURE,
  DELETE_SHIFT_TIME_SUCCESS,
} from "../../types/schedule";
import { toastSuccess } from "../../modules/utils";
import { deleteApi } from "../api";

export const deleteShiftTimeRequest = () => ({
  type: DELETE_SHIFT_TIME_REQUEST,
});

export const deleteShiftTimeSuccess = (data) => ({
  type: DELETE_SHIFT_TIME_SUCCESS,
  data: data,
});

export const deleteShiftTimeFailure = (error) => ({
  type: DELETE_SHIFT_TIME_FAILURE,
  error,
});

export const deleteShiftTime = (data) => (dispatch, getState) => {
  dispatch(deleteShiftTimeRequest());
  return deleteApi(`bshift/api/shifttime/${data?.id}`)
    .then((data) => {
      dispatch(deleteShiftTimeSuccess(data));
    })
    .catch((error) => {
      toastSuccess("Something went wrong");
      dispatch(deleteShiftTimeFailure(error));
    });
};
