import { toast } from "react-toastify";
import {
  FETCH_RESTAURANT_CREATE_REQUEST,
  FETCH_RESTAURANT_CREATE_SUCCESS,
  FETCH_RESTAURANT_CREATE_FAILURE,
} from "../../types/restaurants";
import { postApi } from "../api";

export const fetchCreateRestaurantRequest = () => ({
  type: FETCH_RESTAURANT_CREATE_REQUEST,
});

export const fetchCreateRestaurantSuccess = (data) => ({
  type: FETCH_RESTAURANT_CREATE_SUCCESS,
  data: data,
});

export const fetchCreateRestaurantFailure = (error) => ({
  type: FETCH_RESTAURANT_CREATE_FAILURE,
  error,
});

export const fetchCreateRestaurant = (apiData) => (dispatch, getState) => {
  dispatch(fetchCreateRestaurantRequest());
  return postApi(`bshift/api/restaurants`, apiData)
    .then((data) => {
      toast.success("Restaurant added successfully!");
      dispatch(fetchCreateRestaurantSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchCreateRestaurantFailure(error));
    });
};
