import {
	FETCH_RESTAURANTS_REQUEST,
	FETCH_RESTAURANTS_SUCCESS,
	FETCH_RESTAURANTS_FAILURE,
} from '../../types/restaurants';
import { getApi } from '../api';

export const fetchRestaurantsRequest = () => ({
	type: FETCH_RESTAURANTS_REQUEST,
});

export const fetchRestaurantsSuccess = (data) => ({
	type: FETCH_RESTAURANTS_SUCCESS,
	data: data,
});

export const fetchRestaurantsFailure = (error) => ({
	type: FETCH_RESTAURANTS_FAILURE,
	error,
});

export const fetchRestaurants = (data) => (dispatch, getState) => {
	dispatch(fetchRestaurantsRequest());
	return getApi(`bshift/api/restaurants`)
		.then((data) => {
			dispatch(fetchRestaurantsSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchRestaurantsFailure(error));
		});
};
