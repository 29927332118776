import {
  FETCH_CREATE_COPY_SCHEDULE_WEEK_REQUEST,
  FETCH_CREATE_COPY_SCHEDULE_WEEK_FAILURE,
  FETCH_CREATE_COPY_SCHEDULE_WEEK_SUCCESS,
} from "../../types/schedule";
import { postApi } from "../api";

export const createCopyScheduleWeekRequest = () => ({
  type: FETCH_CREATE_COPY_SCHEDULE_WEEK_REQUEST,
});

export const createCopyScheduleWeekSuccess = (data) => ({
  type: FETCH_CREATE_COPY_SCHEDULE_WEEK_SUCCESS,
  data: data,
});

export const createCopyScheduleWeekFailure = (error) => ({
  type: FETCH_CREATE_COPY_SCHEDULE_WEEK_FAILURE,
  error,
});

export const createCopyScheduleWeek = (date, data) => (dispatch, getState) => {
  dispatch(createCopyScheduleWeekRequest());

  return postApi(`bshift/api/schedule_week/${date}`, data)
    .then((data) => {
      dispatch(createCopyScheduleWeekSuccess(data));
    })
    .catch((error) => {
      dispatch(createCopyScheduleWeekFailure(error));
    });
};
