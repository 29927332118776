import {
	FETCH_DELETE_USER_REQUEST,
	FETCH_DELETE_USER_SUCCESS,
	FETCH_DELETE_USER_FAILURE,
} from '../../types/users';
import { deleteApi } from '../api';
import { toast } from 'react-toastify';

export const fetchDeleteUserRequest = () => ({
	type: FETCH_DELETE_USER_REQUEST,
});

export const fetchDeleteUserSuccess = (data) => ({
	type: FETCH_DELETE_USER_SUCCESS,
	data: data,
});

export const fetchDeleteUserFailure = (error) => ({
	type: FETCH_DELETE_USER_FAILURE,
	error,
});

export const fetchDeleteUser = (id) => (dispatch, getState) => {
	dispatch(fetchDeleteUserRequest());
	return deleteApi(`users/api/users/${id}`)
		.then((data) => {
			dispatch(fetchDeleteUserSuccess(data));
		})
		.catch((error) => {
			toast.error("This employee can't be deleted!");
			dispatch(fetchDeleteUserFailure(error));
		});
};
