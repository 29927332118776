import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import moment from "moment";
import { fetchCalendarEvent } from "../actions/calendar/fetchCalendarEvent";
import { fetchNewFoodGallery } from "../actions/foodGallery/newFoodGallery";
import { fetchRestaurants } from "../actions/restaurants/restaurants";
import { isValidArray } from "../modules/utils";
import LaborCost from "./LaborCost";
import QuizList from "./QuizList";
import SalesData from "./SalesData";
import "react-lazy-load-image-component/src/effects/blur.css";
import { getPermission } from "../modules/utils";
import Gallery from "react-grid-gallery";

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const profileData = useSelector((state) => state.profileData.data);

  const {
    restaurantsData,
    restaurantsDataLoading,
    // foodGalleryData,
    // foodGalleryDataLoading,
    fetchCalendarEventDataLoading,
    fetchCalendarEventData,
    announcementsData,
  } = useSelector((state) => ({
    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    // foodGalleryData: state.newFoodGalleryData.data,
    // foodGalleryDataLoading: state.newFoodGalleryData.isLoading,

    fetchCalendarEventDataLoading: state.fetchCalendarEventData.isLoading,
    fetchCalendarEventData: state.fetchCalendarEventData.data?.results,

    announcementsData: state.announcementsData.data,
  }));

  const [finalGalleryData, setFinalGallerData] = useState([]);
  const foodGalleryData = useSelector((state) => state.newFoodGalleryData.data);
  const foodGalleryisLoading = useSelector(
    (state) => state.newFoodGalleryData.isLoading
  );

  const [optionRestaurant, setOptionRestaurant] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState({
    value: 7,
    label: "3 West",
  });
  const [isMonthView, setIsMonthView] = useState(true);
  const [eventData, setEventData] = useState([]);

  const [imageData, setImageData] = useState([]);
  const [paramsData, setParamsData] = useState({
    limit: 1,
    offset: 0,
    restaurant: null,
    // restaurant: 7,
  });

  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    // setParamsData((prev) => {
    //   return {
    //     ...prev,
    //     offset: 0,
    //     restaurant: restaurantsData?.results[0]?.id,
    //   };
    // });
    // setImageData([]);

    const optionRestaurant =
      restaurantsData &&
      restaurantsData?.results.map((d) => {
        return {
          value: d.id,
          label: d.title,
        };
      });
    setOptionRestaurant(optionRestaurant);
    setSelectedRestaurant(
      isValidArray(optionRestaurant) ? optionRestaurant[0] : []
    );

    if (restaurantsData !== null) {
      setParamsData((prev) => {
        return {
          ...prev,
          restaurant: restaurantsData?.results[0]?.id,
        };
      });
    }
  }, [restaurantsData]);

  useEffect(() => {
    if (paramsData?.restaurant !== null) {
      dispatch(fetchNewFoodGallery(paramsData, false));
    }
  }, [paramsData?.restaurant]);

  useEffect(() => {
    dispatch(fetchRestaurants());
    dispatch(fetchCalendarEvent());
  }, []);

  // useEffect(() => {
  //   dispatch(fetchNewFoodGallery(paramsData, false));
  // }, [paramsData]);

  // useEffect(() => {
  //   const newImages = [];

  //   const result = foodGalleryData?.results?.map((res) => {
  //     res?.editor_images?.map((img) => newImages.push(img));
  //     res?.files?.map((img) => newImages.push(img?.attachment_file));
  //     return newImages;
  //   });

  //   const finalresult = newImages?.map((res) => {
  //     return {
  //       src: res,
  //       thumbnail: res,
  //       thumbnailWidth: 350,
  //       thumbnailHeight: 180,
  //     };
  //   });

  //   if (
  //     finalresult.length === 0 &&
  //     foodGalleryDataLoading === false
  //     // finalresult.length == 0 &&
  //     // !isValidArray(imageData?.results) &&
  //     // foodGalleryData &&
  //     // !foodGalleryDataLoading
  //   ) {
  //     setParamsData((prevData) => {
  //       return {
  //         ...prevData,
  //         offset: prevData.offset + 1,
  //       };
  //     });
  //   } else {
  //     setImageData(finalresult);
  //   }
  // }, [foodGalleryData]);

  useEffect(() => {
    if (foodGalleryData?.results.length > 0) {
      const editorImageData = foodGalleryData?.results[0]?.editor_images?.map(
        (item) => {
          const data = {
            image: item,
            caption: moment(foodGalleryData?.results[0]?.created).format(
              "MM/DD/yyyy"
            ),
          };

          return data;
        }
      );
      foodGalleryData?.results[0]?.files?.forEach((item) => {
        const data = {
          image: item,
          caption: moment(foodGalleryData?.results[0]?.created).format(
            "MM/DD/yyyy"
          ),
        };
        editorImageData.push(data);
      });
      // const finalresult = editorImageData?.map((res) => {
      const finalresult = editorImageData?.slice(0, 15)?.map((res) => {
        return {
          src: res?.image,
          thumbnail: res?.image,

          thumbnailWidth: 200,
          thumbnailHeight: 180,
          caption: res?.caption,
        };
      });

      if (finalresult?.length == 0) {
        let newParams = paramsData;
        newParams.offset = newParams.offset + 1;
        setParamsData(newParams);
        dispatch(fetchNewFoodGallery(newParams));
      }

      setFinalGallerData(finalresult);
    } else {
      setFinalGallerData([]);
    }
  }, [foodGalleryData]);
  useEffect(() => {
    getEventData();
  }, [isMonthView]);

  useEffect(() => {
    getEventData();
  }, [fetchCalendarEventData]);

  const getEventData = () => {
    if (isMonthView && isValidArray(fetchCalendarEventData)) {
      const updated = fetchCalendarEventData.map((d) => {
        const currentMonth = new Date().getMonth();
        if (
          new Date(d?.date_start).getMonth() === currentMonth ||
          new Date(d?.date_end).getMonth() === currentMonth
        ) {
          return {
            title: d?.title,
            start: d?.date_start,
            end: d?.date_end,
          };
        }
      });

      setEventData(updated);
    } else {
      const updated =
        (isValidArray(fetchCalendarEventData) &&
          fetchCalendarEventData.map((d) => {
            console.log("D: ", d);

            const currentDate = new Date().getDate();
            const currentMonth = new Date().getMonth();

            if (
              (new Date(d?.date_start).getDate() === currentDate &&
                new Date(d?.date_start).getMonth() === currentMonth) ||
              (new Date(d?.date_end).getDate() === currentDate &&
                new Date(d?.date_end).getMonth() === currentMonth)
            ) {
              return {
                title: d?.title,
                start: d?.date_start,
                end: d?.date_end,
              };
              // return d;
            }
          })) ||
        [];
      setEventData(updated);
    }
  };

  console.log("Evennn: ", eventData);

  useEffect(() => {
    const optionRestaurant =
      restaurantsData &&
      restaurantsData?.results?.map((d) => {
        return {
          value: d.id,
          label: d.title,
        };
      });
    setOptionRestaurant(optionRestaurant);
    setSelectedRestaurant(
      isValidArray(optionRestaurant) ? optionRestaurant[0] : []
    );
  }, [restaurantsData]);

  const handleChangeRestaurant = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setParamsData((prevData) => {
      return {
        ...prevData,
        offset: 0,
        restaurant: restaurant?.value,
      };
    });
  };

  const tileSliderSetting = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    centerPadding: "50px",
    responsive: [
      {
        breakpoint: 1499,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: false,
        },
      },
      {
        breakpoint: 999,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: false,
        },
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
    ],
  };

  const customButtons = {
    event_title: {
      text: "My Events",
      click: () => history.push("/calendar"),
    },
  };

  return (
    <div className="main_box">
      <div className="pull_left full_section_details">
        <div className="main_container">
          <div className="wrapper">
            <div className="container-fluid">
              {/* <div className="row justify-content-between page_title"> */}
              {/* <div className="col-md-6">
									<h2>Dashboard</h2>
								</div> */}
              <LaborCost />
              {/* </div> */}

              <div className="dashboard_image_carousel dashboard_labor_sales row">
                <SalesData />
                <QuizList />
              </div>

              <div className="dashboard_image_carousel dashboard_labor_sales row">
                {getPermission(
                  "bshift.bs_can_view_sales_check",
                  profileData
                ) && (
                  <div className="dashboard_image_carousel dashboard_food_gallery mt-5 main_info_header col mr-2">
                    {foodGalleryisLoading ? (
                      <h5 className="text-center mt-3 mb-3">
                        <Loader
                          type="TailSpin"
                          color="#38425B"
                          // color="#2196f3"
                          height={50}
                        />
                      </h5>
                    ) : (
                      <div>
                        <div className="row mb-2 d-flex align-items-center">
                          <div className="col-md-6">
                            <h3>
                              <i className="zmdi zmdi-long-arrow-left"></i>Food
                              Gallery
                            </h3>
                          </div>
                          <div className="col-md-6">
                            <div className="main_info_select mb-0">
                              <Select
                                options={optionRestaurant}
                                value={selectedRestaurant}
                                onChange={handleChangeRestaurant}
                                placeholder="Select Restaurant"
                                className="form_select "
                                menuShouldBlockScroll={true}
                              />
                            </div>
                          </div>
                        </div>
                        <Gallery
                          images={finalGalleryData}
                          enableImageSelection={false}
                          margin={5}
                          backdropClosesModal={true}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={`dashboard_image_carousel dashboard_calendar_events mt-5 main_info_header col ${
                    getPermission("bshift.bs_can_view_sales_check", profileData)
                      ? "ml-2"
                      : "col-md-6"
                  }`}
                >
                  <FullCalendar
                    defaultView="dayGridMonth"
                    plugins={[dayGridPlugin]}
                    eventBorderColor={"#38425B"}
                    events={eventData.filter(Boolean)}
                    eventBackgroundColor={"#38425B"}
                    eventDisplay={"auto"}
                    eventTextColor={"white"}
                    displayEventTime={false}
                    headerToolbar={{
                      left: "title",
                      right: "prev next",
                      center: "event_title",
                    }}
                    customButtons={customButtons}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default Dashboard;
