import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { FaPlus } from "react-icons/fa";

import DeleteModal from "..//common/DeleteModal";
import AddDocumentModal from "./modal/AddDocumentModal";

import { fetchDocument } from "../../actions/document/document";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchDeleteDocument } from "../../actions/document/delete";
import { fetchProfile } from "../../actions/profile/profile";
import { customStyles, getPermission } from "../../modules/utils";

export class Video extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file_type: "Video",
      page: 1,
      itemsPerPage: 10,
      search: "",
      isOpenDeleteModal: false,
      deleteId: 0,
      isOpen: false,
      editData: {},
      sort_field: "title",
      sort_type: false,
      employee_flag: false,
    };
  }

  componentDidMount() {
    this.getList();
    this.props.fetchRestaurants();
    this.props.fetchProfile();
  }

  getList = () => {
    const {
      file_type,
      page,
      itemsPerPage,
      search,
      sort_field,
      sort_type,
      employee_flag,
    } = this.state;
    return this.props.fetchDocument(
      file_type,
      page,
      itemsPerPage,
      search,
      sort_field,
      sort_type,
      employee_flag
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page,
      },
      () => {
        this.getList();
      }
    );
  };

  handleRowPerPage = (itemsPerPage) => {
    this.setState(
      {
        itemsPerPage,
      },
      () => {
        this.getList();
      }
    );
  };

  handleSort = (e) => {
    this.setState(
      {
        sort_field: e.selector,
        sort_type: !this.state.sort_type,
      },
      () => {
        this.getList();
      }
    );
  };

  getUserListing = () => {
    const { documentData, restaurantsData, globalSearch } = this.props;

    return documentData?.results
      ?.map((d) => {
        const restaurant = restaurantsData?.results.find((i) => {
          if (i.id === d.restaurant) return i;
        });

        return {
          id: d.id || "",
          title: d.title || "",
          restaurant: restaurant?.title || "",
        };
      })
      .filter((d) =>
        globalSearch
          ? d.title?.toLowerCase().includes(globalSearch) ||
            d.restaurant?.toLowerCase().includes(globalSearch)
          : d
      );
  };

  getTableColumns = () => {
    const { profileData } = this.props;
    return [
      {
        name: "TITLE",
        selector: (row) => row.title,
        sortable: true,
        minWidth: "590px",
      },
      {
        name: "RESTAURANT",
        selector: (row) => row.restaurant,
        sortable: true,
        minWidth: "590px",
      },
      {
        name: "ACTIONS",
        selector: (row) => row.actions,
        center: true,
        sortable: false,
        cell: (row) => (
          <React.Fragment>
            <span>
              {getPermission(
                "bshift.bs_can_view_training_document",
                profileData
              ) && (
                <button
                  value={row.id}
                  className="btn sm btn-outline-primary btn-sm"
                  onClick={() => this.handleview(row.id)}
                >
                  VIEW
                </button>
              )}
              {getPermission(
                "bshift.bs_can_edit_training_video",
                profileData
              ) && (
                <button
                  className="btn sm btn-outline-primary btn-sm"
                  onClick={() => this.handleEdit(row.id)}
                >
                  EDIT
                </button>
              )}
              {getPermission(
                "bshift.bs_can_delete_training_video",
                profileData
              ) && (
                <button
                  value={row.id}
                  className="btn btn-outline-secondary btn-sm ml-1"
                  onClick={() => this.deleteModalToggle(row.id)}
                >
                  DELETE
                </button>
              )}
            </span>
          </React.Fragment>
        ),
      },
    ];
  };

  handleview = (id) => {
    const { documentData } = this.props;
    const data = documentData?.results?.find((d) => {
      if (d.id === id) {
        return d;
      }
    });
    window.open(data.attachment_file, "_blank").focus();
  };

  deleteModalToggle = (id = "") => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
      deleteId: id,
    });
  };

  deleteLog = () => {
    this.props.fetchDeleteDocument(this.state.deleteId).then(() => {
      this.deleteModalToggle();
      this.setState(
        {
          deleteId: 0,
        },
        () => {
          this.getList();
        }
      );
    });
  };

  toggleAddModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      editData: [],
    });
  };

  handleEdit = (id) => {
    const { documentData } = this.props;
    const edit_data = documentData?.results?.find((d) => {
      if (d.id === id) return d;
    });
    this.setState({
      editData: edit_data,
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { isOpen, editData, file_type } = this.state;
    const { documentData, profileData, documentDataLoading } = this.props;
    const paginationTotalRows = documentData?.count;
    return (
      <div className="main_box">
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div className="container-fluid">
                <div className="row mt-5  justify-content-between page_title">
                  <div className="col-md-6">
                    <h2>Videos</h2>
                  </div>
                  {getPermission(
                    "bshift.bs_can_upload_new_training_video",
                    profileData
                  ) && (
                    <div className="col-md-6">
                      <div className="training_select">
                        <button className="btn" onClick={this.toggleAddModal}>
                          <span className="close_icon_report">
                            <FaPlus />
                          </span>
                          UPLOAD NEW VIDEO
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="table_header_line data-table mt-5">
                  <DataTable
                    responsive={true}
                    striped={true}
                    progressPending={documentDataLoading}
                    columns={this.getTableColumns()}
                    data={this.getUserListing()}
                    pagination={true}
                    paginationServer={true}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    paginationTotalRows={paginationTotalRows}
                    onChangePage={this.handlePageChange}
                    onChangeRowsPerPage={this.handleRowPerPage}
                    onSort={this.handleSort}
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeleteModal
          isOpen={this.state.isOpenDeleteModal}
          toggle={this.deleteModalToggle}
          deleteLog={this.deleteLog}
        />
        {isOpen && (
          <AddDocumentModal
            isOpen={isOpen}
            toggle={this.toggleAddModal}
            editData={editData}
            file_type={file_type}
            getList={this.getList}
            fromEmployee={false}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    documentDataLoading: state.documentData.isLoading,
    documentData: state.documentData.data,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    deleteDocumentDataLoading: state.deleteDocumentData.isLoading,
    deleteDocumentData: state.deleteDocumentData.data,

    globalSearch: state.globalSearch.searchQuery,

    profileData: state.profileData.data,
  };
};
const mapDispatchToProps = {
  fetchDocument,
  fetchRestaurants,
  fetchDeleteDocument,
  fetchProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(Video);
