import { getApi } from "../api";
import {
  FETCH_CHAT_LIST_REQUEST,
  FETCH_CHAT_LIST_SUCCESS,
  FETCH_CHAT_LIST_FAILURE,
} from "../../types/chat";
import { fetchChatCounts } from "./unreadCount";

export const fetchChatListRequest = () => ({
  type: FETCH_CHAT_LIST_REQUEST,
});

export const fetchChatListSuccess = (data) => ({
  type: FETCH_CHAT_LIST_SUCCESS,
  data: data,
});

export const fetchChatListFailure = (error) => ({
  type: FETCH_CHAT_LIST_FAILURE,
  error,
});

export const fetchChatList = () => (dispatch, getState) => {
  dispatch(fetchChatListRequest());

  return getApi(`bshift/api/chat`)
    .then((data) => {
      dispatch(fetchChatListSuccess(data));
      dispatch(fetchChatCounts());
    })
    .catch((error) => {
      dispatch(fetchChatListFailure(error));
    });
};
