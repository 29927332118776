import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { fetchCreateRestaurant } from "../../../actions/restaurants/create";
import { fetchUpdateRestaurant } from "../../../actions/restaurants/update";

import { fetchRestaurantGroups } from "../../../actions/restaurantGroups/fetchRestaurantGroups";

import { displayFormErrors } from "../../../modules/validation";

import { fetchRestaurants } from "../../../actions/restaurants/restaurants";
import Select from "react-select";
import { getApi } from "../../../actions/api";
import { toast } from "react-toastify";

class AddRestaurantModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      optionLocation: [],
      locationValue: [],
      isLocation: false,
      selectedRestaurantGroup: {},
    };
  }

  componentDidMount() {
    const { restaurantId, restaurantName, restaurant_brand } = this.props;
    this.props.fetchRestaurantGroups();

    if (restaurantId && restaurant_brand) {
      getApi(`/bshift/api/restaurantbrand/${restaurant_brand}`)
        .then((res) => {
          const final = {
            value: res?.data?.id,
            label: res?.data?.brand_name,
          };
          this.setState({
            locationValue: final,
            // selectedRestaurantGroup: final,
          });
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
    if (restaurantId) {
      this.setState({
        name: restaurantName,
      });
    }
  }

  handleSubmit = (values) => {
    const restaurantBrand = this.state?.locationValue?.value;

    const { actionType, restaurantId, fetchList, toggle } = this.props;
    if (actionType === "update") {
      this.props
        .fetchUpdateRestaurant(restaurantId, {
          title: values?.name,
          restaurant_brand: restaurantBrand,
        })
        .then(() => {
          fetchList();
          toggle();
        });
    } else {
      this.props
        .fetchCreateRestaurant({
          title: values?.name,
          restaurant_brand: restaurantBrand,
        })
        .then(() => {
          fetchList();
          toggle();
        });
    }
  };

  handleRestaurantGroup = (optionEvent) => {
    this.setState({
      locationValue: optionEvent,
    });
  };

  render() {
    const {
      isOpen,
      toggle,
      actionType,
      restaurantName,
      restaurantsData,
      restaurantGroupsData,
    } = this.props;
    const optionLocation =
      restaurantGroupsData &&
      restaurantGroupsData?.results?.map((d) => {
        return {
          value: d.id,
          label: d.brand_name,
        };
      });

    return (
      <Modal isOpen={isOpen} centered>
        <ModalHeader toggle={toggle}>
          {actionType === "create" ? "New" : "Edit"} Restaurant
        </ModalHeader>
        <ModalBody>
          {/* <div className="container-fluid">
						<h3>{actionType === 'create' ? 'New' : 'Edit'} Restaurant</h3> */}
          <Formik
            initialValues={{
              name: this.props.restaurantName ? this.props.restaurantName : "",
              brand_name: this.props.brand_name ? this.props.brand_name : "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("This field is required."),
            })}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              submitCount,
              setFieldValue,
            }) => {
              const showError = (key) =>
                displayFormErrors(key, errors, touched, submitCount);
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group add_modal_input mb-0">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          id="name"
                          onChange={handleChange}
                          value={values.name}
                          name="name"
                        />
                      </div>
                      {showError("name")}
                    </div>
                    <div className="col-12 f_name pt-1">
                      <div className="d-flex simple_title_table pt-4">
                        <label>Restaurant Group</label>
                      </div>
                      <div className="select_input">
                        <Select
                          options={optionLocation}
                          // isMulti
                          isMulti={false}
                          name="brand_name"
                          value={this.state.locationValue}
                          onChange={(e) => this.handleRestaurantGroup(e)}
                          // value={this.state.selectedRestaurantGroup}
                          // onChange={this.handleChangeLocation}
                          placeholder="Select Restaurant Group"
                        />
                      </div>
                    </div>
                    <div className="col-md-12 my-3">
                      <div className="row justify-content-end">
                        <div className="col-md-12 d-flex record_btn res_btn">
                          <button className="" onClick={toggle}>
                            CANCEL
                          </button>
                          <button className="" onClick={handleSubmit}>
                            SAVE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
          {/* </div> */}
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,
    restaurantGroupsData: state.restaurantGroups.data,
  };
};
const mapDispatchToProps = {
  fetchRestaurants,
  fetchCreateRestaurant,
  fetchUpdateRestaurant,
  fetchRestaurantGroups,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddRestaurantModal);
