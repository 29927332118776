import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ChromePicker } from "react-color";

import { fetchUpdateCategory } from "../../../actions/categories/update";
import { fetchCreateCategory } from "../../../actions/categories/create";
import { fetchCategoryDetail } from "../../../actions/categories/detail";

class AddCategoryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isColorPickerVisible: false,
      title: "",
      color: "",
      isTitle: false,
      isColor: false,
    };
  }

  componentDidMount() {
    const { categoryId, fetchCategoryDetail } = this.props;
    if (categoryId) {
      fetchCategoryDetail(categoryId).then(() => {
        const { categoryDetails } = this.props;
        this.setState({
          title: categoryDetails?.title,
          color: categoryDetails?.color,
        });
      });
    }
  }

  toggleColorPicker = () => {
    const { isColorPickerVisible } = this.state;

    this.setState({
      isColorPickerVisible: !isColorPickerVisible,
    });
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  handleSubmit = () => {
    const { actionType, categoryId, fetchCategoryData, toggle } = this.props;
    const { title, color } = this.state;
    if (title && color) {
      if (actionType === "update") {
        this.props
          .fetchUpdateCategory(categoryId, {
            title: title,
            color: color,
          })
          .then(() => {
            fetchCategoryData();
            toggle();
          });
      } else {
        this.props
          .fetchCreateCategory({
            title: title,
            color: color,
          })
          .then(() => {
            fetchCategoryData();
            toggle();
          });
      }
    } else {
      this.setState({
        isTitle: title ? false : true,
        isColor: color ? false : true,
      });
    }
  };

  render() {
    const { isColorPickerVisible, isTitle, isColor } = this.state;
    const { isOpen, toggle, header, categoryId } = this.props;

    return (
      <Modal isOpen={isOpen} centered>
        {/* <ModalHeader>
					<h5 className="modal-title" id="exampleModalLabel">
						{header === 'create' ? 'New' : 'Edit'} Category
					</h5>
				</ModalHeader> */}
        <ModalHeader toggle={toggle}>
          {categoryId ? "Edit Category" : "New Category"}
        </ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12">
              <label>Name</label>
              <input
                type="text"
                value={this.state.title}
                onChange={(e) => this.handleChange("title", e.target.value)}
              />
              {isTitle && this.state.title === "" && (
                <p style={{ color: "red" }}>This field is Required</p>
              )}
            </div>
            <div className="col-12">
              <label>Color</label>
              <input type="text" value={this.state.color} readOnly />
              {isColor && this.state.color === "" && (
                <p style={{ color: "red" }}>This field is Required</p>
              )}
              <ChromePicker
                color={this.state.color}
                onChangeComplete={(e) => this.handleChange("color", e?.hex)}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="record_btn">
            <button
              type="button"
              className=""
              data-dismiss="modal"
              onClick={toggle}
            >
              CANCEL
            </button>
          </div>
          <div className="record_btn">
            <button type="button" className="" onClick={this.handleSubmit}>
              SAVE
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // categoriesDataLoading: state.categoriesData.isLoading,
    // categoriesData: state.categoriesData.data,

    categoryDetailsLoading: state.categoryDetails.isLoading,
    categoryDetails: state.categoryDetails.data,

    categoryCreateLoading: state.categoryCreateData.isLoading,
    categoryCreate: state.categoryCreateData.data,
  };
};
const mapDispatchToProps = {
  fetchUpdateCategory,
  fetchCategoryDetail,
  fetchCreateCategory,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddCategoryModal);
