import React, { useState, useEffect, useRef } from "react";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaAngleDown,
} from "react-icons/fa";
import {
  FormGroup,
  Input,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { Accordion, Card } from "react-bootstrap";

import { fetchGroups } from "../../actions/groups/groups";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchShiftDayPart } from "../../actions/schedule/shiftDayPart";
import { fetchShiftTime } from "../../actions/schedule/shiftTime";
import { fetchUsers } from "../../actions/users/users";
import { fetchSchedule } from "../../actions/schedule/schedule";
import { fetchEditScheduleShift } from "../../actions/schedule/editScheduleShift";
import { fetchDeleteScheduleShift } from "../../actions/schedule/deleteScheduleShift";
import { fetchAddUserShift } from "../../actions/schedule/addUserShift";
import { createCopyScheduleWeek } from "../../actions/schedule/createCopyScheduleWeek";
import Loader from "react-loader-spinner";

import { toast } from "react-toastify";
import {
  formatDateISO,
  getDateShortFormatted,
  getDay,
  isValidArray,
  strTimeFormat,
  getDate,
  isValidObject,
  customStyles,
} from "../../modules/utils";
import schoolImg from "../../assets/css/images/school.png";
import "react-datepicker/dist/react-datepicker.css";
import EditShift from "./modal/EditShift";
import DeleteModal from "../common/DeleteModal";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

const NewSchedule = (props) => {
  const [isLocation, setIsLocation] = useState(false);
  const [isShift, setIsShift] = useState(false);
  const [mon, setMon] = useState(false);
  const [tue, setTue] = useState(false);
  const [wed, setWed] = useState(false);
  const [thu, setThu] = useState(false);
  const [fri, setFri] = useState(false);
  const [sat, setSat] = useState(false);
  const [sun, setSun] = useState(false);
  const [scheduleText, setScheduleText] = useState("");
  const [inDateStart, setInDateStart] = useState(useParams().date);
  const [isEditShiftOpen, setIsEditShiftOpen] = useState(false);
  const [copyRow, setCopyRow] = useState([]);
  const [editShiftData, setEditShiftData] = useState({});
  const [selectDayModal, setSelectDayModal] = useState(false);
  const [customModalTitle, setCustomModalTitle] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [dateArray, setDateArray] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
  const [deleteId, setDeleteId] = useState(0);
  const [deleteRow, setDeleteRow] = useState({});
  const [customDayArray, setCustomDayArray] = useState([]);
  const [employeeId, setEmployeeId] = useState(null);

  const [schedule, setSchedule] = useState(0);
  const [location, setLocation] = useState(0);
  const [shift, setShift] = useState(0);
  const [scheduleDisable, setScheduleDisable] = useState(true);
  const [shiftDisable, setShiftDisable] = useState(true);

  const monRef = useRef();
  const tueRef = useRef();
  const wedRef = useRef();
  const thuRef = useRef();
  const friRef = useRef();
  const satRef = useRef();
  const sunRef = useRef();

  const history = useHistory();
  const dispatch = useDispatch();
  const restaurantsDataList = useSelector(
    (selector) => selector.restaurantsData?.data?.results
  );
  const groupsDataList = useSelector(
    (selector) => selector.groupsData?.data?.results
  );
  const scheduleList = useSelector((selector) => selector.schedule.data);
  const scheduleLoading = useSelector(
    (selector) => selector.schedule.isLoading
  );
  const shiftTimeLoading = useSelector(
    (selector) => selector.shiftTime?.isLoading
  );
  const shiftTimeList = useSelector(
    (selector) => selector.shiftTime?.data?.results
  );
  const shiftDayPartLoading = useSelector(
    (selector) => selector.shiftDayPart?.isLoading
  );
  const shiftDayPartList = useSelector(
    (selector) => selector.shiftDayPart?.data?.results
  );

  const usersDataList = useSelector(
    (selector) => selector.usersData?.data?.results
  );

  const usersLoading = useSelector((selector) => selector.usersData?.isLoading);

  useEffect(() => {
    dispatch(fetchRestaurants());
    dispatch(fetchGroups());
    dispatch(fetchShiftDayPart());
    dispatch(fetchShiftTime());

    if (inDateStart) {
      dispatch(fetchSchedule({ date: inDateStart, viewBy: "week" }));
    }
  }, []);

  const day_start = () => {
    let d = new Date(date); // 17-7
    let dd = d.getDay() || 7;
    d.setDate(d.getDate() - dd + 1);
    return d;
  };

  const day_end = () => {
    let d = new Date(day_start());
    let x = 0;
    d.setDate(d.getDate() + 6 + x);
    return d;
  };

  const day_start_formatted = () => {
    const currentDate = moment(inDateStart);
    const weekStart = currentDate.clone().weekday(1);
    let formatted = weekStart.format("MMMM, DD");
    return formatted;
  };

  const day_end_formatted = () => {
    const currentDate = moment(inDateStart);
    const weekStart = currentDate.clone().weekday(7);
    let formatted = weekStart.format("MMMM, DD");
    return formatted;
  };

  const incWeek = (delta) => {
    let d = new Date(inDateStart);
    d.setDate(d.getDate() + delta * 7);
    setInDateStart(d.toISOString().substr(0, 10));
    const newDate = d.toISOString().substr(0, 10);
    setDate(newDate);
    dispatch(fetchSchedule({ date: newDate, viewBy: "week" }));
  };

  const updateLocation = (e) => {
    setDateArray([]);

    if (e.target.value === "Select Location") {
      setLocation(0);
      setIsLocation(true);
      fetchScheduleApi(schedule, 0);
      setScheduleDisable(true);
      setSchedule(0);
      setScheduleText("Select Schedule");
      setShiftDisable(true);
      dispatch(
        fetchUsers({
          restaurants: -1,
        })
      );
    } else {
      setLocation(e.target.value);
      setIsLocation(false);
      dispatch(
        fetchUsers({
          restaurants: e.target.value,
        })
      );
      fetchScheduleApi(schedule, e.target.value);
      setScheduleDisable(false);
      if (schedule === 0) {
        setShiftDisable(true);
      } else {
        setShiftDisable(false);
      }
    }

    // setLocation(e.target.value === "Select Location" ? 0 : e.target.value);
    // setIsLocation(e.target.value === "Select Location" ? true : false);

    // fetchScheduleApi(
    //   schedule,
    //   e.target.value === "Select Location" ? 0 : e.target.value
    // );

    // if (e.target.value !== "Select Location") {
    //   dispatch(
    //     fetchUsers({
    //       restaurants: e.target.value,
    //     })
    //   );
    // }

    // if (e.target.value === "Select Location") {
    //   setScheduleDisable(true);
    //   setSchedule(0);
    //   setShiftDisable(true);
    // } else {
    //   setScheduleDisable(false);
    //   setShiftDisable(true);
    // }
  };

  const updateSchedule = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    setSchedule(e.target.value === "Select Schedule" ? 0 : e.target.value);
    setScheduleText(e.nativeEvent.target[index].text);
    fetchScheduleApi(
      e.target.value === "Select Schedule" ? 0 : e.target.value,
      location
    );

    if (e.target.value === "Select Schedule") {
      setShiftDisable(true);
    } else {
      setShiftDisable(false);
    }
    // if (location !== 0) {
    //   dispatch(fetchUsers({ restaurants: location }));
    // }
  };

  const editScheduleShift = (passData) => {
    dispatch(fetchEditScheduleShift(passData)).then(() => {
      toggleEditShift();
      getScheduleList();
      fetchScheduleApi();
    });
  };

  const getScheduleList = () => {
    let d = new Date(inDateStart);
    d.setDate(d.getDate() - 7);

    dispatch(
      fetchSchedule({
        date: d.toISOString().substr(0, 10),
        category: 0,
        group: 0,
        restaurant: 0,
        viewBy: "week",
      })
    );

    let items =
      (isValidObject(scheduleList) &&
        scheduleList?.[d.toISOString().substr(0, 10)] &&
        scheduleList?.[d.toISOString().substr(0, 10)].filter(
          (item) =>
            item.group === schedule &&
            (location === item.restaurant || !item.restaurant || !location) &&
            item.date === getDateISOFormatted(1)
        )) ||
      [];
    return items;
  };

  const getApiDate = () => {
    return formatDateISO(getDateApi(1));
  };

  const getDateApi = (num) => {
    const apiDAte = moment(inDateStart);

    return apiDAte._d;
    // return getDate(num, formatDateISO(this.day_start()));
  };

  const fetchScheduleApi = (scheduleFun, locationFun, shift) => {
    let d = new Date(inDateStart);
    d.setDate(d.getDate() - 7);
    // API CALLING HERE
    dispatch(
      fetchSchedule({
        date: getApiDate(),
        category: 0,
        group: scheduleFun,
        restaurant: locationFun,
        // group: scheduleFun ? scheduleFun : schedule,
        // restaurant: locationFun ? locationFun : location,
        viewBy: "week",
      })
    );
  };

  const handleAccordion = (day) => {
    let updated = [...dateArray];

    updated.includes(day)
      ? (updated = updated.filter((item) => item !== day))
      : updated.push(day);

    setDateArray(updated);
  };

  const getSchedule = (day) => {
    if (schedule === 0) {
      let items =
        (isValidObject(scheduleList) &&
          scheduleList?.[inDateStart] &&
          scheduleList?.[inDateStart].filter(
            (item) =>
              (location === item.restaurant || !item.restaurant || !location) &&
              item.date === getDateISOFormatted(day)
          )) ||
        [];
      return items;
    } else {
      let items =
        (isValidObject(scheduleList) &&
          scheduleList?.[inDateStart] &&
          scheduleList?.[inDateStart].filter(
            (item) =>
              item.group === schedule &&
              (location === item.restaurant || !item.restaurant || !location) &&
              item.date === getDateISOFormatted(day)
          )) ||
        [];
      return items;
    }
  };

  const getDateISOFormatted = (day_num) => {
    return formatDateISO(getDate(day_num, inDateStart));
  };

  const getRestaurantName = (id) => {
    let r = restaurantsDataList.find((rest) => rest.id === id);
    return r ? r.title : "";
  };

  const toggleCopyRow = (id) => {
    let updated = [...copyRow];

    updated.includes(id)
      ? (updated = updated.filter((item) => item !== id))
      : updated.push(id);

    setCopyRow(updated);
  };

  const toggleEditShift = (row = {}) => {
    setIsEditShiftOpen(!isEditShiftOpen);
    setEditShiftData(row);
  };

  const deleteModalToggle = (row = "") => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
    setDeleteId(row?.id);
    setDeleteRow(row);
    fetchScheduleApi();
  };

  const getTableColumns = () => {
    return [
      {
        name: "EMPLOYEE",
        selector: (row) => row["name"],
        // selector: "name",
        sortable: true,
        maxWidth: "40px",
        cell: (row) => (
          <span>
            <a href="#">{row?.user?.full_name}</a>
          </span>
        ),
      },
      {
        name: "DAYPART",
        selector: (row) => row["daypart"],
        // selector: "daypart",
        sortable: true,
        maxWidth: "40px",
        cell: (row) => <span>{row?.day_part?.title}</span>,
      },
      {
        name: "SHIFT TIME",
        selector: (row) => row["shifttime"],
        // selector: "shifttime",
        sortable: true,
        cell: (row) => (
          <span>
            {strTimeFormat(row?.time_start)} - {strTimeFormat(row?.time_end)}
          </span>
        ),
      },
      {
        name: "RESTAURANT",
        selector: (row) => row["restaurant"],
        // selector: (row) => row.restaurant,
        maxwidth: "50px",
        sortable: true,
        cell: (row) => <span>{getRestaurantName(row?.restaurant)}</span>,
      },
      {
        name: "NOTE",
        selector: (row) => row["note"],
        // selector: (row) => row.note,
        maxwidth: "30px",
        sortable: true,
        cell: (row) => <span>{row?.note}</span>,
      },
      {
        name: "COPY",
        selector: (row) => row["copy"],
        // selector: (row) => row.copy,
        sortable: true,
        maxWidth: "12px",
        cell: (row) => (
          <span>
            <div className="form-group custom_checkbox">
              <input
                type="checkbox"
                id={`copy${row?.id}`}
                // name={row?.id}
                // checked={copyRow.includes(row?.id)}
                onClick={() => toggleCopyRow(row?.id)}
              />
              <label htmlFor={`copy${row?.id}`}></label>
            </div>
          </span>
        ),
      },
      {
        name: "ACTIONS",
        selector: (row) => row["actions"],
        // selector: (row) => row.actions,
        sortable: false,
        minWidth: "170px",
        center: true,
        cell: (row) => (
          <React.Fragment>
            <span>
              <button
                className="btn sm btn-outline-primary btn-sm"
                onClick={() => toggleEditShift(row)}
              >
                EDIT
              </button>
              <button
                className="btn btn-outline-secondary btn-sm ml-1"
                onClick={() => deleteModalToggle(row)}
              >
                DELETE
              </button>
            </span>
          </React.Fragment>
        ),
      },
    ];
  };

  const getUserList = () => {
    // let items = [];
    // if (location === 0) {
    //   items: [];
    // } else {
    const items =
      usersDataList &&
      (schedule === 0
        ? usersDataList
        : usersDataList.filter((item) => {
            return item.groups && item.groups.includes(parseInt(schedule));
          }));
    // }
    return items;
  };

  const userList = location === 0 ? [] : getUserList();

  const getCategoryListing = (day) => {
    const scheduleData = getSchedule(day);
    return scheduleData;
  };

  const getAlertText = () => {
    // if (location === 0 && shift !== 0)
    //   toast.error("You need to select location!");
    // if (location !== 0 && shift === 0) toast.error("You need to select shift!");
    // if (location === 0 && shift === 0)
    //   toast.error("You need to select shift and location!");
  };

  const getShiftTimes = () => {
    const items =
      shiftTimeList && shiftTimeList.filter((item) => schedule === item.group);
    return items;
  };

  const shiftData = getShiftTimes();

  const getDayPart = (val) => {
    if (shiftDayPartList.length)
      return shiftDayPartList.find((itm) => itm.id === val).title;
    return "";
  };

  const setEmployeeIdFun = (id, title) => {
    setEmployeeId(id);
    setCustomModalTitle(title);
    toggle();
  };

  const toggle = () => {
    setSelectDayModal(!selectDayModal);
  };

  const handleChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setCustomDayArray([...customDayArray, parseInt(value)]);
    } else {
      setCustomDayArray(customDayArray.filter((e) => e !== parseInt(value)));
    }
  };

  const addDayHandler = (id) => {
    const date = customDayArray.map((d) => {
      return moment(getDateISOFormatted(d + 1)).format("YYYY-MM-DD");
    });

    if (schedule !== 0 && location !== 0 && shift !== 0) {
      const apiVal = {
        user_id: id,
        restaurant_id: parseInt(location),
        shift_time_id: parseInt(shift),
        group_id: parseInt(schedule),
        dates: date,
      };

      dispatch(
        fetchAddUserShift(moment(new Date()).format("YYYY-MM-DD"), apiVal)
      ).then((res) => {
        fetchScheduleApi(schedule, location, shift);
      });
    } else {
      if (location === 0 || schedule === 0 || shift === 0) {
        toast.error("You need to select location, group and shift time");
      }
      location === 0 ? setIsLocation(true) : setIsLocation(false);
      schedule === 0 ? setIsLocation(true) : setIsLocation(false);
      shift === 0 ? setIsLocation(true) : setIsLocation(false);
    }

    setCustomDayArray([]);
    toggle();
  };

  const getDisabled = (id, day) => {
    const a = getDay(day);
    const rrr = a?.toLowerCase() === id?.toLowerCase() ? true : false;

    return rrr;
  };

  const handleWeekCheck = (e) => {
    const { name } = e.target;
    if (name === "mon") {
      setMon(!mon);
    } else if (name === "tue") {
      setTue(!tue);
    } else if (name === "wed") {
      setWed(!wed);
    } else if (name === "thu") {
      setThu(!thu);
    } else if (name === "fri") {
      setFri(!fri);
    } else if (name === "sat") {
      setSat(!sat);
    } else if (name === "sun") {
      setSun(!sun);
    }
  };

  const handleCopyShift = (day) => {
    const date = moment(day_start()).format("YYYY-MM-DD");
    const days = [mon, tue, wed, thu, fri, sat, sun]
      .map((d, i) => {
        if (d === true) return i;
      })
      .filter((d) => d !== null || d !== undefined);

    const apiVal = {
      days_to_copy: days,
      ids_to_copy: copyRow,
    };
    dispatch(createCopyScheduleWeek(apiVal, date)).then(() => {
      setCopyRow([]);
      setMon(false);
      setTue(false);
      setWed(false);
      setThu(false);
      setFri(false);
      setSat(false);
      setSun(false);
      fetchScheduleApi();
    });
  };

  const updateShift = (e) => {
    if (e.target.value === "Select Shift") {
      setShift(0);
      setIsShift(true);
    } else {
      setShift(e.target.value);
      setIsShift(false);
    }
    // fetchScheduleApi(schedule, location);

    // const { shift } = this.setState;
    // this.setState({ shift: e.target.value }, () => {
    //   this.setState({
    //     isShift: shift === 0 ? true : false,
    //   });
    // });
    // this.fetchSchedule(this.state.schedule, this.state.location);
  };

  const deleteLog = () => {
    dispatch(fetchDeleteScheduleShift(deleteRow)).then(() => {
      deleteModalToggle();
      setDeleteId(0);
      setDeleteRow({});
      fetchScheduleApi();
    });
  };

  return (
    <div className="main_box">
      <div className="middle_contain ">
        <div className="pull_left right_sidercustom">
          <div className="main-schedule">
            {isLocation || isShift ? (
              <div className="schedule_alert">
                {/* <div className="alert alert-danger col-12" role="alert"> */}
                {getAlertText()}
                {/* </div> */}
              </div>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-12">
                <div className="schdul_hed">
                  <p>
                    Schedules for {day_start_formatted()} -{" "}
                    {day_end_formatted()}
                  </p>
                  <div className="d-flex ">
                    <button
                      type="button"
                      className="btn btn_pre d-flex align-items-center"
                      onClick={() => {
                        incWeek(-1);
                      }}
                    >
                      <FaArrowCircleLeft size={23} /> Previous Week
                    </button>
                    <button
                      type="button"
                      className="btn btn_Next d-flex align-items-center"
                      onClick={() => {
                        incWeek(1);
                      }}
                    >
                      Next Week <FaArrowCircleRight size={23} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="secdul_dp">
              <div className="row">
                <div className="col-12 col-md-3 col-xl-2">
                  <div className="Secdual__FoRm_GroUP">
                    <FormGroup>
                      <Input
                        type="select"
                        name="location"
                        id="exampleSelect"
                        onChange={updateLocation}
                      >
                        <option>Select Location</option>
                        {restaurantsDataList &&
                          restaurantsDataList.map((d) => (
                            <option value={d.id} key={d.id}>
                              {d.title}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-xl-2">
                  <div className="Secdual__FoRm_GroUP">
                    <FormGroup>
                      <Input
                        type="select"
                        name="schedule"
                        onChange={updateSchedule}
                        disabled={scheduleDisable}
                      >
                        <option>Select Schedule</option>
                        {groupsDataList &&
                          groupsDataList.map((d) => (
                            <option value={d.id} key={d.id}>
                              {d.name}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-xl-2">
                  <div className="Secdual__FoRm_GroUP">
                    <FormGroup>
                      <Input
                        type="select"
                        name="shift"
                        onChange={updateShift}
                        disabled={shiftDisable}
                      >
                        <option>Select Shift</option>
                        {shiftTimeList &&
                          shiftData.map((d) => (
                            <option value={d.id}>
                              {getDayPart(d.day_part) +
                                " [" +
                                strTimeFormat(d.time_start) +
                                " - " +
                                strTimeFormat(d.time_end) +
                                "] " +
                                d.note}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  </div>
                </div>
                {/* <Link to="/shift-time"> */}
                <div className="col-md-2">
                  <button
                    type="button"
                    className="btn btn-block btn-primary GreyButton"
                    onClick={() => {
                      history.push("/shift-time");
                    }}
                  >
                    Edit
                  </button>
                </div>
                {/* </Link> */}
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-12 col-xl-3 col-sm-12">
                <div className="tab-content mt-3">
                  <div
                    className="tab-pane active"
                    id="Available"
                    role="tabpanel"
                  >
                    <div className="ListBox_Cover">
                      <div className="hed-school">
                        <div className="school_img">
                          <img src={schoolImg} alt="School" />
                        </div>
                        <p>
                          Add the person and select the days for which you want
                          to schedule.
                          {/* If you need to schedule someone for more than one day,
                          just open the days you need. */}
                        </p>
                        {scheduleLoading && (
                          <Loader
                            type="TailSpin"
                            color="#38425B"
                            // color="#2196f3"
                            height="50"
                            width="50"
                          />
                        )}
                      </div>

                      {usersLoading && (
                        <div className="justify-content-center d-flex text-center">
                          <h5 className="text-center mt-3 mb-3">
                            <Loader
                              type="TailSpin"
                              color="#38425B"
                              // color="#2196f3"
                              height="100"
                              width="100"
                            />
                          </h5>
                        </div>
                      )}

                      {isValidArray(userList) &&
                        userList.map((empl) => (
                          <div
                            className="list-leftSch d-flex justify-end align-center schedule_name_space mb-2"
                            key={empl.id}
                          >
                            <span>{empl.full_name}</span>
                            <div
                              className="icon_infoPlus "
                              style={{ marginBottom: "0px" }}
                            >
                              <button
                                type="button"
                                style={{
                                  color: "#00a0de",
                                  cursor: "pointer",
                                  border: "2px solid",
                                  borderRadius: "15px",
                                }}
                                onClick={() =>
                                  setEmployeeIdFun(empl.id, empl.full_name)
                                }
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="Requested"
                    role="tabpanel"
                    style={{ color: "red" }}
                  ></div>
                  <div className="tab-pane" id="Showall" role="tabpanel"></div>
                </div>
              </div>

              {/* Custom modal */}
              <div>
                <Modal
                  isOpen={selectDayModal}
                  toggle={toggle}
                  className="custom_modal_schedule"
                  // className={this.props.className}
                >
                  <ModalHeader toggle={toggle}>
                    Schedule for {customModalTitle}
                  </ModalHeader>
                  <ModalBody className="row custom_modal_schedule_body">
                    {[...Array(7)].map((x, day) => {
                      return (
                        <FormGroup
                          className="col-md-5 align-items-center"
                          key={day}
                        >
                          <Label
                            check
                            style={{ fontSize: "20px", marginLeft: "25%" }}
                          >
                            <span className="accodinDate">
                              {getDay(day + 1)},
                              {getDateShortFormatted(day + 1, inDateStart)}
                            </span>
                          </Label>
                          <Input
                            ref={monRef}
                            type="checkbox"
                            value={day}
                            onChange={handleChange}
                            style={{
                              position: "absolute",
                              width: "auto",
                              right: "10px",
                            }}
                          />
                        </FormGroup>
                      );
                    })}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={() => addDayHandler(employeeId)}
                      style={{
                        maxWidth: "140px",
                      }}
                    >
                      Add Employee
                    </Button>{" "}
                    <Button color="secondary" onClick={toggle}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
              {/* custom modal */}

              <div className="col-12 col-md-12 col-xl-9 col-sm-12">
                <div className="left_List_Table">
                  <div className="Admini_Hed">
                    <h4>{schedule ? scheduleText : "Administrator"}</h4>
                  </div>
                  <div
                    className="accordion SchedualAccodi"
                    id="accordionExample"
                  >
                    {[...Array(7)].map((x, day) => {
                      return (
                        <Accordion key={day}>
                          <Card className="border_bottom downArrow">
                            <Accordion.Toggle
                              eventKey={1}
                              onClick={() =>
                                handleAccordion(
                                  // getDateShortFormatted(day + 1, inDateStart),
                                  day
                                )
                              }
                            >
                              <div className="card-header" id="headingOne">
                                <h2 className="mb-0 btn_hed_acoo">
                                  <button
                                    // type="button"
                                    className="btn-accodi btn btn-link"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                  >
                                    <div className="btnTxtCver">
                                      <span className="accodinDate">
                                        {getDay(day + 1)}-
                                        {getDateShortFormatted(
                                          day + 1,
                                          inDateStart
                                        )}
                                      </span>
                                      <span className="Shift">
                                        Shift:
                                        {getSchedule(day + 1).length}
                                      </span>
                                    </div>
                                  </button>
                                  <FaAngleDown />
                                </h2>
                              </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={1}>
                              <Card.Body>
                                {getSchedule(day + 1).length !== 0 ? (
                                  <div
                                    id="collapseOne"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordionExample"
                                  >
                                    <div className="card-body-table data-table word_space table_header_line">
                                      <DataTable
                                        className="table"
                                        responsive={true}
                                        striped={true}
                                        // progressPending={this.props.categoriesDataLoading}
                                        columns={getTableColumns(day + 1)}
                                        // data={this.getScheduleList()}
                                        data={getCategoryListing(day + 1)}
                                        customStyles={customStyles}
                                      />

                                      {copyRow.length > 0 ? (
                                        <>
                                          <div className="copy_select ChkBxcvr">
                                            <div className="form-group custom_checkbox">
                                              <input
                                                type="checkbox"
                                                // name="mon"
                                                value={mon}
                                                id={`mon${day}`}
                                                onChange={handleWeekCheck}
                                                disabled={getDisabled(
                                                  "mon",
                                                  day + 1
                                                )}
                                              />
                                              <label
                                                htmlFor={`mon${day}`}
                                              ></label>
                                              <span>Mon</span>
                                            </div>
                                            <div className="form-group custom_checkbox">
                                              <input
                                                type="checkbox"
                                                name="tue"
                                                value={tue}
                                                id={`tue${day}`}
                                                onChange={handleWeekCheck}
                                                disabled={getDisabled(
                                                  "tue",
                                                  day + 1
                                                )}
                                              />
                                              <label
                                                htmlFor={`tue${day}`}
                                              ></label>{" "}
                                              <span>Tue</span>
                                            </div>
                                            <div className="form-group custom_checkbox">
                                              <input
                                                type="checkbox"
                                                name="wed"
                                                value={wed}
                                                id={`wed${day}`}
                                                onChange={handleWeekCheck}
                                                disabled={getDisabled(
                                                  "wed",
                                                  day + 1
                                                )}
                                              />
                                              <label
                                                htmlFor={`wed${day}`}
                                              ></label>{" "}
                                              <span>Wed</span>
                                            </div>
                                            <div className="form-group custom_checkbox">
                                              <input
                                                type="checkbox"
                                                name="thu"
                                                value={thu}
                                                id={`thu${day}`}
                                                onChange={handleWeekCheck}
                                                disabled={getDisabled(
                                                  "thu",
                                                  day + 1
                                                )}
                                              />
                                              <label
                                                htmlFor={`thu${day}`}
                                              ></label>{" "}
                                              <span>Thu</span>
                                            </div>
                                            <div className="form-group custom_checkbox">
                                              <input
                                                type="checkbox"
                                                name="fri"
                                                value={fri}
                                                id={`fri${day}`}
                                                onChange={handleWeekCheck}
                                                disabled={getDisabled(
                                                  "fri",
                                                  day + 1
                                                )}
                                              />
                                              <label
                                                htmlFor={`fri${day}`}
                                              ></label>{" "}
                                              <span>Fri</span>
                                            </div>
                                            <div className="form-group custom_checkbox">
                                              <input
                                                type="checkbox"
                                                name="sat"
                                                value={sat}
                                                id={`sat${day}`}
                                                onChange={handleWeekCheck}
                                                disabled={getDisabled(
                                                  "sat",
                                                  day + 1
                                                )}
                                              />
                                              <label
                                                htmlFor={`sat${day}`}
                                              ></label>{" "}
                                              <span>Sat</span>
                                            </div>
                                            <div className="form-group custom_checkbox">
                                              <input
                                                type="checkbox"
                                                name="sun"
                                                value={sun}
                                                id={`sun${day}`}
                                                onChange={handleWeekCheck}
                                                disabled={getDisabled(
                                                  "sun",
                                                  day + 1
                                                )}
                                              />
                                              <label
                                                htmlFor={`sun${day}`}
                                              ></label>{" "}
                                              <span>Sun</span>
                                            </div>
                                            <div
                                              className="record_btn"
                                              onClick={() =>
                                                handleCopyShift(day + 1)
                                              }
                                            >
                                              <button>
                                                COPY SELECTED SHIFT(S)
                                              </button>
                                            </div>{" "}
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                ) : (
                                  <span
                                    style={{
                                      padding: "10px",
                                      flex: "1 1 auto",
                                      maxWidth: "100%",
                                    }}
                                  >
                                    Sorry, no schedules were found
                                  </span>
                                )}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isEditShiftOpen && (
        <EditShift
          isOpen={isEditShiftOpen}
          toggle={toggleEditShift}
          editShiftData={editShiftData}
          restaurantsDataList={restaurantsDataList}
          shiftTimeList={shiftTimeList}
          shiftDayPartList={shiftDayPartList}
          editScheduleShift={editScheduleShift}
          fetchSchedule={fetchSchedule}
        />
      )}

      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={deleteModalToggle}
        deleteLog={deleteLog}
      />
    </div>
  );
};

export default NewSchedule;
