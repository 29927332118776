import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { FormGroup } from "reactstrap";
import { connect } from "react-redux";
import moment from "moment";
import "react-dates/initialize";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Select from "react-select";
import DateFnsUtils from "@date-io/date-fns";

import { FaPlus } from "react-icons/fa";

import { fetchManagerLogs } from "../../actions/managerLogs/managerLogs";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { createManagerLogs } from "../../actions/managerLogs/createLog";
import { deleteLogRecord } from "../../actions/managerLogs/deleteLogRecord";
import { fetchProfile } from "../../actions/profile/profile";
import { setManagerLogFilter } from "../../actions/managerLogs/managerLogFilter";

import LogReport from "./modals/LogReport";
import AddNewLog from "./modals/AddNewLog";
import DeleteModal from "../common/DeleteModal";
import { customStyles, getPermission } from "../../modules/utils";

import "react-dates/lib/css/_datepicker.css";

export class ManagersLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      isOpenLogModal: false,
      isOpenAddNewLogModal: false,
      isOpenDeleteModal: false,
      deleteId: "",
    };
  }

  componentDidMount() {
    const { setManagerLogFilter, fetchProfile } = this.props;

    fetchProfile();
    fetchRestaurants();
    this.getInitialData();

    const restaurantFilterId = this.getRestaurantFilterId();

    if (restaurantFilterId) {
      setManagerLogFilter({
        restaurantId: restaurantFilterId,
      });
    }
  }

  getInitialData = async () => {
    const { managerLogFilter, fetchManagerLogs } = this.props;

    const filterDate = managerLogFilter.date
      ? moment(managerLogFilter.date).format("YYYY-MM-DD")
      : null;

    fetchManagerLogs({
      search: "",
      page: managerLogFilter.page,
      itemPerPage: managerLogFilter.itemsPerPage,
      mustSort: false,
      multiSort: false,
      date: filterDate,
      restaurant: managerLogFilter.restaurantId,
      sort_field: managerLogFilter.sort_field,
      sort_type: managerLogFilter.sort_type,
    });
  };

  componentDidUpdate(prevProps) {
    const { managerLogFilter } = this.props;

    if (
      JSON.stringify(prevProps.managerLogFilter) !==
      JSON.stringify(managerLogFilter)
    ) {
      this.getInitialData();
    }
  }

  handlePageChange = (page) => {
    const { setManagerLogFilter } = this.props;

    setManagerLogFilter({
      page: page,
    });
  };

  handleRowPerPage = (itemsPerPage) => {
    const { setManagerLogFilter } = this.props;

    setManagerLogFilter({
      itemsPerPage: itemsPerPage,
    });
  };

  createLogRequest = (passData = []) => {
    if (passData) {
      const apiVal = {
        created_by: passData?.created_by || "",
        date: passData?.date,
        records: passData?.records || [],
        restaurant: passData?.restaurant || "",
        user: passData?.user || "",
      };
      this.props.createManagerLogs(apiVal).then(() => {
        const { createLogsData, restaurantsData, history } = this.props;
        const restaurantName = restaurantsData?.results?.find((data) => {
          if (data.id === passData?.restaurant) return data.title;
        });
        history.push({
          pathname: `managers-log/${createLogsData.restaurant}/${createLogsData.id}`,
          state: {
            log_record: createLogsData.id,
            restaurantName: restaurantName.title,
            restaurantId: passData?.restaurant,
            date: moment(passData?.date).format("dddd, MMMM D, YYYY"),
          },
        });
      });
    }
  };

  logReportModal = (bool) => {
    this.setState({ isOpenLogModal: !this.state.isOpenLogModal });
  };

  newLogReport = () => {
    this.setState({ isOpenAddNewLogModal: !this.state.isOpenAddNewLogModal });
  };

  handleChangeRestaurant = (selectedRestaurant) => {
    const { setManagerLogFilter } = this.props;

    setManagerLogFilter({
      restaurantId: selectedRestaurant?.value,
    });
  };

  getRestaurantFilterId = () => {
    if (this.props?.location?.state?.restaurantFilterId) {
      return this.props?.location?.state?.restaurantFilterId;
    }
    return "";
  };

  handleChangeDate = (date) => {
    const { setManagerLogFilter } = this.props;

    setManagerLogFilter({
      date: date ?? null,
    });
  };

  deleteModalToggle = (id = "") => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
      deleteId: id,
    });
  };

  deleteLog = () => {
    this.props.deleteLogRecord({ id: this.state.deleteId }).then(() => {
      this.deleteModalToggle();
    });
  };

  editManagerLog = (restaurantId = "", id = "", location = "", date = "") => {
    const { history, managerLogFilter } = this.props;

    history.push({
      pathname: `managers-log/${restaurantId}/${id}`,
      state: {
        log_record: id,
        restaurantName: location,
        restaurantId: restaurantId,
        date: moment(date).format("dddd, MMMM D, YYYY"),
        restaurantFilterId: managerLogFilter.restaurantId,
      },
    });
  };

  handleSort = (e) => {
    const { setManagerLogFilter } = this.props;

    setManagerLogFilter({
      sort_field: e.selector === "location" ? "restaurant" : e.selector,
      sort_type: !this.state.sort_type,
    });
  };

  getLogsListing = () => {
    const { managerLogsData, restaurantsData, globalSearch } = this.props;

    let temp = [];
    for (let i = 0; i < managerLogsData?.results.length; i++) {
      let restaurants = managerLogsData?.results[i].restaurant;
      const location = restaurantsData?.results?.filter(
        (data) => data.id === restaurants
      );

      if (location && location.length != 0) {
        temp.push({
          ...managerLogsData?.results[i],
          location: location[0]?.title || "",
        });
      }
    }

    return temp.filter((d) =>
      globalSearch
        ? d.location?.toString().toLowerCase().includes(globalSearch) ||
          d.date?.toLowerCase().includes(globalSearch) ||
          d.loc_weather?.toLowerCase().includes(globalSearch) ||
          d.loc_open?.toLowerCase().includes(globalSearch) ||
          d.loc_mid?.toLowerCase().includes(globalSearch) ||
          d.loc_close?.toLowerCase().includes(globalSearch)
        : d
    );
  };

  getTableColumns = () => {
    const { profileData } = this.props;
    return [
      {
        name: "LOCATION",
        selector: (row) => row.location,
        // selector: "location", // 'location'
        sortable: true,
        cell: (row) => (
          <div
            onClick={() =>
              this.editManagerLog(
                row.restaurant,
                row.id,
                row.location,
                row.date
              )
            }
            style={{
              cursor: "pointer",
              color: "#38425B",
              // color: "#0079c4"
            }}
          >
            {row.location}
          </div>
        ),
      },
      {
        name: "DATE",
        selector: (row) => row.date,
        // selector: "date",
        // right: true,
        sortable: true,
        cell: (row) => {
          return <span>{moment(row.date).format("ddd, MMM D, YYYY")}</span>;
        },
      },
      {
        name: "WEATHER",
        selector: (row) => row.loc_weather,
        // selector: "loc_weather",
        sortable: true,
      },
      {
        name: "OPEN",
        selector: (row) => row.loc_open,
        // selector: "loc_open",
        sortable: true,
      },
      {
        name: "MID",
        selector: (row) => row.loc_mid,
        // selector: "loc_mid",
        sortable: true,
      },
      {
        name: "CLOSE",
        selector: (row) => row.loc_close,
        // selector: "loc_close",
        sortable: true,
      },
      {
        name: "ACTIONS",
        selector: (row) => row.actions,
        sortable: false,
        center: true,
        cell: (row) => (
          <span>
            {getPermission("bshift.bs_add_log_record", profileData) && (
              <button
                className="btn sm btn-outline-primary btn-sm"
                onClick={() =>
                  this.editManagerLog(
                    row.restaurant,
                    row.id,
                    row.location,
                    row.date
                  )
                }
              >
                EDIT
              </button>
            )}

            {getPermission("bshift.bs_delete_log_record", profileData) && (
              <button
                value={row.id}
                className="btn btn-outline-secondary btn-sm ml-1"
                onClick={() => this.deleteModalToggle(row.id)}
              >
                DELETE
              </button>
            )}
          </span>
        ),
      },
    ];
  };

  render() {
    const {
      restaurantsData,
      managerLogsDataLoading,
      managerLogsData,
      profileData,
      managerLogFilter,
    } = this.props;

    const {
      isOpenLogModal,
      isOpenAddNewLogModal,
      isOpenDeleteModal,
    } = this.state;
    const paginationTotalRows = managerLogsData?.count;

    if (!isOpenLogModal) {
      document.body.classList.remove("custom-modal-open");
    }

    const optionRestaurant =
      restaurantsData &&
      restaurantsData?.results.map((d) => {
        return {
          value: d.id,
          label: d.title,
        };
      });

    const selectedRestaurant = restaurantsData?.results?.find((data) => {
      return data.id === managerLogFilter.restaurantId;
    });

    return (
      <div className="main_box">
        {/* <Navbar />
				<div className="middle_contain ">
					<Sidebar /> */}
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div className="container-fluid">
                <div className="row mt-4 justify-content-between page_title">
                  <div className="col-md-6">
                    <h2>Managers Log</h2>
                    {/* <div class="custom-control custom-switch manager-logs-switch">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customSwitch1"
                      />
                      <label class="custom-control-label" for="customSwitch1">
                        Daily email of Managers Log
                      </label>
                    </div> */}
                  </div>
                  <div className="col-md-6 manager_form">
                    <FormGroup>
                      <Select
                        options={optionRestaurant}
                        value={
                          selectedRestaurant && {
                            value: selectedRestaurant?.id,
                            label: selectedRestaurant?.title,
                          }
                        }
                        onChange={this.handleChangeRestaurant}
                        placeholder="Select Restaurant"
                        isClearable
                        menuShouldBlockScroll={true}
                      />
                    </FormGroup>
                    <div className="row managers_log">
                      <div className="mr-2" style={{ cursor: "pointer" }}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          style={{ cursor: "pointer" }}
                        >
                          <DatePicker
                            variant="dialogue"
                            label="Select Date"
                            inputVariant="outlined"
                            value={
                              managerLogFilter.date
                                ? moment(managerLogFilter.date).format(
                                    "MM/DD/YYYY"
                                  )
                                : null
                            }
                            name="date"
                            onChange={this.handleChangeDate}
                            clearable
                            className="select_date_filter"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="main_info_select_1 ">
                        {getPermission(
                          "bshift.bs_view_log_report",
                          profileData
                        ) && (
                          <div className="mr-2">
                            <button
                              type="button"
                              className="btn  btn-primary"
                              onClick={this.logReportModal}
                            >
                              REPORT
                            </button>
                          </div>
                        )}

                        {getPermission(
                          "bshift.bs_add_log_record",
                          profileData
                        ) && (
                          <div className="">
                            <button
                              type="button"
                              className="btn btn-primary"
                              style={{ whiteSpace: "nowrap" }}
                              onClick={this.newLogReport}
                            >
                              <span className="close_icon_report">
                                {" "}
                                <FaPlus />{" "}
                              </span>
                              NEW LOG
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table_header_line data-table mt-5">
                  {restaurantsData &&
                    this.props?.managerLogsData?.results.length > 0 && (
                      <DataTable
                        columns={this.getTableColumns()}
                        data={this.getLogsListing()}
                        progressPending={managerLogsDataLoading}
                        pagination
                        responsive={true}
                        paginationServer={true}
                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                        striped
                        paginationTotalRows={paginationTotalRows}
                        onChangePage={this.handlePageChange}
                        onChangeRowsPerPage={this.handleRowPerPage}
                        customStyles={customStyles}
                        paginationPerPage={managerLogFilter.itemsPerPage}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {isOpenLogModal && (
          <LogReport isOpen={isOpenLogModal} toggle={this.logReportModal} />
        )}
        <AddNewLog
          isOpen={isOpenAddNewLogModal}
          toggle={this.newLogReport}
          restaurantsData={restaurantsData}
          createLogRequest={this.createLogRequest}
        />
        <DeleteModal
          isOpen={isOpenDeleteModal}
          toggle={this.deleteModalToggle}
          deleteLog={this.deleteLog}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    managerLogsDataLoading: state.managerLogsData.isLoading,
    managerLogsData: state.managerLogsData.data,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    createLogsDataLoading: state.createLogsData.isLoading,
    createLogsData: state.createLogsData.data,

    deleteLogRecordDataLoading: state.deleteLogRecordData.isLoading,
    deleteLogRecordData: state.deleteLogRecordData.data,

    globalSearch: state.globalSearch.searchQuery,

    profileData: state.profileData.data,

    managerLogFilter: state.managerLogFilter,
  };
};
const mapDispatchToProps = {
  fetchManagerLogs,
  fetchRestaurants,
  createManagerLogs,
  deleteLogRecord,
  fetchProfile,
  setManagerLogFilter,
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagersLog);
