export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';

export const FETCH_EDIT_PROFILE_REQUEST = 'FETCH_EDIT_PROFILE_REQUEST';
export const FETCH_EDIT_PROFILE_FAILURE = 'FETCH_EDIT_PROFILE_FAILURE';
export const FETCH_EDIT_PROFILE_SUCCESS = 'FETCH_EDIT_PROFILE_SUCCESS';

export const FETCH_CHANGE_PASSWORD_REQUEST = 'FETCH_CHANGE_PASSWORD_REQUEST';
export const FETCH_CHANGE_PASSWORD_FAILURE = 'FETCH_CHANGE_PASSWORD_FAILURE';
export const FETCH_CHANGE_PASSWORD_SUCCESS = 'FETCH_CHANGE_PASSWORD_SUCCESS';
