import {
	DELETE_CALENDAR_EVENT_REQUEST,
	DELETE_CALENDAR_EVENT_FAILURE,
	DELETE_CALENDAR_EVENT_SUCCESS,
} from '../../types/calendar';

const deleteCalendarEventReducer = (
	state = {
		data: null,
		error: null,
		isLoading: false,
	},
	action,
) => {
	switch (action.type) {
		case DELETE_CALENDAR_EVENT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case DELETE_CALENDAR_EVENT_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case DELETE_CALENDAR_EVENT_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default deleteCalendarEventReducer;
