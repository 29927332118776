import {
  FETCH_CALENDAR_EVENT_REQUEST,
  FETCH_CALENDAR_EVENT_FAILURE,
  FETCH_CALENDAR_EVENT_SUCCESS,
} from "../../types/calendar";
import { getApi } from "../api";

export const fetchCalendarEventRequest = () => ({
  type: FETCH_CALENDAR_EVENT_REQUEST,
});

export const fetchCalendarEventSuccess = (data) => ({
  type: FETCH_CALENDAR_EVENT_SUCCESS,
  data: data,
});

export const fetchCalendarEventFailure = (error) => ({
  type: FETCH_CALENDAR_EVENT_FAILURE,
  error,
});

export const fetchCalendarEvent = (data, restaurants, kinds) => (
  dispatch,
  getState
) => {
  let url;

  if (restaurants && kinds) {
    if (restaurants.length == 0) {
      url = `bshift/api/calendarevent?kind=${kinds.toString()}`;
    } else if (kinds.length == 0) {
      url = `bshift/api/calendarevent?restaurant=${restaurants.toString()}`;
    } else {
      url = `bshift/api/calendarevent?restaurant=${restaurants.toString()}&kind=${kinds.toString()}`;
    }
  }

  dispatch(fetchCalendarEventRequest());
  return getApi(
    restaurants && kinds
      ? url
      : data
      ? `bshift/api/calendarevent/${data}`
      : `bshift/api/calendarevent`
  )
    .then((data) => {
      dispatch(fetchCalendarEventSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchCalendarEventFailure(error));
    });
};
