import {
	FETCH_TRAINING_QUIZ_REQUEST,
	FETCH_TRAINING_QUIZ_SUCCESS,
	FETCH_TRAINING_QUIZ_FAILURE,
} from '../../types/training';
import { getApi } from '../api';

export const fetchTrainingQuizRequest = () => ({
	type: FETCH_TRAINING_QUIZ_REQUEST,
});

export const fetchTrainingQuizSuccess = (data) => ({
	type: FETCH_TRAINING_QUIZ_SUCCESS,
	data: data,
});

export const fetchTrainingQuizFailure = (error) => ({
	type: FETCH_TRAINING_QUIZ_FAILURE,
	error,
});

export const fetchTrainingQuiz = () => (dispatch, getState) => {
	dispatch(fetchTrainingQuizRequest());

	return getApi(`bshift/api/training_quizzes`)
		.then((data) => {
			dispatch(fetchTrainingQuizSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchTrainingQuizFailure(error));
		});
};
