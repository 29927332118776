import {
  FETCH_SALES_LIST_REQUEST,
  FETCH_SALES_LIST_SUCCESS,
  FETCH_SALES_LIST_FAILURE,
} from "../../types/sales";
import { getApi } from "../api";

export const fetchSalesListRequest = () => ({
  type: FETCH_SALES_LIST_REQUEST,
});

export const fetchSalesListSuccess = (data) => ({
  type: FETCH_SALES_LIST_SUCCESS,
  data: data,
});

export const fetchSalesListFailure = (error) => ({
  type: FETCH_SALES_LIST_FAILURE,
  error,
});

export const fetchSalesList = (apiParams) => (dispatch) => {
  dispatch(fetchSalesListRequest());
  return getApi(`bshift/api/sales_check`, apiParams)
    .then((data) => {
      dispatch(fetchSalesListSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchSalesListFailure(error));
    });
};
