import {
	FETCH_TASK_USERS_REQUEST,
	FETCH_TASK_USERS_SUCCESS,
	FETCH_TASK_USERS_FAILURE,
} from '../../types/users';

const taskUserListReducer = (
	state = {
		data: null,
		error: null,
		isLoading: false,
	},
	action,
) => {
	switch (action.type) {
		case FETCH_TASK_USERS_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_TASK_USERS_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_TASK_USERS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default taskUserListReducer;
