import {
	FETCH_CLEAR_SCHEDULE_WEEK_REQUEST,
	FETCH_CLEAR_SCHEDULE_WEEK_FAILURE,
	FETCH_CLEAR_SCHEDULE_WEEK_SUCCESS,
} from '../../types/schedule';
import { deleteApiWithData } from '../api';

export const clearScheduleWeekRequest = () => ({
	type: FETCH_CLEAR_SCHEDULE_WEEK_REQUEST,
});

export const clearScheduleWeekSuccess = (data) => ({
	type: FETCH_CLEAR_SCHEDULE_WEEK_SUCCESS,
	data: data,
});

export const clearScheduleWeekFailure = (error) => ({
	type: FETCH_CLEAR_SCHEDULE_WEEK_FAILURE,
	error,
});

export const clearScheduleWeek = (date, data) => (dispatch, getState) => {
	dispatch(clearScheduleWeekRequest());

	return deleteApiWithData(`bshift/api/schedule_week/${date}`, data)
		.then((data) => {
			dispatch(clearScheduleWeekSuccess(data));
		})
		.catch((error) => {
			dispatch(clearScheduleWeekFailure(error));
		});
};
