import {
  CREATE_LOG_RECORD_VALUE_REQUEST,
  CREATE_LOG_RECORD_VALUE_FAILURE,
  CREATE_LOG_RECORD_VALUE_SUCCESS,
} from "../../types/managerLogs";
import { postApi } from "../api";

export const createLogRecordValueRequest = () => ({
  type: CREATE_LOG_RECORD_VALUE_REQUEST,
});

export const createLogRecordValueSuccess = (data) => ({
  type: CREATE_LOG_RECORD_VALUE_SUCCESS,
  data: data,
});

export const createLogRecordValueFailure = (error) => ({
  type: CREATE_LOG_RECORD_VALUE_FAILURE,
  error,
});

export const createLogRecordValue = (formData, logId) => (
  dispatch,
  getState
) => {
  dispatch(createLogRecordValueRequest());

  return postApi(`bshift/api/logrecord_values/${logId}`, formData, true)
    .then((data) => {
      dispatch(createLogRecordValueSuccess(data));
    })
    .catch((error) => {
      dispatch(createLogRecordValueFailure(error));
    });
};
