import {
	FETCH_DELETE_DOCUMENT_REQUEST,
	FETCH_DELETE_DOCUMENT_SUCCESS,
	FETCH_DELETE_DOCUMENT_FAILURE,
} from '../../types/document';

const deleteDocumentReducer = (
	state = {
		data: null,
		error: null,
		isLoading: false,
	},
	action,
) => {
	switch (action.type) {
		case FETCH_DELETE_DOCUMENT_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_DELETE_DOCUMENT_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_DELETE_DOCUMENT_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default deleteDocumentReducer;
