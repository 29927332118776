import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";
import { customStyles, strTimeFormat } from "../../../modules/utils";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import DataTable from "react-data-table-component";

import { fetchDropRequest } from "../../../actions/schedule/dropRequest";
import { fetchPickupRequest } from "../../../actions/schedule/pickupRequest";
import { fetchCreateDropRequest } from "../../../actions/schedule/createDropRequest";
import { fetchScheduleCount } from "../../../actions/schedule/scheduleCount";
import { fetchUpdatePickupRequest } from "../../../actions/schedule/updatePickupRequest";

class ShiftChangeRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sort_dropField: "name",
      sort_dropType: "ASC",
      sort_pickUpField: "name",
      sort_pickUpType: "ASC",
    };
  }

  componentDidMount() {
    const {
      restaurant,
      category,
      position,
      fetchDropRequest,
      fetchPickupRequest,
    } = this.props;
    fetchDropRequest({ restaurant, category, position });
    fetchPickupRequest({ restaurant, category, position });
  }

  getDropListing = () => {
    const { dropRequestList } = this.props;
    const { sort_dropField, sort_dropType } = this.state;

    return dropRequestList
      ?.map((d) => {
        const shiftTime = `${strTimeFormat(
          d?.shift_time?.time_start
        )} - ${strTimeFormat(d?.shift_time?.time_end)}`;
        return {
          id: d.id,
          name: d?.user?.full_name || "",
          date: d?.date || "",
          dayPart: d?.day_part?.title || "",
          shiftTime: shiftTime || "",
          restaurant: d?.restaurant?.title || "",
          position: d?.group?.name || "",
        };
      })
      .sort((a, b) =>
        sort_dropType === "ASC"
          ? a[sort_dropField].localeCompare(b[sort_dropField])
          : b[sort_dropField].localeCompare(a[sort_dropField])
      );
  };

  getDropTableColumns = () => {
    return [
      {
        name: "EMPLOYEE",
        // selector: "name",
        selector: (row) => row["name"],
        sortable: true,
      },
      {
        name: "DATE",
        // selector: "date",
        selector: (row) => row["date"],
        sortable: true,
        left: true,
      },
      {
        name: "DAYPART",
        selector: (row) => row["dayPart"],
        // selector: "dayPart",
        sortable: true,
        left: true,
      },
      {
        name: "SHIFT TIME",
        selector: (row) => row["shiftTime"],
        // selector: "shiftTime",
        sortable: true,
        left: true,
      },
      {
        name: "RESTAURANT",
        selector: (row) => row["restaurant"],
        // selector: "restaurant",
        sortable: true,
        left: true,
      },
      {
        name: "POSITION",
        selector: (row) => row["position"],
        // selector: "position",
        sortable: true,
        left: true,
      },

      {
        name: "APPROVE",
        selector: (row) => row["approve"],
        // selector: "approve",
        center: true,
        cell: (row) => (
          <h4 style={{ color: "green", cursor: "pointer" }}>
            <FaCheckCircle
              onClick={() => this.handleDropStatus(row?.id, "Drop Confirmed")}
            />
          </h4>
        ),
      },
      {
        name: "REJECT",
        selector: (row) => row["reject"],
        // selector: "reject",
        center: true,
        cell: (row) => (
          <h4 style={{ color: "red", cursor: "pointer" }}>
            <FaTimesCircle
              onClick={() => this.handleDropStatus(row?.id, "Drop Rejected")}
            />
          </h4>
        ),
      },
    ];
  };

  handleDropStatus = (id, status) => {
    const apiVal = {
      shift_status: status,
    };
    this.props.fetchCreateDropRequest(id, apiVal).then(() => {
      const { restaurant, category, position } = this.props;
      this.props.fetchDropRequest({ restaurant, category, position });
      this.props.fetchScheduleCount(restaurant, category, position);
      this.props.fetchSchedule();
    });
  };

  getPickUpListing = () => {
    const { pickupRequestList } = this.props;
    const { sort_pickUpField, sort_pickUpType } = this.state;

    return pickupRequestList
      ?.map((d) => {
        const shiftTime = `${strTimeFormat(
          d?.schedule_view?.shift_time?.time_start
        )} - ${strTimeFormat(d?.schedule_view?.shift_time?.time_end)}`;
        return {
          id: d.id,
          name: d?.created_by?.full_name || "",
          date: d?.schedule_view?.date || "",
          dayPart: d?.schedule_view?.day_part?.title || "",
          shiftTime: shiftTime || "",
          restaurant: d?.schedule_view?.restaurant?.title || "",
          position: d?.group?.name || "",
        };
      })
      .sort((a, b) =>
        sort_pickUpType === "ASC"
          ? a[sort_pickUpField].localeCompare(b[sort_pickUpField])
          : b[sort_pickUpField].localeCompare(a[sort_pickUpField])
      );
  };

  getPickUpTableColumns = () => {
    return [
      {
        name: "EMPLOYEE",
        selector: (row) => row["name"],
        // selector: "name",
        sortable: true,
      },
      {
        name: "DATE",
        selector: (row) => row["date"],
        // selector: "date",
        sortable: true,
        left: true,
      },
      {
        name: "DAYPART",
        selector: (row) => row["dayPart"],
        // selector: "dayPart",
        sortable: true,
        left: true,
      },
      {
        name: "SHIFT TIME",
        selector: (row) => row["shiftTime"],
        // selector: "shiftTime",
        sortable: true,
        left: true,
      },
      {
        name: "RESTAURANT",
        selector: (row) => row["restaurant"],
        // selector: "restaurant",
        sortable: true,
        left: true,
      },
      {
        name: "POSITION",
        selector: (row) => row["position"],
        // selector: "position",
        sortable: true,
        left: true,
      },

      {
        name: "APPROVE",
        selector: (row) => row["approve"],
        // selector: "approve",
        center: true,
        cell: (row) => (
          <h4 style={{ color: "green", cursor: "pointer" }}>
            <FaCheckCircle
              onClick={() =>
                this.handlePickupStatus(row?.id, "Pick Up Confirmed")
              }
            />
          </h4>
        ),
      },
      {
        name: "REJECT",
        selector: (row) => row["reject"],
        // selector: "reject",
        center: true,
        cell: (row) => (
          <h4 style={{ color: "red", cursor: "pointer" }}>
            <FaTimesCircle
              onClick={() =>
                this.handlePickupStatus(row?.id, "Pick Up Rejected")
              }
            />
          </h4>
        ),
      },
    ];
  };

  handlePickupStatus = (id, status) => {
    const apiVal = {
      pickup_request_status: status,
    };
    this.props.fetchUpdatePickupRequest(id, apiVal).then(() => {
      const { restaurant, category, position } = this.props;
      this.props.fetchPickupRequest({ restaurant, category, position });
      this.props.fetchScheduleCount(restaurant, category, position);
      this.props.fetchSchedule();
    });
  };

  handleDropSort = (e) => {
    this.setState({
      sort_dropField: e.selector,
      sort_dropType: this.state.sort_dropType === "ASC" ? "DESC" : "ASC",
    });
  };

  handlePickUpSort = (e) => {
    this.setState({
      sort_pickUpField: e.selector,
      sort_pickUpType: this.state.sort_pickUpType === "ASC" ? "DESC" : "ASC",
    });
  };

  render() {
    const {
      isOpen,
      toggle,
      pickupRequestLoading,
      dropRequestLoading,
    } = this.props;
    return (
      <Modal isOpen={isOpen} className="shift_change_modal">
        <ModalHeader toggle={toggle}>Shift Change Requests</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-12 as_chbx">
              <div className="v-data-table text-center schedule-table theme--light">
                <div className="v-data-table__wrapper">
                  <div className="v-toolbar-content" style={{ height: "64px" }}>
                    <div
                      data-v-07a1d9f8=""
                      className="v-toolbar-title red--text"
                    >
                      Drop Shift Requests
                    </div>
                  </div>

                  <DataTable
                    className="table"
                    responsive={true}
                    striped={true}
                    progressPending={dropRequestLoading}
                    columns={this.getDropTableColumns()}
                    data={this.getDropListing()}
                    pagination={true}
                    paginationServer={false}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    onSort={this.handleDropSort}
                    customStyles={customStyles}
                  />
                </div>
              </div>
              <div className="v-data-table text-center schedule-table theme--light as_chbx">
                <div className="v-data-table__wrapper">
                  <div className="v-toolbar-content" style={{ height: "64px" }}>
                    <div
                      data-v-07a1d9f8=""
                      className="v-toolbar-title blue--text"
                    >
                      Pick Up Shift Requests
                    </div>
                  </div>

                  <DataTable
                    className="table"
                    responsive={true}
                    striped={true}
                    progressPending={pickupRequestLoading}
                    columns={this.getPickUpTableColumns()}
                    data={this.getPickUpListing()}
                    pagination={true}
                    paginationServer={false}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    onSort={this.handlePickUpSort}
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pickupRequestLoading: state.pickupRequest.isLoading,
    pickupRequestList: state.pickupRequest?.data?.results,

    dropRequestLoading: state.dropRequest.isLoading,
    dropRequestList: state.dropRequest?.data?.results,
  };
};
const mapDispatchToProps = {
  fetchDropRequest,
  fetchCreateDropRequest,
  fetchPickupRequest,
  fetchScheduleCount,
  fetchUpdatePickupRequest,
};
export default connect(mapStateToProps, mapDispatchToProps)(ShiftChangeRequest);
