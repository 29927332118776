import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
// import DatePicker from 'react-datepicker';
import DateFnsUtils from "@date-io/date-fns";

import trainingQuizStatic from "../../../modules/trainingQuiz.json";

import { fetchRestaurants } from "../../../actions/restaurants/restaurants";
import { fetchGroups } from "../../../actions/groups/groups";
import { fetchUsers } from "../../../actions/users/users";
import { fetchTrainingQuiz } from "../../../actions/training/trainingQuiz";
import {
  isValidArray,
  sortSelectOptionWithLabels,
} from "../../../modules/utils";
import { fetchAddQuiz } from "../../../actions/training/addQuiz";

export class AddQuizModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      optionPosition: [],
      optionLocation: [],
      optionEmployee: [],
      positionValue: [],
      locationValue: [],
      employeeValue: [],
      optionQuiz: [],
      quizValue: [],
      date: moment(),
      isQuiz: false,
    };
  }

  componentDidMount() {
    const {
      fetchGroups,
      fetchRestaurants,
      fetchTrainingQuiz,
      fetchUsers,
    } = this.props;

    fetchGroups();
    fetchRestaurants();
    fetchUsers();
    fetchTrainingQuiz();
  }

  handleChangeOption = (optionPosition) => {
    const positionValue = optionPosition.map((d) => {
      return d.value;
    });
    this.setState({ optionPosition, positionValue });
  };

  handleChangeLocation = (optionLocation) => {
    const locationValue = optionLocation.map((d) => {
      return d.value;
    });
    this.setState({ optionLocation, locationValue });
  };

  handleChangeOptionQuiz = (optionQuiz) => {
    const quizValue = optionQuiz.map((d) => {
      return d.value;
    });
    this.setState({ optionQuiz, quizValue }, () => {
      const { quizValue } = this.state;
      this.setState({
        isQuiz: quizValue.length === 0 ? true : false,
      });
    });
  };

  handleChangeEmployee = (optionEmployee) => {
    const employeeValue = optionEmployee.map((d) => {
      return d.value;
    });
    this.setState({ optionEmployee, employeeValue });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const manager = localStorage.getItem("loggedUserId");
    const {
      locationValue,
      positionValue,
      employeeValue,
      date,
      quizValue,
      isQuiz,
    } = this.state;

    const apiVal = {
      employee_groups: positionValue,
      employees: employeeValue,
      restaurants: locationValue,
      training_quizzes: quizValue,
      manager: manager,
      due_date: moment(date).format("YYYY-MM-DD"),
    };

    if (quizValue.length === 0) {
      this.setState({
        isQuiz: !isQuiz,
      });
    } else {
      this.props.fetchAddQuiz(apiVal).then(() => {
        this.props.toggle();
      });
    }
  };

  handleDateChange = (date) => {
    this.setState({ date });
  };

  render() {
    const {
      groupsData,
      restaurantsData,
      usersData,
      isOpen,
      toggle,
      trainingQuizData,
    } = this.props;
    const { date, locationValue, isQuiz } = this.state;

    const dataTrainingEmployee = isValidArray(usersData?.results)
      ? usersData.results
      : trainingQuizStatic.results;

    const optionEmployee = isValidArray(dataTrainingEmployee)
      ? dataTrainingEmployee
          .filter((data) => {
            return isValidArray(data?.restaurants)
              ? data.restaurants.some((r) => locationValue.indexOf(r) >= 0)
              : false;
          })
          .map((d) => ({ value: d.id, label: d.full_name }))
      : [];

    const optionLocation = restaurantsData?.results.map((d) => {
      return {
        value: d.id,
        label: d.title,
      };
    });
    const optionPosition = groupsData?.results.map((d) => {
      return {
        value: d.id,
        label: d.name,
      };
    });

    const dataTrainingQuiz = isValidArray(trainingQuizData?.results)
      ? trainingQuizData.results
      : trainingQuizStatic.results;

    const optionQuiz = isValidArray(dataTrainingQuiz)
      ? dataTrainingQuiz
          .filter((data) => {
            return isValidArray(data?.restaurants)
              ? data.restaurants.some((r) => locationValue.indexOf(r) >= 0)
              : false;
          })
          .map((d) => ({ value: d.id, label: d.title }))
      : [];

    return (
      <Modal isOpen={isOpen} centered className="assign_quiz_model">
        <ModalHeader toggle={toggle}>Assign Quiz</ModalHeader>
        <ModalBody>
          <div className="add_quiz_modal">
            <form onSubmit={this.handleSubmit}>
              <div className="form-group announce_form form_input ">
                <Select
                  options={sortSelectOptionWithLabels(optionLocation)}
                  isMulti
                  name="optionLocation"
                  value={this.state.optionLocation}
                  onChange={(e) => this.handleChangeLocation(e)}
                  placeholder="Select location"
                />
              </div>
              <div className="form-group announce_form form_input ">
                <Select
                  options={sortSelectOptionWithLabels(optionQuiz)}
                  isMulti
                  name="optionQuiz"
                  value={this.state.optionQuiz}
                  onChange={(e) => this.handleChangeOptionQuiz(e)}
                  placeholder="Select Quiz"
                />
                {isQuiz && (
                  <p style={{ color: "red" }}>This field is required.</p>
                )}
              </div>
              <div className="form-group announce_form form_input">
                <Select
                  options={optionPosition}
                  isMulti
                  value={this.state.optionPosition}
                  onChange={(e) => this.handleChangeOption(e)}
                  placeholder="Select position"
                />
              </div>
              <div className="form-group assign_employee announce_form form_input">
                <Select
                  options={optionEmployee}
                  isMulti
                  value={this.state.optionEmployee}
                  onChange={(e) => this.handleChangeEmployee(e)}
                  placeholder="Select employee"
                />
              </div>
              <div className="calander_model assigned_modal_date mb-5">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    // label="DateTimePicker"
                    autoOk
                    variant="inline"
                    format="MM/dd/yyyy"
                    InputAdornmentProps={{ position: "start" }}
                    inputVariant="outlined"
                    value={this.state.date}
                    name="date"
                    onChange={(date) => this.handleDateChange(date)}
                    maxDate={new Date()}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div className="d-flex new_quizzes">
                <div className="record_btn">
                  <button onClick={toggle}>CANCEL</button>
                </div>
                <div className="record_btn">
                  <button type="submit">SAVE</button>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groupsDataLoading: state.groupsData.isLoading,
    groupsData: state.groupsData.data,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    usersDataLoading: state.usersData.isLoading,
    usersData: state.usersData.data,

    trainingQuizDataLoading: state.trainingQuizData.isLoading,
    trainingQuizData: state.trainingQuizData.data,

    addQuizDataLoading: state.addQuizData.isLoading,
    addQuizData: state.addQuizData.data,
  };
};
const mapDispatchToProps = {
  fetchGroups,
  fetchRestaurants,
  fetchUsers,
  fetchTrainingQuiz,
  fetchAddQuiz,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddQuizModal);
