import {
  FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_REQUEST,
  FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_SUCCESS,
  FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_FAILURE,
} from "../../types/announcements";
import { getApi } from "../api";

export const fetchEmailAnnouncementsDetailsRequest = () => ({
  type: FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_REQUEST,
});

export const fetchEmailAnnouncementsDetailsSuccess = (data) => ({
  type: FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_SUCCESS,
  data: data,
});

export const fetchEmailAnnouncementsDetailsFailure = (error) => ({
  type: FETCH_ANNOUNCEMENTS_DETAILS_EMAIL_FAILURE,
  error,
});

export const fetchAnnouncementDetailsEmail = (params) => (
  dispatch,
  getState
) => {
  dispatch(fetchEmailAnnouncementsDetailsRequest());
  return getApi(`bshift/api/announcements`, { is_email: true, ...params })
    .then((data) => {
      dispatch(fetchEmailAnnouncementsDetailsSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchEmailAnnouncementsDetailsFailure(error));
    });
};
