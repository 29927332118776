import { toastError } from '../../modules/utils';
import {
	FETCH_TODO_TASK_DETAIL_REQUEST,
	FETCH_TODO_TASK_DETAIL_SUCCESS,
	FETCH_TODO_TASK_DETAIL_FAILURE,
} from '../../types/todo';
import { getApi } from '../api';

export const fetchTodoTaskDetailRequest = () => ({
	type: FETCH_TODO_TASK_DETAIL_REQUEST,
});

export const fetchTodoTaskDetailSuccess = (data) => ({
	type: FETCH_TODO_TASK_DETAIL_SUCCESS,
	data: data,
});

export const fetchTodoTaskDetailFailure = (error) => ({
	type: FETCH_TODO_TASK_DETAIL_FAILURE,
	error,
});

export const fetchTodoTaskDetail = (id) => (dispatch) => {
	dispatch(fetchTodoTaskDetailRequest());
	return getApi(`restaurant/api/todo_task_detail/${id}`)
		.then((data) => {
			dispatch(fetchTodoTaskDetailSuccess(data));
		})
		.catch((error) => {
			toastError('Something went wrong!');
			dispatch(fetchTodoTaskDetailFailure(error));
		});
};
