import { toastError } from "../../modules/utils";
import {
  FETCH_TODO_LIST_REQUEST,
  FETCH_TODO_LIST_FAILURE,
  FETCH_TODO_LIST_SUCCESS,
} from "../../types/todo";
import { getApi } from "../api";

export const fetchTodoListRequest = () => ({
  type: FETCH_TODO_LIST_REQUEST,
});

export const fetchTodoListSuccess = (data) => ({
  type: FETCH_TODO_LIST_SUCCESS,
  data: data,
});

export const fetchTodoListFailure = (error) => ({
  type: FETCH_TODO_LIST_FAILURE,
  error,
});

export const fetchTodoList = (apiData) => (dispatch) => {
  dispatch(fetchTodoListRequest());
  return getApi(`restaurant/api/todo_list`, apiData)
    .then((data) => {
      dispatch(fetchTodoListSuccess(data));
    })
    .catch((error) => {
      toastError("Something went wrong!");
      dispatch(fetchTodoListFailure(error));
    });
};
