import React, { Component } from "react";

export class RowComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { data } = this.props;
    // console.log(
    //   "🚀 ~ file: RowComponent.js ~ line 12 ~ RowComponent ~ render ~ data",
    //   data
    // );
    const { category, email, name, phone, position, restaurants, id } = data;
    return (
      <div className="p-3">
        <p>
          <div className="row">
            <div className="col-1">
              <b>Name:</b>
            </div>
            <div className="col-11">{name}</div>
          </div>
        </p>
        <p>
          <div className="row">
            <div className="col-1">
              <b>Email:</b>
            </div>
            <div className="col-11">{email}</div>
          </div>
        </p>
        <p>
          <div className="row">
            <div className="col-1">
              <b>Phone:</b>
            </div>
            <div className="col-11">{phone}</div>
          </div>
        </p>
        <p>
          <div className="row">
            <div className="col-1">
              <b>Position:</b>
            </div>
            <div className="col-11">{position}</div>
          </div>
        </p>
        <p>
          <div className="row">
            <div className="col-1">
              <b>Category:</b>
            </div>
            <div className="col-11">{category}</div>
          </div>
        </p>
        <p>
          <div className="row">
            <div className="col-1">
              <b>Restaurant:</b>
            </div>
            <div className="col-11">{restaurants}</div>
          </div>
        </p>
      </div>
    );
  }
}

export default RowComponent;
