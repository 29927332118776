import {
  FETCH_FOOD_GALLERY_REQUEST,
  FETCH_FOOD_GALLERY_SUCCESS,
  FETCH_FOOD_GALLERY_FAILURE,
} from "../../types/foodGallery";
import { getApi, fullGetApi } from "../api";

export const fetchFoodGalleryRequest = () => ({
  type: FETCH_FOOD_GALLERY_REQUEST,
});

export const fetchFoodGallerySuccess = (data) => ({
  type: FETCH_FOOD_GALLERY_SUCCESS,
  data: data,
});

export const fetchFoodGalleryFailure = (error) => ({
  type: FETCH_FOOD_GALLERY_FAILURE,
  error,
});

export const fetchNewFoodGallery = (params, url) => (dispatch, getState) => {
  dispatch(fetchFoodGalleryRequest());
  return getApi(`bshift/api/get_images`, params)
    .then((data) => {
      dispatch(fetchFoodGallerySuccess(data));
    })
    .catch((error) => {
      dispatch(fetchFoodGalleryFailure(error));
    });

  // if (url == true) {
  //   console.log("Second");
  //   dispatch(fetchFoodGalleryRequest());
  //   return fullGetApi(params)
  //     .then((data) => {
  //       dispatch(fetchFoodGallerySuccess(data));
  //     })
  //     .catch((error) => {
  //       dispatch(fetchFoodGalleryFailure(error));
  //     });
  // }
};
