import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";

import {
  addDocumentFormValidation,
  displayFormErrors,
} from "../../../modules/validation";
import { isValidArray, isValidObject } from "../../../modules/utils";

import { fetchRestaurants } from "../../../actions/restaurants/restaurants";
import { fetchGroups } from "../../../actions/groups/groups";
import { fetchUsersDropdown } from "../../../actions/users/userDropdownData";
import { fetchAddDocument } from "../../../actions/document/add";
import { fetchEditDocument } from "../../../actions/document/edit";

export class AddDocumentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_all: false,
      is_visible: false,
      optionPosition: null,
      optionLocation: null,
      optionEmployee: null,
      positionValue: null,
      locationValue: null,
      employeeValue: null,
      selectedFile: "",
      attachment_file: "",
      isLocation: false,
    };
  }

  componentDidMount() {
    this.props.fetchUsersDropdown();
    this.props.fetchGroups().then(() => {
      const { editData } = this.props;
      if (isValidObject(editData)) {
        this.getInitialState();
      }
    });
  }

  getInitialState = () => {
    const {
      restaurantsData,
      editData,
      groupsData,
      usersDropdownData,
    } = this.props;

    const restaurant = restaurantsData?.results.find((d) => {
      if (d.id === editData?.restaurant) return d;
    });

    const position = editData?.groups
      .map((d) => {
        const value = groupsData?.results.find((i) => {
          if (i.id === d) return i;
        });
        return {
          value: value?.id,
          label: value?.name,
        };
      })
      .filter((d) => d.value !== null && d.value !== undefined);
    const positionValue = position
      .map((d) => {
        return d.value;
      })
      .filter((d) => d !== null && d !== undefined);

    const employee = editData?.employees
      .map((d) => {
        const value = usersDropdownData?.results.find((i) => {
          if (i.id === d) return i;
        });
        return {
          value: value?.id || "",
          label: value?.full_name || "",
        };
      })
      .filter((d) => d.value !== null && d.value !== undefined);
    const employeeValue = isValidArray(employee)
      ? employee
          .map((d) => {
            return d.value;
          })
          .filter((d) => d !== null && d !== undefined)
      : null;

    this.setState({
      locationValue: [restaurant?.id],
      optionLocation: {
        value: [restaurant?.id],
        label: [restaurant?.title],
      },
      positionValue,
      optionPosition: position || [],
      is_all: editData?.all_groups,
      is_visible: editData?.visible,
      optionEmployee: employee || [],
      employeeValue,
      attachment_file: editData?.attachment_file,
    });
  };

  onFileChange = (event) => {
    const files = event.target.files;
    this.setState({ selectedFile: files });
  };

  handleAllCheck = () => {
    this.setState({
      is_all: !this.state.is_all,
    });
  };

  handleVisibleCheck = () => {
    this.setState({
      is_visible: !this.state.is_visible,
    });
  };

  getInitialValue = () => {
    const { editData } = this.props;
    return {
      title: (editData && editData?.title) || "",
    };
  };

  handleChangeLocation = (optionLocation) => {
    const locationValue = isValidObject(optionLocation)
      ? optionLocation.value
      : null;
    this.setState({ optionLocation, locationValue }, () => {
      this.setState({
        isLocation: isValidObject(optionLocation) ? false : true,
      });
    });
  };

  handleChangeOption = (optionPosition) => {
    const positionValue = optionPosition.map((d) => {
      return d.value;
    });
    this.setState({ optionPosition, positionValue });
  };

  handleChangeEmployee = (optionEmployee) => {
    const employeeValue = optionEmployee.map((d) => {
      return d.value;
    });
    this.setState({ optionEmployee, employeeValue });
  };

  onHandleSubmit = (values) => {
    const {
      editData,
      fetchEditDocument,
      toggle,
      file_type,
      getList,
      fromEmployee,
    } = this.props;
    const {
      is_all,
      is_visible,
      locationValue,
      positionValue,
      employeeValue,
      selectedFile,
    } = this.state;

    if (locationValue === "" || locationValue === null) {
      this.setState({
        isLocation: true,
      });
    } else {
      // console.log('editdata', editData);

      if (isValidObject(editData) && !fromEmployee) {
        let formData = new FormData();

        formData.append("id", parseInt(editData?.id));
        formData.append("title", values.title);
        formData.append("visible", is_visible);
        formData.append("all_groups", is_all);
        formData.append("file_type", file_type);
        isValidArray(positionValue) &&
          positionValue.forEach((item) => {
            formData.append("groups", item);
          });
        employeeValue &&
          employeeValue.forEach((item) => {
            formData.append("employees", item);
          });
        formData.append("restaurant", locationValue);
        for (let i = 0; i < selectedFile.length; i++) {
          formData.append("new_attachment_file", selectedFile[i]);
        }
        formData.append("attachment_file", editData?.attachment_file);
        formData.append("file_name", editData?.file_name);

        //console.log('1111111');

        fetchEditDocument(parseInt(editData?.id), formData).then(() => {
          getList();
          toggle();
        });
      } else {
        let formData = new FormData();

        formData.append("title", values.title);
        formData.append("visible", is_visible);
        formData.append("all_groups", is_all);
        formData.append("file_type", file_type);
        isValidArray(positionValue) &&
          positionValue.forEach((item) => {
            formData.append("groups", item);
          });
        employeeValue &&
          employeeValue.forEach((item) => {
            formData.append("employees", item);
          });
        formData.append("restaurant", locationValue);
        for (let i = 0; i < selectedFile.length; i++) {
          formData.append("new_attachment_file", selectedFile[i]);
        }
        if (fromEmployee) {
          formData.append("employee_flag", true);
        }
        this.props.fetchAddDocument(formData).then(() => {
          if (getList) {
            getList();
          }
          toggle();
        });
      }
    }
  };

  render() {
    const { is_all, is_visible, isLocation } = this.state;
    const {
      isOpen,
      toggle,
      restaurantsData,
      file_type,
      usersDropdownData,
      groupsData,
      editData,
      fromEmployee,
    } = this.props;
    const optionLocation = restaurantsData?.results.map((d) => {
      return {
        value: d.id,
        label: d.title,
      };
    });
    const optionEmployee = usersDropdownData?.results.map((d) => {
      return {
        value: d.id,
        label: d.full_name,
      };
    });
    const optionPosition = groupsData?.results.map((d) => {
      return {
        value: d.id,
        label: d.name,
      };
    });

    return (
      <Modal isOpen={isOpen} centered className=" document_model">
        <ModalHeader toggle={toggle}>
          {file_type === "Document"
            ? isValidObject(editData) && !fromEmployee
              ? "Edit Document"
              : "Add Document"
            : isValidObject(editData)
            ? "Edit Video"
            : "Add Video"}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={this.getInitialValue()}
            validationSchema={addDocumentFormValidation}
            onSubmit={this.onHandleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              submitCount,
            }) => {
              const showError = (key) =>
                displayFormErrors(key, errors, touched, submitCount);
              return (
                <form onSubmit={handleSubmit}>
                  <div className="form-group announce_form form_input">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Title"
                      name="title"
                      onChange={handleChange}
                      value={values.title}
                    />
                    {showError("title")}
                  </div>
                  <div className="form-group announce_form document_form form_input ">
                    <Select
                      options={optionLocation}
                      name="location"
                      value={this.state.optionLocation}
                      onChange={(e) => this.handleChangeLocation(e)}
                      placeholder="Select location"
                      isClearable
                    />
                    {isLocation && (
                      <p style={{ color: "red" }}>This field is required.</p>
                    )}
                  </div>
                  <div className="form-group announce_form document_form form_input">
                    <Select
                      options={optionPosition}
                      isMulti
                      value={this.state.optionPosition}
                      onChange={(e) => this.handleChangeOption(e)}
                      placeholder="Select position"
                    />
                    {/* {/ {showError('select')} /} */}
                  </div>
                  <div className="form-group announce_form document_form form_input">
                    <Select
                      options={optionEmployee}
                      isMulti
                      value={this.state.optionEmployee}
                      onChange={(e) => this.handleChangeEmployee(e)}
                      placeholder="Select employee"
                    />
                    {/* {/ {showError('select')} /} */}
                  </div>
                  <div className="all_groups_checkbox">
                    <div className="form-check all_checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={is_all}
                        onChange={this.handleAllCheck}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      >
                        All Groups
                      </label>
                    </div>
                    <div className="form-check visible_checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={is_visible}
                        onChange={this.handleVisibleCheck}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      >
                        Visible
                      </label>
                    </div>
                  </div>
                  <div className="form-group announce_form form_input">
                    <input
                      type="file"
                      className="form-control"
                      onChange={this.onFileChange}
                      placeholder=" Select files to attach to your message"
                    />
                  </div>
                  {isValidObject(editData) && (
                    <a href={this.state.attachment_file} target="_blank">
                      {editData?.file_name}
                    </a>
                  )}

                  <div className="d-flex video_doc">
                    <div className="record_btn">
                      <button className="btn btn-primary mr-2" onClick={toggle}>
                        CANCEL
                      </button>
                    </div>
                    <div className="record_btn">
                      <button className="btn btn-primary" type="submit">
                        SAVE
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groupsDataLoading: state.groupsData.isLoading,
    groupsData: state.groupsData.data,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    // usersDataLoading: state.usersData.isLoading,
    //usersData: state.usersData.data,

    usersDataLoading: state.usersDropdownData.isLoading,
    usersDropdownData: state.usersDropdownData.data,

    addDocumentDataLoading: state.addDocumentData.isLoading,
    addDocumentData: state.addDocumentData.data,

    editDocumentDataLoading: state.editDocumentData.isLoading,
    editDocumentData: state.editDocumentData.data,
  };
};
const mapDispatchToProps = {
  fetchGroups,
  fetchRestaurants,
  //fetchUsers,
  fetchAddDocument,
  fetchEditDocument,
  fetchUsersDropdown,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentModal);
