import { getApi } from "../api";
import {
  FETCH_CHAT_COUNT_REQUEST,
  FETCH_CHAT_COUNT_SUCCESS,
  FETCH_CHAT_COUNT_FAILURE,
} from "../../types/chat";

export const fetchChatCountRequest = () => ({
  type: FETCH_CHAT_COUNT_REQUEST,
});

export const fetchChatCountSuccess = (data) => ({
  type: FETCH_CHAT_COUNT_SUCCESS,
  data: data,
});

export const fetchChatCountFailure = (error) => ({
  type: FETCH_CHAT_COUNT_FAILURE,
  error,
});

export const fetchChatCounts = (id, flag, page) => (dispatch, getState) => {
  dispatch(fetchChatCountRequest());
  if (!page) {
    page = 1;
  }

  return getApi(`bshift/api/chat/unread_count`)
    .then((data) => {
      dispatch(fetchChatCountSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchChatCountFailure(error));
    });
};
