import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

// import Notifications from './Notifications';

import { fetchRestaurants } from "../../actions/restaurants/restaurants";

import { isValidArray } from "../../modules/utils";

export class RestaurantManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this.fetchRestaurantList();
  }

  fetchRestaurantList = () => {
    this.props.fetchRestaurants();
  };

  getRestaurantList = () => {
    const { restaurantsData, globalSearch } = this.props;

    if (isValidArray(restaurantsData?.results)) {
      return restaurantsData?.results.filter((d) =>
        globalSearch ? d.title?.toLowerCase().includes(globalSearch) : d
      );
    }

    return [];
  };

  redirectToTodo = (id) => {
    this.props.history.push(`/todo/${id}`);
  };

  render() {
    const restaurantList = this.getRestaurantList();

    return (
      <div className="main_box">
        <div className="pull_left right_sidercustom">
          <div className="main_container">
            <div className="wrapper">
              <div className="wrapper_inner">
                <div className="dashboard">
                  <h1 className="d-flex justify-content-center mb-4 mt-0">
                    <i className="zmdi zmdi-long-arrow-left"></i>
                    Your restaurant boards
                  </h1>
                </div>

                <div className="Restaurant_cover">
                  {/* <div className="RestauRent_Left"> */}
                  <div className="BOx_notiCover">
                    {restaurantList.map((restaurant) => (
                      <div
                        className="box_notofication cursor-pointer"
                        style={{
                          background: restaurant?.color || "grey",
                        }}
                        key={restaurant?.id}
                        onClick={() => this.redirectToTodo(restaurant?.id)}
                      >
                        <div className="DtlIn">
                          <div className="pull_left">
                            <h2 className="rest_title">{restaurant?.title}</h2>
                          </div>
                          {/* <div className="Three_Dots">
																<a href="#">
																	<i className="zmdi zmdi-more"></i>
																</a>
															</div> */}
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* </div> */}
                  {/* <Notifications /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    globalSearch: state.globalSearch.searchQuery,
  };
};

const mapDispatchToProps = { fetchRestaurants };

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(RestaurantManagement);
