import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";

import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchDeleteRestaurant } from "../../actions/restaurants/delete";
import { fetchProfile } from "../../actions/profile/profile";

import AddRestaurantModal from "./modals/AddRestaurantModal";
import { FaPlus } from "react-icons/fa";
import DeleteModal from "../common/DeleteModal";
import { customStyles, getPermission } from "../../modules/utils";
export class Restaurants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      actionType: "",
      activeId: "",
      activeName: "",
      sort_type: "ASC",
      activeGroupId: "",
      activeGroupName: "",
      restaurant_brand: "",
      // isGroupOpen:false,
      // groupActionType:"",
      // groupId:"",
    };
  }

  componentDidMount() {
    this.fetchRestaurantsData();
  }

  fetchRestaurantsData = () => {
    this.props.fetchRestaurants();
  };

  getRestaurantListing = () => {
    const { restaurantsData, globalSearch } = this.props;

    return restaurantsData?.results
      ?.map((d) => {
        return {
          id: d?.id,
          title: d?.title || "",
          restaurant_brand: d?.restaurant_brand || "",
        };
      })
      .filter((d) =>
        globalSearch ? d.title?.toLowerCase().includes(globalSearch) : d
      )
      .sort((a, b) =>
        this.state.sort_type === "ASC"
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title)
      );
  };

  getTableColumns = () => {
    const { profileData } = this.props;
    return [
      {
        name: "TITLE",
        selector: (row) => row.title,
        sortable: true,
        minWidth: "500px",
      },
      {
        name: "ACTIONS",
        selector: (row) => row.actions,
        sortable: false,
        center: true,

        cell: (row) => {
          return (
            <React.Fragment>
              {getPermission("bshift.bs_can_edit_restaurants", profileData) && (
                <span
                  className="btn sm btn-outline-primary btn-sm"
                  onClick={() => {
                    this.toggleModal(
                      "update",
                      row?.id,
                      row?.title,
                      row?.restaurant_brand
                    );
                  }}
                >
                  EDIT
                </span>
              )}
              {getPermission(
                "bshift.bs_can_delete_restaurants",
                profileData
              ) && (
                <span
                  className="btn sm btn-outline-secondary btn-sm ml-1"
                  onClick={() => this.deleteModalToggle(row?.id)}
                >
                  DELETE
                </span>
              )}
            </React.Fragment>
          );
        },
      },
    ];
  };

  toggleModal = (
    type = "",
    restaurantId = "",
    restaurantName = "",
    restaurant_brand = ""
  ) => {
    this.setState({
      actionType: type,
      activeId: restaurantId,
      activeName: restaurantName,
      restaurant_brand: restaurant_brand,
      isOpen: !this.state.isOpen,
    });
  };

  deleteModalToggle = (id = "") => {
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
      deleteId: id,
    });
  };

  deleteRestaurant = () => {
    const { deleteId } = this.state;
    this.props.fetchDeleteRestaurant(deleteId).then(() => {
      this.deleteModalToggle();
      this.fetchRestaurantsData();
    });
  };

  handleSort = (e) => {
    this.setState({
      sort_field: e.selector,
      sort_type: this.state.sort_type === "ASC" ? "DESC" : "ASC",
    });
  };

  render() {
    const { isOpen, isOpenDeleteModal } = this.state;
    const { profileData } = this.props;

    return (
      <div className="main_box">
        <div className="middle_contain ">
          <div className="pull_left full_section_details">
            <div className="main_container">
              <div className="wrapper">
                <div className="container-fluid">
                  <div className=" row mt-5 mb-5 justify-content-between page_title main_align_1 ">
                    <div className="col-md-6">
                      <h2>
                        <i className="zmdi zmdi-long-arrow-left"></i>Restaurants
                      </h2>
                    </div>

                    {/* <div className="col-md-3">
                      <div className="main_info_select">
                        <button
                          type="button"
                          className="btn"
                          onClick={() => this.toggleGroupModal("create")}
                        >
                          <span className="close_icon_report">
                            <FaPlus />
                          </span>
                          New Restaurant Group
                        </button>
                      </div>
                    </div> */}

                    {getPermission(
                      "bshift.bs_can_add_restaurants",
                      profileData
                    ) && (
                      <div className="col-md-3">
                        <div className="main_info_select">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => this.toggleModal("create")}
                          >
                            <span className="close_icon_report">
                              <FaPlus />
                            </span>
                            New Restaurant
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="restaurant_main">
                    <DataTable
                      className="table"
                      responsive={true}
                      striped={true}
                      progressPending={this.props.restaurantsDataLoading}
                      columns={this.getTableColumns()}
                      data={this.getRestaurantListing()}
                      pagination={true}
                      paginationServer={false}
                      paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                      // expandOnRowClicked={true}
                      // expandableRows={true}
                      // expandableRowsComponent={RestaurantRowComponent}
                      onSort={this.handleSort}
                      customStyles={customStyles}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <AddRestaurantModal
            toggle={this.toggleModal}
            isOpen={isOpen}
            restaurantId={this.state.activeId}
            restaurantName={this.state.activeName}
            actionType={this.state.actionType}
            fetchList={this.fetchRestaurantsData}
            restaurant_brand={this.state.restaurant_brand}
          />
        )}
        <DeleteModal
          isOpen={isOpenDeleteModal}
          toggle={this.deleteModalToggle}
          deleteLog={this.deleteRestaurant}
          restaurantDelete={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    globalSearch: state.globalSearch.searchQuery,

    profileData: state.profileData.data,
  };
};
const mapDispatchToProps = {
  fetchRestaurants,
  fetchDeleteRestaurant,
  fetchProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(Restaurants);
