import {
  FETCH_ANNOUNCEMENTS_BYID_REQUEST,
  FETCH_ANNOUNCEMENTS_BYID_SUCCESS,
  FETCH_ANNOUNCEMENTS_BYID_FAILURE,
} from "../../types/announcements";
import { getApi } from "../api";

export const fetchAnnouncementsByIdRequest = () => ({
  type: FETCH_ANNOUNCEMENTS_BYID_REQUEST,
});

export const fetchAnnouncementsByIdSuccess = (data) => ({
  type: FETCH_ANNOUNCEMENTS_BYID_SUCCESS,
  data: data,
});

export const fetchAnnouncementsByIdFailure = (error) => ({
  type: FETCH_ANNOUNCEMENTS_BYID_FAILURE,
  error,
});

export const fetchAnnouncementsById = (id) => (dispatch, getState) => {
  dispatch(fetchAnnouncementsByIdRequest());
  return getApi(`bshift/api/announcements/${id}`)
    .then((data) => {
      dispatch(fetchAnnouncementsByIdSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchAnnouncementsByIdFailure(error));
    });
};
