import {
	FETCH_DROP_REQUEST_REQUEST,
	FETCH_DROP_REQUEST_SUCCESS,
	FETCH_DROP_REQUEST_FAILURE,
} from '../../types/schedule';
import { getApi } from '../api';

export const fetchDropRequestRequest = () => ({
	type: FETCH_DROP_REQUEST_REQUEST,
});

export const fetchDropRequestSuccess = (data) => ({
	type: FETCH_DROP_REQUEST_SUCCESS,
	data: data,
});

export const fetchDropRequestFailure = (error) => ({
	type: FETCH_DROP_REQUEST_FAILURE,
	error,
});

export const fetchDropRequest = (data) => (dispatch, getState) => {
	dispatch(fetchDropRequestRequest());

	const { restaurant, category, position } = data;

	let queryParams = [];
	if (restaurant) {
		queryParams = [...queryParams, `restaurant=${restaurant}`];
	}
	if (category) {
		queryParams = [...queryParams, `category=${category}`];
	}
	if (position) {
		queryParams = [...queryParams, `position=${position}`];
	}

	return getApi(`bshift/api/schedule_drop_request?${queryParams.join('&')}`)
		.then((data) => {
			dispatch(fetchDropRequestSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchDropRequestFailure(error));
		});
};
