import { toast } from "react-toastify";
import {
  CREATE_CALENDAR_EVENT_REQUEST,
  CREATE_CALENDAR_EVENT_FAILURE,
  CREATE_CALENDAR_EVENT_SUCCESS,
} from "../../types/calendar";
import { postApi, putApi, patchApi } from "../api";

export const createCalendarEventRequest = () => ({
  type: CREATE_CALENDAR_EVENT_REQUEST,
});

export const createCalendarEventSuccess = (data) => ({
  type: CREATE_CALENDAR_EVENT_SUCCESS,
  data: data,
});

export const createCalendarEventFailure = (error) => ({
  type: CREATE_CALENDAR_EVENT_FAILURE,
  error,
});

export const createCalendarEvent = (data, id) => (dispatch, getState) => {
  let finalData;
  if (data?.invited_users.length == 0) {
    const { invited_users, ...newData } = data;
    finalData = newData;
  } else {
    finalData = data;
  }
  dispatch(createCalendarEventRequest());
  if (id) {
    return patchApi(`bshift/api/calendarevent/${id}`, finalData)
      .then((data) => {
        toast.success("Event updated successfully!");
        dispatch(createCalendarEventSuccess(data));
      })
      .catch((error) => {
        dispatch(createCalendarEventFailure(error));
      });
  } else {
    return postApi("bshift/api/calendarevent", finalData)
      .then((data) => {
        toast.success("Event created successfully!");
        dispatch(createCalendarEventSuccess(data));
      })
      .catch((error) => {
        dispatch(createCalendarEventFailure(error));
      });
  }
};
