import {
	UPDATE_LOG_RECORD_VALUE_REQUEST,
	UPDATE_LOG_RECORD_VALUE_FAILURE,
	UPDATE_LOG_RECORD_VALUE_SUCCESS,
} from '../../types/managerLogs';
import { putApi } from '../api';

export const updateLogRecordValueRequest = () => ({
	type: UPDATE_LOG_RECORD_VALUE_REQUEST,
});

export const updateLogRecordValueSuccess = (data) => ({
	type: UPDATE_LOG_RECORD_VALUE_SUCCESS,
	data: data,
});

export const updateLogRecordValueFailure = (error) => ({
	type: UPDATE_LOG_RECORD_VALUE_FAILURE,
	error,
});

export const updateLogRecordValue = (id, formData) => (dispatch, getState) => {
	dispatch(updateLogRecordValueRequest());

	return putApi(`bshift/api/logrecord_values_detail/${id}`, formData, true)
		.then((data) => {
			dispatch(updateLogRecordValueSuccess(data));
		})
		.catch((error) => {
			dispatch(updateLogRecordValueFailure(error));
		});
};
