export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";

export const FETCH_SINGLE_USER_REQUEST = "FETCH_SINGLE_USER_REQUEST";
export const FETCH_SINGLE_USER_FAILURE = "FETCH_SINGLE_USER_FAILURE";
export const FETCH_SINGLE_USER_SUCCESS = "FETCH_SINGLE_USER_SUCCESS";

export const FETCH_EDIT_USER_REQUEST = "FETCH_EDIT_USER_REQUEST";
export const FETCH_EDIT_USER_FAILURE = "FETCH_EDIT_USER_FAILURE";
export const FETCH_EDIT_USER_SUCCESS = "FETCH_EDIT_USER_SUCCESS";

export const FETCH_ADD_USER_REQUEST = "FETCH_ADD_USER_REQUEST";
export const FETCH_ADD_USER_FAILURE = "FETCH_ADD_USER_FAILURE";
export const FETCH_ADD_USER_SUCCESS = "FETCH_ADD_USER_SUCCESS";

export const FETCH_DELETE_USER_REQUEST = "FETCH_DELETE_USER_REQUEST";
export const FETCH_DELETE_USER_FAILURE = "FETCH_DELETE_USER_FAILURE";
export const FETCH_DELETE_USER_SUCCESS = "FETCH_DELETE_USER_SUCCESS";
export const FETCH_TASK_USERS_REQUEST = "FETCH_TASK_USERS_REQUEST";
export const FETCH_TASK_USERS_SUCCESS = "FETCH_TASK_USERS_SUCCESS";
export const FETCH_TASK_USERS_FAILURE = "FETCH_TASK_USERS_FAILURE";

export const FETCH_USERS_DROPDOWN_REQUEST = "FETCH_USERS_DROPDOWN_REQUEST";
export const FETCH_USERS_DROPDOWN_SUCCESS = "FETCH_USERS_DROPDOWN_SUCCESS";
export const FETCH_USERS_DROPDOWN_FAILURE = "FETCH_USERS_DROPDOWN_FAILURE";

export const FETCH_USERS_SEARCH_REQUEST = "FETCH_USERS_SEARCH_REQUEST";
export const FETCH_USERS_SEARCH_SUCCESS = "FETCH_USERS_SEARCH_SUCCESS";
export const FETCH_USERS_SEARCH_FAILURE = "FETCH_USERS_SEARCH_FAILURE";
