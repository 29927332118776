import {
	DELETE_GROUPS_REQUEST,
	DELETE_GROUPS_FAILURE,
	DELETE_GROUPS_SUCCESS,
} from '../../types/groups';
import { deleteApi } from '../api';

export const deleteGroupsRequest = () => ({
	type: DELETE_GROUPS_REQUEST,
});

export const deleteGroupsSuccess = (data) => ({
	type: DELETE_GROUPS_FAILURE,
	data: data,
});

export const deleteGroupsFailure = (error) => ({
	type: DELETE_GROUPS_SUCCESS,
	error,
});

export const deleteGroups = (formData) => (dispatch, getState) => {
	dispatch(deleteGroupsRequest());
	return deleteApi(`users/api/groups/${formData}`)
		.then((data) => {
			dispatch(deleteGroupsSuccess(data));
		})
		.catch((error) => {
			dispatch(deleteGroupsFailure(error));
		});
};
