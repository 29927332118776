import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import moment from "moment";
import { FaPlus } from "react-icons/fa";

import AssignedQuizModal from "./modal/AssignedQuizModal";
import AddQuizModal from "./modal/AddQuizModal";

import { fetchAssignedQuiz } from "../../actions/training/assignedQuiz";
import { fetchUsers } from "../../actions/users/users";
import { fetchUsersDropdown } from "../../actions/users/userDropdownData";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchProfile } from "../../actions/profile/profile";
import { customStyles, getPermission } from "../../modules/utils";
export class AssignedQuizzes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      quizData: null,
      isAddQuiz: false,
      page: 1,
      search: "",
      itemsPerPage: 10,
      sort_field: "title",
      sort_type: false,
    };
  }

  componentDidMount() {
    this.getList();
    this.props.fetchRestaurants();
    //this.props.fetchUsers();
    this.props.fetchUsersDropdown();
  }

  getList = () => {
    const { page, itemsPerPage, search, sort_field, sort_type } = this.state;
    return this.props.fetchAssignedQuiz(
      page,
      itemsPerPage,
      search,
      sort_field,
      sort_type
    );
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page,
      },
      () => {
        this.getList();
      }
    );
  };

  handleRowPerPage = (itemsPerPage) => {
    this.setState(
      {
        itemsPerPage,
      },
      () => {
        this.getList();
      }
    );
  };

  handleSort = (e) => {
    this.setState(
      {
        sort_field: e.selector,
        sort_type: !this.state.sort_type,
      },
      () => {
        this.getList();
      }
    );
  };

  getUserListing = () => {
    const {
      assignedQuizData,
      restaurantsData,
      usersData,
      globalSearch,
      usersDropdownData,
    } = this.props;

    return assignedQuizData?.results
      ?.map((d) => {
        const restaurant = restaurantsData?.results.find((i) => {
          if (i.id === d.restaurant) return i;
        });
        // const employee = usersData?.results.find((i) => {
        //   if (i.id === d.employee) return i;
        // });

        // const employee = usersDropdownData?.results.find((i) => {
        //   if (i.id === d.employee) return i;
        // });
        return {
          id: d.id || "",
          title: d.title || "",
          restaurant: restaurant?.title || "",
          employee: d?.employee_name || "",
          // employee: employee?.full_name || "",
          created: d.created
            ? moment(d.created).format("MM/DD/YYYY, hh:mm")
            : "",
          completed_date: d.completed_date
            ? moment(d.completed_date).format("MM/DD/YYYY, hh:mm")
            : "",
          quiz_status: d.quiz_status || "",
          rating: d.rating || "",
        };
      })
      .filter((d) =>
        globalSearch
          ? d.title?.toLowerCase().includes(globalSearch) ||
            d.restaurant?.toLowerCase().includes(globalSearch) ||
            d.employee?.toLowerCase().includes(globalSearch) ||
            d.created?.toLowerCase().includes(globalSearch) ||
            d.completed_date?.toLowerCase().includes(globalSearch) ||
            d.quiz_status?.toLowerCase().includes(globalSearch) ||
            d.rating?.toString().includes(globalSearch)
          : d
      );
  };

  getTableColumns = () => {
    return [
      {
        name: "TITLE",
        selector: (row) => row.title,
        sortable: true,
      },
      {
        name: "RESTAURANT",
        selector: (row) => row.restaurant,
        sortable: true,
      },
      {
        name: "EMPLOYEE",
        selector: (row) => row?.employee,
        sortable: true,
      },
      {
        name: "CREATED",
        selector: (row) => row.created,
        sortable: true,
      },
      {
        name: "COMPLETED",
        selector: (row) => row.completed_date,
        sortable: true,
      },
      {
        name: "STATUS",
        selector: (row) => row.quiz_status,
        sortable: true,
      },
      {
        name: "RATING",
        selector: (row) => row.rating,
        sortable: true,
      },
      {
        name: "ACTIONS",
        selector: (row) => row.actions,
        sortable: false,
        cell: (row) => (
          <React.Fragment>
            <span>
              <button
                className="btn sm btn-outline-primary btn-sm"
                onClick={() => this.handleView(row.id)}
              >
                VIEW
              </button>
            </span>
          </React.Fragment>
        ),
      },
    ];
  };

  handleView = (id) => {
    const { assignedQuizData } = this.props;
    const field_data = assignedQuizData?.results?.find((d) => {
      if (d.id === id) return d;
    });
    this.setState({
      quizData: field_data,
      isOpen: !this.state.isOpen,
    });
  };

  toggleQuizModal = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      quizData: null,
    });
  };

  toggleAddQuizModal = () => {
    this.setState({
      isAddQuiz: !this.state.isAddQuiz,
    });
  };

  render() {
    const {
      assignedQuizData,
      assignedQuizDataLoading,
      profileData,
      usersDropdownData,
    } = this.props;
    const { isOpen, quizData, isAddQuiz } = this.state;
    const paginationTotalRows = assignedQuizData?.count;
    return (
      <div className="main_box">
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div className="container-fluid">
                <div className="row mt-5 mb-5 justify-content-between page_title">
                  <div className="col-md-6">
                    <h2>Assigned Quizzes</h2>
                  </div>
                  <div className="col-md-6 ">
                    <div className="main_info_select">
                      {/* <a
                        href="http://thebusinessshifts.com/admin/login/?next=/admin/bshift/trainingquiz/"
                        target="_blank"
                        className="btn btn-primary"
                      >
                        <span className="close_icon_report">
                          {" "}
                          <FaPlus />{" "}
                        </span>
                        CREATE NEW QUIZ
                      </a> */}
                      <a
                        className="btn"
                        href="https://admin.thebusinessshifts.com/adminbshift/trainingquiz/"
                        //href="http://3.132.219.157:8000/adminbshift/trainingquiz/"
                        //href="http://3.219.165.65:8000/adminbshift/trainingquiz/"
                        target="_blank"
                      >
                        <span className="close_icon_report">
                          <FaPlus />
                        </span>
                        CREATE NEW QUIZ
                      </a>
                      {getPermission(
                        "bshift.bs_assign_quizzes",
                        profileData
                      ) && (
                        <button
                          className="btn  ml-2"
                          onClick={this.toggleAddQuizModal}
                        >
                          <span className="close_icon_report">
                            <FaPlus />
                          </span>
                          ASSIGN NEW QUIZ
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="table_header_line data-table mt-5">
                  <DataTable
                    responsive={true}
                    striped={true}
                    progressPending={assignedQuizDataLoading}
                    columns={this.getTableColumns()}
                    data={this.getUserListing()}
                    pagination={true}
                    paginationServer={true}
                    paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                    paginationTotalRows={paginationTotalRows}
                    onChangePage={this.handlePageChange}
                    onChangeRowsPerPage={this.handleRowPerPage}
                    onSort={this.handleSort}
                    customStyles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <AssignedQuizModal
            isOpen={isOpen}
            toggle={this.toggleQuizModal}
            quizData={quizData}
            getList={this.getList}
          />
        )}
        {isAddQuiz && (
          <AddQuizModal isOpen={isAddQuiz} toggle={this.toggleAddQuizModal} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assignedQuizDataLoading: state.assignedQuizData.isLoading,
    assignedQuizData: state.assignedQuizData.data,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    usersDataLoading: state.usersData.isLoading,
    //usersData: state.usersData.data,

    usersDropdownData: state.usersDropdownData.data,

    globalSearch: state.globalSearch.searchQuery,

    profileData: state.profileData.data,
  };
};
const mapDispatchToProps = {
  fetchAssignedQuiz,
  fetchUsers,
  fetchRestaurants,
  fetchProfile,
  fetchUsersDropdown,
};
export default connect(mapStateToProps, mapDispatchToProps)(AssignedQuizzes);
