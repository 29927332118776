import {
	FETCH_CALENDAR_EVENT_KIND_REQUEST,
	FETCH_CALENDAR_EVENT_KIND_FAILURE,
	FETCH_CALENDAR_EVENT_KIND_SUCCESS,
} from '../../types/calendar';
import { getApi } from '../api';

export const fetchCalendarEventKindRequest = () => ({
	type: FETCH_CALENDAR_EVENT_KIND_REQUEST,
});

export const fetchCalendarEventKindSuccess = (data) => ({
	type: FETCH_CALENDAR_EVENT_KIND_SUCCESS,
	data: data,
});

export const fetchCalendarEventKindFailure = (error) => ({
	type: FETCH_CALENDAR_EVENT_KIND_FAILURE,
	error,
});

export const fetchCalendarEventKind = (data) => (dispatch, getState) => {
	dispatch(fetchCalendarEventKindRequest());
	return getApi(`bshift/api/calendareventkind`)
		.then((data) => {
			dispatch(fetchCalendarEventKindSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchCalendarEventKindFailure(error));
		});
};
