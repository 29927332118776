import { SET_MANAGER_LOG_FILTER } from "../../types/managerLogs";

const initialState = {
  date: null,
  restaurantId: "",
  page: 1,
  itemsPerPage: 10,
  sort_field: "date",
  sort_type: true,
};

const managerLogFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MANAGER_LOG_FILTER:
      return { ...state, ...action.payload };

    default:
      return state;
  }
};

export default managerLogFilterReducer;
