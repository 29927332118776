import {
  FETCH_USERS_DROPDOWN_REQUEST,
  FETCH_USERS_DROPDOWN_SUCCESS,
  FETCH_USERS_DROPDOWN_FAILURE,
} from "../../types/users";
import { getApi } from "../api";

export const fetchUsersDropdownRequest = () => ({
  type: FETCH_USERS_DROPDOWN_REQUEST,
});

export const fetchUsersDropdownSuccess = (data) => ({
  type: FETCH_USERS_DROPDOWN_SUCCESS,
  data: data,
});

export const fetchUsersDropdownFailure = (error) => ({
  type: FETCH_USERS_DROPDOWN_FAILURE,
  error,
});

export const fetchUsersDropdown = (restaurant) => (dispatch, getState) => {
  dispatch(fetchUsersDropdownRequest());

  return getApi(`users/api/usersID`, { restaurant: restaurant })
    .then((data) => {
      dispatch(fetchUsersDropdownSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchUsersDropdownFailure(error));
    });
};
