import {
	FETCH_MODULES_REQUEST,
	FETCH_MODULES_SUCCESS,
	FETCH_MODULES_FAILURE,
} from '../../types/modules';

const modulesReducer = (
	state = {
		data: null,
		error: null,
		isLoading: false,
	},
	action,
) => {
	switch (action.type) {
		case FETCH_MODULES_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_MODULES_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_MODULES_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default modulesReducer;
