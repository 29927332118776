import {
	UPDATE_SHIFT_TIME_REQUEST,
	UPDATE_SHIFT_TIME_FAILURE,
	UPDATE_SHIFT_TIME_SUCCESS,
} from '../../types/schedule';

const updateShiftTimeReducer = (
	state = {
		data: {},
		error: null,
		isLoading: false,
	},
	action,
) => {
	switch (action.type) {
		case UPDATE_SHIFT_TIME_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case UPDATE_SHIFT_TIME_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case UPDATE_SHIFT_TIME_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default updateShiftTimeReducer;
