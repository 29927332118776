import * as types from "../../types/restaurantGroup";
import { getApi } from "../api";

export const fetchRestaurantGroupsRequest = () => ({
  type: types.FETCH_RESTAURANT_GROUPS_REQUEST,
});

export const fetchRestaurantGroupsSuccess = (data) => ({
  type: types.FETCH_RESTAURANT_GROUPS_SUCCESS,
  data: data,
});

export const fetchRestaurantGroupsFailure = (err) => ({
  type: types.FETCH_RESTAURANT_GROUPS_FAILURE,
  data: err,
});

export const fetchRestaurantGroups = (data) => async (dispatch, getState) => {
  dispatch(fetchRestaurantGroupsRequest());

  try {
    const data = await getApi(`bshift/api/restaurantbrand`);
    dispatch(fetchRestaurantGroupsSuccess(data.data));
  } catch (err) {
    dispatch(fetchRestaurantGroupsFailure(err));
  }
};
