import { toastError } from '../../modules/utils';
import {
	FETCH_TODO_TASK_LIST_REQUEST,
	FETCH_TODO_TASK_LIST_SUCCESS,
	FETCH_TODO_TASK_LIST_FAILURE,
} from '../../types/todo';
import { getApi } from '../api';

export const fetchTodoTaskListRequest = () => ({
	type: FETCH_TODO_TASK_LIST_REQUEST,
});

export const fetchTodoTaskListSuccess = (data) => ({
	type: FETCH_TODO_TASK_LIST_SUCCESS,
	data: data,
});

export const fetchTodoTaskListFailure = (error) => ({
	type: FETCH_TODO_TASK_LIST_FAILURE,
	error,
});

export const fetchTodoTaskList = (apiData) => (dispatch) => {
	dispatch(fetchTodoTaskListRequest());
	return getApi(`restaurant/api/todo_task_list`, apiData)
		.then((data) => {
			dispatch(fetchTodoTaskListSuccess(data));
		})
		.catch((error) => {
			toastError('Something went wrong!');
			dispatch(fetchTodoTaskListFailure(error));
		});
};
