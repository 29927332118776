import React, { Component } from "react";
import {
  FormGroup,
  Input,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
} from "reactstrap";
import {
  formatDateISO,
  getDate,
  invertHex,
  strTimeFormat,
  isValidArray,
  formatCost,
  formatDate,
  isValidObject,
  getPermission,
} from "../../modules/utils";
import { fetchCategories } from "../../actions/categories/categories";
import { fetchGroups } from "../../actions/groups/groups";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchSchedule } from "../../actions/schedule/schedule";
import { fetchProfile } from "../../actions/profile/profile";
import { createScheduleTemplate } from "../../actions/schedule/createScheduleTemplate";
import { fetchScheduleTemplate } from "../../actions/schedule/scheduleTemplate";
import { fetchScheduleCount } from "../../actions/schedule/scheduleCount";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import moment from "moment";
import { connect } from "react-redux";
import ShiftChangeRequest from "./modal/ShiftChangeRequest";
import LoadTemplate from "./modal/LoadTemplate";
import ScheduleTemplate from "./modal/ScheduleTemplate";
import {
  FaArrowCircleLeft,
  FaArrowCircleRight,
  FaCalendarAlt,
} from "react-icons/fa";
import WeekCopy from "./modal/WeekCopy";
import WeekClear from "./modal/WeekClear";
import WeekSchedule from "./WeekSchedule";

import { jsPDF } from "jspdf";

export class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: formatDateISO(
        moment(new Date().toISOString().substr(0, 10)).clone().weekday(1)
      ),
      // date: new Date().toISOString().substr(0, 10),
      startDate: new Date(),
      datePickerIsOpen: false,
      showMonth: false,
      showLaborCost: false,
      week_count: 3,
      hours_start: 8,
      hours_end: 23,
      viewBy: "week",
      isOpenChangeReqModal: false,
      restaurant: 0,
      category: 0,
      position: 0,
      dropdownOpen: false,
      isOpenLoadTemplateModal: false,
      laborCostsTotal: 0,
      laborCostsCategory: {},
      laborCostsGroup: {},
      laborCostsDay: {},
      isOpenWeekCopyModal: false,
      isOpenWeekClearModal: false,
      isOpenScheduleTemplateModal: false,
      isOpenDatePicker: false,
      templateData: [],
    };

    this.toggleDatePicker = this.toggleDatePicker.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.pdfRef = React.createRef(null);
  }

  componentDidMount() {
    const { restaurant, category, position } = this.state;
    const {
      fetchCategories,
      fetchGroups,
      fetchRestaurants,
      fetchProfile,
      fetchScheduleCount,
    } = this.props;

    fetchCategories();
    fetchGroups();
    fetchRestaurants();
    fetchProfile();
    fetchScheduleCount(restaurant, category, position);
  }

  getApiDate = () => {
    const { viewBy, date } = this.state;
    const val = moment(date);
    return viewBy === "week" ? formatDateISO(val._d) : date;
    // return viewBy === "week" ? formatDateISO(this.getDate(1)) : date;
  };

  fetchSchedule = () => {
    const { category, position, restaurant, viewBy } = this.state;

    const finalDate = formatDateISO(moment(this.state.date).clone().weekday(1));

    this.props
      .fetchSchedule({
        date: this.getApiDate(),
        category,
        group: position,
        restaurant,
        viewBy,
      })
      .then(() => {
        // const { scheduleList: schedule_items } = this.props;

        let laborCostsTotal = 0;
        let laborCostsCategory = {};
        let laborCostsDay = [0, 0, 0, 0, 0, 0, 0, 0];
        const schedule_items = this.getItems();

        if (isValidArray(schedule_items)) {
          schedule_items.forEach((item) => {
            laborCostsTotal += item.cost;
            if (item.user?.groups.length > 0) {
              if (!laborCostsCategory[item?.user.groups[0].category])
                laborCostsCategory[item?.user.groups[0].category] = {
                  id: item?.user.groups[0].category,
                  total: 0,
                };
              laborCostsCategory[item?.user.groups[0].category]["total"] +=
                item?.cost;
            }
            let idx =
              new Date(item?.date).getDay() - this.getDate(1).getDay() + 1;

            laborCostsDay[idx] += item?.cost;
          });
        }

        if (!viewBy) {
          this.setState({ viewBy: "day" });
        }

        this.setState({
          laborCostsTotal,
          laborCostsCategory,
          laborCostsDay,
        });
      });
  };

  getDate = (num) => {
    return getDate(num, formatDateISO(this.day_start()));
  };

  day_start() {
    const { date } = this.state;

    const val = moment(date);
    return val._d;

    // let d = new Date(date);
    // let dd = d.getDay() || 7;
    // d.setDate(d.getDate() - dd + 1);
    // return d;
  }

  day_end() {
    const { showMonth, week_count } = this.state;

    let d = new Date(this.day_start());
    let x = 0;
    if (showMonth) x = 7 * week_count;
    d.setDate(d.getDate() + 6 + x);
    return d;
  }

  day_start_formatted() {
    const currentDate = moment(this.state.date);
    const weekStart = currentDate.clone().weekday(1);
    let formatted = weekStart.format("MMMM,DD");
    return formatted;
    // return formatDateShort(this.day_start());
  }

  day_end_formatted() {
    const currentDate = moment(this.state.date);
    const weekStart = currentDate.clone().weekday(7);
    let formatted = weekStart.format("MMMM,DD");
    return formatted;
    // return formatDateShort(this.day_end());
  }

  incWeek(delta) {
    const { date } = this.state;

    let d = new Date(date);
    d.setDate(d.getDate() + delta * 7);
    this.setState({ date: d.toISOString().substr(0, 10) }, this.fetchSchedule);
  }

  incDate(delta) {
    const { date } = this.state;

    let d = new Date(date);
    d.setDate(d.getDate() + delta);
    this.setState({ date: d.toISOString().substr(0, 10) }, this.fetchSchedule);
  }

  showMonth = () => {
    this.setState({ showMonth: !this.state.showMonth });
  };

  showLaborCost = () => {
    this.setState({ showLaborCost: !this.state.showLaborCost });
  };

  getHours() {
    let ret = [];
    for (let i = this.state.hours_start; i <= this.state.hours_end; i++) {
      ret.push({ hour: i, title: moment(i, "H").format("ha") });
    }
    return ret;
  }

  getHeaderCellStyle(hour) {
    const { hours_start, hours_end } = this.state;

    let idx = hour.hour - hours_start;
    let dx = 100 / (hours_end - hours_start + 1);

    let ret = {
      left: idx * dx + "%",
      width: dx + "%",
    };

    return ret;
  }

  updateViewBy = (e) => {
    if (e === "day") {
      this.setState({ viewBy: e }, this.fetchSchedule);
    } else if (e === "week") {
      this.setState({ viewBy: e }, this.fetchSchedule);
    } else if (e.target.value) {
      this.setState({ viewBy: e.target.value }, this.fetchSchedule);
    }
  };

  currentDateFmt() {
    const { date } = this.state;
    return moment(date, "YYYY-MM-DD").format("dddd, MMMM D, YYYY");
  }

  toggleChangeReqModal = () => {
    this.setState({ isOpenChangeReqModal: !this.state.isOpenChangeReqModal });
  };

  updateRestaurant = (e) => {
    if (e.target?.value) {
      this.setState({ restaurant: e.target.value }, this.fetchSchedule);
    } else {
      this.setState({ restaurant: e }, this.fetchSchedule);
    }
    //this.setState({ restaurant: e.target.value }, this.fetchSchedule);
  };

  updateCategory = (e) => {
    this.setState({ category: e.target.value }, this.fetchSchedule);
  };

  updatePosition = (e) => {
    this.setState({ position: e.target.value }, this.fetchSchedule);
  };

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleLoadTemplateModal = () => {
    this.setState({
      isOpenLoadTemplateModal: !this.state.isOpenLoadTemplateModal,
    });
  };

  goToAddSchedule = () => {
    const { history } = this.props;

    const currentDate = moment(this.state.date);
    const weekStart = currentDate.clone().weekday(1);
    // let formatted = weekStart.format("MMMM,DD");
    history.push(`/schedule/${formatDateISO(weekStart)}`);
    // history.push(`/schedule/${formatDateISO(this.day_start(1))}`);
    // history.push(`/schedule/${formatDateISO(this.getDate(1))}`);
  };

  goToEditSchedule = () => {
    const { history } = this.props;
    history.push(`/schedule/${formatDateISO(this.getDate(1))}`);
  };

  getItems = () => {
    let { scheduleList: schedule_items } = this.props;

    const date = this.getApiDate();

    if (this.state.viewBy === "week") {
      return (schedule_items = isValidArray(schedule_items?.[date])
        ? schedule_items[date]
        : []);
    } else {
      return (schedule_items = isValidArray(schedule_items?.[date]?.results)
        ? schedule_items[date]?.results
        : []);
    }
  };

  getScheduleGroupedByCategory() {
    const {
      date,
      category: flt_category,
      position: flt_group,
      restaurant: flt_restaurant,
    } = this.state;

    let hash = {};
    let ret = [];

    let schedule_items = this.getItems();

    if (schedule_items.length) {
      schedule_items = schedule_items.filter(
        (item) =>
          (flt_group === 0 || item.group === flt_group) &&
          (!flt_restaurant ||
            flt_restaurant === (item.restaurant ? item.restaurant : null)) &&
          (flt_category === 0 || flt_category === item.category.id)
      );
    }

    const cat = {
      color: "#1976D2FF",
      created: "2020-03-23T07:48:21.423418Z",
      created_by: null,
      deleted: null,
      disabled: false,
      icon: null,
      id: 5,
      modified: "2020-07-02T08:26:47.793857Z",
      modified_by: null,
      so: 0,
      title: "FOH",
      visible: true,
    };

    if (isValidArray(schedule_items)) {
      schedule_items.forEach((schedule_item) => {
        // schedule_item.category = cat;

        if (hash[schedule_item.category?.id] === undefined) {
          hash[schedule_item?.category?.id] = {
            instance: schedule_item?.category,
            children: {},
          };
        }

        if (
          hash[schedule_item?.category?.id]["children"][schedule_item?.group] ===
          undefined
        ) {
          hash[schedule_item?.category?.id]["children"][
            schedule_item?.group
          ] = {
            instance: this.getGroup(schedule_item?.group),
            children: {},
          };
        }

        if (
          hash[schedule_item?.category?.id]["children"][schedule_item?.group][
            "children"
          ][schedule_item.user?.id] === undefined
        ) {
          hash[schedule_item?.category?.id]["children"][schedule_item?.group][
            "children"
          ][schedule_item.user?.id] = {
            instance: schedule_item?.user,
            children: {},
            cost: schedule_item?.cost,
          };
        }

        if (
          hash[schedule_item?.category?.id]["children"][schedule_item?.group][
            "children"
          ][schedule_item?.user?.id]["children"][schedule_item?.date] ===
          undefined
        ) {
          hash[schedule_item?.category?.id]["children"][schedule_item?.group][
            "children"
          ][schedule_item?.user?.id]["children"][schedule_item?.date] = {
            date: schedule_item?.date,
            children: {},
          };
        }

        if (
          hash[schedule_item?.category?.id]["children"][schedule_item?.group][
            "children"
          ][schedule_item?.user?.id]["children"][schedule_item?.date][
            "children"
          ][schedule_item?.day_part?.id] === undefined
        ) {
          hash[schedule_item?.category?.id]["children"][schedule_item?.group][
            "children"
          ][schedule_item?.user?.id]["children"][schedule_item?.date][
            "children"
          ][schedule_item?.day_part?.id] = {
            instance: schedule_item?.day_part,
            children: [],
          };
        }

        hash[schedule_item?.category?.id]["children"][schedule_item?.group][
          "children"
        ][schedule_item?.user?.id]["children"][schedule_item?.date]["children"][
          schedule_item?.day_part?.id
        ]["children"].push(schedule_item);
      });

      for (const category_id in hash) {
        ret.push({ type: "category", instance: hash[category_id]["instance"] });
        for (const position_id in hash[category_id]["children"]) {
          ret.push({
            type: "position",
            instance: hash[category_id]["children"][position_id]["instance"],
          });
          for (const user_id in hash[category_id]["children"][position_id][
            "children"
          ]) {
            ret.push({
              type: "user",
              instance:
                hash[category_id]["children"][position_id]["children"][user_id][
                  "instance"
                ],
              cost: this.getGroupUserCost(
                hash[category_id]["children"][position_id]["children"][user_id][
                  "children"
                ]
              ),
              shifts: this.getGroupUserDates(
                hash[category_id]["children"][position_id]["children"][user_id][
                  "children"
                ]
              ),
            });
          }
        }
      }
    }

    return ret || [];
  }

  getGroup(grp_id) {
    const { groupsDataList: items } = this.props;
    return items.find((itm) => itm.id === grp_id);
  }

  getGroupUserCost(dates) {
    let ret = 0;
    for (const date in dates) {
      for (const day_part_id in dates[date]["children"]) {
        dates[date]["children"][day_part_id]["children"].forEach((item) => {
          ret += item.cost;
        });
      }
    }
    return ret.toFixed(2);
  }

  getGroupUserDates(dates) {
    let ret = [];
    for (let i = 1; i < 8; i++) {
      let date = this.getDateISOFormatted(i);
      ret.push(dates[date] !== undefined ? dates[date] : []);
    }

    return ret;
  }

  getDateISOFormatted(day_num) {
    const { date } = this.state;
    return formatDateISO(this.getDate(day_num));
  }

  getCategoryName(category_id) {
    const { categoriesDataList } = this.props;

    if (category_id > -1) {
      if (categoriesDataList) {
        let x = categoriesDataList.find((rest) => rest.id === category_id);
        if (x) return x["title"];
      }
    }
    return "-No category-";
  }

  saveAsTemplate = (data) => {
    this.props.createScheduleTemplate(data).then(() => {
      this.toggleScheduleTemplateModal();
    });
  };

  toggleWeekCopyModal = () => {
    this.setState({ isOpenWeekCopyModal: !this.state.isOpenWeekCopyModal });
  };

  getParentStart() {
    return formatDate(this.day_start());
  }

  getParentEnd() {
    return formatDate(this.day_end());
  }

  toggleWeekClearModal = () => {
    this.setState({ isOpenWeekClearModal: !this.state.isOpenWeekClearModal });
  };

  toggleScheduleTemplateModal = () => {
    this.setState({
      isOpenScheduleTemplateModal: !this.state.isOpenScheduleTemplateModal,
    });
  };

  toggleDatePicker() {
    this.setState({
      isOpenDatePicker: !this.state.isOpenDatePicker,
    });
  }

  handleDateChange(dateVal) {
    const { showMonth } = this.state;
    var curr = new Date(dateVal);

    if (showMonth) {
      var first = curr.getDate() - (curr.getDay() - 1);
      var firstDay = new Date(curr.setDate(first));
    } else {
      var firstDay = curr;
    }

    firstDay.setDate(firstDay.getDate());
    this.setState(
      { date: firstDay.toISOString().substr(0, 10) },
      this.fetchSchedule
    );
  }

  getUserRowClass(item) {
    const { profileData: profile } = this.props;

    let flg = false;
    if (isValidArray(item.children)) {
      item.children.forEach((shift) => {
        flg =
          flg ||
          (shift.user.id === profile.id && shift.shift_status === "") ||
          shift.shift_status === "Drop Request";
        flg = flg || shift.shift_status === "Drop Confirmed";
      });
    }

    return flg ? "has-user-bar" : "";
  }

  getShiftCellStyle(shift) {
    const { hours_start, hours_end } = this.state;
    let placeholder_start = moment(hours_start, "H").unix();
    let placeholder_end = moment(hours_end, "H").unix() + 3600;
    var placeholder_dx = placeholder_end - placeholder_start;
    let shift_start = moment(shift.shift_time.time_start, "HH-mm-ss").unix();
    let shift_end = moment(shift.shift_time.time_end, "HH-mm-ss").unix();
    var shift_dx = shift_end - shift_start;

    return {
      left: ((shift_start - placeholder_start) * 100) / placeholder_dx + "%",
      width: (shift_dx * 100) / placeholder_dx + "%",
    };
  }

  render() {
    const {
      date,
      showMonth,
      showLaborCost,
      viewBy,
      isOpenChangeReqModal,
      isOpenLoadTemplateModal,
      restaurant,
      category,
      position,
      dropdownOpen,
      laborCostsTotal,
      laborCostsCategory,
      isOpenWeekCopyModal,
      isOpenWeekClearModal,
      isOpenScheduleTemplateModal,
      isOpenDatePicker,
      templateData,
      laborCostsDay,
    } = this.state;

    const {
      categoriesDataList,
      groupsDataList,
      restaurantsDataList,
      profileData: profile,
      scheduleList,
      scheduleCountData,
    } = this.props;

    const hours = this.getHours();
    const scheduleData = this.getScheduleGroupedByCategory();
    const optionPosition = groupsDataList?.map((d) => {
      return {
        value: d.id,
        label: d.name,
      };
    });

    return (
      <div className="main_box">
        <div className="pull_left full_section_details">
          <div className="main_container">
            <div className="wrapper">
              <div className="container-fluid">
                <div className="row d-flex align-items-center mt-4">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="Scadual-Date">
                      {viewBy === "week" ? (
                        <h5>
                          Schedules for{" "}
                          <span>
                            {this.day_start_formatted()} -{" "}
                            {this.day_end_formatted()}
                          </span>
                        </h5>
                      ) : (
                        // : viewBy === "day" ?
                        <h5>
                          Schedules for <span>{this.currentDateFmt()}</span>
                        </h5>
                      )}
                    </div>
                  </div>
                  <div className=" col-sm-12 col-md-12 col-lg-6 btn_schedule_group">
                    <div className="BTnGroupScha next_prev_btn d-flex">
                      <button
                        type="button"
                        className="btn  btn_Pre d-flex flex-row align-items-center"
                        onClick={() => {
                          viewBy === "week"
                            ? this.incWeek(-1)
                            : this.incDate(-1);
                        }}
                      >
                        <FaArrowCircleLeft size={20} />
                        Previous {viewBy === "week" ? "Week" : "Day"}
                      </button>

                      <button
                        type="button"
                        className="btn  btn_NextSchedual btn_Pre d-flex flex-row align-items-center"
                        onClick={() => {
                          viewBy === "week" ? this.incWeek(1) : this.incDate(1);
                        }}
                      >
                        Next {viewBy === "week" ? "Week" : "Day"}{" "}
                        <FaArrowCircleRight size={20} />
                      </button>

                      <button
                        type="button"
                        className="btn  btn_Pre d-flex flex-row align-items-center"
                        onClick={this.toggleDatePicker}
                      >
                        <FaCalendarAlt size={20} />
                        Select {viewBy === "week" ? "Week" : "Date"}
                      </button>
                    </div>
                  </div>

                  <div className="col-12 col-md-12   ustify-content-between mt-4 mb-5 buttonVscr">
                    <div className="d-flex justify-content-between view_shift_btn float-right">
                      <button
                        type="button"
                        className="btn btn-primary mx-3"
                        disabled={this.props?.scheduleLoading}
                        onClick={() => {
                          const doc = new jsPDF("l", "pt", "a2");
                          // const doc = new jsPDF("l", "pt", [1500, 800]);

                          // Adding the fonts.
                          doc.setFont("Inter-Regular", "normal");
                          doc.html(this.pdfRef.current, {
                            async callback(doc) {
                              await doc.save("document");
                            },
                          });
                        }}
                      >
                        Download
                      </button>

                      {isValidObject(scheduleCountData) && (
                        <button className="rounded-circle btn btn-danger mr-2">
                          {scheduleCountData?.count}
                        </button>
                      )}
                      <button
                        type="button"
                        className="btn btn-block btn-success "
                        onClick={this.toggleChangeReqModal}
                      >
                        View Shift Change Requests
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12 col-md-2 btn_form_group">
                    <FormGroup>
                      <Input
                        type="select"
                        name="restaurant"
                        id="exampleSelect"
                        className="schedule-select"
                        onChange={this.updateRestaurant}
                      >
                        <option value={0}>Select Location</option>
                        {restaurantsDataList.map((d, index) => (
                          <option value={d.id} key={index}>
                            {d.title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    {/* </div>
									<div className="col-12 col-md-2"> */}
                    <FormGroup>
                      <Input
                        type="select"
                        name="category"
                        id="category"
                        className="schedule-select"
                        onChange={this.updateCategory}
                      >
                        <option value={0}>Select Category</option>
                        {categoriesDataList.map((d, index) => (
                          <option value={d.id} key={index}>
                            {d.title}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                    {/* </div>
									<div className="col-12 col-md-2"> */}
                    <FormGroup>
                      <Input
                        type="select"
                        name="postion"
                        className="schedule-select"
                        onChange={this.updatePosition}
                      >
                        <option value={0}>Select Position</option>
                        {groupsDataList.map((d, index) => (
                          <option value={d.id} key={index}>
                            {d.name}
                          </option>
                        ))}
                      </Input>
                      {/* <Select
												options={optionPosition}
												value={this.state.optionPosition}
												onChange={this.updatePosition}
												placeholder="Select position"
											/> */}
                    </FormGroup>
                    {/* </div>
									<div className="col-12 col-md-2"> */}
                    <FormGroup>
                      <Input
                        type="select"
                        name="viewBy"
                        onChange={this.updateViewBy}
                        className="schedule-select"
                        value={viewBy ? viewBy : null}
                      >
                        <option>Select Period</option>
                        <option value="week">Week</option>
                        <option value="day">Day</option>
                      </Input>
                    </FormGroup>
                    {/* </div>
									<div className="col-12 col-md-3"> */}
                    {/* <div className="row">
											<div className="col-6"> */}
                    {viewBy === "week" && (
                      <FormGroup check>
                        <div className="form-group show_month ">
                          {/* <Input
														type="checkbox"
														className="sch-checkbox"
														id="MonthShow"
														onClick={this.showMonth}
													/> */}
                          <input
                            type="checkbox"
                            id="MonthShow"
                            onClick={this.showMonth}
                            className="check_list_box"
                          />

                          <label htmlFor="MonthShow">Show Month</label>
                        </div>
                      </FormGroup>
                    )}
                    {/* </div>
										//custom_checkbox
											<div className="col-6"> */}
                    <FormGroup check>
                      <div className="form-group show_month ">
                        {/* <Input
													type="checkbox"
													className="sch-checkbox"
													id="laborCost"
													onClick={this.showLaborCost}
												/> */}

                        <input
                          type="checkbox"
                          id="laborCost"
                          onClick={this.showLaborCost}
                          className="check_list_box"
                        />

                        <label htmlFor="laborCost">Show Labor cost</label>
                      </div>
                    </FormGroup>
                  </div>
                </div>
                {getPermission("bshift.bs_can_manage_schedule", profile) && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className=" Mange-Schedual-Btn mb-2 mt-4">
                        <ButtonDropdown
                          isOpen={dropdownOpen}
                          toggle={this.toggleDropdown}
                        >
                          <DropdownToggle caret>Manage Schedule</DropdownToggle>
                          <DropdownMenu>
                            {scheduleData.length ? (
                              <DropdownItem onClick={this.goToEditSchedule}>
                                Edit
                              </DropdownItem>
                            ) : (
                              <DropdownItem onClick={this.goToAddSchedule}>
                                Create New Schedule
                              </DropdownItem>
                            )}

                            {scheduleData.length ? (
                              <React.Fragment>
                                <DropdownItem disabled>Post</DropdownItem>
                                <DropdownItem
                                  onClick={this.toggleWeekCopyModal}
                                >
                                  Copy
                                </DropdownItem>
                                <DropdownItem
                                  onClick={this.toggleWeekClearModal}
                                >
                                  Clear
                                </DropdownItem>
                                <DropdownItem
                                  onClick={this.toggleScheduleTemplateModal}
                                >
                                  Save as template
                                </DropdownItem>
                              </React.Fragment>
                            ) : (
                              <DropdownItem
                                onClick={this.toggleLoadTemplateModal}
                              >
                                Load Template
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: `${this.props.scheduleLoading ? "flex" : "none"}`,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="schedule_loader"
                >
                  <Loader
                    type="TailSpin"
                    color="#38425B"
                    // color="#2196f3"
                    height="100"
                    width="100"
                  />
                </div>
                <div
                  className="row"
                  ref={this.pdfRef}
                  style={{
                    display: `${this.props.scheduleLoading ? "none" : "block"}`,
                  }}
                >
                  <div className="application col-md-12 mt-3">
                    <div className="row row-week">
                      {viewBy === "week" ? (
                        <React.Fragment>
                          {[...Array(showMonth ? 4 : 1)].map((x, week) => {
                            return (
                              <React.Fragment key={week}>
                                <WeekSchedule
                                  date={date}
                                  showLaborCost={showLaborCost}
                                  category={category}
                                  position={position}
                                  restaurant={restaurant}
                                  viewBy={viewBy}
                                  groupsDataList={groupsDataList}
                                  categoriesDataList={categoriesDataList}
                                  inWeekNum={week}
                                  profile={profile}
                                  laborCostsCategory={laborCostsCategory}
                                  laborCostsDay={laborCostsDay}
                                  laborCostsTotal={laborCostsTotal}
                                />
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {showLaborCost && (
                            <div
                              className="week day_view_schedule d-flex flex-md-row flex-column pa-0 mt-3"
                              style={{ width: "100%" }}
                            >
                              <div
                                className="col"
                                style={{
                                  border: "1px solid",
                                  borderRadius: "5px",
                                  padding: "10px",
                                }}
                              >
                                <div>
                                  <strong>Labor costs</strong> Day Total:
                                  <span
                                    className={
                                      formatCost(laborCostsTotal) < 0
                                        ? "text-red"
                                        : "text-green"
                                    }
                                  >
                                    ${formatCost(laborCostsTotal)}
                                  </span>
                                </div>
                                {isValidObject(laborCostsCategory) &&
                                  Object.keys(laborCostsCategory).map(
                                    (key, index) => {
                                      return (
                                        <div key={index}>
                                          {this.getCategoryName(
                                            laborCostsCategory[key]?.id
                                          )}
                                          :{" "}
                                          <span
                                            className={
                                              formatCost(
                                                laborCostsCategory[key]?.total
                                              ) < 0
                                                ? "text-red"
                                                : "text-green"
                                            }
                                          >
                                            $
                                            {formatCost(
                                              laborCostsCategory[key]?.total
                                            )}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          )}
                          <div className="text-center schedule-day">
                            <div className="head">
                              <div className="user-head">
                                {this.currentDateFmt()}
                              </div>
                              <div className="times-head">
                                {hours.map((d, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="text-center"
                                      style={this.getHeaderCellStyle(d)}
                                    >
                                      {d.title}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            {scheduleData.map((item, index) => {
                              return (
                                <React.Fragment key={index}>
                                  {item.type === "category" ||
                                    (item.type === "position" && (
                                      <div
                                        className="text-left user-row"
                                        style={{
                                          backgroundColor: item.instance.color
                                            ? item.instance.color
                                            : "gray",
                                        }}
                                      >
                                        <div className="user-col">
                                          <span
                                            style={{
                                              color: invertHex(
                                                item.instance.color
                                              ),
                                            }}
                                          >
                                            {item.type === "category"
                                              ? item.instance.title
                                              : item.instance.name}
                                          </span>
                                        </div>
                                        <div className="times-col">
                                          {hours.map((d, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className="text-center"
                                                style={this.getHeaderCellStyle(
                                                  d
                                                )}
                                              ></div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    ))}
                                  {item.type === "user" && (
                                    <div
                                      // className="text-left user-row"
                                      className={this.getUserRowClass(item)}
                                    >
                                      <div className="font-weight-bold day_username body-2 user-col">
                                        {item.instance.full_name}{" "}
                                        {showLaborCost && (
                                          <span
                                            className={
                                              item?.cost < 0
                                                ? "text-red"
                                                : "text-green"
                                            }
                                          >
                                            {" "}
                                            - ${item?.cost}
                                          </span>
                                        )}
                                      </div>
                                      <div className="times-col">
                                        {isValidArray(item.children) &&
                                          item.children.map((shift, index) => {
                                            return (
                                              <div
                                                key={index}
                                                className="shift"
                                                style={this.getShiftCellStyle(
                                                  shift
                                                )}
                                              >
                                                <div>
                                                  <div>
                                                    <span
                                                      className="font-weight-light caption mx-auto shift"
                                                      style={{
                                                        borderRadius: 0,
                                                        borderBottomWidth:
                                                          "2px",
                                                        borderBottomColor:
                                                          shift.color,
                                                        borderBottomStyle:
                                                          "solid",
                                                      }}
                                                    >
                                                      {strTimeFormat(
                                                        shift.time_start
                                                      )}
                                                      -
                                                      {strTimeFormat(
                                                        shift.time_end
                                                      )}
                                                    </span>
                                                    <Card
                                                      className="mt-1 mb-3"
                                                      elevation="0"
                                                    >
                                                      <div>
                                                        {(shift.user.id ===
                                                          profile.id &&
                                                          shift.shift_status ===
                                                            "") ||
                                                          (shift.shift_status ===
                                                          "Drop Request" ? (
                                                            <button
                                                              type="button"
                                                              className="white--text"
                                                              onClick={() =>
                                                                this.handleShift(
                                                                  shift
                                                                )
                                                              }
                                                              disabled={
                                                                shift.shift_status ===
                                                                "Drop Request"
                                                              }
                                                            >
                                                              Give Up
                                                            </button>
                                                          ) : (
                                                            shift.shift_status ===
                                                              "Drop Confirmed" && (
                                                              <button
                                                                type="button"
                                                                className="black--text"
                                                                onClick={() => {}}
                                                                disabled={
                                                                  shift.shift_status ===
                                                                  "Drop Request"
                                                                }
                                                              >
                                                                <span>
                                                                  Open Shift
                                                                  <br />
                                                                  Pickup
                                                                </span>
                                                              </button>
                                                            )
                                                          ))}
                                                      </div>
                                                    </Card>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isOpenChangeReqModal && (
          <ShiftChangeRequest
            isOpen={isOpenChangeReqModal}
            toggle={this.toggleChangeReqModal}
            restaurant={restaurant}
            category={category}
            position={position}
            fetchSchedule={this.fetchSchedule}
          />
        )}

        {isOpenLoadTemplateModal && (
          <LoadTemplate
            isOpen={isOpenLoadTemplateModal}
            toggle={this.toggleLoadTemplateModal}
            templateData={templateData}
            date={this.day_start()}
            fetchSchedule={this.fetchSchedule}
            updateViewBy={this.updateViewBy}
            viewBy={viewBy}
            restaurant={this.updateRestaurant}
          />
        )}

        {isOpenWeekCopyModal && (
          <WeekCopy
            isOpen={isOpenWeekCopyModal}
            toggle={this.toggleWeekCopyModal}
            inStart={moment(this.getParentStart()).format("YYYY-MM-DD")}
            inEnd={moment(this.getParentEnd()).format("YYYY-MM-DD")}
            groups={groupsDataList}
            scheduleItems={scheduleData}
            scheduleList={scheduleList}
          />
        )}

        {isOpenWeekClearModal && (
          <WeekClear
            isOpen={isOpenWeekClearModal}
            toggle={this.toggleWeekClearModal}
            inStart={moment(this.getParentStart()).format("YYYY-MM-DD")}
            inEnd={moment(this.getParentEnd()).format("YYYY-MM-DD")}
            groups={groupsDataList}
            // scheduleItems={scheduleList}
            scheduleItems={scheduleData}
            scheduleList={scheduleList}
            fetchSchedule={this.fetchSchedule}
          />
        )}

        {isOpenScheduleTemplateModal && (
          <ScheduleTemplate
            isOpen={isOpenScheduleTemplateModal}
            toggle={this.toggleScheduleTemplateModal}
            inStart={this.getParentStart()}
            inEnd={this.getParentEnd()}
            groups={groupsDataList}
            scheduleItems={scheduleList}
            saveAsTemplate={this.saveAsTemplate}
          />
        )}

        <div className="d-none">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              open={isOpenDatePicker}
              margin="normal"
              label="Date picker"
              value={date}
              onChange={this.handleDateChange}
              onAccept={this.toggleDatePicker}
              onClose={this.toggleDatePicker}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoriesDataLoading: state.categoriesData.isLoading,
    categoriesDataList: state.categoriesData?.data?.results || [],

    groupsDataLoading: state.groupsData.isLoading,
    groupsDataList: state.groupsData?.data?.results || [],

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsDataList: state.restaurantsData?.data?.results || [],

    scheduleLoading: state.schedule.isLoading,
    scheduleList: state.schedule?.data || [],

    createScheduleTemplateDataLoading:
      state.createScheduleTemplateData.isLoading,
    createScheduleTemplateData: state.createScheduleTemplateData.data,

    scheduleTemplateLoading: state.scheduleTemplate.isLoading,
    scheduleTemplate: state.scheduleTemplate.data,

    scheduleCountDataLoading: state.scheduleCountData.isLoading,
    scheduleCountData: state.scheduleCountData.data,

    profileData: state.profileData.data,
  };
};
const mapDispatchToProps = {
  fetchCategories,
  fetchGroups,
  fetchRestaurants,
  fetchSchedule,
  fetchProfile,
  createScheduleTemplate,
  fetchScheduleTemplate,
  fetchScheduleCount,
  fetchProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
