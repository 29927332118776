import {
	FETCH_LOG_RECORD_VALUE_REQUEST,
	FETCH_LOG_RECORD_VALUE_FAILURE,
	FETCH_LOG_RECORD_VALUE_SUCCESS,
} from '../../types/managerLogs';
import { getApi } from '../api';

export const fetchLogRecordValueRequest = () => ({
	type: FETCH_LOG_RECORD_VALUE_REQUEST,
});

export const fetchLogRecordValueSuccess = (data) => ({
	type: FETCH_LOG_RECORD_VALUE_SUCCESS,
	data: data,
});

export const fetchLogRecordValueFailure = (error) => ({
	type: FETCH_LOG_RECORD_VALUE_FAILURE,
	error,
});

export const fetchLogRecordValue = (formData) => (dispatch, getState) => {
	dispatch(fetchLogRecordValueRequest());

	return getApi(`bshift/api/logrecord_values/${formData.log_id}`, formData)
		.then((data) => {
			dispatch(fetchLogRecordValueSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchLogRecordValueFailure(error));
		});
};
