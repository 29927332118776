export const FETCH_TODO_ACTIVITY_REQUEST = 'FETCH_TODO_ACTIVITY_REQUEST';
export const FETCH_TODO_ACTIVITY_FAILURE = 'FETCH_TODO_ACTIVITY_FAILURE';
export const FETCH_TODO_ACTIVITY_SUCCESS = 'FETCH_TODO_ACTIVITY_SUCCESS';

export const FETCH_CREATE_TODO_REQUEST = 'FETCH_CREATE_TODO_REQUEST';
export const FETCH_CREATE_TODO_FAILURE = 'FETCH_CREATE_TODO_FAILURE';
export const FETCH_CREATE_TODO_SUCCESS = 'FETCH_CREATE_TODO_SUCCESS';

export const FETCH_UPDATE_TODO_REQUEST = 'FETCH_UPDATE_TODO_REQUEST';
export const FETCH_UPDATE_TODO_FAILURE = 'FETCH_UPDATE_TODO_FAILURE';
export const FETCH_UPDATE_TODO_SUCCESS = 'FETCH_UPDATE_TODO_SUCCESS';

export const FETCH_TODO_LIST_REQUEST = 'FETCH_TODO_LIST_REQUEST';
export const FETCH_TODO_LIST_FAILURE = 'FETCH_TODO_LIST_FAILURE';
export const FETCH_TODO_LIST_SUCCESS = 'FETCH_TODO_LIST_SUCCESS';

export const FETCH_TODO_DETAIL_REQUEST = 'FETCH_TODO_DETAIL_REQUEST';
export const FETCH_TODO_DETAIL_FAILURE = 'FETCH_TODO_DETAIL_FAILURE';
export const FETCH_TODO_DETAIL_SUCCESS = 'FETCH_TODO_DETAIL_SUCCESS';

export const FETCH_TODO_DELETE_REQUEST = 'FETCH_TODO_DELETE_REQUEST';
export const FETCH_TODO_DELETE_FAILURE = 'FETCH_TODO_DELETE_FAILURE';
export const FETCH_TODO_DELETE_SUCCESS = 'FETCH_TODO_DELETE_SUCCESS';

export const FETCH_CREATE_TODO_COMMENT_REQUEST =
	'FETCH_CREATE_TODO_COMMENT_REQUEST';
export const FETCH_CREATE_TODO_COMMENT_SUCCESS =
	'FETCH_CREATE_TODO_COMMENT_SUCCESS';
export const FETCH_CREATE_TODO_COMMENT_FAILURE =
	'FETCH_CREATE_TODO_COMMENT_FAILURE';

export const FETCH_UPDATE_TODO_COMMENT_REQUEST =
	'FETCH_UPDATE_TODO_COMMENT_REQUEST';
export const FETCH_UPDATE_TODO_COMMENT_SUCCESS =
	'FETCH_UPDATE_TODO_COMMENT_SUCCESS';
export const FETCH_UPDATE_TODO_COMMENT_FAILURE =
	'FETCH_UPDATE_TODO_COMMENT_FAILURE';

export const FETCH_TODO_COMMENT_LIST_REQUEST =
	'FETCH_TODO_COMMENT_LIST_REQUEST';
export const FETCH_TODO_COMMENT_LIST_SUCCESS =
	'FETCH_TODO_COMMENT_LIST_SUCCESS';
export const FETCH_TODO_COMMENT_LIST_FAILURE =
	'FETCH_TODO_COMMENT_LIST_FAILURE';

export const FETCH_TODO_COMMENT_DETAIL_REQUEST =
	'FETCH_TODO_COMMENT_DETAIL_REQUEST';
export const FETCH_TODO_COMMENT_DETAIL_SUCCESS =
	'FETCH_TODO_COMMENT_DETAIL_SUCCESS';
export const FETCH_TODO_COMMENT_DETAIL_FAILURE =
	'FETCH_TODO_COMMENT_DETAIL_FAILURE';

export const FETCH_TODO_COMMENT_DELETE_REQUEST =
	'FETCH_TODO_COMMENT_DELETE_REQUEST';
export const FETCH_TODO_COMMENT_DELETE_SUCCESS =
	'FETCH_TODO_COMMENT_DELETE_SUCCESS';
export const FETCH_TODO_COMMENT_DELETE_FAILURE =
	'FETCH_TODO_COMMENT_DELETE_FAILURE';

export const FETCH_CREATE_TODO_TASK_REQUEST = 'FETCH_CREATE_TODO_TASK_REQUEST';
export const FETCH_CREATE_TODO_TASK_SUCCESS = 'FETCH_CREATE_TODO_TASK_SUCCESS';
export const FETCH_CREATE_TODO_TASK_FAILURE = 'FETCH_CREATE_TODO_TASK_FAILURE';

export const FETCH_UPDATE_TODO_TASK_REQUEST = 'FETCH_UPDATE_TODO_TASK_REQUEST';
export const FETCH_UPDATE_TODO_TASK_SUCCESS = 'FETCH_UPDATE_TODO_TASK_SUCCESS';
export const FETCH_UPDATE_TODO_TASK_FAILURE = 'FETCH_UPDATE_TODO_TASK_FAILURE';

export const FETCH_TODO_TASK_LIST_REQUEST = 'FETCH_TODO_TASK_LIST_REQUEST';
export const FETCH_TODO_TASK_LIST_SUCCESS = 'FETCH_TODO_TASK_LIST_SUCCESS';
export const FETCH_TODO_TASK_LIST_FAILURE = 'FETCH_TODO_TASK_LIST_FAILURE';

export const FETCH_TODO_TASK_DETAIL_REQUEST = 'FETCH_TODO_TASK_DETAIL_REQUEST';
export const FETCH_TODO_TASK_DETAIL_SUCCESS = 'FETCH_TODO_TASK_DETAIL_SUCCESS';
export const FETCH_TODO_TASK_DETAIL_FAILURE = 'FETCH_TODO_TASK_DETAIL_FAILURE';

export const FETCH_TODO_TASK_DELETE_REQUEST = 'FETCH_TODO_TASK_DELETE_REQUEST';
export const FETCH_TODO_TASK_DELETE_SUCCESS = 'FETCH_TODO_TASK_DELETE_SUCCESS';
export const FETCH_TODO_TASK_DELETE_FAILURE = 'FETCH_TODO_TASK_DELETE_FAILURE';
