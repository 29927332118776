import React from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";

export default function ImageDisplay(props) {
  const handleClose = () => {
    props.setFileUpload("");
    props.setImageUrl("");
    props.inputRef.current.value = null;
  };

  return props?.isImage ? (
    <div
      style={{
        bottom: "85px",
        background: "#FFFFEO",
        // width: "100px",
        left: "20px",
        position: "absolute",
        display: "flex",
      }}
    >
      <img
        src={props?.image}
        alt="image"
        className="h-28 w-28"
        style={{
          height: "150px",
          // minWidth: "150px",
        }}
      />
      <AiOutlineCloseSquare
        className="h2 mx-2 imageCloseButton"
        onClick={handleClose}
      />
    </div>
  ) : (
    <div
      style={{
        background: "#FFFFEO",
        left: "40px",
        bottom: "90px",
        position: "absolute",
        display: "flex",
        alignItems: "center",
      }}
    >
      <p className="text-primary m-0">{props?.image}</p>
      <AiOutlineCloseSquare
        className="h6 my-0 mx-2 imageCloseButton"
        onClick={handleClose}
      />
    </div>
  );
}
