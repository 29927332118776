import * as types from "../../types/restaurantGroup";

const initialState = {
  data: {},
  isLoading: false,
  error: null,
};

export const createRestaurantGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_RESTAURANT_GROUP_CREATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_RESTAURANT_GROUP_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case types.FETCH_RESTAURANT_GROUP_CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.data,
      };
    default:
      return state;
  }
};
