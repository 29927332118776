import {
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE,
} from "../../types/groups";
import { getApi } from "../api";

export const fetchGroupsRequest = () => ({
  type: FETCH_GROUPS_REQUEST,
});

export const fetchGroupsSuccess = (data) => ({
  type: FETCH_GROUPS_SUCCESS,
  data: data,
});

export const fetchGroupsFailure = (error) => ({
  type: FETCH_GROUPS_FAILURE,
  error,
});

export const fetchGroups = (data) => (dispatch, getState) => {
  dispatch(fetchGroupsRequest());
  return getApi("users/api/groups")
    .then((data) => {
      dispatch(fetchGroupsSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchGroupsFailure(error));
    });
};
