import React, { Component, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import { BrowserRouter as Router } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

import Calendar from "./components/calendar/Calendar";
import SalesData from "./components/salesData/SalesData";
import ManagersLog from "./components/managersLog/ManagersLog";
import Schedule from "./components/schedule/Schedule";
import Restaurants from "./components/employees/Restaurants";
import Positions from "./components/employees/Positions";
import Categories from "./components/employees/Categories";
import MainInfo from "./components/employees/MainInfo";
import Login from "./components/login/Login";
import MyQuizzes from "./components/training/MyQuizzes";
import Documents from "./components/training/Document";
import AssignedQuizzes from "./components/training/AssignedQuizzes";
import Videos from "./components/training/Video";
import AddEditEmployee from "./components/employees/AddEditEmployee";
import AddEditLog from "./components/managersLog/AddEditLog";
import Sidebar from "./components/common/Sidebar";
import Navbar from "./components/common/Navbar";
import ScrollToTop from "./components/common/ScrollToTop";
import RestaurantManagement from "./components/restaurantManagement";
import Todo from "./components/restaurantManagement/todoIndex";
import ShiftTime from "./components/schedule/ShiftTime";
import Profile from "./components/profile/Profile";
import ChangePassword from "./components/profile/ChangePassword";
import FoodGallery from "./components/foodGallery/FoodGallery";
import Dashboard from "./components/Dashboard";
import ChatLayout from "./components/Chat/index";
import { fetchProfile } from "./actions/profile/profile";
import { fetchChatCounts } from "./actions/chat/unreadCount";

import { getPermission, isValidObject } from "./modules/utils";
import EmployeeDocuments from "./components/training/EmployeeDocuments";

import { getApi } from "./actions/api";
import { toast } from "react-toastify";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import ResetPassword from "./components/forgotPassword/ResetPassword";
import NewFoodGallery from "./components/foodGallery/NewFoodGallery";
import AddEmployee from "./components/employees/AddEmployee";
import EditEmployee from "./components/employees/EditEmployee";
import AnnouncementsDetails from "./components/common/AnnouncementsDetails";
import Calculator from "./components/calculator/Calculator";

import { requestForToken, onMessageListener } from "./firebase";
import { fetchChatMessages } from "./actions/chat/ChatMessages";

import { fetchChatList } from "./actions/chat/userChatList";
import RestaurantGroup from "./components/employees/RestaurantGroup";
import CustomCalendar from "./components/calendar/CustomCalendar";
import NewSchedule from "./components/schedule/New Schedule";
import Chat from "./components/NewChat/Chat";
import ContactUs from "./components/cms/ContactUs";
import AboutUs from "./components/cms/AboutUs";
import PrivacyPolicies from "./components/cms/PrivacyPolicies";
import TermsAndConditions from "./components/cms/TermsAndConditions";

const AuthRouteComp = ({ component: Component, ...rest }) => {
  const hasAuthAccessToken = localStorage.getItem("loginAccessToken");

  return (
    <Route
      {...rest}
      render={(props) => {
        // return hasAuthAccessToken &&
        // 	getPathAccess(window.location.pathname, rest.profileData) ? (
        return hasAuthAccessToken ? (
          <>
            <Navbar />
            <div className="middle_contain">
              <Sidebar />
              <Component {...props} />
            </div>
          </>
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const hasAuthAccessToken = localStorage.getItem("loginAccessToken");
  return (
    <Route
      {...rest}
      render={(props) => {
        return !hasAuthAccessToken ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        );
      }}
    />
  );
};

const getPathAccess = (pathRoute, data) => {
  const permissionString = pathRoute.includes("-")
    ? toCamelCase(pathRoute)
    : pathRoute.slice(0);
  if (isValidObject(data)) {
    const result = getPermission(routeObject[permissionString], data);
    return result;
  } else return true;
};

const toCamelCase = (str) => {
  let string = str
    .toLowerCase()
    .replace(/[^A-Za-z0-9]/g, " ")
    .split(" ")
    .reduce((result, word) => result + capitalize(word.toLowerCase()));
  return string.charAt(0).toLowerCase() + string.slice(1);
};

const capitalize = (str) =>
  str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);

export const routeObject = {
  calendar: "bshift.bs_can_view_calendar",
  mainInfo: "users.bs_can_view_employees",
  positions: "users.bs_can_view_employee_positions",
  restaurants: "bshift.bs_can_view_restaurants",
  managersLog: "bshift.bs_view_log_record",
  salesData: "bshift.bs_can_view_sales_check",
  schedule: "bshift.bs_can_view_schedule",
  myQuizzes: "bshift.bs_view_training",
  assignedQuizzes: "bshift.bs_view_assigned_quizzes",
  documents: "bshift.bs_can_view_training_document",
  videos: "bshift.bs_can_view_training_video",
  emp_document: "users.bs_can_view_employees_document",
};

class Routes extends Component {
  componentDidMount() {
    const loginAccessToken = localStorage.getItem("loginAccessToken");
    if (loginAccessToken) {
      this.props.fetchProfile();
    }
  }

  componentDidUpdate() {
    onMessageListener()
      .then((payload) => {
        toast.success(`${payload?.notification?.title}`);
        this.props.fetchChatCounts();
      })
      .catch((err) => console.log("failed: ", err));
  }

  render() {
    const { profileData } = this.props;
    return (
      <Router>
        {/* <Route exact={false} name="Login" path="/login" component={Login} /> */}
        <ScrollToTop />
        <Switch>
          <PublicRoute
            exact={true}
            name="Login"
            path="/login"
            component={Login}
          />
          <Route
            exact={false}
            name="Forgot Password"
            path="/forgot-password"
            component={ForgotPassword}
          />
          <AuthRouteComp
            exact={true}
            name="Profile"
            path="/profile"
            component={Profile}
          />
          <AuthRouteComp
            exact={true}
            name="Add Log"
            path="/change-password"
            component={ChangePassword}
          />
          <AuthRouteComp
            exact={false}
            name="Calendar"
            path="/calendar"
            component={CustomCalendar}
            profileData={profileData}
          />
          {/* <AuthRouteComp
            exact={false}
            name="Calendar"
            path="/calendar"
            component={Calendar}
            profileData={profileData}
          /> */}
          {profileData?.permissions.includes("bshift.bs_view_log_record") && (
            <AuthRouteComp
              exact={true}
              name="Managers Log"
              path="/managers-log"
              component={ManagersLog}
              profileData={profileData}
            />
          )}
          {profileData?.permissions.includes(
            "bshift.bs_can_view_sales_check"
          ) && (
            <AuthRouteComp
              exact={false}
              name="Sales Data"
              path="/salesData"
              component={SalesData}
              profileData={profileData}
            />
          )}
          <AuthRouteComp
            exact={true}
            name="Schedule"
            path="/schedule"
            component={Schedule}
            profileData={profileData}
          />
          {profileData?.permissions.includes(
            "bshift.bs_can_manage_schedule"
          ) && (
            <AuthRouteComp
              exact={true}
              name="Add Schedule"
              path="/schedule/:date"
              component={NewSchedule}
              profileData={profileData}
            />
          )}
          {/* <AuthRouteComp
            exact={true}
            name="Add Schedule"
            path="/schedule/:date"
            component={AddSchedule}
          /> */}
          {/* <AuthRouteComp
            exact={true}
            name="Add Schedule"
            path="/schedule/:date"
            component={CustomAddSchedule}
          /> */}
          <AuthRouteComp
            exact={false}
            name="Training"
            path="/training"
            component={MyQuizzes}
          />
          <AuthRouteComp
            exact={false}
            name="Categories"
            path="/categories"
            component={Categories}
          />
          {profileData?.permissions.includes("users.bs_can_view_employees") && (
            <AuthRouteComp
              exact={false}
              name="MainInfo"
              path="/mainInfo"
              component={MainInfo}
              profileData={profileData}
            />
          )}
          {profileData?.permissions.includes(
            "users.bs_can_view_employee_positions"
          ) && (
            <AuthRouteComp
              exact={false}
              name="Positions"
              path="/positions"
              component={Positions}
              profileData={profileData}
            />
          )}
          {profileData?.permissions.includes(
            "bshift.bs_can_view_restaurants"
          ) && (
            <AuthRouteComp
              exact={false}
              name="Restaurants"
              path="/restaurants"
              component={Restaurants}
              profileData={profileData}
            />
          )}
          {profileData?.permissions.includes(
            "bshift.bs_can_view_restaurants"
          ) && (
            <AuthRouteComp
              exact={false}
              name="Restaurantgroups"
              path="/restaurantgroups"
              component={RestaurantGroup}
              profileData={profileData}
            />
          )}
          <AuthRouteComp
            exact={false}
            name="MyQuizzes"
            path="/my-quizzes"
            component={MyQuizzes}
            profileData={profileData}
          />
          {profileData?.permissions.includes(
            "bshift.bs_can_view_training_document"
          ) && (
            <AuthRouteComp
              exact={false}
              name="Document"
              path="/documents"
              component={Documents}
              profileData={profileData}
            />
          )}
          {profileData?.permissions.includes(
            "users.bs_can_view_employees_document"
          ) && (
            <AuthRouteComp
              exact={false}
              name="EmpDocument"
              path="/employee-documents"
              component={EmployeeDocuments}
              profileData={profileData}
            />
          )}
          {profileData?.permissions.includes(
            "bshift.bs_view_assigned_quizzes"
          ) && (
            <AuthRouteComp
              exact={false}
              name="AssignedQuizzes"
              path="/assigned-quizzes"
              component={AssignedQuizzes}
              profileData={profileData}
            />
          )}
          <AuthRouteComp
            exact={false}
            name="Videos"
            path="/videos"
            component={Videos}
            profileData={profileData}
          />

          {profileData?.permissions.includes("users.bs_can_edit_employees") && (
            <AuthRouteComp
              exact={true}
              name="Add Log"
              path="/add-employee/:id"
              component={AddEditEmployee}
            />
          )}
          {profileData?.permissions.includes("users.bs_can_add_employees") && (
            <AuthRouteComp
              exact={false}
              name="Add Employee"
              path="/add-employee"
              component={AddEditEmployee}
            />
          )}

          {profileData?.permissions.includes("bshift.bs_add_log_record") && (
            <AuthRouteComp
              exact={true}
              name="Add Log"
              path="/managers-log/:restaurantId/:logId"
              component={AddEditLog}
            />
          )}
          <AuthRouteComp
            exact={true}
            name="Restaurant management"
            path="/restaurant-management"
            component={RestaurantManagement}
          />
          <AuthRouteComp
            exact={true}
            name="Chat"
            path="/chat"
            component={ChatLayout}
          />
          <AuthRouteComp
            exact={true}
            name="mychat"
            path="/mychat"
            component={Chat}
          />
          {profileData?.is_superuser && (
            <AuthRouteComp
              exact={true}
              name="Announcements Details"
              path="/announcements-details"
              component={AnnouncementsDetails}
            />
          )}
          <AuthRouteComp
            exact={true}
            name="Calculator"
            path="/calculator"
            component={Calculator}
          />
          <AuthRouteComp
            exact={true}
            name="Todo"
            path="/todo/:restaurantId"
            component={Todo}
          />
          <AuthRouteComp
            exact={true}
            name="Shift Time"
            path="/shift-time"
            component={ShiftTime}
          />
          {getPermission("bshift.bs_can_view_sales_check", profileData) && (
            <AuthRouteComp
              exact={true}
              name="Food Gallery"
              path="/food-gallery"
              component={FoodGallery}
            />
          )}
          {/* <AuthRouteComp
            exact={true}
            name="Food Gallery"
            path="/food-gallery"
            component={NewFoodGallery}
          /> */}
          <AuthRouteComp
            exact={true}
            name="Dashboard"
            path="/dashboard"
            component={Dashboard}
          />
          <Route
            exact={false}
            name="Contact Us"
            path="/contact-us"
            component={ContactUs}
          />
          <Route
            exact={false}
            name="About Us"
            path="/about-us"
            component={AboutUs}
          />
          <Route
            exact={false}
            name="Privacy Policy"
            path="/privacy-policy"
            component={PrivacyPolicies}
          />
          <Route
            exact={false}
            name="Terms & Conditions"
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Redirect to="/login" />
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profileData: state.profileData.data,
    chatUserObject: state.chatUserObject.obj,
  };
};
const mapDispatchToProps = {
  fetchProfile,
  fetchChatMessages,
  fetchChatList,
  fetchChatCounts,
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
