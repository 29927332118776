import {
	DELETE_LOG_RECORD_REQUEST,
	DELETE_LOG_RECORD_FAILURE,
	DELETE_LOG_RECORD_SUCCESS,
} from '../../types/managerLogs';
import { deleteApi } from '../api';

export const deleteLogRecordRequest = () => ({
	type: DELETE_LOG_RECORD_REQUEST,
});

export const deleteLogRecordSuccess = (data) => ({
	type: DELETE_LOG_RECORD_SUCCESS,
	data: data,
});

export const deleteLogRecordFailure = (error) => ({
	type: DELETE_LOG_RECORD_FAILURE,
	error,
});

export const deleteLogRecord = (formData) => (dispatch, getState) => {
	dispatch(deleteLogRecordRequest());

	return deleteApi(`bshift/api/logrecord/${formData.id}`)
		.then((data) => {
			dispatch(deleteLogRecordSuccess(data));
		})
		.catch((error) => {
			dispatch(deleteLogRecordFailure(error));
		});
};
