import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { isValidObject } from "../../../modules/utils";

import { fetchUsers } from "../../../actions/users/users";
import { fetchSaveDraft } from "../../../actions/training/saveDraft";
import {
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";
import Completed from "../common/Completed";

export class AssignedQuizModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quizDataFields: [],
    };
  }

  componentDidMount() {
    this.props.fetchUsers();
    this.setState({ quizDataFields: this.props.quizData?.fields || [] });
  }

  handleChange = (id, e) => {
    const { fields } = this.props.quizData;
    const fieldId = e.target.name.split("_");

    const updatedVal = fields.map((data) => {
      if (data.id === fieldId[1]) {
        data.answer = e.target.value;
      }
      return data;
    });

    this.setState({ quizDataFields: updatedVal });
  };

  handleRating = (id, e) => {
    const { fields } = this.props.quizData;
    const fieldId = e.target.name.split("_");

    const updatedVal = fields.map((data) => {
      if (data.id === fieldId[1]) {
        data.rating = parseInt(e.target.value);
      }
      return data;
    });

    this.setState({ quizDataFields: updatedVal });
  };
  handleManagerNote = (id, e) => {
    const { fields } = this.props.quizData;
    const fieldId = e.target.name.split("_");

    const updatedVal = fields.map((data) => {
      if (data.id === fieldId[1]) {
        data.manager_note = e.target.value;
      }
      return data;
    });

    this.setState({ quizDataFields: updatedVal });
  };

  getDisabled = () => {
    const { fields } = this.props.quizData;
    const result = fields?.find((d) => d.rating === null);
    if (result) {
      return true;
    }
    return false;
  };

  handleSubmit = (flag) => {
    const { quizData } = this.props;
    const apiVal = {
      ...quizData,
      fields: this.state.quizDataFields,
      quiz_status: flag ? "Completed" : "Manager Draft",
    };
    this.props.fetchSaveDraft(quizData?.id, apiVal).then(() => {
      const { getList, toggle } = this.props;
      getList();
      toggle();
      this.setState({
        quizDataFields: [],
      });
    });
  };

  handleCancel = () => {
    this.setState(
      {
        quizDataFields: [],
      },
      this.props.toggle
    );
  };

  render() {
    const { isOpen, toggle, usersData, quizData, values } = this.props;
    const { quizDataFields } = this.state;

    const created_by =
      isValidObject(usersData) &&
      usersData?.results.find((d) => {
        if (d.id === quizData?.created_by) return d;
      });
    const employee =
      isValidObject(usersData) &&
      usersData?.results.find((d) => {
        if (d.id === quizData?.employee) return d;
      });
    return (
      <Modal isOpen={isOpen} centered className="add_quizzes_model">
        <ModalHeader>
          <h5 className="modal-title" id="exampleModalLabel">
            Quizzes
          </h5>
        </ModalHeader>
        <ModalBody>
          <div className="body_header">
            <p>
              <b>Created:</b>{" "}
              {moment(quizData?.created).format("MM/DD/YYYY, hh:mm")},
              {created_by?.full_name || ""}
            </p>
            <p>
              <b>Employee:</b> {employee?.full_name || ""}
              {moment(quizData?.due_date).format("MM/DD/YYYY, hh:mm")}
            </p>
          </div>
          <div>
            <p style={{ fontSize: "20px" }}>
              <b>Quiz Questions:</b>
            </p>
          </div>

          {quizData?.quiz_status === "Awaiting" ||
          quizData?.quiz_status === "Employee Draft" ||
          quizData?.quiz_status === "Completed" ? (
            <div className="quiz_accordion card accordion_card">
              {quizData?.quiz_status === "Awaiting" && (
                <Completed
                  quizData={quizData}
                  quizDataFields={quizDataFields}
                />
              )}
              {quizData?.quiz_status === "Completed" && (
                <Completed
                  quizData={quizData}
                  quizDataFields={quizDataFields}
                  flag={true}
                />
              )}
              {quizData?.quiz_status === "Employee Draft" && (
                <Completed
                  quizData={quizData}
                  quizDataFields={quizDataFields}
                />
              )}
            </div>
          ) : (
            <>
              <div className="quiz_accordion card accordion_card">
                {quizDataFields?.map((d) => {
                  return (
                    <div key={d.id}>
                      <div className="d-flex">
                        {quizData?.quiz_status === "Manager Draft" &&
                          (d.rating === 5 ? (
                            <h6 style={{ color: "green" }}>
                              <FaCheckCircle />
                            </h6>
                          ) : (
                            <h6 style={{ color: "red" }}>
                              <FaTimesCircle />
                            </h6>
                          ))}
                        <label
                          className={
                            quizData?.quiz_status === "Manager Draft"
                              ? "tab-label ml-2"
                              : "tab-label"
                          }
                          htmlFor="rd1"
                        >
                          {d.title}
                        </label>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">
                          Answer
                        </label>
                        <textarea
                          className="form-control"
                          // rows="3"
                          value={d?.answer}
                          disabled
                        />
                      </div>
                      <div className="form-group select_body">
                        <label htmlFor="exampleFormControlSelect1">
                          Select Rating
                        </label>
                        <select
                          name={`rating_${d.id}`}
                          className="form-control"
                          value={d.rating}
                          onChange={(e) => this.handleRating(d.id, e)}
                        >
                          <option value="">Select Rating</option>
                          <option value={5}>Correct</option>
                          <option value={0}>Not Correct</option>
                        </select>
                        <div className="form-group">
                          <label htmlFor="exampleFormControlTextarea1">
                            Manager Note
                          </label>
                          <textarea
                            className="form-control"
                            name={`note_${d.id}`}
                            rows="2"
                            value={d.manager_note}
                            onChange={(e) => this.handleManagerNote(d.id, e)}
                          >
                            {d.manager_note}
                          </textarea>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <div className="record_btn" onClick={this.handleCancel}>
            <button>CANCEL</button>
          </div>
          {(quizData?.quiz_status === "Pending" ||
            quizData?.quiz_status === "Manager Draft") && (
            <>
              <div
                className="record_btn"
                onClick={() => this.handleSubmit(false)}
              >
                <button>SAVE DRAFT</button>
              </div>
              <div
                className={!this.getDisabled() ? "record_btn" : "cancle_btn"}
                disabled={this.getDisabled()}
                onClick={() => this.handleSubmit(true)}
              >
                <button style={{ color: "gray" }}>SUBMIT</button>
              </div>
            </>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usersDataLoading: state.usersData.isLoading,
    usersData: state.usersData.data,

    saveDraftDataLoading: state.saveDraftData.isLoading,
    saveDraftData: state.saveDraftData.data,
  };
};
const mapDispatchToProps = {
  fetchUsers,
  fetchSaveDraft,
};
export default connect(mapStateToProps, mapDispatchToProps)(AssignedQuizModal);
