import {
  FETCH_SCHEDULE_REQUEST,
  FETCH_SCHEDULE_SUCCESS,
  FETCH_SCHEDULE_FAILURE,
} from "../../types/schedule";
import { getApi } from "../api";

export const fetchScheduleRequest = () => ({
  type: FETCH_SCHEDULE_REQUEST,
});

export const fetchScheduleSuccess = (date, data) => ({
  type: FETCH_SCHEDULE_SUCCESS,
  data: data,
  date,
});

export const fetchScheduleFailure = (error) => ({
  type: FETCH_SCHEDULE_FAILURE,
  error,
});

export const fetchSchedule = (data) => (dispatch, getState) => {
  dispatch(fetchScheduleRequest());
  let { date, category, group, restaurant, viewBy } = data;

  let queryParams = [];
  if (category !== undefined) {
    queryParams = [...queryParams, `category=${category}`];
  }
  if (group !== undefined) {
    queryParams = [...queryParams, `group=${group}`];
  }
  if (restaurant !== undefined) {
    queryParams = [...queryParams, `restaurant=${restaurant}`];
  }

  let url =
    viewBy != "week"
      ? `bshift/api/schedule_day/${date}`
      : `bshift/api/schedule/${date}`;

  if (viewBy == "week") {
    url += "/6";
  }

  return getApi(`${url}?${queryParams.join("&")}`)
    .then((data) => {
      dispatch(fetchScheduleSuccess(date, data));
    })
    .catch((error) => {
      dispatch(fetchScheduleFailure(error));
    });
};
