import React, { Component } from "react";
import { FaAlignJustify, FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  AiFillDashboard,
  AiFillCalendar,
  AiFillSchedule,
  AiFillFileImage,
  AiFillCalculator,
} from "react-icons/ai";
import { FaBullhorn } from "react-icons/fa";
import { MdQuiz, MdNoteAlt, MdOutlineImage, MdFoodBank } from "react-icons/md";
import {
  BsFillPeopleFill,
  BsJournals,
  BsInfoCircleFill,
  BsFillFileEarmarkTextFill,
  BsCollectionPlayFill,
  BsFillChatLeftTextFill,
  BsFillGridFill,
  BsFillFileBarGraphFill,
} from "react-icons/bs";
import { FaCaretDown } from "react-icons/fa";

import { fetchProfile } from "../../actions/profile/profile";
import { getPermission } from "../../modules/utils";

class MobileMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      isVisible: false,
      isEmployee: false,
      isTraining: false,
    };
  }

  componentDidMount = () => {
    this.updateWindowDimensions();

    window.addEventListener("resize", this.updateWindowDimensions);
    this.props.fetchProfile();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions);
  };

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  handleClick = () => {
    this.setState({
      isVisible: !this.state.isVisible,
    });
  };
  handleEmployee = () => {
    this.setState({
      isEmployee: !this.state.isEmployee,
    });
  };
  handleTraining = () => {
    this.setState({
      isTraining: !this.state.isTraining,
      isEmployee: false,
    });
  };

  render() {
    const { width, isVisible, isEmployee, isTraining } = this.state;
    const isMobileView = width <= 1024;
    const { profileData } = this.props;

    if (isMobileView) {
      return (
        <div className="side_menu_1">
          <div
            id="side_button"
            className="side_menu_list_1 "
            style={{ height: 50, width: 50 }}
          >
            <h2 style={{ color: "white" }}>
              <FaAlignJustify onClick={this.handleClick} />
            </h2>

            {isVisible && (
              <>
                <div className="toggle_sidebar">
                  <ul className="pl-2">
                    <li>
                      <Link to="/dashboard" onClick={this.handleClick}>
                        <AiFillDashboard className="d-inline mx-3 sidebar-icon" />
                        {/* <i className="zmdi zmdi-palette"></i> */}
                        Dashboard
                      </Link>
                    </li>
                    {getPermission(
                      "bshift.bs_can_view_calendar",
                      profileData
                    ) && (
                      <li>
                        <Link to="/calendar" onClick={this.handleClick}>
                          <AiFillCalendar className="d-inline mx-3 sidebar-icon" />
                          {/* <i className="zmdi zmdi-palette"></i> */}
                          Calendar
                        </Link>
                      </li>
                    )}

                    <li onClick={this.handleEmployee}>
                      <Link to="/mainInfo">
                        <FaCaretDown className="d-inline mx-3 sidebar-icon" />
                        {/* <i className="zmdi zmdi-email"></i>  */}
                        Employees
                      </Link>
                      {isEmployee && (
                        <ul className="sub_menu2">
                          {getPermission(
                            "users.bs_can_view_employees",
                            profileData
                          ) && (
                            <li>
                              <Link
                                to="/mainInfo"
                                className="menu_sub_left"
                                onClick={this.handleClick}
                              >
                                <BsInfoCircleFill className="d-inline mx-3 sidebar-icon" />
                                Main info
                              </Link>
                            </li>
                          )}

                          <li>
                            <Link
                              to="/categories"
                              className="menu_sub_left"
                              onClick={this.handleClick}
                            >
                              <BsFillGridFill className="d-inline mx-3 sidebar-icon" />
                              Categories
                            </Link>
                          </li>
                          {getPermission(
                            "users.bs_can_view_employee_positions",
                            profileData
                          ) && (
                            <li>
                              <Link
                                to="/positions"
                                className="menu_sub_left"
                                onClick={this.handleClick}
                              >
                                <BsFillPeopleFill className="d-inline mx-3 sidebar-icon" />
                                Positions
                              </Link>
                            </li>
                          )}
                          {getPermission(
                            "bshift.bs_can_view_restaurants",
                            profileData
                          ) && (
                            <li>
                              <Link
                                to="/restaurants"
                                className="menu_sub_left"
                                onClick={this.handleClick}
                              >
                                <MdFoodBank className="d-inline mx-3 sidebar-icon" />
                                Restaurant
                              </Link>
                            </li>
                          )}
                          {getPermission(
                            "bshift.bs_can_view_restaurants",
                            profileData
                          ) && (
                            <li>
                              <Link
                                to="/restaurantgroups"
                                className="menu_sub_left"
                              >
                                <MdFoodBank className="d-inline mx-3 sidebar-icon" />
                                Restaurant Groups
                              </Link>
                            </li>
                          )}
                        </ul>
                      )}
                    </li>
                    {getPermission(
                      "bshift.bs_view_log_record",
                      profileData
                    ) && (
                      <li>
                        <Link to="/managers-log" onClick={this.handleClick}>
                          {/* <i className="zmdi zmdi-assignment"></i> */}
                          <MdNoteAlt className="d-inline mx-3 sidebar-icon" />
                          Managers Log
                        </Link>
                      </li>
                    )}
                    {getPermission(
                      "bshift.bs_can_view_sales_check",
                      profileData
                    ) && (
                      <li>
                        <Link to="/food-gallery" onClick={this.handleClick}>
                          {/* <i className="zmdi zmdi-assignment"></i>  */}
                          <MdOutlineImage className="d-inline mx-3 sidebar-icon" />
                          Gallery
                          {/* Food Section */}
                        </Link>
                      </li>
                    )}
                    {getPermission(
                      "bshift.bs_can_view_sales_check",
                      profileData
                    ) && (
                      <li>
                        <Link to="/salesData" onClick={this.handleClick}>
                          {/* <i className="zmdi zmdi-rss"></i>  */}
                          <BsFillFileBarGraphFill className="d-inline mx-3 sidebar-icon" />
                          Sales Data
                        </Link>
                      </li>
                    )}

                    {getPermission(
                      "bshift.bs_can_view_schedule",
                      profileData
                    ) && (
                      <li>
                        <Link to="/schedule" onClick={this.handleClick}>
                          {/* <i className="zmdi zmdi-open-in-browser"></i> */}
                          <AiFillSchedule className="d-inline mx-3 sidebar-icon" />
                          Schedule
                        </Link>
                      </li>
                    )}

                    {getPermission("bshift.bs_view_training", profileData) && (
                      <li onClick={this.handleTraining}>
                        <Link to="/my-quizzes">
                          {/* <i className="zmdi zmdi-email"></i>  */}
                          <FaCaretDown className="d-inline mx-3 sidebar-icon" />
                          Training
                        </Link>
                        {isTraining && (
                          <ul className="sub_menu2">
                            <li>
                              <Link
                                to="/my-quizzes"
                                className="menu_sub_left"
                                onClick={this.handleClick}
                              >
                                <MdQuiz className="d-inline mx-3 sidebar-icon" />
                                My Quizzes
                              </Link>
                            </li>
                            {getPermission(
                              "bshift.bs_view_assigned_quizzes",
                              profileData
                            ) && (
                              <li>
                                <Link
                                  to="/assigned-quizzes"
                                  className="menu_sub_left"
                                  onClick={this.handleClick}
                                >
                                  <MdQuiz className="d-inline mx-3 sidebar-icon" />
                                  Assigned Quizzes
                                </Link>
                              </li>
                            )}

                            {getPermission(
                              "bshift.bs_can_view_training_document",
                              profileData
                            ) && (
                              <li>
                                <Link
                                  to="/documents"
                                  className="menu_sub_left"
                                  onClick={this.handleClick}
                                >
                                  {" "}
                                  <BsFillFileEarmarkTextFill className="d-inline mx-3 sidebar-icon" />
                                  Document
                                </Link>
                              </li>
                            )}
                            {getPermission(
                              "bshift.bs_can_view_training_video",
                              profileData
                            ) && (
                              <li>
                                <Link
                                  to="/videos"
                                  className="menu_sub_left"
                                  onClick={this.handleClick}
                                >
                                  <BsCollectionPlayFill className="d-inline mx-3 sidebar-icon" />
                                  Videos
                                </Link>
                              </li>
                            )}

                            {getPermission(
                              "users.bs_can_view_employees_document",
                              profileData
                            ) && (
                              <li>
                                <Link
                                  to="/employee-documents"
                                  className="menu_sub_left"
                                  onClick={this.handleClick}
                                >
                                  <BsFillFileEarmarkTextFill className="d-inline mx-3 sidebar-icon" />
                                  Employee Documents
                                </Link>
                              </li>
                            )}
                          </ul>
                        )}
                      </li>
                    )}

                    <li>
                      <Link
                        to="/restaurant-management"
                        onClick={this.handleClick}
                      >
                        {/* <i className="zmdi zmdi-open-in-browser"></i>  */}
                        <BsFillGridFill className="d-inline mx-3 sidebar-icon" />
                        Management
                      </Link>
                    </li>

                    <li>
                      <Link to="/chat" onClick={this.handleClick}>
                        {/* <i className="zmdi zmdi-open-in-browser"></i>  */}
                        <BsFillChatLeftTextFill className="d-inline mx-3 sidebar-icon" />
                        Chat
                      </Link>
                    </li>
                    {profileData?.is_superuser && (
                      <li>
                        <Link
                          to="/announcements-details"
                          onClick={this.handleClick}
                        >
                          <FaBullhorn
                            onClick={this.handleDock}
                            data-tip="Announcements"
                            data-for="announcement-tip"
                            className="d-inline mx-3 sidebar-icon"
                          />
                          Announcements
                        </Link>
                      </li>
                    )}
                    {getPermission(
                      "bshift.bs_view_log_record",
                      profileData
                    ) && (
                      <li>
                        <Link to="/calculator">
                          <AiFillCalculator
                            // onClick={this.handleDock}
                            data-tip="Calculator"
                            data-for="calculator-tip"
                            className="d-inline mx-3 sidebar-icon"
                          />
                          Calculator
                        </Link>
                      </li>
                    )}
                  </ul>

                  <div className="close_icon1">
                    <h4 style={{ color: "#a7b1c2" }}>
                      <FaTimes onClick={this.handleClick} />
                    </h4>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    profileData: state.profileData.data,
  };
};
const mapDispatchToProps = {
  fetchProfile,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
