import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Gallery from "react-grid-gallery";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import Select from "react-select";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchNewFoodGallery } from "../../actions/foodGallery/newFoodGallery";
import { isValidArray } from "../../modules/utils";
import moment from "moment";

//import foodGallery from '../../modules/foodgallary.json';

const FoodGallery = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [imageData, setImageDate] = useState({});

  const [finalGalleryData, setFinalGallerData] = useState([]);

  const restaurantsData = useSelector((state) => state.restaurantsData.data);
  const restaurantsisLoading = useSelector(
    (state) => state.restaurantsData.isLoading
  );

  const foodGalleryData = useSelector((state) => state.newFoodGalleryData.data);
  const foodGalleryisLoading = useSelector(
    (state) => state.newFoodGalleryData.isLoading
  );

  const [optionRestaurant, setOptionRestaurant] = useState([]);
  const [selectedRestaurant, setSelectedRestaurant] = useState(
    restaurantsData?.results[0]?.id
  );

  useEffect(() => {
    dispatch(fetchRestaurants());
  }, []);

  const [paramsData, setParamsData] = useState({
    limit: 1,
    offset: 0,
    restaurant: null,
  });

  useEffect(() => {
    const optionRestaurant =
      restaurantsData &&
      restaurantsData?.results.map((d) => {
        return {
          value: d.id,
          label: d.title,
        };
      });
    setOptionRestaurant(optionRestaurant);
    setSelectedRestaurant(
      isValidArray(optionRestaurant) ? optionRestaurant[0] : []
    );

    if (restaurantsData !== null) {
      setParamsData((prev) => {
        return {
          ...prev,
          restaurant: restaurantsData?.results[0]?.id,
        };
      });
    }
  }, [restaurantsData]);

  useEffect(() => {
    if (paramsData?.restaurant !== null) {
      dispatch(fetchNewFoodGallery(paramsData, false));
    }
  }, [paramsData?.restaurant]);

  useEffect(() => {
    if (foodGalleryData?.results.length > 0) {
      const editorImageData = foodGalleryData?.results[0]?.editor_images?.map(
        (item) => {
          const data = {
            image: item,
            caption: moment(foodGalleryData?.results[0]?.created).format(
              "MM/DD/yyyy"
            ),
          };

          return data;
        }
      );
      foodGalleryData?.results[0]?.files?.forEach((item) => {
        const data = {
          image: item,
          caption: moment(foodGalleryData?.results[0]?.created).format(
            "MM/DD/yyyy"
          ),
        };
        editorImageData.push(data);
      });
      const finalresult = editorImageData?.map((res) => {
        return {
          src: res?.image,
          thumbnail: res?.image,
          thumbnailWidth: 350,
          thumbnailHeight: 180,
          caption: res?.caption,
        };
      });

      if (finalresult?.length === 0) {
        let newParams = paramsData;
        newParams.offset = newParams.offset + 1;
        setParamsData(newParams);
        dispatch(fetchNewFoodGallery(newParams));
      }

      setFinalGallerData(finalresult);
    } else {
      setFinalGallerData([]);
    }
  }, [foodGalleryData]);

  const handleChangeRestaurant = (restaurant) => {
    setSelectedRestaurant(restaurant);
    setParamsData((prev) => {
      return {
        ...prev,
        restaurant: restaurant.value,
        offset: 0,
      };
    });
  };

  const previousHandler = () => {
    let newParams = paramsData;
    newParams.offset = newParams.offset - 1;
    setParamsData(newParams);
    dispatch(fetchNewFoodGallery(newParams));
  };

  const nextHandler = () => {
    let newParams = paramsData;
    newParams.offset = newParams.offset + 1;
    setParamsData(newParams);
    dispatch(fetchNewFoodGallery(newParams));
  };

  return (
    <div className="main_box">
      <div className="pull_left full_section_details">
        <div className="main_container">
          <div className="wrapper">
            <div className="container-fluid">
              <div className="row mt-5  justify-content-between page_title">
                <div className="col-md-6">
                  <h2>Gallery</h2>
                </div>
                <div className="col-md-6">
                  <div className="main_info_select">
                    <Select
                      options={optionRestaurant}
                      value={selectedRestaurant}
                      onChange={handleChangeRestaurant}
                      placeholder="Select Restaurant"
                      className="form_select "
                      menuShouldBlockScroll={true}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column">
                <div className="mt-5 d-flex flex-column">
                  {foodGalleryisLoading ? (
                    <div className="justify-content-center d-block text-center">
                      <h5 className="text-center mt-3 mb-3">
                        <Loader
                          type="TailSpin"
                          color="#38425B"
                          // color="#2196f3"
                          height="100"
                          width="100"
                        />
                      </h5>
                    </div>
                  ) : (
                    isValidArray(finalGalleryData) && (
                      <Gallery
                        images={finalGalleryData}
                        enableImageSelection={false}
                        margin={7}
                        backdropClosesModal={true}
                      />
                    )
                  )}
                  {finalGalleryData &&
                    !foodGalleryisLoading &&
                    Object.keys(finalGalleryData).length === 0 && (
                      <h1>No images available for this restaurant.</h1>
                    )}
                </div>
                <div className="my-5">
                  <nav aria-label="...">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          !foodGalleryData?.previous && "disabled"
                        }`}
                      >
                        <button
                          className={`page-link `}
                          tabIndex="-1"
                          onClick={previousHandler}
                        >
                          Previous
                        </button>
                      </li>
                      <li
                        className={`page-item ${
                          !foodGalleryData?.next && "disabled"
                        }`}
                      >
                        <button className={`page-link `} onClick={nextHandler}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodGallery;
