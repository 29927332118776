import {
	FETCH_GROUPS_REQUEST,
	FETCH_GROUPS_SUCCESS,
	FETCH_GROUPS_FAILURE,
} from '../../types/groups';

const groupsReducer = (
	state = {
		data: null,
		error: null,
		isLoading: false,
	},
	action,
) => {
	switch (action.type) {
		case FETCH_GROUPS_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_GROUPS_SUCCESS:
			return {
				...state,
				isLoading: false,
				data: action.data.data,
				error: null,
			};
		case FETCH_GROUPS_FAILURE:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};
		default:
			return state;
	}
};

export default groupsReducer;
