import React, { Component } from "react";
import { Formik } from "formik";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import moment from "moment";
import DataTable from "react-data-table-component";
import CircularProgress from "@material-ui/core/CircularProgress";

import { profileValidation, displayFormErrors } from "../../modules/validation";
import userImgPlaceHolder from "../../assets/css/images/user_placeholder.png";
import { customStyles, fileToBase64, isValidObject } from "../../modules/utils";

import { fetchProfile } from "../../actions/profile/profile";
import { fetchGroups } from "../../actions/groups/groups";
import { fetchRestaurants } from "../../actions/restaurants/restaurants";
import { fetchEditProfile } from "../../actions/profile/edit";
import { fetchProfileAssignedQuiz } from "../../actions/training/profileAssignedQuiz";
import { fetchRestaurantGroups } from "../../actions/restaurantGroups/fetchRestaurantGroups";
import { fetchSingleUser } from "../../actions/users/singleUser";
import { getPermission } from "../../modules/utils";

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      search: "",
      itemsPerPage: 10,
      imageSrc: "",
      sort_field: "title",
      sort_type: false,
    };
  }

  componentDidMount() {
    this.props.fetchProfile().then(() => {
      this.getInitialValue();
    });
    this.props.fetchGroups();
    this.props.fetchRestaurants();
    this.getAssignedQuiz();
    this.props.fetchRestaurantGroups();
    this.props.fetchSingleUser(localStorage.getItem("loggedUserId"));
  }

  onHandleSubmit = (values) => {
    const { profileData, fetchEditProfile } = this.props;
    const id = localStorage.getItem("loggedUserId");

    const apiVal = {
      ...profileData,
      address: values.address,
      avatar: values.imageSrc,
      birthday: moment(values.birthday).format("YYYY-MM-DD"),
      hire_date: moment(values.hire_date).format("YYYY-MM-DD"),
      city: values.city,
      phone: values.phone,
      first_name: values.firstName,
      last_name: values.lastName,
      zipcode: values.zip,
      state: values.state,
      email: values.email,
      toggle: values.toggle,
    };

    fetchEditProfile(apiVal, id).then(() => {
      this.props.fetchProfile();
    });
  };

  getAssignedQuiz = () => {
    const { page, itemsPerPage, sort_field, sort_type } = this.state;
    const id = localStorage.getItem("loggedUserId");
    return this.props.fetchProfileAssignedQuiz(
      page,
      itemsPerPage,
      sort_field,
      sort_type,
      "Completed",
      id
    );
  };

  onChangeFile = (event, setFieldValue) => {
    fileToBase64(event).then((res) => {
      setFieldValue("imageSrc", res.file);
    });
  };

  clearImg = (setFieldValue) => {
    setFieldValue("imageSrc", "");
  };

  handleBirthDateChange = (date, setFieldValue) => {
    setFieldValue("birthday", date);
  };

  handleHireDateChange = (date, setFieldValue) => {
    setFieldValue("hire_date", date);
  };

  handlePageChange = (page) => {
    this.setState(
      {
        page,
      },
      () => {
        this.getAssignedQuiz();
      }
    );
  };

  handleRowPerPage = (itemsPerPage) => {
    this.setState(
      {
        itemsPerPage,
      },
      () => {
        this.getAssignedQuiz();
      }
    );
  };

  handleSort = (e) => {
    this.setState(
      {
        sort_field: e.selector,
        sort_type: !this.state.sort_type,
      },
      () => {
        // this.getAssignedQuiz();
      }
    );
  };

  handleview = (attachments) => {
    window.open(attachments, "_blank").focus();
  };

  getInitialValue = () => {
    const { profileData } = this.props;
    return {
      firstName: profileData?.first_name || "",
      lastName: profileData?.last_name || "",
      email: profileData?.email || "",
      phone: profileData?.phone || "",
      address: profileData?.address || "",
      city: profileData?.city || "",
      state: profileData?.state || "",
      zip: profileData?.zipcode || "",
      imageSrc: profileData?.avatar || "",
      birthday: profileData?.birthday || null,
      hire_date: profileData?.hire_date || null,
      toggle: profileData?.toggle || false,
    };
  };

  getUserListing = () => {
    const { profileAssignedData, restaurantsData, usersData } = this.props;

    const newS = profileAssignedData?.results?.map((d) => {
      const restaurant = restaurantsData?.results.find((i) => {
        if (i.id === d.restaurant) return i;
      });

      return {
        id: d.id || "",
        title: d.title || "",
        restaurant: restaurant?.title || "",
        completed_date: d.completed_date
          ? moment(d.completed_date).format("MM/DD/YYYY, hh:mm")
          : "",
        rating: d.rating || "",
      };
    });
    return newS;
  };

  getDocListing = () => {
    const { singleUserData } = this.props;
    return singleUserData?.more_data;
  };

  getTableColumns = () => {
    return [
      {
        name: "TITLE",
        selector: (row) => row["title"],
        // selector: "title",
        sortable: true,
      },
      {
        name: "RESTAURANT",
        selector: (row) => row["restaurant"],
        // selector: "restaurant",
        sortable: true,
      },

      {
        name: "COMPLETED",
        selector: (row) => row["completed_date"],
        // selector: "completed_date",
        sortable: true,
      },

      {
        name: "RATING%",
        selector: (row) => row["rating"],
        // selector: "rating",
        sortable: true,
      },
    ];
  };

  getDocumentCoulmns = () => {
    return [
      {
        name: "File Type",
        selector: (row) => row["file_type"],
        // selector: "title",
        sortable: true,
      },
      {
        name: "Action",
        selector: (row) => row.actions,
        cell: (row) => (
          <React.Fragment>
            <span className="d-flex">
              {
                <button
                  value={row.id}
                  className="btn sm btn-outline-primary btn-sm"
                  onClick={() => this.handleview(row.attachment)}
                >
                  View
                </button>
              }
            </span>
          </React.Fragment>
        ),
      },
    ];
  };

  render() {
    const {
      profileData,
      groupsData,
      restaurantsData,
      profileDataLoading,
      restaurantsDataLoading,
      groupsDataLoading,
      profileAssignedData,
      profileAssignedDataLoading,
      singleUserDataLoading,
      singleUserData,
    } = this.props;
    const position = isValidObject(groupsData)
      ? profileData?.groups.map((d) => {
          const value = groupsData?.results.find((i) => i.id === d);
          return value.name;
        })
      : [];
    const restaurant = isValidObject(restaurantsData)
      ? profileData?.restaurants.map((d) => {
          const value = restaurantsData?.results.find((i) => i.id === d);
          return value.title;
        })
      : [];

    const restaurantBrand = isValidObject(restaurantsData)
      ? profileData?.restaurants_brand?.map((d) => {
          return d.brand_name;
        })
      : "";

    const totalRestaurants = this.props.restaurantsData?.results?.map(
      (d) => d.title
    );

    const totalBrands = this.props.restaurantGroupsData?.results?.map(
      (d) => d?.brand_name
    );

    const paginationTotalRows = profileAssignedData?.count;
    const initialValues = this.getInitialValue();
    if (
      profileDataLoading ||
      restaurantsDataLoading ||
      groupsDataLoading ||
      profileAssignedDataLoading
    ) {
      return (
        <div className="main_box">
          <div className="pull_left full_section_details">
            <div className="main_container">
              <div className="wrapper">
                <div
                  className="container-fluid mt-5"
                  style={{ marginLeft: "50%" }}
                >
                  <CircularProgress />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="main_box">
          {/* <Navbar />
				<div className="middle_contain ">
					<Sidebar /> */}
          <div className="pull_left full_section_details">
            <div className="main_container">
              <div className="wrapper">
                <div className="container-fluid">
                  <div className="">
                    <div className="dashboard Profile_title row mt-5">
                      <h2>
                        <i className="zmdi zmdi-long-arrow-left"></i>My Profile{" "}
                        {profileData?.is_superuser && (
                          <span className="text-primary">(Superuser)</span>
                        )}
                      </h2>
                    </div>
                    <div className="user_content personal_detail employee_detail">
                      <Formik
                        onSubmit={this.onHandleSubmit}
                        initialValues={initialValues}
                        validationSchema={profileValidation}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          submitCount,
                          setFieldValue,
                        }) => {
                          const showError = (key) =>
                            displayFormErrors(
                              key,
                              errors,
                              touched,
                              submitCount
                            );

                          const img = values.imageSrc
                            ? values.imageSrc
                            : userImgPlaceHolder;
                          // const img = userImgPlaceHolder;

                          return (
                            <form onSubmit={handleSubmit} className="p-3">
                              <div className="row">
                                <div className="col-lg-4 col-md-12 ">
                                  <div className="per_detail">
                                    <div className="pull_left">
                                      <div className="upload_user_photo float-left">
                                        <input
                                          type="file"
                                          id="file-btn4"
                                          className="d-none"
                                          ref={(ref) => (this.upload = ref)}
                                          onChange={(e) => {
                                            this.onChangeFile(e, setFieldValue);
                                          }}
                                          accept="image/*"
                                        />
                                        <img
                                          src={img}
                                          width="100px"
                                          height="100px"
                                          alt=""
                                          className="img-fluid profile_img"
                                        />
                                      </div>
                                    </div>
                                    <div className="pull_right avatar_link">
                                      <div
                                        onClick={() => {
                                          this.upload.click();
                                        }}
                                      >
                                        Edit Avatar
                                      </div>
                                      <div
                                        type="reset"
                                        onClick={() =>
                                          this.clearImg(setFieldValue)
                                        }
                                      >
                                        Clear Avatar
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-4 col-md-12 d-flex justify-content-left align-items-center">
                                  {getPermission(
                                    "users.bs_can_edit_employees",
                                    profileData
                                  ) && (
                                    <div className="custom-control custom-switch manager-logs-switch">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id="toggle"
                                        value={values.toggle}
                                        onChange={handleChange}
                                        name="toggle"
                                        defaultChecked={values.toggle}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="toggle"
                                      >
                                        Daily emails of Managers Log
                                      </label>
                                    </div>
                                  )}
                                </div>
                                <div className="col-lg-4 col-md-12"></div>
                                <div className="col-lg-4 col-md-12 mb-4">
                                  <label className="custom_input_label_form">
                                    Phone :
                                  </label>
                                  <input
                                    value={values.phone}
                                    onChange={handleChange}
                                    type="text"
                                    name="phone"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mb-4">
                                  <label className="custom_input_label_form">
                                    First Name :
                                  </label>
                                  <input
                                    type="text"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    name="firstName"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mb-4">
                                  <label className="custom_input_label_form">
                                    Last Name :
                                  </label>
                                  <input
                                    value={values.lastName}
                                    onChange={handleChange}
                                    type="text"
                                    name="lastName"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 detail_address mb-4">
                                  <label className="custom_input_label_form">
                                    Email Address :
                                  </label>
                                  {/* <input
                                    value={values.email}
                                    onChange={handleChange}
                                    type="email"
                                    name="email"
                                  /> */}
                                  <p>{values.email}</p>
                                  {showError("email")}
                                </div>

                                <div className="col-lg-4 col-md-12  emp_bday mb-4">
                                  <label className="custom_input_label_form">
                                    Birthday :
                                  </label>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      // label="DateTimePicker"
                                      autoOk
                                      variant="inline"
                                      format="MM/dd/yyyy"
                                      // InputAdornmentProps={{
                                      //   position: "start",
                                      // }}
                                      inputVariant="outlined"
                                      value={values.birthday}
                                      name="birthday"
                                      onChange={(birthDate) =>
                                        this.handleBirthDateChange(
                                          birthDate,
                                          setFieldValue
                                        )
                                      }
                                      maxDate={new Date()}
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-lg-4 col-md-12  emp_bday mb-4">
                                  <label className="custom_input_label_form">
                                    Hire Date :
                                  </label>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                      autoOk
                                      variant="inline"
                                      format="MM/dd/yyyy"
                                      // InputAdornmentProps={{
                                      //   position: "start",
                                      // }}
                                      // label="DateTimePicker"
                                      inputVariant="outlined"
                                      value={values.hire_date}
                                      name="hire_date"
                                      onChange={(hireDate) =>
                                        this.handleHireDateChange(
                                          hireDate,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-lg-4 col-md-12 mb-4">
                                  <label className="custom_input_label_form">
                                    Address :
                                  </label>
                                  <input
                                    value={values.address}
                                    onChange={handleChange}
                                    type="text"
                                    name="address"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mb-4">
                                  <label className="custom_input_label_form">
                                    City :
                                  </label>
                                  <input
                                    value={values.city}
                                    onChange={handleChange}
                                    type="text"
                                    name="city"
                                  />
                                </div>
                                <div className="col-lg-4 col-md-12 mb-4">
                                  <label className="custom_input_label_form">
                                    State :
                                  </label>
                                  <input
                                    value={values.state}
                                    onChange={handleChange}
                                    type="text"
                                    name="state"
                                  />
                                </div>
                                <div className="col-lg-6 col-md-12 mb-4">
                                  <label className="custom_input_label_form">
                                    Zip Code :
                                  </label>
                                  <input
                                    value={values.zip}
                                    onChange={handleChange}
                                    type="text"
                                    name="zip"
                                  />
                                </div>
                                <div className="col-lg-6 col-md-12 mb-4">
                                  <label className="custom_input_label_form">
                                    Restaurant Group:
                                  </label>{" "}
                                  <span>
                                    {totalBrands && profileData?.is_superuser
                                      ? totalBrands.toString()
                                      : restaurantBrand
                                      ? restaurantBrand.toString()
                                      : ""}

                                    {/* {restaurantBrand
                                      ? restaurantBrand.toString()
                                      : ""} */}
                                  </span>
                                  {/* <input
                                    value={
                                      restaurantBrand ? restaurantBrand : ""
                                    }
                                    disabled={true}
                                    type="text"
                                    name="zip"
                                  /> */}
                                </div>
                                <div className="col-lg-6 col-md-12 f_name pt-1 mb-4">
                                  <div className="d-flex simple_title_table ">
                                    <p className="custom_input_label_form">
                                      Position:
                                    </p>{" "}
                                    <span className="ml-2">
                                      {position ? position.toString() : ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-12 f_name pt-1 mb-4">
                                  <div className="d-flex simple_title_table ">
                                    <p className="custom_input_label_form">
                                      Restaurants:
                                    </p>{" "}
                                    <span className="ml-2">
                                      {profileData?.is_superuser
                                        ? totalRestaurants.toString()
                                        : restaurant
                                        ? restaurant.toString()
                                        : ""}
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-12">
                                  <div className="d-flex simple_title_table ">
                                    <p className="custom_input_label_form">
                                      Salary ( $ per hour) :{" "}
                                    </p>
                                    <span className="ml-2">
                                      {" "}
                                      {profileData?.salary || ""}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-6"></div>
                                <div className="add_employee_btn">
                                  <div className="record_btn">
                                    <button type="submit">SAVE</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          );
                        }}
                      </Formik>
                    </div>

                    {/* {profileAssignedData && ( */}
                    <div className="user_content personal_detail employee_detail mt-3">
                      <DataTable
                        responsive={true}
                        striped={true}
                        progressPending={profileAssignedDataLoading}
                        columns={this.getTableColumns()}
                        data={this.getUserListing()}
                        pagination={true}
                        paginationServer={false}
                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                        paginationTotalRows={paginationTotalRows}
                        onChangePage={this.handlePageChange}
                        onChangeRowsPerPage={this.handleRowPerPage}
                        onSort={this.handleSort}
                        customStyles={customStyles}
                      />
                    </div>
                    {/* )} */}

                    <div className="user_content personal_detail employee_detail mt-3">
                      <DataTable
                        responsive={true}
                        striped={true}
                        progressPending={singleUserDataLoading}
                        columns={this.getDocumentCoulmns()}
                        data={this.getDocListing()}
                        pagination={true}
                        paginationServer={false}
                        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
                        paginationTotalRows={paginationTotalRows}
                        onChangePage={this.handlePageChange}
                        onChangeRowsPerPage={this.handleRowPerPage}
                        customStyles={customStyles}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* </div> */}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    profileDataLoading: state.profileData.isLoading,
    profileData: state.profileData.data,

    groupsDataLoading: state.groupsData.isLoading,
    groupsData: state.groupsData.data,

    restaurantsDataLoading: state.restaurantsData.isLoading,
    restaurantsData: state.restaurantsData.data,

    profileAssignedDataLoading: state.profileAssignedData.isLoading,
    profileAssignedData: state.profileAssignedData.data,

    singleUserDataLoading: state.singleUserData.isLoading,
    singleUserData: state.singleUserData.data,

    editProfileDataLoading: state.editProfileData.isLoading,
    editProfileData: state.editProfileData.data,
    restaurantGroupsData: state.restaurantGroups.data,
  };
};
const mapDispatchToProps = {
  fetchProfile,
  fetchRestaurants,
  fetchGroups,
  fetchProfileAssignedQuiz,
  fetchEditProfile,
  fetchRestaurantGroups,
  fetchSingleUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
