import { toastSuccess } from "../../modules/utils";
import {
  FETCH_CHANGE_PASSWORD_REQUEST,
  FETCH_CHANGE_PASSWORD_SUCCESS,
  FETCH_CHANGE_PASSWORD_FAILURE,
} from "../../types/profile";
import { putApi } from "../api";

export const fetchChangePasswordRequest = () => ({
  type: FETCH_CHANGE_PASSWORD_REQUEST,
});

export const fetchChangePasswordSuccess = (data) => ({
  type: FETCH_CHANGE_PASSWORD_SUCCESS,
  data: data,
});

export const fetchChangePasswordFailure = (error) => ({
  type: FETCH_CHANGE_PASSWORD_FAILURE,
  error,
});

export const fetchChangePassword = (formData) => (dispatch, getState) => {
  dispatch(fetchChangePasswordRequest());
  return putApi(`users/api/password`, formData)
    .then((data) => {
      dispatch(fetchChangePasswordSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchChangePasswordFailure(error));
    });
};
