import React from "react";
import {
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";

const Completed = ({ quizDataFields, quizData, flag }) => {
  return (
    <>
      {quizDataFields.map((d) => {
        return (
          <div key={d?.id}>
            <div className={flag ? "completed_title" : "awaiting_title"}>
              {flag &&
                (quizData?.quiz_status === "Completed" && d.rating === 5 ? (
                  <h5 style={{ color: "green" }}>
                    <FaCheckCircle />
                  </h5>
                ) : (
                  <h5 style={{ color: "red" }}>
                    <FaTimesCircle />
                  </h5>
                ))}
              <label className="tab-label" htmlFor="rd1">
                {d.title}
              </label>
            </div>
            <div className="form-group" key={d.id}>
              <label htmlFor="exampleFormControlTextarea1">Answer</label>
              <textarea
                className="form-control"
                // id={d.id}
                name={`name_${d.id}`}
                // rows="2"
                value={d.answer}
                onChange={(e) => this.handleChange(d.id, e)}
                disabled={true}
              >
                {d.answer}
              </textarea>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Completed;
