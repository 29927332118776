import {
	FETCH_LOG_RECORD_VALUE_DETAIL_REQUEST,
	FETCH_LOG_RECORD_VALUE_DETAIL_FAILURE,
	FETCH_LOG_RECORD_VALUE_DETAIL_SUCCESS,
} from '../../types/managerLogs';
import { getApi } from '../api';

export const fetchLogRecordValueDetailRequest = () => ({
	type: FETCH_LOG_RECORD_VALUE_DETAIL_REQUEST,
});

export const fetchLogRecordValueDetailSuccess = (data) => ({
	type: FETCH_LOG_RECORD_VALUE_DETAIL_SUCCESS,
	data: data,
});

export const fetchLogRecordValueDetailFailure = (error) => ({
	type: FETCH_LOG_RECORD_VALUE_DETAIL_FAILURE,
	error,
});

export const fetchLogRecordValueDetail = (formData) => (dispatch, getState) => {
	dispatch(fetchLogRecordValueDetailRequest());

	return getApi(`bshift/api/logrecord_values_detail/${formData}`)
		.then((data) => {
			dispatch(fetchLogRecordValueDetailSuccess(data));
		})
		.catch((error) => {
			dispatch(fetchLogRecordValueDetailFailure(error));
		});
};
