import {
  FETCH_CHAT_COUNT_REQUEST,
  FETCH_CHAT_COUNT_SUCCESS,
  FETCH_CHAT_COUNT_FAILURE,
} from "../../types/chat";

const chatCountReducer = (
  state = {
    data: null,
    error: null,
    isLoading: false,
    userId: "",
  },
  action
) => {
  switch (action.type) {
    case FETCH_CHAT_COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_CHAT_COUNT_SUCCESS:
      console.log("Action: ", action.data);
      return {
        ...state,
        isLoading: false,
        data: action.data.data?.unread_count,
        error: null,
      };
    case FETCH_CHAT_COUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default chatCountReducer;
