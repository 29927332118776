import { toast } from "react-toastify";
import {
  CREATE_GROUPS_REQUEST,
  CREATE_GROUPS_FAILURE,
  CREATE_GROUPS_SUCCESS,
} from "../../types/groups";
import { postApi } from "../api";

export const createGroupsRequest = () => ({
  type: CREATE_GROUPS_REQUEST,
});

export const createGroupsSuccess = (data) => ({
  type: CREATE_GROUPS_SUCCESS,
  data: data,
});

export const createGroupsFailure = (error) => ({
  type: CREATE_GROUPS_FAILURE,
  error,
});

export const createGroups = (formData) => (dispatch, getState) => {
  dispatch(createGroupsRequest());
  return postApi("users/api/groups", formData)
    .then((data) => {
      toast.success("Group created successfully!");
      dispatch(createGroupsSuccess(data));
    })
    .catch((error) => {
      dispatch(createGroupsFailure(error));
    });
};
