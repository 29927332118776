import {
  FETCH_LOAD_TEMPLATE_REQUEST,
  FETCH_LOAD_TEMPLATE_SUCCESS,
  FETCH_LOAD_TEMPLATE_FAILURE,
} from "../../types/schedule";
import { putApi } from "../api";

export const fetchLoadTemplateRequest = () => ({
  type: FETCH_LOAD_TEMPLATE_REQUEST,
});

export const fetchLoadTemplateSuccess = (data) => ({
  type: FETCH_LOAD_TEMPLATE_SUCCESS,
  data: data,
});

export const fetchLoadTemplateFailure = (error) => ({
  type: FETCH_LOAD_TEMPLATE_FAILURE,
  error,
});

export const fetchLoadTemplate = (id, data) => (dispatch, getState) => {
  dispatch(fetchLoadTemplateRequest());

  return putApi(`bshift/api/schedule_template/${id}`, data)
    .then((data) => {
      dispatch(fetchLoadTemplateSuccess(data));
    })
    .catch((error) => {
      dispatch(fetchLoadTemplateFailure(error));
    });
};
