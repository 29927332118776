import React from "react";

const Home = () => {
  return (
    // <div className="flex w-9/12 justify-center items-center">
    <div className="d-flex col-12 col-lg-7 col-xl-9 justify-content-center align-items-center items-center chat_home">
      <h2>Select a conversation</h2>
    </div>
  );
};

export default Home;
